'use strict';

/**
 * Controller for the sidenav directive
 */
class ContactFormCtrl {
  
  constructor(CustomerStore, $timeout, $element) {
    this.CustomerStore = CustomerStore;

    // model for the most recent service added (or not yet added)
    this.service = '';
    // id of the p42Selected contact
    this.selectedId = '';
    // contact to display info for
    this.contact = {};
    // list of contacts allowed to be p42Selected
    this.contacts = [];
    this.noContacts = false;
    // setup contact roles
    this.roleNames = {
      'operator': 'Operator',
      'deputy': 'Deputy Operator',
      'accredited': 'Accredited Person'
    };
    this.roleFields = {
      'operator': 'operator',
      'deputy': 'deputy_operator',
      'accredited': 'accredited_person'
    };

    // wait until the DOM has loaded
    $timeout(() => {
      // The md-select directive eats keydown events for some quick select logic.
      // Since we have a search input here, we don't need that logic.
      $element.find('md-select-header').find('input').on('keydown', function(ev) {
          ev.stopPropagation();
      });
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit () {
    if (this.p42Editable === undefined) {
      // if p42Editable was not specified default to making the contact p42Editable
      this.p42Editable = true;
    }
    if (this.p42Required === undefined) {
      // if p42Required was not specified default to making the form not p42Required
      this.p42Required = false;
    }
    if (Array.isArray(this.p42Roles)) {
      // if roles is an array then validate the array
      const keys = Object.keys(this.roleNames);
      this.p42Roles.filter((role) => {
        // for each role keep only roles defined in this.roleNames
        return keys.indexOf(role) !== -1;
      });
    } else {
      // if roles was not defined (enough) then use an empty array (meaning none)
      this.p42Roles = [];
    }
    if (this.p42Default !== undefined) {
      // if the default id is provided then select that contact
      if (this.p42Default.id) {
        // if the contact is an object then use it instead of loading it from the Hub
        this.contacts.push(this.p42Default);
        this.contact = this.p42Default;
        this.p42Default = this.p42Default.id;
        this.selectedId = this.p42Default;
      } else if (typeof this.p42Default === 'string') {
        // if the value is an ObjectId string then load the customers
        this.loadContacts().then((done) => {
          this.selectedId = this.p42Default;
          // same as select contact without sending the id
          const found = this.contacts.find((element, index, array) => {
            return element.id === this.selectedId;
          });
          if (found) {
            // only update the customer if it was found
            this.contact = found; 
          }
        });
      }
    }
  }

  /**
   * Load list of contacts
   */
  loadContacts () {
    if (!this.noContacts && this.contacts.length === 0) {
      return new Promise ((resolve, reject) => {
        let query = { _id: this.p42Customer, locations: { _id: this.p42Location } };
        if (this.p42Roles.length > 0) {
          // if this form handles certain roles then query by those roles too
          query.contacts = {};
          this.p42Roles.forEach((e, i, a) => {
            // for each role, query for records that match the corresponding field name(s)
            query.contacts[this.roleFields[e]] = true;
          });
        }
        this.CustomerStore.get(query, (err, customers) => {
          // on error reject
          if (err) return reject(err);
          // on success
          // customers should only have one customer with one location (at most)
          if (customers.length > 0 && Array.isArray(customers[0].locations) && customers[0].locations.length > 0) {
            // if there are contacts to chose from then add them to the list
            this.contacts = customers[0].locations[0].contacts;
          } else {
            // if there are no contacts make sure we don't do a request every time
            this.noContacts = true;
          }
          this.contacts.forEach((e, i, a) => {
            // for each contact parse the dates
            this.parseDates(e);
          });
          resolve(this.contacts);
        });
      });
    }
  }

  /**
   * Converts date fields to Javascript dates
   * @param {Object} contact Contact object to parse dates for (mutates the object)
   */
  parseDates (contact) {
    if (contact.certificate_op) {
      // if the certificate expiry is included then convert it to a date
      contact.certificate_op.expiry = new Date(contact.certificate_op.expiry);
    }
    if (contact.certificate_ap) {
      // if the certificate expiry is included then convert it to a date
      contact.certificate_ap.expiry = new Date(contact.certificate_ap.expiry);
    }
  }

  /**
   * Adds the new onsite treatment contact to the database and adds it to the manual
   */
  saveContact () {
    if (this.p42SelectOnly !== undefined) {
      // if the form is not select only then set to false
      this.p42SelectOnly = false;
    }
    // add required hidden fields
    this.contact.customer = this.p42Customer;
    this.contact.location = this.p42Location;
    // identifier is new contact or the full names of the selected roles
    this.contact.identifier = this.p42Roles.map(r => this.roleNames[r]).join(' & ') || 'New Contact';
    // select the checkboxes for OP/AP
    this.p42Roles.forEach((e, i, a) => {
      // for each role, set the corresponding field to true
      this.contact[this.roleFields[e]] = true;
    });
    // now save the contact
    this.CustomerStore.saveContact(this.contact, (err, updatedContact) => {
      if (err) return console.error('save contact error',err);
      this.parseDates(updatedContact);
      this.contact = updatedContact;
      this.selectedId = updatedContact.id;
      // update select options
      this.contacts.push(updatedContact);
      // output the contact
      this.selectContact();
    });
  }

  /**
   * Populates the form with the selected contact's information
   */
  selectContact () {
    const contact = this.contacts.find((element, index, array) => {
      if (element.id === this.selectedId) {
        this.p42Selected({ id: element.id });
        return true;
      }
      this.p42Selected({ id: false });
      return false;
    });
    if (!this.p42SelectOnly && contact) {
      // if the form selection is allowed to persist then set the contact
      this.contact = contact;
    } else {
      // if the form is not allowed to persist then unselect the id as the output has been sent
      this.selectedId = '';
    }
  }
}

(function (app) {
  app.controller('ContactFormCtrl', ContactFormCtrl);
}(angular.module('app.customerClient')));
