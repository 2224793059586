'use strict';

(function (app) {
  app.factory('auditTrailStore', function (Store, $http) {
    const auditTrailStore = new Store({
      model: 'auditTrail'
    });

    auditTrailStore.create = function (data, callback) {
     

      this._$http({
        method: 'POST',
        url: this.baseRoute + '/createAuditTrail',
        data: data
      }).then(
        (res) => {
          callback(null, res.data);
        },
        (err) => {
          callback(err);
        }
      );
    }
    return auditTrailStore;
  });
  
}(angular.module('app.auditTrail')));
