'use strict';

(function (app) {
  app.factory('BatchStore', function (Store, $http) {
    const BatchStore =  new Store({
      model: 'Batch'
    });

    return BatchStore;
  });

}(angular.module('app.batch')));
