'use strict';

/**
 * Component allowing the full form for adding providers to be embedded in another form
 *
 * p42Order:           MongoDB sort string to order records by
 * p42Page:            Number of page to display records for (defaults to 1)
 * p42Limit:           Number of records to display per page (defaults to 15 or what was last used)
 * p42Populate:        Mongoose populate string to populate references with (defaults to empty)
 * p42Store:           Store service to request the data from
 * p42Fields:          Array of field objects to display
 * p42Query:           MongoDB query to get the data with
 * p42Tabs:            Array of tab objects
 * p42Dropdown:        Array of dropdown (md-select option) objects
 * p42RowClass:        Function that returns an ng-class compatible expression where the first argument is the record
 * p42RowStyle:        Function that returns an ng-style compatible expression where the first argument is the record
 * p42RowClicked:      Returns the selected records for whole row
 * p42Search:          Search query to show results for (defaults to default query) (exclude this attribute to disable searching)
 * p42SearchMultiple:  Array of Model names to also search against (must have a field linking to the p42Store's Model)
 * p42Selected:        Returns the selected records
 * p42Displayed:       Returns the displayed records
 * p42Count:           Returns the total number of records (including displayed records)
 * p42TabChange:       Returns the tab object of the newly active tab
 * p42DropdownChange:  Returns the dropdown option object of the newly active dropdown option
 * p42Key:             An array of objects used for key/legends for rows that may be highlighted.
 * p42DataModeller:    The data modeller that is built into list view is restrictive in that the list view module just displays
                        results form a search query on the UI. There is a use case in HUB where we need to model data differently i.e.
                        there is an array of objects of array of objects, we need to roll the results up to an array of objects and
                        display it on the list view. Values = true |false to enable or disable datamodeller
 * p42ModelRecords:    Function that models the data for listView display
 
 *
 * The field objects that p42Fields takes are of the form:
 * {
 *   path: String, // name of the path to get to the value in the model
 *   name: String, // name to display for the field
 *   hideXs: Boolean, // whether the field should show when viewed on a mobile (defaults to false)
 *   transform: Function // a function that takes the value of the field as an argument and returns the value to display
 * }
 * A field object may be omitted in favour of a string, in which case the string is the path.
 * It is recommended to only show two or three fields containing the most crucial information for mobile users.
 *
 * The tab objects that p42Tabs takes are of the form:
 * {
 *   name: String, // label for the tab
 *   query: Object, // MongoDB query to show data for
 *   active: Boolean // whether the tab is active by default (only one tab can be active at a time) 
 *   badge: Number //Small circle number display in the top-right corner
 * }
 *
 * The dropdown objects that p42Dropdowns takes are of the form:
 * {
 *   name: String, // label for the dropdown
 *   query: Object, // MongoDB query to show data for
 *   active: Boolean // whether the option is active by default (only one option can be active at a time) 
 * }
 *
 * The 'Key' objects that p42Key uses are of the form:
 * {
 *   description: String, // The description that will be displayed for the user.
 *   color: String, // The colour that the help/legend/key box will be displayed as - can be a simple colour ("red"), rgb ("rgb(255, 215, 64)") or anything css allows.
 * }
 */
(function (app) {
  app.component('p42ListView', {
    restrict: 'E',
    controller: 'ListViewCtrl',
    controllerAs: 'vm',
    transclude: {
      // p42-list-view-actions is the selection of buttons for each record
      actions: '?p42ListViewActions',
      // p42-list-view-action-header replaces p42-action-header using transclusion
      header: '?p42ListViewActionHeader'
    },
    bindings: {
      // @DEPRECATED in favour of named transclusion slots
      p42ActionHeader: '<',
      p42Controller: '<',
      p42Order: '<',
      p42Page: '@',
      p42Limit: '@',
      p42Populate: '<',
      p42Store: '<',
      p42Fields: '<',
      p42Query: '<',
      p42Tabs: '<',
      p42Dropdown: '<',
      p42Showdropdown: '<',
      p42DropdownTabs: '<',
      p42DropdownSearch: '<',
      p42RowClass: '<',
      p42RowStyle: '<',
      p42RowClicked: '&',
      p42Search: '@',
      p42SearchMultiple: '<',
      p42BackendSearch: '<',        // bi-passes angularjs filter and searches the backend
      p42Selected: '&',
      p42Displayed: '&',
      p42Count: '&',
      p42TabChange: '&',
      p42BeforeTabChange: '&',
      p42DropdownChange: '&',
      p42Key: '<',
      p42DropdownLabel: '@',
      p42OnRecordChange: '&',
      p42DisableToggleSearch : '<',   // disable toggle search - default enabled

      // model data using the function passed in for unorthodox listViews (optional)
      p42DataModeller: '<',
      p42ModelRecords: '&',
      // Note: will be deprecated in the future and set to always be true
      p42NamedTransclude: '<'
    },
    templateUrl: '/html/listview/listView.component.html'
  });
}(angular.module('app.listView')));
