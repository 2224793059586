'use strict';

(function (app) {
  app.factory('LabAssetStore', function (Store, $http) {
    const LabAssetStore = new Store({
      model: 'LabAsset'
    });

    /**
     * Validates that the asset identifier has not been used previously
     * @param {String} identifier Identifier to check
     * @param {Function} callback Callback in format (err, value) where value will be true if the identifier is valid and a string of the next valid identifier if not
     */
    LabAssetStore.validateIdentifier = function(identifier, callback) {
      this._$http({
        method: 'GET',
        url: this.baseRoute + '/validateIdentifier/' + identifier
      }).then(
        (res) => {
          // on success
          callback(null, res.data);
        },
        (err) => {
          // on error
          callback(err);
        }
      );
    }

    return LabAssetStore;
  });

}(angular.module('app.labAsset')));
