'use strict';

/**
 * Controller for the labChemicals view
 */
class LabChemicalsCtrl {

  /**
   * - Assigns injected variables to this
   * - Calls _query()
   * - Watches for stateChange and unsubscribes labChemicals array
   */
  constructor (LabChemicalStore) {
    this.LabChemicalStore = LabChemicalStore;

    this.fields = [{
      path: 'reagentNumber',
      name: 'Reagent Number'
    }, {
      path: 'reagent',
      name: 'Reagent Name'
    }, {
      path: 'purity',
      name: 'Purity'
    }, {
      path: 'description',
      name: 'Description'
    }, {
      path: 'dateMade',
      name: 'Created'
    }];
  }

  removeLabChemical(labChemical) {
    labChemical.remove(true);
  }
}

(function(app) {
  app.controller('LabChemicalsCtrl', LabChemicalsCtrl);
}(angular.module('app.labChemical')));
