'use strict';

class EditTableDialogCtrl{

	constructor($state, $mdToast, $mdDialog, $http) {
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.$http = $http;
    this.control = {};

    this.fields = this.fields.filter(field => field.runsheet_visibility);
    this.constants = this.fields.filter(field => field.input_type === 'C').map(field => field.name).join(" ");
    this.dummyReferences = angular.copy(this.runsheet.references);
    

    this.back = () => {
        this.changeReferences(()=>{
          $mdDialog.hide('yes');
        });
    };

    this.cancel = () => {
        $mdDialog.hide('cancel');
    };
    
  }
  
  updateRowOffset() {
    this.hot.render();
    this.runsheet.save();
  }

  toggleEdit() {
    // this.hot.render();
  }

  changeReference(name, index) {
   
    this.runsheet.references[index] = name;
    this.runsheet.save(()=>{
      this.runsheet.editConstant = showConstants;
      // this.$state.reload();
    });

  }

  changeReferences(callback){

    let showConstants = this.runsheet.editConstant;
    for(let i=0;i<this.dummyReferences.length;i++){
      this.runsheet.references[i] = this.dummyReferences[i];
    }
    console.log(this.runsheet.references);
    this.runsheet.save((err,runsheet)=>{
      console.log('to');
      this.runsheet.editConstant = showConstants;
      this.hot.render();
      if(callback)
        callback();
    });
    // this.$state.reload();
  }

  // Future development for adding new reference row
  // addReferenceRow() {
  //   this.disableAdd = true;
  //   this.$mdDialog.hide();
  //   this.control.values = this.fields.map(field => field.constant?Number(field.constant):0);

  //   let data = {
  //     control: this.control
  //   };
    
  //   this.$http({
  //     method: 'POST',
  //     url: '/api/Runsheet/newReference/' + this.runsheet.id,
  //     data: data
  //   }).then(
  //     (res) => {
  //       this.$state.reload();
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );
  // }

}

(function (app) {
    app.controller('EditTableDialogCtrl', EditTableDialogCtrl);
  }(angular.module('app.runsheet')));