'use strict';

(function (app) {
  app.factory('ProgramStore', function (Store, $http) {
    const ProgramStore = new Store({
      model: 'Program'
    });

    ProgramStore.create = function (data, callback) {
      $http({
        method: 'POST',
        url: '/api/program/create',
        data: data
      })
      .then(
        function (res) {
          callback(null, res);
        },
        function (res) {
          callback(res);
        }
      );
    };

    ProgramStore.getProgramList = function (data, callback) {
      $http({
        method: 'GET',
        url: '/api/program/get-program-list',
        data: {}
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };

    return ProgramStore;
  });

}(angular.module('app.program')));
