'use strict';
const _ = require('lodash');

/**
 * Controller for the certificate view view
 */
class FileFormCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $scope, $state, $stateParams, file, program) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.file = file;
    this.program = program;
    this.readOnly = $stateParams.readOnly;

    if(this.program!=undefined&&this.program._id)
      this.file.program = this.program._id

  }

  addFile(rawFile) {
    // merge the saved file information into this file
    Object.assign(this.file, rawFile);
    // make the reference change, forcing the file upload form to update
    this.file = this.file.clone();
    this.saveFile();
  }
  
  saveFile() {
    this.file.save((fileErr, fileData) => {
      if(fileErr) {
        this.$rootScope.showToast(`File save error`);
        console.log('file save error:', fileErr);
      } else {
        this.$rootScope.showToast(`File "${fileData.name}" saved`);
      }
      this.saving = false;
      this.$state.go('files');
    });
  }
}

(function (app) {
  app.controller('FileFormCtrl', FileFormCtrl);
}(angular.module('app.fileshared')));
