'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('customers', {
      url: '/customers/:status/:duplicate',
      resolve: {
        query: function ($stateParams) {
          let builtQuery;
          if (!$stateParams.status) {
            $stateParams.status = 'Approved';
            builtQuery = {status: 'Approved'};
          } else if ($stateParams.status === 'Draft' ) {
            builtQuery = {status: 'Draft',"pendingCustomerDetails.0": {$exists: $stateParams.duplicate === 'true' ? true : false }};
          } else {
            builtQuery = {status: 'Approved'};
          }
          return builtQuery;         
        },
        counts: function (CustomerStore) {
          return new Promise( (resolve, reject) => {
            CustomerStore.get({status: 'Draft',"pendingCustomerDetails.0": {$exists: true }}, (err, customers) => {
              if (err) reject(err);
              
              // on success
              let duplicateCount = 0;
              angular.forEach(customers, function (customer) {
                if (customer.pendingCustomerDetails)  duplicateCount += customer.pendingCustomerDetails.length;
              });
              CustomerStore.get({status: 'Draft',"pendingCustomerDetails.0": {$exists: false }}, (err, customers) => {
                if (err) {
                  // on fail
                  reject(err || 'No customer found');
                } else {
                  // on success
                  let newCustomerCount =  customers.length;
                  resolve({duplicateCount:duplicateCount, newCustomerCount: newCustomerCount });
                }
              });
            });
          });
        }
      },
      views: {
        '@': {
          templateUrl: '/html/customer/customer-list.html',
          controller: 'CustomerListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Customers'
      }
    });
    
    $stateProvider.state('customers.edit', {
      url: '/edit/:id',
      params: {
        id: 'new',
        index: 0
      },
      views: {
        '@': {
          templateUrl: '/html/customer/customer-view.html',
          controller: 'CustomerViewCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      resolve: {
        customer: function ($stateParams, CustomerStore) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
            if ($stateParams.id === 'new') {
              // if customer does not exist create new customer
              CustomerStore.newResource({}, resolve);
            } else {
              
              // if customer exists then load it
              CustomerStore.get({ _id: $stateParams.id }, { populate: 'createdByUserId  locations.contacts'}, (err, customers) => { //locations.programs locations.services locations.contacts.agent_from
                if (err || customers.length === 0) {
                  // on fail
                  reject(err || 'No customer found');
                } else {
                  // on success
                  resolve(customers[0]);
                }
              });
            }
          });
        },
        userAttached: function (UserStore,customer) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
            
            // if customer exists then load it
            if(customer!=undefined && customer.id!=undefined)
            {
              UserStore.get({ customer: customer.id }, {}, (err, users) => {
                
                if(users && users.length>0)
                  resolve(users.length);
                else
                  resolve(0);
                
              });
            }
            else
              resolve(0);
          });
        },
        // feesettings: function ($stateParams , FeeSettingsStore) {
        //   return new Promise((resolve, reject) => {
        //   FeeSettingsStore.get({}, {  }, (err, FeeSettings) => {
            
        //     if (err || FeeSettings.length === 0) {
        //       // on fail
        //       resolve([]);
        //     } else {
        //       // on success
        //       resolve(FeeSettings);
        //     }
        //   });
        // });
      // },
      customertypes: function($rootScope, Statics) {
        return $rootScope.wrapResolve((resolve, reject) => {
          Statics.get('customertypes', (err, data) => {
            if (err) return reject(err);
            resolve(data);
          });
        });
      }
    },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });

   

    $stateProvider.state('customers.edit.files', {
      url: '/files',
      views: {
        'form@customers.edit': {
          templateUrl: '/html/customer/customer-file-list.html',
          controller: 'CustomerFileListCtrl',
          controllerAs: 'vm',
          bindToController: true,
        }
      },
      ncyBreadcrumb: {
        label: 'Files'
      }
    });

  });                      
  
  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('Customers', {
      title: 'Customers',
      subtitle: 'View and edit customer details',
      params: {
        status : 'Approved',
        duplicate: false
      },
      icon: '/icons/md/social/svg/design/ic_person_24px.svg',
      state: 'customers',
      permission: 'customer-view',
      items: [{
        title: 'Make new customer',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        params: {
          id: null
        },
        state: 'customers.edit',
        permission: 'customer-edit'
      }]
    });

    SidenavProvider.addItem('My Company', {
      title: 'Company profile',
      subtitle: 'View and edit customer profile',
      params: {id: undefined},
      icon: '/icons/md/social/svg/design/ic_person_24px.svg',
      state: 'customers.edit',
      permission: 'freight-forwarder'
      
    });
    SidenavProvider.addItem('Customers', {
      title: 'Locations',
      subtitle: 'View Locations Details',
      params: {
        status : 'Approved',
        duplicate: false
      },
      icon: '/icons/md/social/svg/design/ic_person_24px.svg',
      state: 'locations',
      permission: 'location-view'
    }); 

    SidenavProvider.addItem('Customers', {
      title: 'Contacts',
      subtitle: 'View Contacts Details',
      params: {
        status : 'Approved',
        duplicate: false
      },
      icon: '/icons/md/social/svg/design/ic_person_24px.svg',
      state: 'contacts',
      permission: 'contact-view'
    });

    
    // SidenavProvider.addItem('Company Details', {
    //   title: 'Customer Profile',
    //   subtitle: 'View and edit customer profile',
    //   params: {id: undefined},
    //   icon: '/icons/md/social/svg/design/ic_person_24px.svg',
    //   state: 'customers.edit',
    //   permission: 'normal-user'   
    // });
    // SidenavProvider.addItem('Company Details', {
    //   title: 'Locations',
    //   subtitle: 'View Locations Details',
    //   params: {
    //     status : 'Approved',
    //     duplicate: false
    //   },
    //   icon: '/icons/md/social/svg/design/ic_person_24px.svg',
    //   state: 'locations',
    //   permission: 'normal-user'
    // });

    // SidenavProvider.addItem('Company Details', {
    //   title: 'Contacts',
    //   subtitle: 'View Contacts Details',
    //   params: {
    //     status : 'Approved',
    //     duplicate: false
    //   },
    //   icon: '/icons/md/social/svg/design/ic_person_24px.svg',
    //   state: 'contacts',
    //   permission: 'normal-user'
    // });
    
    // SidenavProvider.addItem('Settings', {
    //   title: 'My Profile',
    //   subtitle: 'View and edit profile',
    //   icon: '/icons/md/social/svg/design/ic_group_24px.svg',
    //   state: 'users.edit',
    //   params: { id: undefined },
    //   permission: 'normal-user'
    //  });
    // SidenavProvider.addItem('Settings', {
    //   title: 'Users',
    //   subtitle: 'View and edit users',
    //   icon: '/icons/md/social/svg/design/ic_group_24px.svg',
    //   state: 'users',
    //   condition: '($rootScope.User.hasPermission("admin") || $rootScope.User.hasPermission("client-admin")|| $rootScope.User.hasPermission("ff-admin"))',
    //   params: {
    //     status: 'Approved',
    //   },
    //   permission: 'normal-user',
    //   items: [{
    //     title: 'Add User',
    //     icon: '/icons/md/content/svg/design/ic_add_24px.svg',
    //     state: 'users.edit',
    //     params: { id: 'new' },
    //     // permission: 'client'
    //   }]
    // });

    // SidenavProvider.addItem('Settings', {
    //   title: 'My Profile',
    //   subtitle: 'View and edit profile',
    //   icon: '/icons/md/social/svg/design/ic_group_24px.svg',
    //   state: 'users.edit',
    //   params: { id: undefined },
    //   permission: 'admin'
    //  });
    //  SidenavProvider.addItem('Settings', {
    //   title: 'Users',
    //   subtitle: 'View and edit users',
    //   icon: '/icons/md/social/svg/design/ic_group_24px.svg',
    //   state: 'users',
    //   condition: '',
    //   params: {
    //     status: 'Approved',
    //   },
    //   permission: 'admin',
    //   restriction:'verifier|coo-user',
    //   items: [{
    //     title: 'Add User',
    //     icon: '/icons/md/content/svg/design/ic_add_24px.svg',
    //     state: 'users.edit',
    //     params: { id: 'new' },
    //     // permission: 'client'
    //   }]
    // });
    // SidenavProvider.addItem('Settings', {
    //   title: 'Settings',
    //   subtitle: 'Settings',
    //   params: {},
    //   icon: '/icons/md/action/svg/design/ic_settings_24px.svg',
    //   state: 'admin.settings',
    //   permission: 'admin',
    //   restriction:'verifier|coo-user'
    // });
    // SidenavProvider.addItem('Settings', {
    //   title: 'Fee Settings',
    //   subtitle: 'View and edit fee settings',
    //   icon: '/icons/md/social/svg/design/ic_person_24px.svg',
    //   state: 'feesettings',
    //   permission: 'admin',
    //   restriction:'verifier|coo-user',
    //   items: [{
    //     title: 'Make new settings',
    //     icon: '/icons/md/content/svg/design/ic_add_24px.svg',
    //     params: {
    //       id: null
    //     },
    //     state: 'feesettings.edit',
    //     permission: 'admin'
    //   }]
    // });
    
    // SidenavProvider.addItem('Settings', {
    //   title: 'My Profile',
    //   subtitle: 'View and edit profile',
    //   icon: '/icons/md/social/svg/design/ic_group_24px.svg',
    //   state: 'users.edit',
    //   params: { id: undefined },
    //   permission: 'freight-forwarder'
    //  });
    //  SidenavProvider.addItem('Settings', {
    //   title: 'Users',
    //   subtitle: 'View and edit users',
    //   icon: '/icons/md/social/svg/design/ic_group_24px.svg',
    //   state: 'users',
    //   condition: '($rootScope.User.hasPermission("admin") || $rootScope.User.hasPermission("client-admin") ||$rootScope.User.hasPermission("ff-admin") )',
    //   params: {
    //     status: 'Approved',
    //   },
    //   permission: 'freight-forwarder',
    //   items: [{
    //     title: 'Add User',
    //     icon: '/icons/md/content/svg/design/ic_add_24px.svg',
    //     state: 'users.edit',
    //     params: { id: 'new' },
    //     // permission: 'client'
    //   }]
    // });
    
  });
}(angular.module('app.customer', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics',
  'app.location',
  'app.contact'
])));
