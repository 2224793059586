'use strict';

/**
 * Component for selecting a customer from the list
 *
 * p42Default:    Customer id or Customer resource to set as the default selected value
 * p42Query:      Query used to get the list of locations
 * p42Required:   Whether a contact must be created or selected (defaults to false)
 * p42Selected:   Callback function containing an object with the key id and the value the id of the selected customer
 */
(function (app) {
  app.component('p42LocationSelector', {
    restrict: 'E',
    controller: 'LocationSelectorCtrl',
    controllerAs: 'vm',
    bindings: {
      p42Required: '<',
      p42Default: '<',
      p42Selected: '&',
      p42Query: '<',
      p42Label: '@'
    },
    templateUrl: '/html/customerclient/location-selector.component.html'
  });
}(angular.module('app.customerClient')));
