'use strict';

/**
 * Controller for the file view view
 */
class FileViewCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($window, $state, FileStore, file) {
    this.$window = $window;
    this.$state = $state;
    this.file = file;
    this.FileStore = FileStore;
  }
  
  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.file.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        console.log('in file view - would transition to file list view but i wont');
        //this.$state.transitionTo('files');
      }
    });
  }
}

(function (app) {
  app.controller('FileViewCtrl', FileViewCtrl);
}(angular.module('app.fileshared')));
