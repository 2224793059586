'use strict';

(function (app) {
  app.factory('Control', function (Resource) {
    return class Control extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Control' }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.control')));
