'use strict';

const async = require('async');

/**
 * Function used to splice strings in a similar manner to array.splice()
 * @param  {string} str - String to splice
 * @param  {number} index - Index to start the splice
 * @param  {number} count - How many characters to replace
 * @param  {string} add - The string to insert at the index
 * @return {string} Returns newly spliced string
 */
function stringSplice(str, index, count, add = '') {
  return str.slice(0, index) + add + str.slice(index + count);
}


/**
 * Function used to splice arrays 
 * @param  {string} str - String to splice
 * @param  {number} index - Index to start the splice
 * @param  {number} count - How many characters to replace
 * @param  {string} add - The string to insert at the index
 * @return {string} Returns newly spliced string
 */
function arraySplice(array, index, count, add) {
  if(index >= array.length && add.length > 0) {
    return array.concat(add);
  } else {
    if(add.length > 0)
      return array.splice(index, count, ...add);
    else
      return array.splice(index, count);
  }
  //return str.slice(0, index) + add + str.slice(index + count);
}

(function (app) {
  app.factory('HistoryStore', function (Store, $http, SocketIO) {
    const HistoryStore =  new Store('History');

    HistoryStore.addHistory = function addHistory(data) {
      this._updateObject(data, (err, history) => {
        // Going through each of the queries and adding this history to it
        _.each(this.queries, (hash) => {
          if(hash.query.query.doc_id == history.doc_id) {
            hash.arr.unshift(history);
          }
        });
      });
    };
    
    HistoryStore.rewind = (document, timestamp, options, callback) => {

      let populate = options.populate;
      let isEmbedded = options.isEmbedded || false;
      
      // Setting up the store to listen for document updates
      if(!isEmbedded) {
        SocketIO.on(document._id + ':update', HistoryStore.addHistory.bind(HistoryStore));     
      }
      
      async.waterfall([
        function rewindDocument(rewindCallback) {
          // If this is a subDocument, move on as the history is stored against the parent document
          if(isEmbedded) {
            return rewindCallback(null, document);
          }
          
          // Setting up the query to retrieve the history objects
          let query = {
            doc_id: document._id,
            timestamp: {
              $gte: timestamp
            }
          }

          // Cloning the object
          let tempObject = document.clone();
          
          // Fetching the history for this document
          HistoryStore.get(query, { sort: { timestamp: -1}}, (err, historyList) => {
            // Iterate through each historyList object
            _.each(historyList, (operation) => {
              _.each(operation.diff, (val, key) => {
                if (val.type === 'SET') {
                  tempObject[key] = val.old;
                } else if (val && _.isArray(val)) {
                  // Reversing operations
                  val = _.reverse(val);

                  let oldValue = tempObject[key];

                  if (_.isString(tempObject[key])) {
                    // Go through the diffs
                    _.each(val, (diff) => {
                      oldValue = stringSplice(oldValue, diff.index, diff.new.length, diff.old);
                    });
                  } else if (_.isArray(tempObject[key])) {
                    // Go through the diffs
                    _.each(val, (diff) => {
                      oldValue.splice(diff.index, diff.new.length, ...diff.old);
                    });
                  }

                  // Apply the diff to the object
                  tempObject[key] = oldValue;
                }
              });
            });
            
            // Marking the object as a snapshotted object
            tempObject.__meta.options.history = timestamp;
            tempObject.__meta.options.ignoreUpdates = true;        
            
            rewindCallback(null, tempObject);            
          });          
        }.bind(this),
        function populateRewindedDocument(document, populateCallback) {
          // Checking if we need to fetch and rewind other documents
          if(!_.isEmpty(populate)) {
            // We have a populate object so start poulating!
            if(!_.isArray(populate)) {
              populate = [populate];
            }
            
            async.each(populate, (p, eachCallback) => {
              // Getting the path and model
              let path = p.path;
              let model = p.model;             

              // If there is no model then it must be a embedded document
              if(!model) {
                let embeddedOptions = {
                  populate: p.populate,
                  isEmbedded: true,
                }

                let embeddedDocuments = document[path];
                
                if(!_.isArray(embeddedDocuments)) {
                  embeddedDocuments = [embeddedDocuments];
                }   
                
                async.each(embeddedDocuments, (embeddedDocument, eachEmbeddedDocumentCallback) => {
                  HistoryStore.rewind(embeddedDocument, timestamp, embeddedOptions, (err, rewindedEmbeddedDocument) => {
                    embeddedDocument = rewindedEmbeddedDocument;
                    
                    eachEmbeddedDocumentCallback(err);
                  });
                } ,(err) => {
                  eachCallback(err);
                });
              } else {
                
                // Getting the otherstore
                let otherStore = HistoryStore.getOtherStore(model);

                let query = {};
                
                // Checking if we need to retrieve one or many documents
                if(_.isArray(document[path])) {
                  query = {
                    _id: {
                      $in: document[path],
                    }
                  };
                } else {
                  query = {
                    _id: document[path],
                  };
                }
                // Getting the documents
                otherStore.get(query, {}, (err, subDocuments) => {
                  async.each(subDocuments, (subDocument, eachSubDocumentCallback) => {
                    const subDocumentOptions = {
                      populate: p.populate
                    };
                    
                    HistoryStore.rewind(subDocument, timestamp, subDocumentOptions, (err, rewindedSubDocument) => {
                      document[path] = rewindedSubDocument;
                      eachSubDocumentCallback();
                    });
                  }, (err) => {
                    eachCallback(err);
                  });
                });
              }
            }, function donePopulate(err) {
              populateCallback(err, document);
            });
          } else {
            populateCallback(null, document);
          }
        }.bind(this)
      ], function finishedRewind(err, rewindedDocument) {
        callback(err, rewindedDocument);
      });      
    };
    
    return HistoryStore;
  });
  
}(angular.module('app.history')));
