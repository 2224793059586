'use strict';

class ApproveJobsDialogCtrl {

  constructor($scope, $state, $mdToast, $mdDialog, $rootScope, $http, auditTrailStore) {
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.user = $rootScope.User;
    this.$http = $http;
    this.auditTrailStore = auditTrailStore;

    this.approvedList = this.runsheet.jobs;
    const all = this.runsheet.jobs.length;

    this.sampleMap = new Map();
    this.jobs.forEach((job) => {
      let selectedJobSamples = [];
      job.samples.forEach((sample) => {
        if (this.samples.find((jobSample) => {
            return jobSample.id === sample;
          })) selectedJobSamples.push(sample);
      });
      this.sampleMap.set(job.id, selectedJobSamples)
    })

    this.back = () => {
      $mdDialog.hide();
    };

    this.checkboxData = [];
    if (this.zeroCount === 0) {
      this.checkboxData[0] = true;
    }
    if (this.hideCount === 0) {
      this.checkboxData[1] = true;
    }

  }

  allisChecked() {
    return this.jobs.length === this.approvedList.length;
  }

  allisClicked() {
    if (this.jobs.length === this.approvedList.length) {
      this.approvedList = [];
    } else {
      this.approvedList = this.jobs.map(job => job.id);
    }
  }

  isIndeterminate() {
    return (this.approvedList.length !== 0 && this.jobs.length !== this.approvedList.length)
  }

  exists(id) {
    return this.approvedList.indexOf(id) > -1
  }

  toggle(id) {
    if (this.approvedList.indexOf(id) > -1) {
      this.approvedList.splice(this.approvedList.indexOf(id), 1);
    } else {
      this.approvedList.push(id);
    }
  }

  save() {
    this.saveLoading = true;
    if (this.jobs.length === this.approvedList.length) {
      console.log('Approve all jobs')
      var postData = { 
        entityType:'runsheet',
        entityId:this.runsheet._id,
        entityName:this.runsheet.name,
        userID:this.user._id,
        userName:this.user.given_name+" " + this.user.surname,
        action:'Approve Jobs',
        actionMessage:'Approve Jobs'
      };
      
      this.auditTrailStore.create(postData, (err,data) => {
        if(err){
          console.log(err,data)
        }
      })
      
      this.$http({
        method: 'POST',
        'url': '/api/Runsheet/approveAll',
        data: {
          runsheet: this.runsheet.id,
          approvedJobs: this.approvedList,
          comment: this.comment,
        }
      }).then((res) => {
        this.$mdDialog.hide();
        if (res.data) {
          this.$state.go('runsheets', {
            tab: 'complete'
          });
        } else {
          this.$state.go('runsheets', {
            tab: 'incomplete'
          })
        }
      })

    } else {
      // console.log(this.runsheet.jobs, this.approvedList)
      let recreatedjobs = this.jobs.map(job => job.id).filter(id => this.approvedList.indexOf(id) === -1);
      //These are actually the rejected jobs
      console.log('partly approve jobs', recreatedjobs)
      let samples = []
      recreatedjobs.forEach((id) => {
        samples = samples.concat(this.sampleMap.get(id));
      });
      this.$http({
        method: 'POST',
        'url': '/api/Runsheet/approve',
        data: {
          runsheet: this.runsheet.id,
          approvedJobs: this.approvedList,
          unapprovedJobs: recreatedjobs,
          comment: this.comment,
          samples: samples,
        }
      }).then((res) => {
        this.$mdDialog.hide();
        this.$state.go('runsheets.edit', {
          id: res.data
        })
      })
    }
  }

}

(function(app) {
  app.controller('ApproveJobsDialogCtrl', ApproveJobsDialogCtrl);
}(angular.module('app.runsheet')));
