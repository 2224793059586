'use strict';

const async = require('async');
const Handsontable = require('handsontable/dist/handsontable.full');
const math          = require('mathjs');
/**
 * Controller for the Runsheet edit view
 */
class RunsheetCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $state, $mdDialog, $http, $mdSidenav, $mdToast, $timeout, $scope, $window, $document, SocketIO, BatchStore, JobStore, RunsheetStore, RunsheetValueStore, runsheetInfo, runsheetType, WebUSB, samples, users, values, jobs, auditTrailStore,AuditTrailService) {
    this.$rootScope = $rootScope;
    this.user = $rootScope.User;
    this.$state = $state;
    this.$mdDialog = this._$mdDialog = $mdDialog;
    this.$window = $window;
    this.$http = $http;
    this.$document = $document;
    this.$scope = $scope;
    this.RunsheetStore = RunsheetStore;
    this.$timeout = $timeout;
    this.BatchStore = BatchStore;
    this.JobStore = JobStore;
    this.RunsheetValueStore = RunsheetValueStore;
    this.runsheetInfo = runsheetInfo;
    this.$mdSidenav = $mdSidenav;
    this.$mdToast = $mdToast;
    this.WebUSB = WebUSB;
    this.SocketIO = SocketIO;
    this.runsheetType = runsheetType;
    this.samples = samples;
    this.users = users;
    this.values = values;
    this.jobs = jobs;
    this.auditTrailStore = auditTrailStore;
    this.auditTrail = []
    this.claimList = []
    this.showAudit = false
    this.showClaim = false
    this.AuditTrailService = AuditTrailService;
    this.loading = false;
    this.claimed_by = "";
    this.difference = 0;
    this.dependentRunsheetUpdated = 0;
    this.dependentRunsheetApproved = true;
    this.changedCells = []
    this.runsheetValuesRef = []
    this.afterChangeFlag = 0;
    this.approveClick = false;
    this.runsheetCells = []
    this.allRunsheetData = []
    this.getRunsheetValuesNotPresent();
    this.setupRunsheet___ = 0;

    // console.log(this.values);
  }

  $onInit() {
    /** @type {Boolean} Boolean value indicating whether the Runsheet is loading */
    this.loading = true;
    /** @type {Runsheet} The Runsheet we are viewing */
    this.runsheet = this.runsheetInfo[0];



    this.getAuditTrails();
    this.$scope.breadcrumb = this.runsheet.name;
    // whether to show the relation column
    this.relationsUsed = false;

    // setup jobs
    this.jobLabTests = [];
    this.jobIds = {};
    this.jobPreservative = {};
    for (const job of this.jobs) {
      // map MongoDB ids to T-numbers, Preservative
      this.jobIds[job._id] = job.job_id;
      this.jobPreservative[job._id] = job.preservative;
      this.jobLabTests = this.jobLabTests.concat(job.labTests);
    }

    this.rowTypes = [];

    //row color map by row number
    //map all, approved and unaaproved job_numbers
    this.allJobsNumber = '';
    this.approvedJobsNumber = '';
    this.unapprovedJobsNumber = '';
    this.rowColors = [];
    if (this.runsheet.status === 1) {
      this.jobs.forEach((job) => {
        this.allJobsNumber += 'T' + job.job_id + ' ';
      })
    } else if (this.runsheet.status > 1) {
      for (let i = 0; i < this.runsheet.controls.length; i++) {
        this.rowColors.push('control');
      }

      this.jobs.forEach((job) => {
        if (this.runsheet.jobsApproved.indexOf(job.id) > -1) {
          for (let j = 0; j < job.samples.length; j++) {
            this.rowColors.push(1);
          }
          this.approvedJobsNumber += 'T' + job.job_id + ' ';
        } else {
          for (let k = 0; k < job.samples.length; k++) {
            this.rowColors.push(0);
          }
          this.unapprovedJobsNumber += 'T' + job.job_id + ' ';
        }
      })
    }

    // Get the list of devices from the server
    //this.WebUSB.getDevices();

    // setup runsheetType and fields
    this.fieldsMap = {};
    for (const labTest of this.runsheetType.labTests) {
      // for each labtest
      if (this.jobLabTests.indexOf(labTest._id) !== -1) {
        // if the lab test is included in the runsheet
        for (const field of labTest.fields) {
          // for each field add it to the map
          // No need to map the field which the field's values are not created
          if (field.runsheet_visibility || field.report_visibility || field.input_type !== 'N') {
            this.fieldsMap[field._id] = field;
          }
        }
      }
    }

    //console.log("----------- runsheet.controller $onInit ----- fieldsMap ", this.fieldsMap);


    // Note: retention/penetration checks are not done until loadData is called
    this.hasRetention = false;
    this.hasPenetration = false;
    this.runsheetClaimed = false;
    if (this.runsheet.status === 1) {

      if(this.runsheet.claimed_by.length > 0)
        this.runsheetClaimed = true;
      
      if(this.runsheet.claimed_by[0]!=undefined && this.runsheet.claimed_by[0]._id)
        this.runSheetClaimByID = this.runsheet.claimed_by[0]._id;
      
      this.userClaimed = _.find(this.runsheet.claimed_by, (u) => {
        return u.id == this.$rootScope.User._id;
      }) != null;
    } else {
      this.userClaimed = false;
    }

    //Mapping claimed usernames
    this.claims = '';
    if (this.runsheet.claimed_by.length > 0) {
      this.claimed_by = this.runsheet.claimed_by.map(user => user.given_name + " "+ user.surname).join(" ");
      this.claims = 'Claimed by: ' + this.runsheet.claimed_by.map(user => user.given_name + " "+ user.surname).join(" ");
    }
    else{
      this.claims = 'Claimed by: No one';
    }
    //console.log("----------- runsheet.controller $onInit ----- this.claims ", this.claims);


    /** @type {Array} Runsheet.values restructured to work with Handsontable nicely */
    this.runsheetData = null;
    this.runsheetReferences = null;
    this.runsheetDataIgnoreUpdate = null;

    // If we're already connected start listening for events
    this.$scope.$on('webusbDataIn', ($event, data) => {

      //console.log("----------- runsheet.controller $onInit ----- data ", data);
      const selectedCells = this.hot.getSelected();
      if (selectedCells) {
        const row = selectedCells[0];
        const col = selectedCells[1];
        this.hot.setDataAtCell(row, col, data);
        this.hot.selectCell(row + 1, col);
      }
    });

    //this.runsheet.on('update', this.updateButtons.bind(this));

    this.QCformValidations = [];
    this.approveLoading = true;
    this.runsheet.qualityChecks_topLeft.length === 0 ? this.QCformValidations.push(null) : this.QCformValidations.push(false);
    this.runsheet.qualityChecks_topRight.length === 0 ? this.QCformValidations.push(null) : this.QCformValidations.push(false);
    this.runsheet.qualityChecks_bottomLeft.length === 0 ? this.QCformValidations.push(null) : this.QCformValidations.push(false);
    this.runsheet.qualityChecks_bottomRight.length === 0 ? this.QCformValidations.push(null) : this.QCformValidations.push(false);

    angular.element(document.getElementById('top')).bind("scroll", (e) => {
      this.$scope.$apply(() => {
        this.scrollTopBtn = true;
      })
    })

  }

  formatDate(date)
  {
    let current_datetime = new Date(date)
    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes()
    return formatted_date
  }

  getAuditTrails()
  {

    this.auditTrailStore.get({entity_id:this.runsheet.id }, { populate: ""}, (err, auditTrails) => {
      if(err)
        console.log(err)
      else{
        this.auditTrail = auditTrails;
       this.auditTrail.map((at) =>{
          if(at.action == 'Claim Runsheet'){
            if(!this.claimList.includes(at.user_name))
              this.claimList.push(at.user_name);
          }
        });

      }
    })
  }

  // Seeing if the user has claimed the runsheet
  userApproved() {
    return this.runsheet.approved_by == this.$rootScope.User._id;
  }
  setupRunsheet(renderNew) {
    async.series([
      function getRunsheetData(callback) {
        this.loadData((ld) => {
          //console.log("------------- -Loading data ---------------- ");
          callback(null, ld);
        });
      }.bind(this),
      
      function getVisibleColumns(callback) {
        this.loadVisibleColumns((vc) => {
          this.vc1 = vc;
          callback(null, vc);
        });
      }.bind(this),
      function setupSocketIO(callback) {
        this.socketIOSetup();
        callback();
      }.bind(this)
    ], (err, results) => {
      // console.log(results);
      if (err || results.length < 3) {
        console.log('No data for HandsOnTable', err, results);
      } else {
        // Setting up HandsOnTable
        let container = document.getElementById('runsheet');
        /** @type {Handsontable} The Hansontable instance */
        
        // console.log("results[0]", results[0].length);
        // console.log("results[0][0]", results[0][0].length);
        // console.log("results[0][1]", results[0][1].length);
        // console.log("results[0][2]", results[0][2].length);
        
        
        if(typeof this.hot!='undefined') {
          this.hot.loadData(results[0]);
          this.hot.render();
          // this.hot.destroy();
        }
        else {
          this.hot = new Handsontable(container, {
            rowHeaders: this.getRowHeader.bind(this),
            colHeaders: this.getColumnHeader.bind(this),
            colWidths: this.getColumnWidth.bind(this),
            afterChange: this.onAfterChange.bind(this),
            cells: this.onGetCellProperties.bind(this),
            fillHandle: false,      //changed it to false to stop copying selected cell.
            columns: results[1],
            data: results[0],
            maxRows: results[0].length,
            viewportRowRenderingOffset: results[0].length
            //copyPaste:            false
          });
        }
        

        // console.log("results[0]", results[0].length);
        // console.log("results[0][0]", results[0][0].length);
        // console.log("results[0][1]", results[0][1].length);
        // console.log("results[0][2]", results[0][2].length);

        
        if(results && results.length>0)
          this.runsheetCells = results[0];

        if(this.runsheet.processCompleted==false && results && results.length>0) {

          if(this.runsheetCells!=undefined && this.runsheetCells && this.runsheetCells.length>0) {
            let runsheetTable = this.runsheetCells;
            runsheetTable.forEach((rowData,rindex)=>{
              let row = rindex;
              if(rowData && rowData.length>0) {
                rowData.forEach((columnData,cindex)=>{
                  let col = cindex;

                  let cellId = this.runsheetReferences[row][col] && this.runsheetReferences[row][col].ref;
                  if(cellId!=undefined) {

                    let obj = {
                      id: cellId,
                      val: columnData,
                      row: rindex,
                      col: cindex,
                    };

                    this.values.forEach((value,index)=>{
                      if(cellId==value._id) {
                        obj.cell_ID = this.values[index].cell._id;
                      }
                    })

                    this.changedCells.forEach((cell_,inde)=>{
                      if(cell_.id==obj.id) {
                        this.changedCells.splice(inde,1);
                      }
                    })

                    this.changedCells.push(obj)
                  }
                })
              }
            })
           
          }
          
          // console.log(this.changedCells);
          
        }
        //   this.hot.updateSettings({
        //     cells: function (row, col, prop) {
        //          var cell = hot.getCell(row,col);   // get the cell for the row and column
        //          cell.style.backgroundColor = "green";  // set the background color
        //     }
        // });
        if(this.changedCells!=undefined && this.changedCells && this.changedCells.length>0) {
          // this.hot.selectCell(this.changedCells[(this.changedCells.length - 1)].row, this.changedCells[(this.changedCells.length - 1)].col);
        }
        this.hot.render();

        //this.isRunsheetDataModified();

      }

      this.loading = false;
    });
  }


  /*
   * Updates handson table after row start numbers have been updated
   */
  updateRunsheet() {
    this.hot.render();
    this.runsheet.save();
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.runsheet.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('runsheets');
      }
    });
  }

  loadVisibleColumns(callback) {
    // Note: map sample column to itself
    let vc = [{
      data: 0
    }];

    this.columnMap = [0];
    const fields = this.runsheetType.getFields();
    // Getting the relevant fields
    let relevant_labTests = _.filter(this.runsheetType.labTests, (labTest) => {
      return this.jobLabTests.indexOf(labTest._id) !== -1;
    });

    this.relevant_fields = _.flatMap(relevant_labTests, function flatMapFields(labTest) {
      return _.filter(labTest.fields, function filterFields(field) {
        // if (field.retention_visibility && !this.hasRetention) {
        //   // if retention data is required and not found then exclude
        //   return false;
        // }
        // if (field.penetration_visibility && !this.hasPenetration) {
        //   // if penetration data is required and not found then exclude
        //   return false;
        // }
        return field.runsheet_visibility;
      }.bind(this));
    }.bind(this));

    // Filtering out the irrelvant fields/columns
    this.visibleFields = _.forEach(fields, (field) => {
      if (!_.isObject(field)) return true;

      for (var i = 0; i < this.relevant_fields.length; i++) {
        if (this.relevant_fields[i].id === field.id) return;
      }
      field.runsheet_visibility = false;
    });

    // Getting the correct columns
    _.each(this.visibleFields, (field, i) => {
      if (field.runsheet_visibility) {
        // Mapping the index to correct sample
        const mappedIndex = _.findIndex(this.runsheetReferences[this.sampleIndex], (o) => {
          return o.colName === field.name;
        });
        let data = {
          data: mappedIndex
        };
        if (field.input_type === 'C') data.renderer = this.constantRenderer;
        vc.push(data);
        this.columnMap.push(mappedIndex);
      }
    });

    if (this.relationsUsed) {
      // if relations are used then add relation column to map to itself
      vc.push({
        data: 4
      });
    }
    callback(vc);
  }

  /**
   * Helper function for sorting samples
   * Notes: Samples should have jobs populated
   * @param {Sample} sample1 First sample to compare
   * @param {Sample} sample2 Seconds sample to compare
   * @return {Boolean} Returns true if the sample2 should be ordered before sample1, and false otherwise
   */
  compareSamples(sample1, sample2) {
    return this.jobPreservative[sample1.job] > this.jobPreservative[sample2.job] || (this.jobPreservative[sample1.job] === this.jobPreservative[sample2.job] && this.jobIds[sample1.job] > this.jobIds[sample2.job]) || (this.jobIds[sample1.job] === this.jobIds[sample2.job] && sample1.sample_num > sample2.sample_num);
  }

  /**
   * Gets the sample identifier ask seen on the runsheet
   * @param {Sample} sample Sample to get the identifier for
   * @return {String} Human readable, unique identifier for the sample
   */
  getSampleIdentifier(sample) {
    let suffix = sample.type === 'p' ? 'P' : '';
    if (sample.override_sample_name) {
      // if the sample name is specified then override the default
      return sample.override_sample_name + suffix;
    }
    // if the sample name is not specified then use the default
    if (this.jobIds[sample.job]) {
      return 'T' + this.jobIds[sample.job] + '-' + sample.sample_num + suffix;
    } else {
      return 'Not found'
    }
  }

  /**
   * Creates the Handsontable structure
   */
  loadData(loadCallback) {
    let rows = [];
    let rowsRef = [];
    let rowsUpdate = [];
    let samplesAdded = [];
    let samplesStart = 0;
    this.sampleIndex = 0;
    let index = 0;

    //console.log("------------------------------ Loading data for runsheet ------------------ ");
    async.eachSeries(this.runsheetType.rows, function iteratee(row, rowDone) {
      // If row type is sample, we need to create a new row for each Sample
      if (row.type === 'Sample') {
        this.sampleIndex = index;

        async.eachSeries(this.samples, function iteratee(sample, sampleDone) {
          let cells = [];
          let cellsRef = [];
          let cellsUpdate = [];

          //console.log("--------------loading for sample "+ sample.name);

          // setup information for a blank row if we cannot find the sample
          let rowName = 'data error';
          let sampleRef = {
            ref: '',
            type: 'T'
          };
          if (sample) {
            rowName = this.getSampleIdentifier(sample);
            sampleRef.ref = sample._id;
          }
          //console.log("-------loadData------- getSampleIdentifier "+ rowName);
          // add the sample name
          // console.log('rowName',rowName);
          cells.push(rowName);
          cellsRef.push(sampleRef);
          cellsUpdate.push(false);

          //map rowTypes with job objectId
          if (sample.type !== 'q') {
            this.rowTypes.push(sample.job.toString());
          }
          let cellIndex = 0;
          // For each cell we need to find the corresponsing Runsheet Value
          async.eachSeries(row.cells, function iteratee(cell, cellDone) {
            let field = this.fieldsMap[cell.field];
            if (!field) {
              // if the field will not be displayed then do not process it
              return cellDone();
            }
            async.waterfall([
              function firstStep(firstStepCallback) {
                
                
                let value = _.find(this.values, function(v) {
                  // Note: sample is not populated
                  return cell._id == v.cell._id && sample && v.sample == sample._id;
                });
                if(value!=undefined && value) {
                  this.calculateValue(value,firstStepCallback,rowName);
                }
                else {
                  firstStepCallback(null,0);
                }
              }.bind(this),
              
            ], (err, value) => {
              // setup information for a blank cell if we cannot find the value
              let cellValue = 0;
              let valueRef = {
                ref: '',
                type: field.input_type,
                colName: field.name || '',
              };

              if (value) {
                //console.log("--------- value :", value.value);
                if (value.type != 'N') {
                  cellValue = value.value;

                }
                valueRef.ref = value._id;
              } else {
                 console.error("RunsheetType Cell sample row value not Found in all values: ", cell);
              }
              //console.log("-------loadData------- valueRef "+ valueRef.toString);

              // In CCAB runsheet, if value not found, assign empty to that cell
              if (value) {
                cells.push(value.value);
              } else {
                cells.push('');
              }
              cellsRef.push(valueRef);
              cellsUpdate.push(false);
              cellIndex++;
              
              cellDone()
            });
            
            
          }.bind(this), (err) => {
            // Note: rows are added in the correct order based on the first cell
            if (samplesAdded.length > 1) {
              // if there has been a previous sample
              if (this.compareSamples(samplesAdded[samplesAdded.length - 1], sample)) {
                // if this sample gets added before the previous one then find where to insert
                for (let s = 0; s < samplesAdded.length; s++) {
                  // for each sample already added
                  if (this.compareSamples(samplesAdded[s], sample)) {
                    // if this sample gets added before the one added previously then do so
                    const insertAt = samplesStart + s;
                    rows.splice(insertAt, 0, cells);
                    rowsRef.splice(insertAt, 0, cellsRef);
                    rowsUpdate.splice(insertAt, 0, cellsUpdate);
                    samplesAdded.splice(s, 0, sample);
                    this.rowTypes.splice(insertAt, 0, sample.type === 'q' ? false : sample.job.toString())
                    return sampleDone();
                  }
                }
              }
            }

            // add the reference column values
            let relationName = 'data error';
            const relationRef = {
              ref: '',
              type: 'T'
            };
            if (sample.relation) {
              // if the sample relates to another sample
              const relation = this.samples.find((samp) => {
                // find the related sample
                return sample.relation === samp._id;
              });
              if (relation) {
                // if the relation was found then get the name and ref
                relationName = this.getSampleIdentifier(relation);
                relationRef.ref = relation._id;
                //this.relationsUsed = true;              - Hold it in data but turn off display
              }
            } else {
              // if there is no sample relation
              relationName = '';
            }
            cells.push(relationName);
            cellsRef.push(relationRef);
            cellsUpdate.push(false);

            // update retention/penetration status
            if (sample.type === 'r') {
              this.hasRetention = true;
            } else if (sample.type === 'p') {
              this.hasPenetration = true;
            }

            // add the row
            rows.push(cells);
            rowsRef.push(cellsRef);
            rowsUpdate.push(cellsUpdate);
            samplesAdded.push(sample);

            sampleDone()
          });

        }.bind(this), (err) => {
          index++;
          rowDone()
        });
        
      } else {

        // Otherwise we only need to create one row
        let cells = [];
        let cellsRef = [];
        let cellsUpdate = [];

        // add the sample column placeholder values
        let rowName = this.runsheet.references[index] || row.name || 'Control';
        cells.push(rowName);
        cellsRef.push('');
        cellsUpdate.push(false);
        //map rowTypes with control identifier
        this.rowTypes.push(false);
        async.eachSeries(row.cells, function iteratee(cell, cellDone) {
          
          let field = this.fieldsMap[cell.field];

          if (!field) {
            // if the field will not be displayed then do not process it
            return cellDone();
          }

          async.waterfall([
            function firstStep(firstStepCallback) {

              let value = _.find(this.values, function(value) {
                return cell._id == value.cell._id;
              });

              if(value!=undefined && value) {
                this.calculateValue(value,firstStepCallback,rowName);
              }
              else {
                firstStepCallback(null,0);
              }
            }.bind(this),
              
          ], (err, value) => {
            if(err) {
              cellDone()
            }
            else {
              if (value) {
                value.on('update', (data) => {
                  this.hot.render();
                });
                cells.push(value.value);
                let ref = {
                  ref: value._id
                };
                cellsRef.push(ref);
              } else {
                cellsRef.push('');
                cells.push("");
                // console.error("RunsheetType Cell control row value not Found in all values: ", cell);
              }
              cellsUpdate.push(false);
              cellDone()
            }
          });
        }.bind(this), (err) => {
          // add the relation column placeholder value
          cells.push('');
          cellsRef.push('');
          cellsUpdate.push(false);

          // add the row
          rows.push(cells);
          rowsRef.push(cellsRef);
          samplesStart++;
          index++;
          rowDone()
        });
      }
    }.bind(this), (err) => {

      if (!this.sampleIndex) {
        // if there are no samples then spoof sample type checks
        this.hasRetention = true;
        this.hasPenetration = true;
      }

      this.runsheetData = rows;

      // this.runsheet.controls.forEach(control => {
      //   control.values.unshift(control.name);
      //   let index = this.rowTypes.lastIndexOf(control.job.toString()) + 1;
      //   this.runsheetData.splice(index, 0, control.values);
      //   this.rowTypes.splice(index, 0, control.name);
      // })
      // Creating the cellMap
      this.cellMap = {};

      _.each(rowsRef, (row, i) => {
        _.each(row, (cell, j) => {
          this.cellMap[cell.ref] = [i, j];
        });
      });
      
      this.runsheetReferences = rowsRef;
      this.runsheetDataIgnoreUpdate = rowsUpdate;
      // console.log(rows,rowsRef,rowsUpdate)
      // console.log(this.rowTypes)

      loadCallback(this.runsheetData)

    });
    
  }

  getRunsheetValuesNotPresent() {
    this.extraRunsheetValues = []
    if(this.values!=undefined && this.values && this.values.length>0) {
      async.waterfall([
        function collectIds(collectIdCallback){

          async.each(this.values, (value, eachCallback) => {

            let expression = value.expression;
            let node = math.parse(value.expression);

            const nodes   = [];
            node.traverse((node, path, parent) => {
              switch (node.type) {
                case 'SymbolNode':
                  nodes.push(node);
                  break;
              }
            });

            async.each(nodes, (node, nodeEachCallback) => {
              const id = node.name.replace('VAL_', '');

              let valueNotPresent = this.values.filter(value => value._id === id);

              if(valueNotPresent!=undefined && valueNotPresent && Array.isArray(valueNotPresent) && valueNotPresent.length==0 && 
                this.extraRunsheetValues.indexOf(id)==-1) {
                
                this.extraRunsheetValues.push(id)
              }
              nodeEachCallback(null)
            }, (err) => {

              eachCallback()
            })
            
          }, (err) => {
            collectIdCallback(null)
          })

        }.bind(this),
        function addRunsheetValues(addRunsheetValueCallback) {
          if(this.extraRunsheetValues !=undefined && this.extraRunsheetValues && Array.isArray(this.extraRunsheetValues) && 
            this.extraRunsheetValues.length>0) {

            this.RunsheetValueStore.get({ _id: {$in:this.extraRunsheetValues} }, (err,runsheetValues) => {
              if(runsheetValues && runsheetValues.length>0) {
                // console.log(runsheetValues.length)
                runsheetValues.forEach((runsheetVal)=>{
                  // console.log('runsheetVal',runsheetVal._id,runsheetVal.value)
                  this.values.push(runsheetVal)
                })
              }
              addRunsheetValueCallback(null)
            })
          }
          else {
            addRunsheetValueCallback(null)
          }
        }.bind(this)
      ], (err, value_) => {
        
        if(this.extraRunsheetValues.length>0) {
          // console.log('getRunsheetValuesNotPresent',this.extraRunsheetValues.length)  
          this.getRunsheetValuesNotPresent()
        }
        
        // console.log('extraRunsheetValues length',this.extraRunsheetValues.length)
        
        if(this.extraRunsheetValues.length==0 && this.setupRunsheet___==0) {
          this.setupRunsheet___ = 1
          // console.log('setting up runsheet')
          this.setupRunsheet(false)
        }

      });
    }
  }

  calculateValue(value,calculateValueCallback,rowName=null) {
    if (isNaN(value.value) || value.value === Infinity || Number.isFinite(value.value)==false) {
      value.value = value.cell.default_value;
    }

    if (value.expression==undefined || !value.expression) {
      this.values.forEach((value____,index)=>{
        if(value____._id==value._id) {
          
          this.values[index].value = value.value; 
        }
      })
      return calculateValueCallback(null,value,)
      // return value.value;
    }

    let index = 0;
    let expression = value.expression;
    let node = math.parse(value.expression);

    const nodes   = [];
    node.traverse((node, path, parent) => {
      switch (node.type) {
        case 'SymbolNode':
          nodes.push(node);
          break;
      }
    });

    async.each(nodes, (node, eachCallback) => {
      
      const id = node.name.replace('VAL_', '');
      async.waterfall([
        function calculateValue(calculateCallback) {
          let runsheetVal___;
          async.eachSeries(this.values, function iteratee(runsheetValue, valueDone) {
            if(runsheetValue._id == id) {
              runsheetVal___ = runsheetValue;
            }
            valueDone();
          }, function done() {
            calculateCallback(null,runsheetVal___)
          });
        }.bind(this),
        function(runsheetValue, runsheetCallback) {
          if (!runsheetValue) {
            return runsheetCallback(null, 0);
          }
          this.calculateValue(runsheetValue,runsheetCallback,rowName);
          // runsheetCallback(null,this.values[index].value);
        }.bind(this)
      ], (err, value_) => {
        if(!value_ || value_==0) {
          expression = expression.replace(node.name, 0);
        }
        else {
          expression = expression.replace(node.name, value_.value);
        }
        
        // console.log('calculateValue',value._id,expression,node,value_);
        eachCallback(err);
        index++;
      });
    }, (err) => {
      if (err) {
        calculateValueCallback(null,0)
      } else { 
        let val = math.eval(expression);

        // if(id=='610d6916e59b591d5482ab34')
        //   console.log(val);
        if (isNaN(val) || val === Infinity || Number.isFinite(val)==false) {
          val = value.cell.default_value;
        }
        // console.log(expression,value._id,val)
        this.values.forEach((value____,index)=>{
          if(value____._id==value._id) {
            if(value____.value!=value.value) {
              // console.log(value.value,value.cell.name)
              // console.log('value',value)
            }
            if(val==0 && this.values[index].value>0)
              this.values[index].value = value.value;
            else {
              this.values[index].value = val;   
            }
            // console.log(rowName,value.expression,value._id,expression,value.value,value.cell.name)
            calculateValueCallback(null,this.values[index])
          }
        })
        
      }
      
    });
  }
  /**
   * Updates every cell when there is an update message from socket.io
   */
  socketIOSetup() {
    // Setup for cells
    // console.log("--------------- updating cell information in socketIOSetup ");
    _.forEach(this.runsheetReferences, (row, i) => {
      _.forEach(row, (cell, j) => {
        this.SocketIO.on(cell.ref + ':update', function(data) {
          let r = i;
          let c = j;
          // console.log(cell.ref + ':update',data,data.value);

          // this.updateCell(r, c, data.value);
          this.dependentRunsheetUpdated = 1;
          // if(typeof this.hot !=="undefined")
          //   this.hot.render();
        }.bind(this));
      });
    });

    
    // Setup for block updates
    this.SocketIO.on(this.runsheet._id + ":chunkUpdate", function(data) {
      _.forEach(data, function(value, id) {
        let cell = this.cellMap[id];
        if (cell) {
          // Getting the row and col
          let row = cell[0];
          let col = cell[1];
          // Updating values
          this.updateCell(row, col, value);
        }
      }.bind(this));
      this.hot.render();
    }.bind(this));
  }

  updateCell(row, col, value) {
    this.runsheetData[row][col] = value;
  }

  /**
   * For debugging references
   *
   */
  onAfterOnCellMouseDown(event, coords, TD) {
    console.log(this.runsheetReferences[coords.row][this.columnMap[coords.col]]);
  }

  /**
   * Returns index + 1
   * @param {Integer} index The row index
   * @return {Integer} index + 1
   */
  getRowHeader(index) {
    // return index + 1 ;
    return index + this.runsheet.rowOffset;
  }

  getColumnWidth(index) {
    if (index === 0) {
      return 100;
    } else {
      let columnMaxLengthWord = this.runsheetReferences[this.sampleIndex][index];
      if (columnMaxLengthWord && columnMaxLengthWord.colName) {
        columnMaxLengthWord = columnMaxLengthWord.colName.split(" ").reduce((a, b) => a.length > b.length ? a : b);
        if (columnMaxLengthWord.length > 10) return columnMaxLengthWord.length * 10;
      }
      return 100;
    }
  }

  /**
   * Returns the Sample name of the column index
   * @param {Integer} index The column index
   * @return {String} The Sample name
   */
  getColumnHeader(index) {
    // add sample column
    if (index === 0) {
      return 'Sample';
    }

    // add relation column
    if (index === this.runsheetData[0].length - 1) {
      // if the column is last then it is the relation column
      return 'Relation';
    }

    index = this.columnMap[index] || 0;

    //return this.runsheetReferences[this.sampleIndex][index].colName;
    if (this.runsheetReferences[this.sampleIndex] && this.runsheetReferences[this.sampleIndex][index]) {
      return this.runsheetReferences[this.sampleIndex][index].colName;
    } else {
      return 'Missing Header';
    }
  }


  /**
   * Function used to save cells that have changed
   */
  onAfterChange(changes, source) {
    // console.log(changes, source);return
    //console.log("-------------runsheet.controller --> onAfterChange ------ changes: ", changes);
    if (!changes) return;
    // console.log(changes)
    let cells = [];
    _.forEach(changes, (change, i) => {
      // Getting row and col of the change
      let row = change[0];
      let col = change[1];

      // Getting the row
      let cellId = this.runsheetReferences[row][col] && this.runsheetReferences[row][col].ref;
      //console.log("-------------runsheet.controller --> onAfterChange ------  cellId: ", cellId);
      

      // let refCellData = this.runsheetReferences[row][col].ref
      let obj = {
        id: cellId,
        val: change[3],
        row: row,
        col: col,
        // previousValue : change[2],
        // refCell:{}
      };

      this.values.forEach((value,index)=>{
        if(cellId==value._id) {
          this.values[index].value = change[3];
          obj.cell_ID = this.values[index].cell._id;
        }
      })

      this.changedCells.forEach((cell_,inde)=>{
        if(cell_.id==obj.id) {
          this.changedCells.splice(inde,1);
        }
      })

      cells.push(obj);
      this.changedCells.push(obj)
      //console.log("-------------runsheet.controller --> onAfterChange ------  obj: ", obj);
    });
    // runsheetValuesRef
    // console.log(this.changedCells)
   
    let data = {
      cells: cells,
      runsheetId: this.runsheet._id
    };
    // this.changedCells = data;
    // console.log(cells);
    //console.log("-------------runsheet.controller --> onAfterChange ------  this.runsheet: ", this.runsheet.name);
    this.afterChangeFlag = 1;
    //console.log("-------------runsheet.controller --> onAfterChange ------  data: ", data);
    if(this.dependentRunsheetUpdated) {
      alert('Runsheet Updated. Please refresh for updated version');
    }
    else {

      var dataCopy = data
      if(dataCopy.cells!=undefined && dataCopy.cells && dataCopy.cells.length>0) {
        // this.hot.selectCell(dataCopy.cells[(dataCopy.cells.length - 1)].row, dataCopy.cells[(dataCopy.cells.length - 1)].col);
      } 
      this.setupRunsheet(false)

      
      // console.log('else');
    }


  }

  /**
   *  For rendering error row with light red background
   *  https://docs.handsontable.com/5.0.0/demo-conditional-formatting.html
   */
  errorRowRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.style.background = '#EECCCC';
  }

  approvedRowRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.style.background = '#CEC';
  }

  

  /**
   * Function to determine a cells properties
   * @param {Integer} row Index of the row
   * @param {Integer} col Index of the column
   * @param {[type]} prop
   * @return {Object} The cell properties
   */
  
  foreditable(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    // console.log('value',value);
    td.style.background = '#B8B8B8';
    if (typeof value == 'number'){
      let retvalue = value;
      let dp = 0;
      if (cellProperties.decimal_places) dp = cellProperties.decimal_places;
      if (dp == 1) retvalue = Math.ceil(value * 10) / 10 ;
      else if (dp == 2) retvalue = Math.ceil(value * 100) / 100 ; 
      else if (dp == 3) retvalue = Math.ceil(value * 1000) / 1000 ;
      else if (dp == 4) retvalue = Math.ceil(value * 10000) / 10000 ;
      else if (dp == 5) retvalue = Math.ceil(value * 100000) / 100000 ;
      //console.log("value: "+value+"  ---> "+retvalue+" ----> "+retvalue.toFixed(cellProperties.decimal_places));
      // console.log(retvalue,parseFloat(retvalue).toFixed(dp));
      td.innerHTML =  parseFloat(value).toFixed(dp); 
    }
  }  
 
  fornoneditable(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.background = '#FFF9A6';
    if (typeof value == 'number'){
      let retvalue = value;
      let dp = 0;
      if (cellProperties.decimal_places) dp = cellProperties.decimal_places;
      if (dp == 1) retvalue = Math.floor(value * 10) / 10 ;
      else if (dp == 2) retvalue = Math.floor(value * 100) / 100 ; 
      else if (dp == 3) retvalue = Math.floor(value * 1000) / 1000 ;
      else if (dp == 4) retvalue = Math.floor(value * 10000) / 10000 ;
      else if (dp == 5) retvalue = Math.floor(value * 100000) / 100000 ;
      //console.log("value: "+value+"  ---> "+retvalue+" ----> "+retvalue.toFixed(cellProperties.decimal_places));
      td.innerHTML =  parseFloat(value).toFixed(dp); 
    }
  }

  

  onGetCellProperties(td, row, col, prop) {
   
    let cellProperties = {
      readOnly: false,
      type: 'numeric',
      pattern: '0.00',
    };

    let visibleFields = this.visibleFields.filter(field => field.runsheet_visibility === true);

    // Add first column and mark as constant as its the row header
    visibleFields.splice(0, 0, {
      input_type: "C"
    });

    //console.log("visibleFields: "+visibleFields);
    //console.log("col: "+col);

    let column = visibleFields[col];
    // let format = '0.';

    // // for (let i = 0; i < column.decimal_places; i++) {
    // //   format = format + '0';
    // // }
    // cellProperties.format = format;
    
    if (column){
      if(column.decimal_places){
        cellProperties.decimal_places=column.decimal_places;
      }else{
        cellProperties.decimal_places=3
      } 
    }else{
      //console.log("column does not exist :"+ column);
      //console.log("col: "+col);
      //console.log("visibleFields: "+visibleFields.length);
      //console.log("this.visibleFields: "+this.visibleFields.length);
      
    }
    cellProperties.renderer = this.foreditable;
    
    if (column){
      // If expression or !ready() then cell is readOnly
      if (column.input_type === 'E' || (column.input_type === 'C' && !this.runsheet.editConstant) || !this.ready() || !this.userClaimed) {
        cellProperties.readOnly = true;
        cellProperties.renderer = this.fornoneditable;
        // var cell = hot.getCell(row,col);   // get the cell for the row and column
        // cell.style.backgroundColor = "#EEE";
      }
    }
    // If first column (Sample name) or last column (relation) then cell is readOnly + return early || column should be constant
    //if (col === 0 || col === visibleFields.length || !column) {
    if (col === 0) {
      cellProperties.readOnly = true;
      cellProperties.renderer = this.fornoneditable;
      delete cellProperties.type
    }
    return cellProperties;
    // if (this.rowColors[row] == 1) {
    //   cellProperties.renderer = this.approvedRowRenderer.bind(this); // uses function directly
    // }
  }

  /**
   * Replaces the innerHTML of the cell with the fetched value from the RunsheetValue Store
   */
  runsheetValueRenderer(instance, td, row, col, prop, value, cellProperties) {
    switch (cellProperties.type) {
      case "numeric":
        Handsontable.renderers.NumericRenderer.apply(this, arguments);
        break;
      default:
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        break;
    }
  }

  constantRenderer(instance, td) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.style.backgroundColor = 'grey';
    return td;
  }

  /**
   * Returns a Boolean value indicating whether the report is ready for editing
   */
  ready() {
    return (this.runsheet && !this.runsheet.approved && !this.runsheet.rejected && this.runsheetData && this.user.hasPermission("runsheet-edit"));
  }

  /**
   * Calls runsheet.approve
   * then broadcast the event to ion-quality-check-value-view directive
   */
  onApproveClick() {
    var _this = this;
    _this.dependentRunsheetApproved = true; 
    if(typeof _this.runsheet.dependencies[0] != 'undefined') {
      async.mapSeries(_this.runsheet.dependencies, function(runsheet, outerCB) {

        _this.RunsheetStore.get({_id: runsheet},'',(err , dbRunsheet)=>{
          if(dbRunsheet && dbRunsheet.length > 0) {
            if(_this.dependentRunsheetApproved)
              _this.dependentRunsheetApproved = dbRunsheet[0].approved;
            
          } else {  
            _this.dependentRunsheetApproved = true;
          }
          outerCB();
        });
      }, function(err, results) {
        _this.approvedFunction();
      });
    } else {
      _this.approvedFunction();
    }
  }

  approvedFunction() {
    if(this.dependentRunsheetApproved) {
      if(this.changedCells!=undefined && this.changedCells && this.changedCells.length>0) {
        let _this = this;
        var confirm = this.$mdDialog.confirm()
          .title(`You have modified data on runsheet.Do you want to save it? `)
          .ok('Yes')
          .cancel('No');
  
          // console.log(confirm)
          this.$mdDialog.show(confirm).then(()=> {
            // console.log('Yes')
            _this.onReleaseClick();
  
            this.approveClick = true;
          },()=>{
            // console.log('No')
            _this.$rootScope.$broadcast('check-QCvalidation-event', {
              check: true
            });
          })
          .catch(
            function(error) {
              console.log('Error: ' + error);
              _this.$rootScope.$broadcast('check-QCvalidation-event', {
                check: true
              });
            }
        );
      }
      else {
        this.saveMulti();
        this.$rootScope.$broadcast('check-QCvalidation-event', {
          check: true
        });
      }
    } else {
      this.$rootScope.showError('Please Approve Dependent Runsheet First');
    }
  }



  saveMulti(){
    this.allRunsheetData = {}
    if(this.runsheetCells!=undefined && this.runsheetCells && this.runsheetCells.length>0) {
      let runsheetTable = this.runsheetCells;
      runsheetTable.forEach((rowData,rindex)=>{
        let row = rindex;
        if(rowData && rowData.length>0) {
          rowData.forEach((columnData,cindex)=>{
            let col = cindex;

            let cellId = this.runsheetReferences[row][col] && this.runsheetReferences[row][col].ref;
            if(cellId!=undefined) {

              let obj = {
                id: cellId,
                val: columnData,
                row: rindex,
                col: cindex,
              };

              if(this.allRunsheetData[cellId]==undefined)
                this.allRunsheetData[cellId] = {}
              this.allRunsheetData[cellId] = obj
            }
          })
        }
      })
    }


    if(this.allRunsheetData!=undefined && this.allRunsheetData && typeof this.allRunsheetData=='object') {
    this.$http({
      method: 'POST',
      url: '/api/RunsheetValue/saveMulti',
      data: {runsheetId:this.runsheet._id,runsheetCells :this.allRunsheetData}
    }).then((err,data)=>{

      this.changedCells = []
      this.afterChangeFlag = 0
      //this.isRunsheetDataModified();
    },(err,data)=>{
      
    });
    }
  }


  isRunsheetDataModified(){ 
    this.allRunsheetData = {}
    if(this.runsheetCells!=undefined && this.runsheetCells && this.runsheetCells.length>0) {
      let runsheetTable = this.runsheetCells;
      runsheetTable.forEach((rowData,rindex)=>{
        let row = rindex;
        if(rowData && rowData.length>0) {
          rowData.forEach((columnData,cindex)=>{
            let col = cindex;

            let cellId = this.runsheetReferences[row][col] && this.runsheetReferences[row][col].ref;
            if(cellId!=undefined) {

              let obj = {
                id: cellId,
                val: columnData,
                row: rindex,
                col: cindex,
              };

              if(this.allRunsheetData[cellId]==undefined)
                this.allRunsheetData[cellId] = {}
              this.allRunsheetData[cellId] = obj
            }
          })
        }
      })
      
      this.$http({
        method: 'POST',
        url: '/api/RunsheetValue/isRunsheetDataModified',
        data: {runsheetId:this.runsheet._id,runsheetCells :this.allRunsheetData}
      }).then((data, err)=>{
        if(!err)
          // console.log(data.data.isRunsheetDataModified);

          this.SyncButton = data.data.isRunsheetDataModified;
          if(this.SyncButton == false) {
            this.SyncButton = !this.runsheet.processCompleted;
          }

      },(err,data)=>{
        console.log(data, err, 'second');
      });

    }
    
  }
  


  /**
   * Callback binding for ion-quality-check-value-view directive
   * update the validation info for each of QCform (this function gonna get called 4 times if all QCs are existed)
   */
  validationQualityChecks(valid, position)
  {
    this.QCformValidations[position] = valid;
    this.checkandApprove();
  }
  /**
   * approve the runsheet when all QCforms are filled
   */
  checkandApprove() {
    if (this.approveLoading && this.QCformValidations.indexOf(false) < 0) {
      this.approveLoading = false;
      let zeroCount = 0;
      let hideCount = 0;
      let columnContainsValueMap = this.columnMap;
      columnContainsValueMap.shift();

      this.runsheetData.forEach(row => {
        row.forEach((value, index) => {
          if (columnContainsValueMap.includes(index) && value === 0) {
            zeroCount++;
          }
        })
        for (let i = 0; i < row.length; i++) {}
      })

      this._$mdDialog.show({
        clickOutsideToClose: true,
        escapeToClose: false,
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          jobs: this.jobs,
          samples: this.samples,
          runsheet: this.runsheet,
          hot: this.hot,
          zeroCount: zeroCount,
          hideCount: hideCount,
          approveLoading: this.approveLoading,
        },
        templateUrl: '/html/runsheet/approve-jobs-dialog.html',
        controller: 'ApproveJobsDialogCtrl',
      });

      this.$timeout(() => {
        this.approveLoading = true;
      }, 1000);
    }
  }

  // auditDialog(options)
  // {
  //   this.$mdDialog.show({
  //     clickOutsideToClose: true,
  //     escapeToClose: false,
  //     bindToController: true,
  //     controllerAs: 'vm',
  //     locals: options,
  //     templateUrl: '/html/auditTrail/audittrail-dialog.html',
  //     controller: 'AuditTrailDialogCtrl',
  //   });
  // }

  /**
   * Calls runsheet.claim
   */
  onClaimClick() {
    this.userClaimed = true;
    this.runsheetClaimed = true;
    let username = this.$rootScope.User.given_name + " " + this.$rootScope.User.surname;
    this.runsheet.claim((err) => {
      if (err) {
        this.userClaimed = false;
        this.runsheetClaimed = false;
        return false;
        // return this.$mdToast.show(
        //   this.$mdToast.simple()
        //   .textContent('Failed to claim runsheet')
        //   .position('bottom right')
        // );
      }
      this.hot.render();
      this.AuditTrailService.createAuditTrail({
        runsheet:[this.runsheet],
        action:"Claim Runsheet",
        entityType : 'runsheet'
      });
      this.$mdToast.show(this.$mdToast.simple()
        .textContent('Runsheet ' + this.runsheet.name + ' claimed')
        .position('bottom right'));

      this.claims = 'Claimed by: ' + username;
      // if (this.claims.length === 0) {
      //   this.claims = 'Claimed by: ' + username;
      // } else {
      //   this.claims += " " + username;
      // }
    });
  }
  onReleaseClick(){

    // console.log(this.userClaimed , this.runsheet.status, this.user.hasPermission("admin"))
    // console.log(this.$rootScope.User._id,this.runSheetClaimByID)
    // console.log(this.runsheet.processCompleted);
    if(this.runsheet.processCompleted == false) {
      this.afterChangeFlag = true;
    }
    
    this.allRunsheetData = {}


    // console.log("this.runsheetCells[0]", this.runsheetCells[0]);
    // console.log("this.runsheetReferences[0]", this.runsheetReferences[0]);

    // console.log("this.runsheetCells[1]", this.runsheetCells[1]);
    // console.log("this.runsheetReferences[1]", this.runsheetReferences[1]);

    // console.log("this.runsheetCells[3]", this.runsheetCells[2]);
    // console.log("this.runsheetReferences[3]", this.runsheetReferences[2]);
    

    if(this.runsheetCells!=undefined && this.runsheetCells && this.runsheetCells.length>0) {
      let runsheetTable = this.runsheetCells;
      runsheetTable.forEach((rowData,rindex)=>{
        let row = rindex;
        if(rowData && rowData.length>0) {
          rowData.forEach((columnData,cindex)=>{
            let col = cindex;

            let cellId = this.runsheetReferences[row][col] && this.runsheetReferences[row][col].ref;
            if(cellId!=undefined) {

              let obj = {
                id: cellId,
                val: columnData,
                row: rindex,
                col: cindex,
              };

              if(this.allRunsheetData[cellId]==undefined)
                this.allRunsheetData[cellId] = {}
              this.allRunsheetData[cellId] = obj
            }
          })
        }
      })
     
      console.log('allRunsheetData',this.allRunsheetData);
    }

    if(!this.userClaimed && this.$rootScope.User._id!=this.runSheetClaimByID && this.runsheet.status ===1 && 
      this.user.hasPermission("admin")) {
      
      var confirm = this.$mdDialog.confirm()
        .title(`This runsheet is claimed by ${this.claimed_by} . Are you sure to proceed? `)
        .ok('Yes')
        .cancel('No');

      this.$mdDialog.show(confirm).then(()=> {

        let data = {
          cells: this.changedCells,
          runsheetId: this.runsheet._id
        };
        var dataCopy = data
       
        this.$mdDialog.show({                
          templateUrl: '/html/runsheet/template.html',
          controllerAs: "vm",                                
          controller: ($scope) => {
          },
          clickOutsideToClose: false
        });

        let startTime = Math.round(new Date().getTime());
        let endTime;


        if(this.allRunsheetData!=undefined && this.allRunsheetData && typeof this.allRunsheetData=='object') {
          this.$http({
            method: 'POST',
            url: '/api/RunsheetValue/saveMulti',
            data: {runsheetId:this.runsheet._id,runsheetCells :this.allRunsheetData}
          }).then((err,data)=>{
            this.$mdDialog.hide();
            this.RunsheetStore.get({_id:this.runsheet._id},'',(err,dbRunsheet)=>{
              if(dbRunsheet && dbRunsheet.length>0 && dbRunsheet[0].processCompleted==false) {
                this.$rootScope.showError('Runsheet values are not saved properly. Please claim runsheet and release.');
              }
            });
            this.hot.render();
            this.releaseRunsheet();
            this.changedCells = []
            this.afterChangeFlag = 0
            endTime = Math.round(new Date().getTime());
            this.difference = endTime - startTime;
            //this.isRunsheetDataModified();
          },(err,data)=>{
            this.$mdToast.show(this.$mdToast.simple()
              .textContent('Error saving ' + this.changedCells.length + cellText)
              .position('bottom right'));

            endTime = Math.round(new Date().getTime());
            this.difference = endTime - startTime;
          });
        }
        this.hot.render();
      });
    }
    else {
      this.$mdDialog.show({                
        templateUrl: '/html/runsheet/template.html',
        controllerAs: "vm",                                
        controller: ($scope) => {
        },
        clickOutsideToClose: false
      });

      let startTime = Math.round(new Date().getTime());
      let endTime;

      if(this.allRunsheetData!=undefined && this.allRunsheetData && typeof this.allRunsheetData=='object') {
        this.$http({
          method: 'POST',
          url: '/api/RunsheetValue/saveMulti',
          data: {runsheetId:this.runsheet._id,runsheetCells :this.allRunsheetData}
        }).then((err,data)=>{
          this.$mdDialog.hide();
          this.RunsheetStore.get({_id:this.runsheet._id},'',(err,dbRunsheet)=>{
            if(dbRunsheet && dbRunsheet.length>0 && dbRunsheet[0].processCompleted==false) {
              this.$rootScope.showError('Runsheet values are not saved properly. Please claim runsheet and release.');
            }
          });
          this.hot.render();
          this.releaseRunsheet();
          this.changedCells = []
          this.afterChangeFlag = 0
          endTime = Math.round(new Date().getTime());
          this.difference = endTime - startTime;
          //this.isRunsheetDataModified();
        },(err,data)=>{
          this.$mdToast.show(this.$mdToast.simple()
            .textContent('Error saving ' + this.changedCells.length + cellText)
            .position('bottom right'));

          endTime = Math.round(new Date().getTime());
          this.difference = endTime - startTime;
        });
      }
      this.hot.render();
    }
  }

  releaseRunsheet(){
    this.userClaimed = false;
    this.runsheetClaimed = false;
    this.runsheet.release((err)=>{
      if (err) {
        this.userClaimed = true;
        this.runsheetClaimed = true;
        return this.$mdToast.show(
          this.$mdToast.simple()
          .textContent('Failed to release runsheet')
          .position('bottom right')
        );
      }
      this.hot.render();
      this.AuditTrailService.createAuditTrail({
        runsheet:[this.runsheet],
        action:"Release Runsheet",
        entityType : 'runsheet'
      });
      if(this.approveClick===true) {
        this.$rootScope.$broadcast('check-QCvalidation-event', {
          check: true
        });
        this.approveClick = false;
      }
      this.$mdToast.show(this.$mdToast.simple()
        .textContent('Runsheet ' + this.runsheet.name + ' Released')
        .position('bottom right'));
      this.claims = 'Claimed by: No One';
    });
  }


  /**
   * Directs the user to the Add Sample view
   * Automatically selects the sample type to be penetration
   */
  /* TODO: It might be nice to see the new samples' job as the one that is selected then the button is clicked
   * For this to work the event propagation needs to be stopped which requires the toolbar-view to use transclusion
   */
  onAddSampleClick() {
    this.BatchStore.get({
      _id: this.runsheet.batch
    }, {}, (err, batches) => {
      let jobIDs = this.jobs.map(job => job.id);
      this.JobStore.get({
        _id: jobIDs
      }, {
        populate: "samples labTests"
      }, (err, jobs) => {
        this.createAuditTrail({action:'Add Sample',auditMessage:'Adding samples'})
        this._$mdDialog.show({
          clickOutsideToClose: true,
          escapeToClose: false,
          bindToController: true,
          controllerAs: 'vm',
          locals: {
            jobs: jobs,
            runsheetID: this.runsheet.id,
            batch: batches[0],
            hot: this.hot,
          },
          templateUrl: '/html/runsheet/add-penetration-sample-dialog.html',
          controller: 'AddPenetrationSampleDialogCtrl',
        });
      })
    })
  }

  createAuditTrail(option) {

    
    var postData = { 
      entityType:'runsheet',
      entityId:this.runsheet._id,
      entityName:this.runsheet.name,
      userID:this.user._id,
      userName:this.user.given_name+" " + this.user.surname ,
      action:option.action,
      actionMessage:option.auditMessage
    };
    this.auditTrailStore.create(postData, (err,data) => {
        if(err){
          console.log(err,data)
        }
    })
  }

  onDeleteSampleClick() {
    this._$mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: false,
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        jobs: this.jobs,
        samples: this.samples,
        runsheetID: this.runsheet.id,
      },
      templateUrl: '/html/runsheet/delete-penetration-sample-dialog.html',
      controller: 'DeletePenetrationSampleDialogCtrl',
    });
    this.createAuditTrail({action:'Delete Sample',auditMessage:'Deleting Samples'})
    // this.BatchStore.get({
    //   _id: this.runsheet.batch
    // }, {}, (err, batches) => {
    //   let jobIDs = this.jobs.map(job => job.id);
    //   this.JobStore.get({
    //     _id: jobIDs
    //   }, {
    //     populate: "samples labTests runsheets"
    //   }, (err, jobs) => {
    //     this._$mdDialog.show({
    //       clickOutsideToClose: true,
    //       escapeToClose: false,
    //       bindToController: true,
    //       controllerAs: 'vm',
    //       locals: {
    //         jobs: jobs,
    //         samples: this.samples,
    //         runsheetID: this.runsheet.id,
    //         batch: batches[0],
    //         hot: this.hot,
    //       },
    //       templateUrl: '/html/runsheet/delete-penetration-sample-dialog.html',
    //       controller: 'DeletePenetrationSampleDialogCtrl',
    //     });
    //   })
    // })
  }

  /**
   * Opens dialog to edit row number
   */
  onEditRowNumberingClick() {
    this._$mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: false,
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        jobs: this.jobs,
        runsheet: this.runsheet,
        hot: this.hot,
        fields: this.visibleFields
      },
      templateUrl: '/html/runsheet/edit-table-dialog.html',
      controller: 'EditTableDialogCtrl',
    }).then((data)=>{

     
      if(data != 'cancel')
      {
        this.$state.reload();
        // this.setupRunsheet();
      }
    });
    this.createAuditTrail({action:'Edit Table',auditMessage:'Editing table'})
  }

  /**
   * Calls runsheet.generateCSV - Legacy
   * Updated to generate CSV file on the client side
   */
  onGenerateClick() {
    // Building the CSV from the Data two-dimensional array
    // Each column is separated by "," and new line "\n" for next row
    this.createAuditTrail({action:'Downloading CSV',auditMessage:'Downloading CSV'})
    // Build column header
    let csvHeaderContent = "data:text/csv;charset=utf-8,";
    let csvContent = '';
    _.forEach(this.runsheetData, (rowArray, rowIndex) => {
      let rowArrayCopy = angular.copy(rowArray);
      //splice the relation column i.e. the last column
      rowArrayCopy.splice(rowArrayCopy.length - 1, 1);
      _.forEach(rowArrayCopy, (col, colIndex) => {
        //dont print hidden columns
        // build header
        if (rowIndex == 0) {
          let header = this.getColumnHeader(colIndex);
          csvHeaderContent += !header ? '' : header + ",";
        }
        if (this.columnMap.includes(colIndex)) {
          csvContent += col + ",";
          if (this.columnMap.length - 1 == colIndex) {
            csvContent += "\r\n";
          }
        }
      });
    });

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    var encodedUri = encodeURI(csvHeaderContent + "\r\n" + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.runsheet.name + ".csv");
    link.innerHTML = "Click Here to download";
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  scrollTop() {
    this.$window.document.getElementById('top').scroll({
      top: 0,
      behavior: "smooth"
    })
    this.$timeout(() => {
      this.scrollTopBtn = false;
    }, 500);
  }

}

(function(app) {
  app.controller('RunsheetCtrl', RunsheetCtrl);
}(angular.module('app.runsheet')));
