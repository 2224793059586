'use strict';

/**
 * Controller for the customer list view
 */
class ContactListCtrl {
  constructor ($rootScope, $window, ContactStore, $state, $filter) {
    this.$window = $window;
    this.selectedRecords = [];
    this.records = [];
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.ContactStore = ContactStore;
    this.user = this.$rootScope.User
    this.order = 'name';

    // if($rootScope.User.permissions.indexOf('admin') == -1){
    //   this.query = {customer: $rootScope.User.customer};
    // }

    this.fields = [{
      path: 'identifier',
      name: 'Identifier',
    },{
      path: 'given_name',
      name: 'Name',
    },{
      path: 'phone',
      name: 'Phone #',
    },{
      path: 'mobile',
      name: 'Mobile #',
    }];
  }



  removeContact (contact) {
    // delete duplicate pendingContact if pendingContactDetailsIndex is defined

    if (contact.pendingContactDetailsIndex >= 0) {
      contact.pendingContactDetails.splice(contact.pendingContactDetailsIndex,1);

      contact.save((contactErr, contactData) => {
        if (contactErr) {
          console.error(contactErr);
          this.$rootScope.showToast('Failed to delete contact ' + contactData.name);
        } else {
          this.$rootScope.showToast(`Duplicate contact "${contactData.name}" deleted`);
          this.$state.reload();
        }
      });
    } else {
      contact.remove(true, (err, res) => {
        if(err == null && res == "") {
          this.$rootScope.showToast(`${ contact.name } successfully deleted`);
          this.$state.reload();
        }
      });
    }
  }
}

(function(app) {
  app.controller('ContactListCtrl', ContactListCtrl);
}(angular.module('app.customer')));
