'use strict';

/**
 * Controller for the lab locations list view
 */
class ReportListCtrl {

  constructor ($rootScope, $state, $stateParams, ReportStore, $filter) {
    this.$state = $state;
    this.ReportStore = ReportStore;
    
    this.populate = 'approved_by';
    this.order = { created_at : -1 };
    // this.actionHeader = '/runsheetListActionHeader.html';
    this.fields = [{
      name: 'Job',
      tpl: '/reportListJobNumber.html'
    }/*,{
      name: 'hidden Jobs for job number searching',
      hidden: false,
      transform: (record) => {
        return record.job_id;
      }

    }*/,{
      name: 'Client',
      path: 'customerName',
    },{
      name: 'Preservative',
      path: 'preservative',
    },{
      name: 'Update At',
      path: 'updated_at',
      transform: (value) => {
        return $filter('date')(value,  $rootScope.dateFilter);
      }
    }];

    this.tabs = [{
      name: 'UnApproved',
      query: { status : 0 },
      status: '0',
    },{
      name: 'Approved',
      query: { status : 2 },
      status: '2',
    }];

    this.tabs.map((tab) => {
      tab.active = $stateParams.tab === tab.status;
      return tab;
    })

  }

  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('reports.view', { id: selected.id });
  }

  onTabChange(tab) {
    this.$state.go(this.$state.current, { tab: tab.status }, { notify: false });
    if (this.fields.length == 4 && tab.status === '2') {
      this.fields.push({
        name: 'Approved By',
        path: 'approved_by',
        transform: (value) => {
          if (!value || !value.given_name) {
            return '';
          } else {
            return value.given_name + ' ' +  value.surname;
          }
        }
      })
    } else if (this.fields.length == 5 && tab.status === '0') {
      this.fields.pop();
    }
  }

}

(function(app) {
  app.controller('ReportListCtrl', ReportListCtrl);
}(angular.module('app.report')));
