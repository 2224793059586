'use strict';

/**
 * Controller for the toolbar directive
 */
class ToolbarCtrl {

  constructor($mdMedia, $mdSidenav, $scope,$rootScope,$window, Toolbar,$http,AuditTrailService) {
    this.$mdMedia = $mdMedia;
    this._$mdSidenav = $mdSidenav;
    this.$window = $window;
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.AuditTrailService = AuditTrailService;
    // gets the list of items
    Toolbar.addItem({
      icon: '/icons/md/action/svg/design/ic_settings_24px.svg',
      title: 'Update Ion Data Now',
      action: this.updateData.bind(this)
    });
    this.items = Toolbar.getItems();    
  }

  /**
   * Toggles the main App sidenav
   */
  toggleSidenav() {
    this._$mdSidenav('sidenav').toggle();
  }
  updateData(){
    let location = this.$window.location.href;
    
    // console.log(location);
    let temp = location.split('/');

    if(temp.length == 6 && temp[3] == 'jobs')
    {

      //   return;
      //   this.$rootScope.showToast('Please go to Jobs to Update');
      // {
      // if(temp[3] != 'jobs')
      // }
      let id = temp[5].split('?')[0];
      console.log(id);

      this.$http({
        method: 'POST',
        url: '/api/TimberPortal/updateJobData/',
        data : {rawId:id}
      }).then( (res) => {

        
        if(res.data.job){
          this.$rootScope.showToast("Job Successfully Updated");
          let current_datetime = new Date();
          this.AuditTrailService.createAuditTrail({
            job:res.data.job,
            entityId:res.data.job.job_id,
            action:"Job : " + res.data.job.job_id + " updated from portal at " + current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds(),
            entityType : 'job'
          });
        }
        else{
          this.$rootScope.showToast('Failed to Update Job. Only Jobs in Prep Phase are allowed to update');
        }
        // this.$window.location.reload();
      });

    }
    else
    {
      // console.log('general h ');
      this.$http({
      method: 'POST',
      url: '/api/TimberPortal/updateAllJobs/'
      }).then((res)=>{
        if(res.data.msg){
          console.log(res);
          this.$rootScope.showToast("ION Data Successfully Updated From Portal");
        }
        else
          this.$rootScope.showToast(res.data.err);
        // location.reload();
      });
    }
  }
}

(function (app) {
  app.controller('ToolbarCtrl', ToolbarCtrl);
}(angular.module('app.toolbar')));