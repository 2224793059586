'use strict';

(function (app) {
  app.factory('settingStore', function (Store, $http) {
    const settingStore = new Store({
      model: 'setting'
    });

    settingStore.create = function (data, callback) {
     

      this._$http({
        method: 'POST',
        url: this.baseRoute + '/createSetting',
        data: data
      }).then(
        (res) => {
          callback(null, res.data);
        },
        (err) => {
          callback(err);
        }
      );
    }
    return settingStore;
  });
  
}(angular.module('app.setting')));
