'use strict';

(function (app) {
  app.factory('Report', function ($http, Resource) {

    return class Report extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'Report' }, data, metaData, callback);
      }

      generateXML (callback) {
        $http({
          method: 'GET',
          url:    '/api/Report/generateXML/' + this._id,
        }).then(
          function (res) {
            callback(null, res);
          },
          function (err) {
            callback(err);
          }
        );
      }
    };

  });
}(angular.module('app.report')));
