class BalanceSettingsCtrl {
  constructor($mdSidenav, $mdToast, $scope, $timeout, Toolbar, WebUSB) {
    this.$mdToast = $mdToast;
    this.$mdSidenav = $mdSidenav;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.Toolbar = Toolbar;
    this.WebUSB = WebUSB;
    
    // this.Toolbar.addItem({
    //   icon: '/icons/md/action/svg/design/ic_settings_24px.svg',
    //   title: 'Balance Settings',
    //   index: 5,
    //   action: this.toggleSideBar.bind(this),
    // });

    this.log = [];
    
    // Listen for webusb data
    this.$scope.$on('webusbDataIn', ($event, data) => {
      this.log.push({
        data: data,
        source: 'balance',
      });
      this.$timeout(() => {
        this.$scope.$apply();
      });
    });

    // Settings
    this.printMode = 1;
  }

  toggleSideBar() {
    this.$mdSidenav('balanceSettings').toggle();    
  }

  connect() {
    this.WebUSB.requestDevice('arduino', (error, device) => {
      if (error) {
        // on error
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Failed to connect balance: ' + error.message)
            .position('bottom right')
        );
        //callback(error);
        console.log(error);
      } else {
        // on success
        this.device = device;
        this.balanceConnected = true;
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Balance connected')
            .position('bottom right')
        );

        // Start listening for data
        this.WebUSB.getData();
        this.loadSettings();
      }
    });
  }
  
  sendCommand(command) {
    const textEncoder = new TextEncoder();
    this.WebUSB.sendData(textEncoder.encode(command));
    this.log.push({
      data: command,
      source: 'client'
    });    
  }

  loadSettings() {
    this.updatePrintMode();
  }
  
  
  updatePrintMode() {
    const command = `P${ this.printMode }`;
    this.sendCommand(command);
  }

  onInputSubmitted() {
    this.sendCommand(this.commandToSend);

    this.log.push({
      data: this.commandToSend,
      source: 'client'
    });
    
    this.commandToSend = null;    
  }
}

(function (app) {
  app.controller('BalanceSettingsCtrl', BalanceSettingsCtrl);
}(angular.module('app.balanceCommunicator')));
