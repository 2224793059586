'use strict';

const pad = require('pad-left');

(function (app) {
  app.factory('setting', function ($http, $injector, $mdDialog, $rootScope, Resource) {
    return class setting extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'setting' }, data, metadata, callback);
      }
      
    };
  });
}(angular.module('app.setting')));
