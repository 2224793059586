'use strict';

const moment  = require('moment');

(function (app) {

  // set up moment to provide dates in NZ format
  app.config(function ($mdDateLocaleProvider) {
    $mdDateLocaleProvider.parseDate = function (dateString) {
      var m = moment(dateString, 'DD/MM/YYYY');
      return m.isValid() ? m.toDate() : new Date(NaN);
    };
    $mdDateLocaleProvider.formatDate = function (date) {
      return moment(date).format('DD/MM/YYYY');
    };
  });

}(angular.module('app.core', [
  // TODO: check if realtime dependency is actually needed
  'app.realtime'
])));