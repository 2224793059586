'use strict';

(function (app) {
  app.factory('QualityCheckValue', function (Resource) {

    return class QualityCheckType extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'QualityCheckValue' }, data, metaData, callback);
      }
    };

  });
}(angular.module('app.qualityCheckValue')));
