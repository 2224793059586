'use strict';

(function (app) {

  app.config(function ($stateProvider) {

    $stateProvider.state('contacts', {
      url: '/contacts',
      views: {
        '@': {
          templateUrl: '/html/contact/contact-list.html',
          controller: 'ContactListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Contacts'
      }
    });

    $stateProvider.state('contacts.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/contact/contact-form.html',
          controller: 'ContactFormCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      resolve: {
        contact: function ($stateParams, ContactStore) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
            if ($stateParams.id === 'new') {
              // if customer does not exist create new customer
              ContactStore.newResource({}, resolve);
            } else {
              // if customer exists then load it
              //contacts.contacts.agent_from contacts.programs contacts.services 
              ContactStore.get({ _id: $stateParams.id }, { populate:'customer createdByUserId'}, (err, contact) => {
                if (err || contact.length === 0) {
                  // on fail
                  reject(err || 'No contact found');
                } else {
                  // on success
                  resolve(contact[0]);
                }
              });
            }
          });
        },
        customer: function ($rootScope, CustomerStore) {
          // ui-router requires a promise
          let user = $rootScope.User
          return new Promise((resolve, reject) => {
            CustomerStore.get({ _id: user.customer }, { }, (err, customer) => {
              if (err || customer.length === 0) {
                // on fail
                reject(err || 'No customer found');
              }
              else if(customer.length === 0){
                resolve([]);
              }
               else {
                // on success
                resolve(customer[0]);
              }
            });  
          });
        },
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });  
  
  app.config(function (SidenavProvider) {
  });

}(angular.module('app.contact', [
  require('angular-material'),
  'app.core',
  'app.route',
  'app.statics'
])));
