'use strict';

/**
 * Controller for the customer form
 */
class CustomerFormCtrl {
  
  constructor(CustomerStore, Statics, $timeout, $element) {
    this.CustomerStore = CustomerStore;

    // model for the most recent service added (or not yet added)
    this.service = '';
    // id of the p42Selected customer
    this.selectedId = '';
    // customer to display info for
    CustomerStore.newResource({}, (customer) => {
      this.customer = customer;
    });
    // list of customers allowed to be p42Selected
    this.customers = [];
    this.noCustomers = false;

    // setup referral types
    this.referralTypes = [];
    this.referrals = [];
    Statics.getFromHub('referral_types', (err, referralTypes) => {
      this.referralTypes = referralTypes;
      // if this customer is selected then load the referrals
      this.populateReferrals(this.customer);
    });

    // wait until the DOM has loaded
    $timeout(() => {
      // The md-select directive eats keydown events for some quick select logic.
      // Since we have a search input here, we don't need that logic.
      $element.find('md-select-header').find('input').on('keydown', function(ev) {
          ev.stopPropagation();
      });
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit () {
    if (this.p42Editable === undefined) {
      // if p42Editable was not specified default to making the customer editable
      this.p42Editable = true;
    }
    if (this.p42Required === undefined) {
      // if p42Required was not specified default to making the form not required
      this.p42Required = false;
    }
    if (this.p42Default !== undefined) {
      // if the default id is provided then select that customer
      if (this.p42Default.id) {
        // if the customer is an object then use it instead of loading it from the Hub
        this.customers.push(this.p42Default);
        this.customer = this.p42Default;
        this.p42Default = this.p42Default.id;
        this.selectedId = this.p42Default;
        // if this customer is selected then load the referrals
        this.populateReferrals(this.customer);
      } else if (typeof this.p42Default === 'string') {
        // if the value is an ObjectId string then load the customers
        this.loadCustomers().then((done) => {
          this.selectedId = this.p42Default;
          // same as select customer without sending the id
          const found = this.customers.find((element, index, array) => {
            if (element.id === this.selectedId) {
              // if this customer is selected then load the referrals
              this.populateReferrals(element);
              return true;
            }
            // else this customer is not selected
            return false;
          });
          if (found) {
            // only update the customer if it was found
            this.customer = found; 
          }
        });
      }
    }
  }

  /**
   * Load list of customers
   */
  loadCustomers () {
    if (!this.noCustomers && this.customers.length === 0) {
      return new Promise ((resolve, reject) => {
        this.CustomerStore.get({}, (err, customers) => {
          // on error reject
          if (err) return reject(err);
          // on success
          // customers should only have one customer with one location (at most)
          if (customers.length > 0) {
            // if there are customers to chose from then add them to the list
            this.customers = customers;
          } else {
            // if there are no customers make sure we don't do a request every time
            this.noCustomers = true;
          }
          resolve(this.customers);
        });
      });
    }
  }

  /**
   * Adds the customer to the database and adds it to the manual
   */
  saveCustomer () {
    if (this.p42SelectOnly !== undefined) {
      // if the form is not select only then set to false
      this.p42SelectOnly = false;
    }
    // make the referrals save on the customer
    this.customer.referrals = this.referrals;
    // now save the customer
    this.customer.save((err, updatedCustomer) => {
      if (err) return console.error(err);
      this.customer = updatedCustomer;
      this.selectedId = updatedCustomer.id;
      // update select options
      this.customers.push(updatedCustomer);
      // output the customer
      this.selectCustomer();
    });
  }

  /**
   * Populates the form with the selected customer's information
   */
  selectCustomer () {
    const customer = this.customers.find((element, index, array) => {

      //First of all, check the selectedId to ensure that something is actually selected
      if (!this.selectedId || this.selectedId === '') {
        //Nothing was selected, so set selected to blank string to avoid transitioning or crashes
        this.customer.name = '';
        this.p42Selected({ id: '' });
        return false;
      }

      if (element.id === this.selectedId) {
        this.p42Selected({ id: element.id });
        return true;
      }

      //Nothing was found/selected, so set selected to blank string to avoid transitioning or crashes
      this.p42Selected({ id: '' });
      return false;
    });
    if (!this.p42SelectOnly && customer) {
      // if the form selection is allowed to persist then set the customer
      this.customer = customer;
    } else {
      // if the form is not allowed to persist then unselect the id as the output has been sent
      this.selectedId = '';
    }
  }

  /**
   * Populates the referrals array based on the values in the customer's referrals
   */
  populateReferrals(customer) {
    if (customer.referrals) {
      // if it is possible for referrals to already be specified
      const refNames = customer.referrals.map(r => r.name);
      this.referrals = this.referralTypes.filter((refType) => {
        let index = refNames.indexOf(refType.name);
        if (index === -1) {
          // if this refType is not selected
          return false;
        }
        // if this refType is selected then populate the value
        refType.notes = customer.referrals[index].notes;
        return true;
      });
    }
  }
}

(function (app) {
  app.controller('CustomerFormCtrl', CustomerFormCtrl);
}(angular.module('app.customerClient')));
