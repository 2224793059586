'use strict';

(function (app) {
  app.factory('SubscriptionStore', function (Store, $http) {
    const SubscriptionStore = new Store({
      model: 'Subscription'
    });

    SubscriptionStore.getSubscriptionList = function (data, callback) {
      $http({
        method: 'GET',
        url: '/api/subscription/get-subscription-list',
        data: {}
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };

    return SubscriptionStore;
  });

}(angular.module('app.subscription')));
