'use strict';

(function (app) {
  app.factory('LabStandard', function ($http, $injector, $mdDialog, Resource) {
    return class LabStandard extends Resource {
      constructor (data, metaData, callback) {
        super('LabStandard', data, metaData, callback);
      }
    };
  });
}(angular.module('app.labStandard')));
