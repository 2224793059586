'use strict';

const pad = require('pad-left');

(function (app) {
  app.factory('APILogEntry', function ($http, $injector, $mdDialog, $rootScope, Resource) {
    return class APILogEntry extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'APILogEntry', baseRoute: 'api' }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.api')));
