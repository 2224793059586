'use strict';
const math = require('mathjs');


class ExpressionDialogCtrl{

	constructor($scope, $state, $mdToast, $mdDialog, $rootScope, $http, RunsheetTypeStore, RunsheetTypeRowStore, RunsheetTypeRowCellStore, allRunsheetTypes, cell) {
    this.$state = $state;
    this.$mdToast = $mdToast;
    this.$http = $http;
    this.user = $rootScope.User;

    this.RunsheetTypeRowStore = RunsheetTypeRowStore;
    this.RunsheetTypeRowCellStore = RunsheetTypeRowCellStore;

    //this.allRunsheetTypes = allRunsheetTypes;
    this.allRunsheetTypes = allRunsheetTypes.filter(rsType => rsType.status <= 2 );
    
    this.allRows = [];
    this.allFields = [];
    this.cell = cell;

    this.error = false;
    this.selectedType = this.runsheetTypeId;
    this.changeRunsheetType();
    
    this.expressionMap = new Map();
    this.expressionString = '';
    if (this.cell && this.cell.expression_pretty && this.cell.expression) {
      this.expressionString = this.cell.expression_pretty;
      let keys = this.cell.expression_pretty.match(/[a-zA-Z0-9_:]{10,}/g);
      let values = this.cell.expression.match(/VAL_\w{24}/g);
      if (keys && values && keys.length === values.length) {
        for( let i=0;i<keys.length;i++) {
          this.expressionMap.set(keys[i],values[i]);
        }
      } else {
        this.expressionString = '';
      }
    }
    // console.log(this.expressionMap)
    this.back = () => {
        $mdDialog.hide();
    };

	}

	changeRunsheetType(){
    this.allRows = [];
    this.allFields = [];
    this.selectedRow = '';
    this.selectedField = '';

    if (this.selectedType.length === 24 ) {
      this.RunsheetTypeRowStore.get({ runsheetType : this.selectedType, removed : false }, (err, rows) =>{
        if (rows.length === 0){
          this.allRows = [];
        } else {
          // console.log('rows',rows)
          // Control cells can not have value in the sample row
          if (this.cell.runsheetRow.type === 'Control') {
            rows = rows.filter(row => row.type === 'Control');
          }
          this.allRows = rows;
          this.selectedRow = this.cell.runsheetRow.id;
          this.changeRow();
        }
      })
    }
	}

	changeRow(){
    this.allFields = [];
    this.selectedField = '';

    if (this.selectedRow.length === 24 ) {
      this.RunsheetTypeRowCellStore.get({ runsheetRow : this.selectedRow }, (err, cells) => {
        if (cells.length === 0){
          this.allFields = [];
        } else {
          // console.log('cells',cells)
          this.allFields = cells.filter(cell => cell.type !== 'N');
        }
      })
    }
	}

	textareaBlur(){
		this.expressionString = this.expressionString?this.expressionString.replace(/\s/g, ''):"";
	}

	textareaChange(){
		this.error = false;
	}

	addExpression(){
		let runsheetTypeFormat = this.allRunsheetTypes.find(runsheetType => runsheetType.id === this.selectedType.toString());
		let runsheetTypeRowFormat = this.allRows.find(row => row.id === this.selectedRow.toString());
		let runsheetTypeFieldFormat = this.allFields.find(field => field.id === this.selectedField.toString());
    
    runsheetTypeFormat = runsheetTypeFormat.name+'_'+runsheetTypeFormat.version_number;
		runsheetTypeRowFormat = runsheetTypeRowFormat.name?runsheetTypeRowFormat.name:runsheetTypeRowFormat.type;
		runsheetTypeFieldFormat = runsheetTypeFieldFormat.name;
		
		let expressionFormatted = runsheetTypeFormat + ':' + runsheetTypeRowFormat + ':' + runsheetTypeFieldFormat;
		//remove all math character with '_' 
    console.log(expressionFormatted);
	
    expressionFormatted = expressionFormatted.replace(/[+*=\/ ()-]/g, '_');
		console.log(expressionFormatted);
		
		this.expressionString = this.expressionString + '  ' + expressionFormatted;

		this.expressionMap.set(expressionFormatted,'VAL_'+this.selectedField.toString());
		// console.log(this.expressionMap)
	}

	save(){
		this.textareaBlur();
		let newExpressionPretty = this.expressionString;
		let newExpression = this.expressionString;
		let testString = this.expressionString;

		this.expressionMap.forEach((value, key) => {
			newExpression = newExpression.replace(new RegExp(key, 'g'), value)
			testString = testString.replace(new RegExp(key, 'g'), '1')
		})

		this.cell.expression = newExpression;
    this.cell.expression_pretty = newExpressionPretty;
    
    //replace Val_id to 1 to test the math calculation
		try {
			// console.log(testString)
			math.eval(testString);
		} catch (err) {
			this.error = true;
			console.error(err)
			return;
    }

    //make sure the expression don't have a two val_id are adjacent to each other
    if (testString) {
      let chars = testString.split("");
      for (let i=0;i<chars.length-1;i++) {
        if (chars[i] === '1' && chars[i] === chars[i+1]) {
          this.error = true;
          return
        }
      }
    }

		this.cell.save((err) => {
      if (err) {
        console.error(err)
      } else {
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Cell Expression Updated')
            .position('bottom right')
            .hideDelay(2000)
        );
        this.back();
        this.$state.reload();
      }
    })
    


	}
}

(function (app) {
    app.controller('ExpressionDialogCtrl', ExpressionDialogCtrl);
  }(angular.module('app.runsheetType')));