'use strict';

(function (app) {
  app.factory('FieldStore', function (Store) {
    const FieldStore = new Store({
      model: 'Field'
    });

    return FieldStore;
  });

}(angular.module('app.field')));
