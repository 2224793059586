'use strict';

(function (app) {
  app.config(function (hljsServiceProvider) {

    hljsServiceProvider.setOptions({
      // replace tab with 2 spaces
      tabReplace: '  '
    });

  });

  app.filter('p42StripTags', function() {
    return function(html) {
      // always returns a string with tags stripped
      return html ? String(html).replace(/<[^>]+>/gm, '') : '';
    };
  });

}(angular.module('app.api', [
  require('angular-material'),
  require('angular-highlightjs'),
  'app.core',
  'app.route'
])));
