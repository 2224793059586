'use strict';

(function (app) {

  app.config(function ($stateProvider) {

    $stateProvider.state('locations', {
      url: '/locations',
      views: {
        '@': {
          templateUrl: '/html/location/customer-location-list.html',
          controller: 'CustomerLocationListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Locations'
      }
    });

    $stateProvider.state('locations.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/location/location-form.html',
          controller: 'LocationFormCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      resolve: {
        location: function ($stateParams, LocationStore) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
            if ($stateParams.id === 'new') {
              // if customer does not exist create new customer
              LocationStore.newResource({}, resolve);
            } else {
              
              // if customer exists then load it
              //locations.contacts.agent_from locations.programs locations.services 
              LocationStore.get({ _id: $stateParams.id }, { populate: 'customer createdByUserId'}, (err, locations) => {
                if (err || locations.length === 0) {
                  // on fail
                  reject(err || 'No location found');
                } else {
                  // on success
                  resolve(locations[0]);
                }
              });
            }
          });
          
        },
        programs: function ($stateParams, ProgramStore) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
          
            ProgramStore.get({ code: "ION TEST" }, { }, (err, programs) => {
              if (err || programs.length === 0) {
                // on fail
                reject(err || 'No program found');
              } else {
                // on success
                resolve(programs[0]);
              }
            });
          });
          
        },
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });  
  
  app.config(function (SidenavProvider) {
  });

}(angular.module('app.location', [
  require('angular-material'),
  'app.core',
  'app.route',
  'app.statics'
])));
