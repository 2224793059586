'use strict';

/**
 * Controller for the file list view
 */
class FileListCtrl {

  constructor ($scope, $window, $mdDialog, $mdMedia, FileStore, $rootScope, $state) {
    this.$window = $window;
    this.$mdDialog = $mdDialog;
    this.$mdMedia = $mdMedia;
    this.$rootScope =  $rootScope;
    this.selectedRecords = [];
    this.records = [];
    this.FileStore = FileStore;
    this.$state = $state;

    this.user = this.$rootScope.User;

    this.query = {'uploader':this.user.id}
    // Most recently updated first, then name
    this.order = {
      date_updated: -1,
      name: 1
    };

    this.fields = [{
      name: 'Preview',
      //tpl:  '/html/fileShared/file-preview.html'
      transform: (file) => {
        return '<div layout="row">'+
                  '<a href="" ng-click="vm.onPreviewClick(record, $event)">' +
                    '<img class="show-transparency file-preview" flex="none" src="'+ file.data_uri +'" alt="'+file.filename+'"/>'+
                  '</a>'+
                '</div>';
        
      }
    }, {
      path: 'name',
      name: 'Filename'
    }, {
      path: 'extension',
      name: 'Extension',
      hideXs: true
    }, {
      path: 'description',
      name: 'Description'
    }];

  }

  preview(file, event) {
    
    // dont read the image if already read before
    if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)  && file.source_image_uri) {
      file.showPhotoDialog(file, event);
    } else { 
      this.FileStore.downloadFile(file, (err) => {
        if(err) console.error(err);
        else    console.log('File downloaded successfully');

        if (!err && file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)) {
          file.showPhotoDialog(file, event);
        } 
      });
    }
  }

  onRecordChange(records) {
    this.records = records;
  }
  
  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  removeFile(file){
    file.__meta.options.baseRoute += '/delete';
    file.remove(true, (err, res) => {
      if(res == "OK") {
        this.$rootScope.showToast(`${ file.filename } successfully deleted`);
        this.$state.reload();
      }
    });
  }
}

(function(app) {
  app.controller('FileListCtrl', FileListCtrl);
}(angular.module('app.fileshared')));
