'use strict';

(function (app) {
  app.factory('File', function ($http, Resource) {
    return class File extends Resource {
      constructor(data, metadata, callback) {
        super({ model: 'File', realtime: false }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.fileshared')));