'use strict';

(function (app) {
  app.factory('LabLocationStore', function (Store) {
    const LabLocationStore = new Store({
      model: 'LabLocation'
    });

    //add methods

    return LabLocationStore;
  });

}(angular.module('app.labLocation')));
