'use strict';

const _ = require('lodash');

/**
 * Controller for the labTests list view
 */
class LabTestListCtrl {

  constructor ($rootScope, $state, $stateParams, $mdToast, LabTestStore) {
    this.LabTestStore = LabTestStore;
    // this.populate =  'approvals.user.contact';
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$mdToast = $mdToast;
    //this.tab = $stateParams.tab;
    //console.log(" tab: "+ this.tab);
    this.fields = [{
      name: 'Name',
      path: 'unique_name',
    },{
      name: 'Version',
      path: 'version_number',
      transform: (value) => {
        let version = '';
        if ( parseInt(value) > 0 )
          version = value;
        return version;
      }
    },{
      name: 'Report Name',
      path: 'report_name',
    },{
      name: 'Fields',
      path: 'fields',
      transform: (array) => {
        return array.length
      }
    },{
      name: 'Status',
      path: 'status',
      transform: (value) => {
        let transform = '';
        switch(value) {
        case 2:
          transform = 'Approved';
          break;
        case 4:
          transform = 'Retired';
          break;
        default:
          transform = 'Draft';
        }
        return transform;
      }
    },{
      name: 'Approvals',        
      tpl: '/labtestApprovals.html'
    }];

    this.sort = { unique_name: 1 };

    this.tabs = [{
      name: 'Draft',
      query: { status : 0 },
      status: '0',
      slug: 'Draft'
    },{
      name: 'Approved',
      query: { status : 2 },
      status: '2',
      slug: 'Approve'
    },{
      name: 'Retired',
      query: { status : 4 },
      status: '4',
      slug: 'Retire'
    }];

    this.tabs.map((tab) => {
      tab.active = $stateParams.tab === tab.status;
      return tab;
    })

  }

  /**
   * Called when the active tab is changed
   */
  onTabChange(tab) {
    this.$state.go(this.$state.current, { tab: tab.status }, { notify: false });
  }

  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('labTests.edit', { id: selected.id });
  }

}

(function(app) {
  app.controller('LabTestListCtrl', LabTestListCtrl);
}(angular.module('app.labTest')));
