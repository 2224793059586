'use strict';

const moment = require('moment');

/**
 * Controller for the subscription edit view
 */
class SubscriptionEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, $scope, subscription, programs) {
    this.$state = $state;
    this.$scope = $scope;
    this.subscription = subscription;
    this.programs = programs;
    this.$scope.breadcrumb = this.subscription.name?this.subscription.name:'New Subscription';
  }

  /**
   * Toggles the model's start_date data
   */
  changeStartDate() {
    if (this.subscription.start_date) {
      // if there is a start date then remove it
      this.oldStartDate = this.subscription.start_date;
      delete this.subscription.start_date;
    } else if (this.oldStartDate) {
      // if there was a start date then restore it
      this.subscription.start_date = this.oldStartDate;
    } else {
      // if there is and was no start date
      this.subscription.start_date = moment().startOf('day');
    }
  }

  /**
   * Toggles the model's end_date data
   */
  changeEndDate() {
    if (this.subscription.end_date) {
      // if there is a end date then remove it
      this.oldEndDate = this.subscription.end_date;
      delete this.subscription.end_date;
    } else if (this.oldEndDate) {
      // if there was a end date then restore it
      this.subscription.end_date = this.oldEndDate;
    } else {
      // if there is and was no end date
      this.subscription.end_date = moment().startOf('day');
    }
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.submitting = true;
    this.subscription.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
        this.submitting = false;
      } else {
        // on success redirect
        this.redirect();
      }
    });
  }

  /**
   * Redirects to the parent state after the user is done with this one
   */
  redirect() {
    this.$state.go('subscriptions');
  }
}

(function (app) {
  app.controller('SubscriptionEditCtrl', SubscriptionEditCtrl);
}(angular.module('app.subscription')));
