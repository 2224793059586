'use strict';

(function (app) {
  app.factory('Role', function (Resource) {
    return class Role extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Role' }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.role')));
