'use strict';

(function (app) {
  app.factory('RoleStore', function (Store) {
    const RoleStore = new Store({
      model: 'Role'
    });

    return RoleStore;
  });

}(angular.module('app.role')));
