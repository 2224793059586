'use strict';

(function (app) {
  app.factory('RunsheetTypeRowStore', function (Store, $http) {
    const RunsheetTypeRowStore = new Store({
      model: 'RunsheetTypeRow'
    });

    RunsheetTypeRowStore.removeRow = function(rowID, callback = angular.noop){
      const url = '/api/RunsheetTypeRow/' + rowID;

      $http.delete(url)
      .then( 
        (res) => callback(res.data), 
        (err) => console.error('Error removing row:',err)
      );
    }

    return RunsheetTypeRowStore;
  });

}(angular.module('app.runsheetTypeRow')));
