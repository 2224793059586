'use strict';

/**
 * Creates a APIData service that fetches API data from the server and processes it client-side
 */
function AuditTrailService(auditTrailStore,$state,$rootScope) {

  class AuditTrailService {

    constructor(auditTrailStore,$state,$rootScope) {
		this.auditTrailStore = auditTrailStore;
		this.$state = $state;
		this.user = $rootScope.User;
    }

    createAuditTrail(data){

     var _this = data;
     var user = this.user;

	  var postData = {
	  entityType:data.entityType,
	  userID:user._id,
	  userName:user.given_name + " " + user.surname,
	  action:data.action
   	};

   	var multiplePostData = {"multipleRunsheets":[]}

    if(data.entityType=='runsheet')
    {
      if(data.runsheet.length == 1){
        postData['entityId'] = data.runsheet[0]._id;
        postData['entityName'] = data.runsheet[0].name;
      }
      postData['jobIDS'] = data.jobIDS;
    }
    else if(data.entityType=='report')
    {
      postData['entityId'] = data.report._id;
      postData['entityName'] = data.report.job_id;
      postData['jobID'] = data.jobID;
    }
    else
    {
      postData['entityId'] = data.job.id;
      postData['entityName'] = data.job.job_id;
    }
    if(data.createMultiple!=undefined && data.createMultiple)
    {
      if(data.entityType=='runsheet' && data.runsheet.length>1) //for more than 1 runsheets
      {

        postData['jobIDS'] = data.jobIDS;
        
        data.runsheet.forEach(function(_runsheet){

          let runsheetData = {
            entityType:_this.entityType,
            userID:user._id,
            userName:user.given_name + " " + user.surname,
            action:_this.action,
            actionMessage:_this.auditMessage,
            entityId:_runsheet.id,
            entityName:_runsheet.name,
            jobIDS:_this.jobIDS   //changed here
          }

          multiplePostData["multipleRunsheets"].push(runsheetData);
        
        }) 
        console.log(multiplePostData,postData);
        this.auditTrailStore.create(multiplePostData, (err,data1) => {
          if(err)
            console.log(err,data1)
        });
      }
      else
      {
        console.log('1 runsheet');
      }
    }
    else
    {

      this.auditTrailStore.create(postData, (err,data2) => {
          
          if(data.allGood)
          {
            this.changingPhase = true;
            data.job.samplesReceived((err, done) => {
              this.changingPhase = false;
              if (err) {
                console.log(err);
              } else {
                this.$state.reload();
              }
            });
          }
          if(data.stateReload)
            this.$state.reload();

          if(data.transitionTo)
            this.$state.transitionTo(data.transitionTo, { id: data.job._id });
      })

    }
      
    }

    
  }

  return new AuditTrailService(auditTrailStore,$state,$rootScope);
}

(function (app) {
  app.factory('AuditTrailService', AuditTrailService);
}(angular.module('app.auditTrail')));


