'use strict';

const async = require('async');

/**
 * Controller for the quality check edit view
 */
class JobViewCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($scope, $rootScope, $state, $templateCache, $sce,$http, $mdDialog, job, JobStore, auditTrailStore, SampleStore, LabTestStore,AuditTrailService) {
    this.$mdDialog = $mdDialog;
    this.LabTestStore = LabTestStore;
    this.$state = $state;
    this.$scope = $scope;
    this.auditTrailStore = auditTrailStore;
    this.AuditTrailService = AuditTrailService;
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.job = job;
    this.siteAddress = '';
    this.user = $rootScope.User;
    this.SampleStore = SampleStore;
    this.JobStore = JobStore;
    this.priority = 'Normal';
    this.auditTrail = []
    this.showAudit = false
    this.inReadyTesting = false
    this.getAuditTrails()
    if (this.job.priority){
      if (this.job.priority === 3)
        this.priority = 'Medium';
      else if (this.job.priority === 5)
        this.priority = 'High';
    }

    if (this.job.phase === 2) {
      this.job.matchingLabTests((err, matchingLabTests) => {
        this.matchingLabTests = matchingLabTests;
      });
      if (!this.job.customer) {
        //console.log("----------------- getting location information ");
            
        this.job.matchingLocation((err, data) => {
          if(data){
            try{
                this.job.location = data.location;
                this.job.customer = data.location.customer;
                this.job.customerName = data.location.customer.name;
                //console.log("-------------- Location Found ---------------job.customerName: ", job.customerName);
                if(job.location.physical){
                  let { unit, street, suburb, city, region, postcode, country } = this.job.location.physical;
                  this.siteAddress = [ unit, street, suburb, city, region, postcode, country ].join(" ");
                }
                console.log(siteAddress);

            }catch(e){
              console.log(e)             
            }
          }
        })
      }else if (job.location) {
        if(job.location.physical){
        //console.log("----------------- job.location.name: ", job.location.name);
          let { unit, street, suburb, city, region, postcode, country } = this.job.location.physical;
          this.siteAddress = [ unit, street, suburb, city, region, postcode, country ].join(" ");
        }
      }
    }else if (job.location){
      if(job.location.physical){
        //console.log("----------------- job.location.name: ", job.location.name);
        let { unit, street, suburb, city, region, postcode, country } = this.job.location.physical;
        this.siteAddress = [ unit, street, suburb, city, region, postcode, country ].join(" ");
      }
    }
    
    // track button states
    this.changingPhase = false;
    this.updatingSamples = false;

    // setup sample table
    this.sampleFields = [{
      path: 'sample_name',
      name: 'Sample Name'
    }];

    
    this.$scope.breadcrumb =  'T' + job.job_id;
    // add dynamic fields for the status if the job is in Lab Received
    this.reloadFields();

    // add reload fields to the phase change
    $scope.$watch('vm.job.phase', this.reloadFields.bind(this));

    //Looping each runsheet to see if the job in runsheet is approved
    //To check if Admin role can delete these runsheets
    this.reportReady = false;
    this.disableDelete = false;
    if (job.runsheets.length > 0) {
      let needApprovedRunsheets = _.uniqBy(this.job.runsheets, 'runsheetType').length;
      let count = 0;
      //console.log('needApprovedRunsheets', needApprovedRunsheets);
      job.runsheets.forEach(runsheet => {
        if (runsheet.jobsApproved.indexOf(job.id.toString()) > -1) {
          count = count +1;
        }
        if (runsheet.status !== 1) {
          this.disableDelete = true;
        }
      })

      //console.log('ApprovedRunsheets', count);
      if (needApprovedRunsheets === count) {
        this.reportReady = true;
      }

      this.filterRunsheet();
    }

    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.job.revert();
    });
  }

  reloadFields() {
    // Clear the sample fields
    this.sampleFields = [{
      path: 'sample_name',
      name: 'Name'
    }];

    if(this.job.phase === 3 || this.job.phase === 4) {
      for (let i = 0; i < this.job.labTests.length; i++) {
        // for each labTest used on this job, create a column in the sample table
        this.sampleFields.push({
          path: 'status',
          name: this.job.labTests[i].unique_name,
          labTest: this.job.labTests[i],
          tpl: '/sample.html'
        });
      };
    }
  }

  onDisplayed()
  {
    if(this.records!=undefined)
    {
      var _this = this
      this.records.forEach(function(sample,findex){
        if(sample.status.length>0)
        {
          var tempArr = [];
          _this.sampleFields.forEach(function(sampleFeild,sindex){
            if(sindex>0)
            {
              var labTestId = sampleFeild.labTest.id
              sample.status.forEach(function(status,tindex){
                if(labTestId==status.labTest)
                {
                  tempArr[sindex - 1] = status
                }
              })
              // console.log(_this.records[index].status[index_])

            }
          })
          _this.records[findex].status = tempArr
        }
      });

    }
  }
  /**
   * For go to sample.edit state
   */
  onSampleClick(selected) {
    this.lastSampleClicked = selected;
  }

  /**
   * Returns a subset of this.matchingLabTests based on searchText
   * @param  {String} searchText
   * @return {[LabTest]} The array of LabTests
   */
  searchLabTests (searchText) {
    return _.filter(this.matchingLabTests, function (labTest) {
      return labTest.unique_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    });
  }

  formatDate(date)
  {
    let current_datetime = new Date(date)
    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes()
    return formatted_date
  }
  
  getAuditTrails()
  {
    this.auditTrailStore.get({entity_id:this.job.id }, { populate: ""}, (err, auditTrails) => {
      this.auditTrail = auditTrails
    })
  }
  changeRecords(records) {
    if(!this.records) this.convertSamples(records);
    this.records = records;
  }

  updateSamples(done = angular.noop) {
    this.updatingSamples = true;

    if(this.inReadyTesting == false)
    {
      this.AuditTrailService.createAuditTrail({
          job:this.job,
          action:"Update Samples",
          entityType : 'job'
      });
    }

    async.each(this.records, (record, recordDone) => {
      // for each sample save it
      record.save(recordDone);
    }, (err) => {
      // when all samples have been saved
      this.updatingSamples = false;
      done(err);
    });

    
  }

  // auditDialog(options)
  // {
  //   this.$mdDialog.show({
  //     clickOutsideToClose: true,
  //     escapeToClose: false,
  //     bindToController: true,
  //     controllerAs: 'vm',
  //     locals: options,
  //     templateUrl: '/html/auditTrail/audittrail-dialog.html',
  //     controller: 'AuditTrailDialogCtrl',
  //   });
  // }
  // Converting Samples to the key value pair structures used in the list view
  convertSamples(samples) {
    _.forEach(samples, (sample) => {
      for(let i = 0; i < sample.status.length; i++) {
        sample.status[sample.status[i].labTest] = sample.status[i].received
      }
    });
  }

  convertRecords(records) {
    // Converting records from key value pair to elements with labtests and received as keys
    _.forEach(records, (record) => {
      // Removing all elements from the array but preserving the key value pairs
      record.status.splice(0,record.status.length);
      record.status = _(record.status).mapValues((object, key) => {
        return {
          labTest: key,
          received: object,
        }
      }).values().value();
      record.save();
    });

    // Convert the samples back
    this.convertSamples(records);
  }

  showRunsheetDialog() {
    this.$mdDialog.show({
      templateUrl: '/rsButtons-dialog.html',
      locals: {
        items: this.job.runsheets
      },
      controller: ['$scope', '$mdDialog', 'items', function($scope, $mdDialog, items) {
        $scope.items = items;
        $scope.closeDialog = function() {
          $mdDialog.hide();
        }
      }],
      clickOutsideToClose: true,
      openFrom: '#rsButton',
      closeTo: '#rsButton',
    });
  }
  progressToLabReceived() {
    var dependenciesIds = [],
    testIds = [],
    allGood = true,
    _this = this;
    this.changingPhase = true;
    this.job.labTests.forEach((labtest) => {
      testIds.push(labtest.id)
      if(labtest.dependencies.length>0){
        labtest.dependencies.forEach((dependnt_id) => {
          dependenciesIds.push(dependnt_id) 
        })
      }
    })
    
    dependenciesIds.forEach((dependency) => {
      // console.log(testIds.indexOf(dependency));
      if(testIds.indexOf(dependency)==-1)
      {
        allGood = false
        this.LabTestStore.get({ _id: dependency }, { populate: 'fields approvals.user.contact' }, function (err, labTests) {
          if (err || labTests.length === 0) {
            // on fail
            reject(err || 'No labTest found');
          } else {
            // on success
            // console.log(labTests[0].unique_name)
            _this.dependenciesError = 'Dependency Error: '+labTests[0].unique_name+' is required';
            _this.$rootScope.showError(_this.dependenciesError);
          }
        });


      }

    })
    if(!allGood)
      return false;

    var postData = {
        job:this.job,
        action:"Progress To Lab Received",
        allGood:allGood,
        entityType : 'job',
        stateReload:true
   }
   if(allGood){
     this.changingPhase = false;
     this.job.save();
   }
   this.AuditTrailService.createAuditTrail(postData);
   // console.log('let us seee',this.$rootScope.AuditTrailDialogCtrl,this.$scope.AuditTrailDialogCtrl);
   // this.$scope.$emit("one",postData);
   
  }



  updateLabtests(){
    this.labtestUpdate = true;
    this.job.matchingLabTests((err, labtests) => {
      this.labtestUpdate = false;
      if (err) {
        console.log(err);
      } else {
        this.job.labTests = labtests;
        this.job.save();

        this.AuditTrailService.createAuditTrail({
          job:this.job,
          action:"Update Default Labtests",
          entityType : 'job'
        });
      
      }
    })


  }

  readyTesting() {
    this.changingPhase = true;
    this.inReadyTesting = true;
    let comments = this.job.comments;
    this.updateSamples((err) => {
      this.job.readyTesting(() => {
        this.job.phase = 4;
        this.changingPhase = false;
        this.job.comments = comments;
        this.job.save();
        this.AuditTrailService.createAuditTrail({
          job:this.job,
          action:"Progress To Testing",
          stateReload:true,
          entityType : 'job'
        });
      });
    });
    this.inReadyTesting = false
    

  }

  createReport() {
    this.changingPhase = true;
    let comments = this.job.comments;

    this.job.createReport((err, updatedJob) => {

      if(updatedJob.msg) // already created report msg 
      {
        this.$rootScope.showToast(updatedJob.msg);
        this.changingPhase = false;
        return;
      }
      
      this.job.comments = comments;
      this.changingPhase = false;
      if (err) {
        console.log(err);
      } 
      else {
       // this.job.save();
       this.AuditTrailService.createAuditTrail({
          report:updatedJob.report,
          action:"Create Report",
          stateReload:true,
          entityType : 'report',
          jobID:this.job.id,
          job:this.job
        });
      }
    });
  }

  deleteRunsheets() {
    let _this = this;
    this.changingPhase = true;
    let totalJobsInRunSheet = this.job.runsheets[0].jobs.length;
    let msg = '';
    if(totalJobsInRunSheet > 1) {
      
      this.$mdDialog.show({                
        templateUrl: '/html/job/dialogTemplate.html',
        controllerAs: "vm",                                
        controller: ($scope,dialog) => {
           $scope.cancel = function() { dialog.cancel(); } 
           $scope.confirm = function() { dialog.hide(); }
           $scope.deleteRunsheets =  function(val) { console.log('This is delete runsheets',val);
                                            dialog.hide(val);
          }
        },
        locals : {
          dialog : this.$mdDialog
        },
        clickOutsideToClose: true
        }).then(function(data) {
            // User Accepted!!
           
            if(data == "1")
              _this.deleteRunsheetForOneJob(true);
            else
            {
              _this.job.deleteRunsheetsForAllJobs((err, updatedJob) => {
                if (err) {
                  console.log(err);
                } 
                else {
                  _this.changingPhase = false;
                  _this.AuditTrailService.createAuditTrail({
                    job:_this.job,
                    action:"Delete Runsheets",
                    stateReload:true,
                    entityType : 'job'
                  });
                }
              });
            }
        }, function() {
            // User cancelled, don't do anything.
            _this.changingPhase = false;
            console.log('You cancelled!!!');
        });
    }
    else
    {
      this.deleteRunsheetForOneJob();      
    }  
  }

  deleteRunsheetForOneJob(choice){
    
    if (choice || this.user.hasPermission("management-buttons") && confirm("Delete runsheets for job T" + this.job.job_id + "\nRunsheets data will delete completely.")) {
      this.job.deleteRunsheets((err, updatedJob) => {
        if (err) {
          console.log(err);
        } else {
          this.changingPhase = false;
          this.AuditTrailService.createAuditTrail({
            job:this.job,
            action:"Delete Runsheets",
            stateReload:true,
            entityType : 'job'
          });
        }
      });
    }
  }
  regressTolabreceived() {
    if (this.user.hasPermission("management-buttons") && confirm("Regress job T" + this.job.job_id + " to Lab Received status?")) {
      this.job.phase = 3;
      this.job.save();
      
      this.AuditTrailService.createAuditTrail({
        job:this.job,
        action:"Regress to Lab received",
        entityType : 'job',
        stateReload:true
      });
    }
  }

  regressToPrepTeam() {
    if (this.user.hasPermission("management-buttons") && confirm("Regress job T" + this.job.job_id + " to Perp Team status?")) {
      this.job.phase = 2;
      this.SampleStore.get({ _id : this.job.samples}, (err, samples) => {
        samples.forEach(sample => {
          sample.status = [];
          sample.save();
        })
      })
      this.job.save();

      this.AuditTrailService.createAuditTrail({
        job:this.job,
        action:"Regress to Prep. Team",
        stateReload:true,
        entityType:"job"
      });
    }
  }


  manageSampleDialogOpen() {
    this.JobStore.get({ _id : this.job.id }, { populate: "samples labTests"}, (err, jobs) => {
      this.$mdDialog.show({
        clickOutsideToClose: true,
        escapeToClose: false,
        bindToController: true,
        controllerAs: 'vm',
        locals: {
          job:jobs[0],
        },
        templateUrl: '/html/job/manage-sample-dialog.html',
      controller: 'ManageSampleDialogCtrl',
      });
    })
  }

  //remove duplicated runsheets caused by mongodb population, and assign error to unapproved runsheet
  filterRunsheet(){
    let runsheets = _.uniqBy(this.job.runsheets, '_id');

    this.filteredRunsheets = [];
    runsheets.forEach(runsheet => {
      if (runsheet.jobsApproved.indexOf(this.job.id) > -1) {
        runsheet.approved = true;
      } else if (runsheet.status == 4) {
        runsheet.approved = 'error';
      }
      this.filteredRunsheets.push(runsheet)
    })
  }

}

(function (app) {
  app.controller('JobViewCtrl', JobViewCtrl);
}(angular.module('app.job')));
