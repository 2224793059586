'use strict';

(function (app) {
  app.factory('Location', function ($http, $injector, $mdDialog, Resource) {
    return class Location extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Location' }, data, metadata, callback);
      }

      /**
       * Get the contact given the index or id
       * @param {Mixed} indexOrId Number/string of the index or string of the id
       * @return {Mixed} Returns the element that matches the index or id
       */
      getContact(indexOrId) {
        if (!Array.isArray(this.contacts) || this.contacts.length === 0) {
          // if there are no contacts to get
          return null;
        }
        // if there are contacts to get
        const int = parseInt(indexOrId);
        // Note: parseInt('5b...') === 5, which is why we check if the values are still equal
        if (int !== NaN && int == indexOrId) {
          // if finding by index
          if (this.contacts[indexOrId]) {
            // if the index was found
            return this.contacts[indexOrId];
          }
          // if the index was not found
          return null;
        }
        // if finding by id
        for (let i = 0; i < this.contacts.length; i++) {
          // for each contact
          if (this.contacts[i]._id === indexOrId) {
            // if the contact matches
            return this.contacts[i];
          }
        }
      }

      /**
       * Get address as a string
       * @param {String} type Type of address to get (either 'physical' or 'postal')
       * @param {Number} lines Maximum number of lines of the address to include
       * @return {Array} Array of strings corresponding to lines of the address
       */
      getAddress(type = 'physical', lines = null) {
        const address = [];
        if (!this[type]) {
          // if there is no address then return
          return address;
        }
        // compile the address
        let line = [];
        // if unit is included then add it
        if (this[type].unit) line.push(this[type].unit);
        // if street is included then add it
        if (this[type].street) line.push(this[type].street);
        if (line.length > 0) {
          // if there is a first line then add it
          address.push(line.join(' '));
          if (lines && lines === address.length) {
            // if the address is ready to be returned
            return address;
          }
          line = [];
        }
        // if suburb is included then add it
        if (this[type].suburb) address.push(this[type].suburb);
        if (lines && lines === address.length) {
          // if the address is ready to be returned
          return address;
        }
        // if city is included then add it
        if (this[type].city) line.push(this[type].city);
        if (lines && lines === address.length) {
          // if the address is ready to be returned
          return address;
        }
        // if postcode is included then add it
        if (this[type].postcode) line.push(this[type].postcode);
        if (line.length > 0) {
          // if there is a first line then add it
          address.push(line.join(' '));
          if (lines && lines === address.length) {
            // if the address is ready to be returned
            return address;
          }
          line = [];
        }
        // if region is included then add it
        if (this[type].region) address.push(this[type].region);
        if (lines && lines === address.length) {
          // if the address is ready to be returned
          return address;
        }
        // if country is included then add it
        if (this[type].country_name) address.push(this[type].country_name);
        return address;
      }
    };
  });
}(angular.module('app.location')));
