'use strict';

(function (app) {
  app.factory('RunsheetValueStore', function (Store, $http) {
    const RunsheetValueStore = new Store({
      model: 'RunsheetValue'
    });

    RunsheetValueStore.saveMultiple = function (data, callback = function(){}) {
      $http({
	      method: 'POST',
	      url: '/api/RunsheetValue/save',
	      data: data
      }).then(callback,callback);
    };    

    RunsheetValueStore.compareCells = function (data, callback = function(){}) {
      $http({
        method: 'POST',
        url: '/api/RunsheetValue/compare',
        data: data
      }).then(callback,callback);
    };    
    return RunsheetValueStore;
  });

}(angular.module('app.runsheetValue')));
