'use strict';

/**
 * Controller for the Account view
 */
class LabStandardCtrl {

  constructor ($state, LabLocationStore, labStandard) {
    this.$state = $state;
    this.LabLocationStore = LabLocationStore;
    this.labStandard = labStandard;

    this.labLocations = [];
  }

  /**
   * Loads the list of labLocations
   */
  loadLabLocations() {
    if (this.labLocations.length === 0) {
      // if the locations have not yet been loaded then load them
      this.LabLocationStore.get({}, (err, locations) => {
        this.labLocations = locations;
      });
    }
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.labStandard.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('labLocations');
      }
    });
  }
}

(function(app) {
  app.controller('LabStandardCtrl', LabStandardCtrl);
}(angular.module('app.labStandard')));
