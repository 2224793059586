'use strict';

// constants
// ten seconds in milliseconds
const TEN_SECONDS = 10000;
/**
 * Controller for the user list view
 */
class UserListCtrl {

  constructor ($state, $stateParams,$rootScope, $scope, UserStore, programs, query, $mdToast, $window, pendingUserCount, $filter,roles) {
    this.$state = $state;
    this.UserStore = UserStore;
    this.$mdToast = $mdToast;
    this.$scope = $scope;
    this.$window = $window;
    this.pendingUserCount = pendingUserCount;
    this.populate = 'contact roles customer';
    this.sort = {'given_name':1}
    this.query = query;
    this.roles = roles;
    this.$rootScope = $rootScope
    this.user = this.$rootScope.User

    if (this.user._id && !this.$rootScope.User.hasPermission('admin') && !this.$rootScope.User.hasPermission('ff-admin')&&!this.$rootScope.User.hasPermission('client-admin'))
      this.query['_id'] = this.user._id;

    // if (this.$rootScope.User.isUserAdmin && !this.$rootScope.User.hasPermission('admin'))
    //   this.query['$or'] = [{"_id":this.user._id},{"createdBy":this.user._id}];      
    this.roleFilter = [{
      name: "All",
      query: this.query,
      slug: "All",
      active: true
    }];
    if(this.roles && this.roles.length!=undefined && this.roles.length>0)
    {
      this.roles.forEach(function(role){
        this.roleFilter.push({
          name: role.name,
          query: Object.assign({roles:{$in:[role._id]}}, this.query),
          slug: "",
          // active: true
        })
      }.bind(this))
    }

    this.fields = [{
      path: 'customer',
      name: 'Customer Name',
      transform: (customer) => {
        if (!customer ) return '';
        if (customer.status == 'Draft') {
          return ' <br/> <br/>' +
                  '<a class="md-icon-button md-warn" href="/customers/Draft/false/edit/'+customer.id+'"> <font color="red">' + customer.name + '</font>'+
                  '</a>';
        } else {
          return'<a class="md-icon-button md-primary" href="/customers/Approved/false/edit/'+customer.id+'"> '+  customer.name +
              '</a>';
        }
        
      }
    },{
      path: 'username',
      name: 'Username'
    },{
      name: 'Name',
      path: 'given_name',
      hideXs: true,
      transform: (given_name,user) => {
        return given_name+' '+user.surname;
      }
    },
    {
      path: 'roles',
      name: 'Roles',
      hideXs: true,
      transform: (roles, user) => {
        let roleName = {},roleNames = [],finalRoles = ''

        if(roles!=undefined&&roles && roles.length>0)
        {
          try{
            roles.forEach(function(role,index){
              if(role && role.code!=undefined && role.code)
              {
                roleName[role.name]=1  
              }
            })  
            roleNames = Object.keys(roleName)
            finalRoles = roleNames.join(', ')

          }catch(e)
          {
            
          }
        }
        return finalRoles;
      },
      enableFilter: true,
      filterOptions: this.roleFilter
    }];
    if(this.$rootScope.User.hasPermission('admin'))
    {
      this.fields.push({
        path: 'signatureUsed',
        name: 'Signature Code',
        
      });
    }
    if (this.$rootScope.User.hasPermission('admin')||this.$rootScope.User.hasPermission('ff-admin')||this.$rootScope.User.hasPermission('client-admin'))
    {
      this.fields.push({
        path: 'isActive',
        name: 'Status',
        transform: (isActive) => {
          let finalStatus;
          if(isActive===true)
            finalStatus = 'Active';
          else
            finalStatus = 'Inactive';

          return finalStatus;
        },
        enableFilter: true,
        filterOptions: [{
          name: "All",
          query: this.query,
          slug: "All",
          active: true
        },{
          name: "Active",
          query: Object.assign({isActive:true}, this.query),
          slug: "",
          active: false
        },{
          name: "InActive",
          query: Object.assign({isActive:false}, this.query),
          slug: "",
          active: false
        }]
      });  
    }
    else
    {
      this.fields.push({
        path: 'status',
        name: 'Status',
        hideXs: true,
        transform: (contact, user) => {
          let finalStatus;
          if(user.isActive===true)
            finalStatus = 'Active';
          else
            finalStatus = 'Inactive';
          return finalStatus;
        }
      });  
    }

  }

  checkDeletePermission(record) {
    if (this.user._id==record.id)
      return false;
    else
      return true
  }

  /**
   * Called when a document is selected
   * @param {Object}  selected  The selected row object.
   * @return  {void}            Assigns the last clicked object the value of the last clicked row.
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  /**
   * Removes the document
   * @param {Object} user User to remove.
   * @return  {void}      Assigns the last clicked object the value of the last clicked row.
   */
  deleteUser(user) {
    user.remove(true, (error, results) => {
      if (!error) {
        // on success
        this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(user.username + ' has been deleted successfully.')
          .hideDelay(TEN_SECONDS)
          .position('bottom right')
        );
        this.$window.location.reload();
      } else {
         // on fail
         this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(user.username + ' can\'t be deleted due to some unexpected outcomes.')
          .hideDelay(TEN_SECONDS)
          .position('bottom right')
        );
      }
    });  
  }

  updateForactivateOrDeactivateUser(user) {
    user.updateForActivateOrDeactivate(true, user , (error, results) => {
      if (!error) {
        // on success
        this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(user.username + ' has been updated successfully.')
          .hideDelay(TEN_SECONDS)
          .position('bottom right')
        );
        this.$window.location.reload();
      } else {
         // on fail
         this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(user.username + ' can\'t be updated due to some unexpected outcomes.')
          .hideDelay(TEN_SECONDS)
          .position('bottom right')
        );
      }
    });
    
  }

  /**
   * Changes the selected program in the state parameter
   * @param {Object} program Custom objects as set up in constructor
   */
  changeProgram(program) {
    this.$state.go(this.$state.current, { tab: program.slug });
  }
}

(function(app) {
  app.controller('UserListCtrl', UserListCtrl);

  app.filter('unique', function () {

    return function (items, filterOn) {
  
      if (filterOn === false) {
        return items;
      }
  
      if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
        var hashCheck = {}, newItems = [];
  
        var extractValueToCompare = function (item) {
          if (angular.isObject(item) && angular.isString(filterOn)) {
            return item[filterOn];
          } else {
            return item;
          }
        };
  
        angular.forEach(items, function (item) {
          var valueToCheck, isDuplicate = false;
  
          for (var i = 0; i < newItems.length; i++) {
            if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
              isDuplicate = true;
              break;
            }
          }
          if (!isDuplicate) {
            newItems.push(item);
          }
  
        });
        items = newItems;
      }
      return items;
    };
  });
}(angular.module('app.singleSignOn')));
