'use strict';

const async = require('async');
const md5   = require('blueimp-md5');
const _     = require('lodash');
const cycle = require('cycle');

// constants
const MIN_NGRAM_LENGTH = 3;
const MAX_NGRAM_LENGTH = 5;

/**
 * Realtime store, used to fetch and query resources on the server
 *
 *  - Contains a cache of resources fetched from the server
 *  - Instantiates Resources
 *  - Maintains a constant connection to the Socket IO server
 */
class Store {
  /**
   * - Assigns injected variables to this
   * - Initalize objects & arrays to store store details
   * - Register Socket.io reconnection events
   * @param {Object} options Object containing options to setup the store with
   * Note: options may be a string of the model, this is DEPRECATED and will be removed in 2.0.0
   * @param {Object} $http Angular http service
   * @param {Object} $rootScope Angular rootscope service
   * @param {Object} $timeout Angular timeout service
   * @param {Object} SocketIO SocketIO client service
   *
   * The the following options can be defined
   *  - model: name of the model
   *  - baseRoute: (optional) name of the base route to use (defaults to the model name)
   *  - cache: (optional) boolean of whether to use the store's internal cache (defaults to false)
   */
  constructor (options, $http, $state, $rootScope, $injector, $timeout, SocketIO, stores,  $window) {
    // setup options
    this.Model = options.model;
    // set permission if a parameter is defined in the store
    this.Permission = options.permission;
    
    this.$window =  $window;
    this.realtime = true;
    this.cache = false;
    // all Angular calls use the API route
    this.baseRoute = '/api/';
    // if baseRoute option is set then use that otherwise use the model name
    this.baseRoute += options.baseRoute ? options.baseRoute : options.model;
    if (typeof options.realtime !== 'undefined') {
      // if realtime option is set then use that
      this.realtime = options.realtime;
    }
    if (typeof options.cache !== 'undefined') {
      // if cache option is set then use that
      this.cache = options.cache;
    }

    // if ignoring metadata then make a note
    this.ignoreMeta = options.ignoreMeta;

    // bind the services
    this._$http      = $http;
    this._$state     = $state;
    this._$rootScope = $rootScope;
    this._$injector  = $injector;
    this._$timeout   = $timeout;
    this.stores      = stores;
    /** @type {Resource} The Resource class of this.Model */
    this.Resource   = $injector.get(this.Model);

    // define the helper fields
    /** @type {Array} The cached store of resources */
    this.store    = [];
    /** @type {Array} An array of references to pre-fetched objects */
    this.fetching = [];
    /** @type {Object} An object containing reference definitions, used to instantiate sub documents */
    this.refs     = {};
    /** @type {Object} A key-value store of all queries currently being watched */
    this.queries  = {};
    /** @type {Object} A key-value store of all paths and their metadata */
    this.paths = {};

    // load references
    this._loadedRefs = false;
    this._gettingRefs = false;
    if (!this.ignoreMeta) {
      if (this._$rootScope.ready) {
        // if the core controller has been setup
        this._$rootScope.ready.then(() => {
          // wait until we have the authentication token before loading refs
          this.getMetaData(angular.noop);
        });
      }
      // if this Store is needed for the core controller then metadata will be loaded later
    }

    // TODO: move realtime out of the base Store
    if (this.realtime) {
      // if realtime is set then setup SocketIO
      this._SocketIO = SocketIO;
      SocketIO.on('disconnect', () => {
        // on disconnection try to reconnect
        SocketIO.once('reconnect', () => {
          // for each query get the contents in case it changed
          _.each(this.queries, (query) => {
            let ids = [];
            for (let obj of query.arr) {
              ids.push(obj._id);
            }
            
            // watch for changes on the model instances that have been used
            SocketIO.emit('query.subscribe', { model: this.Model, query: query.query, ids: ids });
          });
        });
      });
    }
  }

  /**
   * Makes a request to the server to get metadata for the model
   */
  getMetaData(callback) {
    // if a permission check has been configured in the store, check if the user has permissions to access it before loading the meta data
    if( this._$rootScope &&  this._$rootScope.User && this.Permission){
      if( this._$rootScope.User.permissions.indexOf(this.Permission) < 0){
        return 'No permission to access the metaData';
      }
    }

    if (!this._gettingRefs) {
      // if the refs are not already being fetched
      this._gettingRefs = this._$http({
        method: 'GET',
        url: this.baseRoute + '/getMetaData'
      });
    }
    // at this point refs are always getting fetched
    this._gettingRefs.then(
      (res) => {
        // Note: assign is used to keep the reference the same
        Object.assign(this.paths, res.data.paths);
        Object.assign(this.refs, res.data.refs);
        
        this._loadedRefs = true;
        this._gettingRefs = false;
        callback();
      },
      (err) => {
        // on fail use the old route
        if (err.statusText === 'Not Found') {
          // if the new route does not exist then try the old one
          this._gettingRefs = this._$http({ method: 'GET', url: this.baseRoute + '/getRefs' })
          .then(
            (res) => {
              // on success store the refs and note that they were loaded
              Object.assign(this.refs, res.data);
              this._loadedRefs = true;
              this._gettingRefs = false;
              callback();
            },
            (err) => {
              // redirect to 403 or 404 to display a suitable message
              this._gettingRefs = false;
              this._$rootScope.spinner = false;
              if (err.status == -1) {
                $window.location.href = '/';
              } else if (err.status == 403){
                this._$state.go('403', null, { location: false });
              } else {
                this._$state.go('404', null, { location: false });
              }
              callback('failed');
            }
          );
        } else {
          // if the route exists but does not work then getRefs will probably not work either
          // redirect to 403 or 404 to display a suitable message
          this._$rootScope.spinner = false;
          if (err.status == -1) {
            $window.location.href = '/';
          } else if (err.status == 403){
            this._$state.go('403', null, { location: false });
          } else {
            this._$state.go('404', null, { location: false });
          }
          callback('failed');
        }
      }
    );
  }

  /**
   * Creates a new Resource based on the Store
   * The Resource has the structure of the model but no data
   * returns {Resource} Returns the empty resource of the Store's type
   */
  newResource(data, callback) {
    if(_.isNil(data)) data = {};
    let resource = new this.Resource(data, { refs: this.refs, paths: this.paths });
    if (this.cache) {
      // If internal cache is in use then add a remove listener to this resource
      this._$rootScope.$on(resource._id + ':remove', (event, data) => {
        // Removing the document from the store
        _.remove(this.store, function (record) {
          return record._id === data._id;
        });

        // Removing the id from the query hashes
        _.each(this.queries, (query) => {
          _.remove(query.arr, doc => {
            return doc._id === data._id
          });
          
          // updating the count
          // count for some reason is a string
          query.count = query.arr.length.toString();
        });
      });
    }
    
    // If there is no callback defined, return early // this errored on failed login
    if(!callback) {
      console.warn('Using Store.newResource synchronously is deprecated, please use a callback');
      return resource;
    }
    
    if(!this.ignoreMeta && _.isEmpty(resource.__meta.refs) && _.isEmpty(resource.__meta.paths)) {
      // TODO: Check if the function is already in progress
      this.getMetaData(() => {
        resource.setRefs(this.refs);
        resource.setPaths(this.paths);
        callback(resource);
      });
    } else {
      callback(resource);
    }
  }

  // TODO: chop this method up into readable helper functions
  /**
   * Gets records from the server
   *
   * @param {Object}   query    MongoDB query object      (fields to match records we are getting)  e.g. { _id: "1ab18ae96ae26484aea24e54" }
   * @param {Mixed}    options          The options to be passed to the server controller
   * @param {Mixed}    options.populate Mongoose populate string  (fields to populate the records with)     e.g. 'subscriptions roles.permissions'
   * @param {Integer}  options.limit    Number of records to return                                         e.g. (number of records per page)
   * @param {Integer}  options.skip     Number of records to ignore before returning results                e.g. (number of records per page) * (number of pages)
   * @param {Object}   options.sort     MongoDB sort object       (fields to sort records by)               e.g. { name: 1, date: -1 }
   * @param {Object}   options.fields   MongoDB projection object (fields to return objects with)           e.g. { token: -1 }
   * @param {Function} callback Function to return results with (standard Node format)              e.g. (error, records) => { program logic }
   *
   * @return {Object}  error    JavaScript error object containing error
   * @return {Array}   records  Array containing objects representing MongoDB records
   * @return {Number}  count    Integer representing the total number of records for the query
   *
   * Callback function is defined in arguments so JSHint does not complain that the required callback argument is always last
   * Valid signatures:  
   *                    get(query, callback)
   *                    get(query, options, callback)
   * Deprecated signatures: 
   *                    get(query, populate, callback)
   *                    get(query, populate, limit, callback)
   *                    get(query, populate, limit, skip, callback)
   *                    get(query, populate, limit, skip, sort, callback)
   *                    get(query, populate, limit, skip, sort, fields, callback)
   *
   * If query contains the id or _id then only those fields will be checked
   */
  get(query, options = {}, limit = 0, skip = 0, sort = undefined, fields = undefined) {
    let queryObject = {}
    let populate = '';

    if(_.isString(options)) {
      console.warn('get(query, populate, limit, skip, sort, fields, callback) is deprecated. Please use get(query, options, callback) where options is an object of key values i.e { populate: "foo", limit: 15 } ', `(${ this.Model }Store)`);
      // Construct the query object the old way
      queryObject = {
        query: query,
        populate: options,
        limit: limit,
        skip: skip,
        sort: sort,
        fields: fields,
      };

      populate = options || '';
      
    } else {
      // Assigning the query
      queryObject.query = query;
      // Assigning the options to the query object
      if (!options.populate) {
        // if populate is not set then use the default
        options.populate = '';
      }
      queryObject = _.assign(queryObject, options);
      populate = options.populate || '';
    }

    // TODO: Create a copy of the query object to use for the query hash later on
    const args = arguments;
    let count = null;
    async.waterfall([
      // checks the Store for records
      function getFromClient(clientDone) {
        // values are found objects and keys are the order to return them in in an array
        let found = {};
        // keys are not found object ids and values are the index at which they should be inserted into found
        let notFound = {};
        if (!this.cache) {
          // if caching is disabled then skip client part
          return clientDone(null, found, notFound, count);
        }
        // extract ids from query and check local stores first so we don't have to request and process so many references
        const id = query._id || query.id;
        // all ids for this query (if seen previously)
        let ids = [];
        if (id && (typeof id) === 'string') {
          // if id is set then set as found
          ids.push(id);
          count = 1;
        } else if (id && id.$in) {
          // if id is actually an array of ids then use that
          ids = id.$in;
          count = ids.length;
        } else {
          // // if id is not set get the query
          // const storedQuery = this._getSubscribedQuery(queryObject);
          // ids = storedQuery.ids;
          // count = storedQuery.count;
        }
        // TODO: process the rest of the query not just the id
        // convert ids into records
        if (ids.length > 0) {
          // if id was queried for then look to get the id from the store
          /* Note: marker is used because store contents is partially sorted
           * If there are 10 objects loaded initially at the end of 100 objects then marker stops traversing 100 objects for each one.
           * Instead, we traverse 100 objects once and then return the next 10 (provided the ordering hasn't changed).
           */
          let marker = 0;
          for (let idIndex = 0; idIndex < ids.length; idIndex++) {
            // for each id in the list of ids
            if (this.store.length === 0) {
              // if there are no records in the store then add the id to not found
              notFound[ids[idIndex]] = idIndex;
            } else {
              // if there are records in the store then search
              for (let storeIndex = marker; storeIndex < this.store.length; storeIndex++) {
                // find the object being searched for in the store
                const nextIndex = storeIndex + 1;
                if (this.store[storeIndex]._id === ids[idIndex]) {
                  // if we have found the object
                  // if the object is found then add it to the array
                  // Note: we push a cloned object so we can mutate it without affecting the stored data
                  const tempResource = this.store[storeIndex].clone();
                  // map the resource to the index it should be returned in in an array
                  found[idIndex] = tempResource;
                  // keep track of where we are in the store and break out
                  marker = nextIndex;
                  if (marker >= this.store.length) {
                    // if the marker has overflowed then wrap it to zero
                    marker = 0;
                  }
                  break;
                }
                if (marker === nextIndex) {
                  // if the next increment will reach the marker then we have completed without finding the object a loop so break
                  notFound[ids[idIndex]] = idIndex;
                  break;
                }
                if (marker > 0 && this.store.length === nextIndex) {
                  // if the end of the store is reached and we didn't start from zero then start from -1 (which will be incremented on continue)
                  storeIndex = -1;
                }
              }
            }
          }
        }

        //found = {};
        // process population
        if (args.length > 2 && populate !== '') {
          if(!_.isString(populate)) {
            return clientDone(null, found, notFound, count);
          }
          // if population was requested
          populate = populate.split(' ');
          // const finalPopulate = [];
          // // the following loops will map populate = "foo.bar foo.par" to "foo.bar par"
          // for (let p = 0; p < populate.length; p++) {
          //   // for each component in populate group the components relating to the same model
          //   let component = populate[p];
          //   const field = component.split('.')[0];
          //   if (field) {
          //     // if field is valid then find other components that use it
          //     for (let q = p + 1; q < populate.length; q++) {
          //       // iterate from the part we are at to the end of the array looking for groupings
          //       const parts = populate[q].split('.');
          //       if (field == parts[0]) {
          //         // if the populate field at index q is for the same model as at index p then group them
          //         component += ' ' + parts.slice(1).join('.');
          //         // erase the value that was copied
          //         populate[q] = '';
          //       }
          //     }
          //     // all the parts for this field have been combined into a single component
          //     finalPopulate.push(component);
          //   }
          // }
          // populate = finalPopulate;
          
          // population is required so populate
          async.map(found, (record, recordDone) => {
            // for each found record resource, populate fields
            async.each(populate, (field, populateDone) => {
              this._populate(record, this.refs, field, populateDone);
            }, (err) => {
              // all population for this record has been done
              recordDone(err, record);
            });
          }, (err, records) => {
            // records is an array of fetched and populated resources
            clientDone(err, records, notFound, count);
          });
        } else {
          // if population was not requested
          clientDone(null, found, notFound, count);
        }
      }.bind(this),
      // checks the database for records that the Store didn't have
      function getFromServer(alreadyLoaded, notLoaded, expectedCount, serverDone) {
        if (expectedCount !== 0 && (Object.keys(alreadyLoaded).length === 0 || Object.keys(notLoaded).length > 0)) {
          // if extra records may exist then request the data from the server
          this._$http({
            method: 'POST',
            url: this.baseRoute + '/get',
            data: queryObject
          }).then(
            (res) => {
              let ids = [];
              count = res.headers('X-Collection-Count');
              // on success process the array of objects received
              if (this.cache) {
                // if the internal cache is in use then ensure it is updated
                async.eachOf(res.data, function processDocuments(doc, index, docCallback) {
                  // convert each record returned to a resource and cache it
                  this._updateObject(doc, (error, resource) => {
                    // track ids for mapping to the query
                    ids[index] = resource._id;
                    // merge recently loaded and cached records
                    /* Note: checking undefined is not perfect but it is fast and safe for this case
                     * If it is not used then what should be the first element will be inserted wherever since indices start at zero
                     */
                    if (notLoaded[resource._id] !== undefined) {
                      // if there is a not loaded list then insert the object into the results array at the right point
                      alreadyLoaded[notLoaded[resource._id]] = resource;
                    } else {
                      // if there is not a not loaded list then insert in the same order
                      alreadyLoaded[index] = resource;
                    }
                    docCallback(error);
                  });
                }.bind(this), function eachDone(error) {
                  // save query information
                  // TODO: this still uses the old query format
                  this._newSubscribeQuery(queryObject, ids, count);
                  // callback with array of resources
                  serverDone(null, alreadyLoaded);
                }.bind(this));
              } else {
                // if the internal cache is not in use then just get new resources
                async.map(res.data, function processDocuments(doc, docCallback) {
                  // convert each record returned to a resource
                  this.newResource(doc, (resource) => {
                    docCallback(null, resource);
                  });
                }.bind(this), serverDone);
              }
            },
            (res) => {
             
              this._$rootScope.spinner = false;
              if (res.status == 403){
                this._$state.go('403', null, { location: false });
              } else {
                this._$state.go('404', null, { location: false });
              }
              // on fail, callback with error
              serverDone(res);
            }
          );
        } else {
          // if we do not go to the server
          // convert the alreadyLoaded resources to stright data
          serverDone(null, alreadyLoaded);
        }
      }.bind(this),
      // at this point the records are in an object where keys are the order and values are the records
      function mapToArray(recordObject, mappingDone) {
        if (!this.cache) {
          // if the internal cache is not being used then mapping is already done
          return mappingDone(null, recordObject);
        }
        const loadedCount = Object.keys(recordObject).length;
        const mappedRecords = [];
        for (let r = 0; r < loadedCount; r++) {
          // for each index in the new array add the record at that location
          mappedRecords.push(recordObject[r]);
        }
        mappingDone(null, mappedRecords);
      }.bind(this)
    ], (err, records) => {
      // all records have been fetched so call the original callback
      // Note: count | 0 converts count to an integer
      args[args.length - 1](err, records, count | 0);
    });
    // return the query hash for this query which allows tracking of updates to this query to be done
    return this._calculateQueryHash(this.Model, queryObject);
  }

  /**
   * Populates an object with data from different Stores
   * @param  {Object}   record   Record to populate the field for
   * @param  {Object}   refs     Reference object to decide which store to use to populate
   * @param  {Object}   field    Field on the record to populate
   * @param  {Function} callback Function to call in the form (error) => {}
   * @return {void}   Return via callback
   * Note: record is mutated
   */
  _populate(record, refs, field, callback) {
    if (!field) {
      // if there is no field to populate by then finish the population
      return callback();
    }
    // split field so that subdocuments are not queried yet
    field = field.split('.');
    let subPopulate = field.slice(1).join('.');
    field = field[0];
    // for each field on the model's schema
    if (!refs[field] || !record[field]) {
      // if the field cannot be populated then skip
      return callback();
    }
    // check whether the field is a sub document
    if (typeof refs[field].ref === 'object') {
      // if the field is a sub document
      if (!Array.isArray(record[field])) {
        // if we are dealing with a single sub document then recurse
        return this._populate(record[field], refs[field].ref, subPopulate, callback);
      } else {
        // if we are dealing with an array of sub documents
        return async.each(record[field], (subDocument, populateDone) => {
          // for each sub document recurse
          this._populate(subDocument, refs[field].ref, subPopulate, populateDone);
        }, callback);
      }
    }
    // if the reference is included
    if (!Array.isArray(record[field])) {
      // if the reference is not an array then populate
      let subId = record[field];
      if (subId._id) {
        // if the reference is an object then flatten it to an id
        subId = subId._id;
      }
      let otherStore = this.getOtherStore(refs[field].ref);

      otherStore.get({ _id: subId }, { populate: subPopulate }, (err, resources) => {
        // resource is the resource we want to populate with
        record[field] = resources[0];
        callback(err);
      });
    } else {
      // if the reference is an array then process it
      let otherStore = this.getOtherStore(refs[field].ref);

      // get the array of items from the other store
      record[field] = _.flattenDeep(record[field]);
      otherStore.get({ _id: { $in: record[field] } }, { populate: subPopulate }, (err, resources) => {
        record[field] = resources;
        callback(err);
      });
    }
  }


  /**
   * Helper function to return another store, used for populating clientside data
   * @param {String} modelName Name of the model that we want the store of
   * @return {Object} Returns the other store (and creates it if it's missing)
   */
  getOtherStore(modelName) {
    let otherStore = this.stores[modelName];
    if (!otherStore) {
      // if the other store has not been instantiated (due to lazy loading) then instantiate it
      otherStore = this._$injector.get(modelName + 'Store');
    }

    return otherStore;
  }
  
  /**
   * Saves populated fields and depopulates them according to the map of refs
   * This function mutates the data so it is unpopulated and returns a map of the resources that were populated
   * @param {Object} data Raw data object of the same model type the Store is
   * @param {Object} refs Ref object where keys are the field names and values are the model type of the object (or nested refs in the case of subdocuments)
   * @param {Function} callback Called when done in the format (error, references) => {}
   * @return {Object} Returns an object via callback where keys are the field name and values are the populated values
   */
  _depopulateObject(data, refs, callback) {
    let references = {};
    async.eachOf(refs, function eachRef(refObject, refField, gotRef) {
      // for each field on the model's schema
      if (typeof refObject.ref === 'object') {
        // if the reference is a subdocument array
        const resources = [];
        async.each(data[refField], function eachSubdocument(subdocument, subdocumentDone) {
          // for each subdocument in the array
          this._depopulateObject(subdocument, refObject.ref, function subdocumentRefs(error, subRefs) {
            // create a clone to use so that populating it will not affect the stored object
            const subData = _.cloneDeep(subdocument);
            // populate the resource we are returning
            this._repopulateObject(subData, subRefs);
            // add to the resource
            resources.push(subData);
            subdocumentDone(error);
          }.bind(this));
        }.bind(this), function eachDone(error) {
          // once the array is complete store the results
          references[refField] = resources;
          gotRef(error);
        });
      } else if (data[refField] && (data[refField]._id || (data[refField][0] && data[refField][0]._id))) {
        // if the reference is an object or array of objects (not subdocuments)
        let otherStore = this.getOtherStore(refObject.ref);

        if (data[refField]._id) {
          // if the reference is an object then store the resource for later
          if (this.cache) {
            // if the internal cache is in use then save it in the cache
            otherStore._updateObject(data[refField], function objectRef(error, subRef) {
              references[refField] = subRef;
              // flatten this object
              data[refField] = data[refField]._id;
              gotRef(error);
            });
          } else {
            // if the cache is not in use then store locally only
            references[refField] = data[refField];
            data[refField] = data[refField]._id;
            gotRef();
          }
        } else {
          // if the reference is an array then process it
          let ids = [];
          let resources = [];
          async.eachOf(data[refField], function eachArrayRef(subData, index, arrayRefDone) {
            // for each ref object store the id and resource
            // Note: index is used for adding instead of push so items are always kept in order
            ids[index] = subData._id;
            if (this.cache) {
              // if the internal cache is in use then save it in the cache
              otherStore._updateObject(subData, (error, subRefs) => {
                // got the refs so store them
                resources[index] = subRefs;
                arrayRefDone(error);
              });
            } else {
              // if the cache is not in use then store locally only
              resources[index] = subData;
              arrayRefDone();
            }
          }.bind(this), function arrayDone(error) {
            // store the resource for later
            references[refField] = resources;
            // flatten this object
            data[refField] = ids;
            gotRef(error);
          });
        }
      } else {
        // if the reference is invalid then ignore it
        gotRef();
      }
    }.bind(this), function eachOfDone(error) {
      callback(error, references);
    });
  }

  /**
   * Populates them according to the map of refs returned by _depopulateObject
   * This function mutates the data so it is populated
   * @param {Object} data Raw data object of the same model type the Store is
   * @param {Object} refMap Object returned by _depopulateObject
   * @return {void} Returns nothing but mutates data
   */
  _repopulateObject(data, refMap) {
    for (let refField in refMap) {
      // for each reference stored elsewhere, populate the data     
      data[refField] = refMap[refField];
    }
  }

  /**
   * Updates the objects in the store that match the data passed in
   * @param   {Object}    data     Data from the database to store
   * @param   {Function}  callback Called when the resource has been received in the format (error, resource)
   * @return  {void}               Returns resource via callback
   */
  _updateObject(data, callback) {
    // now update the object itself
    async.waterfall([
      // get the references to populate the resource with
      function getReferences(gotReferences) {
        // Note: we need to check the state of the refs because this may have been called via another Store (where this data has been populated)
        if (this.ignoreMeta) {
          // if metadata is skipped then there will be no references
          return gotReferences(null, {});
        }
        if (this._loadedRefs) {
          // if refs have been loaded then depopulate now
          this._depopulateObject(data, this.refs, gotReferences);
        } else {
          // if refs are not yet loaded
          this.getMetaData(() => {
            // wait until the metadata has been processed before depopulating
            this._depopulateObject(data, this.refs, gotReferences);
          });
        }
      }.bind(this),
      // get the resource that matches the data
      function getResource(references, gotResource) {
        for (let index = 0; index < this.store.length; index++) {
          // for each object in the store
          if (data._id === this.store[index]._id) {
            // if the object is in the store
            if (data.__v !== this.store[index].__v) {
              // if the version of the record has changed then update
              this.newResource(data, (resource) => {
                // update the store with the resource
                this.store[index] = resource;
                gotResource(null, references, resource);
              });
            } else {
              // version has not changed so get what is in the store
              gotResource(null, references, this.store[index]);
            }
            // by this point a callback will have been triggered
            return;
          }
        }
        // if the object does not exist in the store then add it
        this.newResource(data, (resource) => {
          this.store.push(resource);
          gotResource(null, references, resource);
        });
      }.bind(this),
    ], function waterfallDone(error, references, resource) {
      // create a clone to return so that populating it will not affect the stored object
      resource = resource.clone();

      // populate the resource we are returning
      this._repopulateObject(resource, references);
      
      // return the data that is now a populated resource
      callback(error, resource);
    }.bind(this));
  }

  /**
   * Converts a new style query to use the old query subscription code
   * Currently, this code only serves to convert the query to the old style
   * This method will become the standard method once the old method is deprecated
   * TODO: stop this method relying on the old subscribeQuery function
   *
   * @param {Object}   queryObject  Combined query object that the store uses internally
   * @param {Array}    ids          Array of ObjectId hashes to be stored against the query
   * @param {Number}   count        Integer of the total count for this query
   */
  _newSubscribeQuery(queryObject, ids, count) {
    let query = Object.assign({}, queryObject);
    // ignore the population in the query hash
    delete query.populate;
    // subscribe
    this.subscribeQuery(query, ids.map((id) => { return { _id: id }; }), count);
  }

  /**
   * Gets the ids associated with a query
   * @param   {Object} queryObject  Internal combined query object to find the ids for
   * @return  {Array}               Returns the array of ids stored against the query
   */
  _getSubscribedQuery(queryObject) {
    // now find the ids
    const hash = this._calculateQueryHash(this.Model, queryObject);
    if (this.queries[hash]) {
      return {
        'ids': this.queries[hash].arr.map(o => o._id),
        'count': this.queries[hash].count
      };
    } else {
      return {
        'ids': [],
        'count': null
      };
    }
  }

  /**
   * Calls remove on a resource on the server
   * @param {String} id The resource id
   * @param  {Function} callback The callback function
   */
  remove (id, callback = function(){}) {
    console.log('/api/' + this.Model + '/delete/' + id)
    this._$http({
      method: 'DELETE',
      url: '/api/' + this.Model + '/delete/' + id
    })
    .then(
      (res) => {
        callback();
      },
      (res) => {
        if (res.status == 403){
          this._$state.go('403', null, { location: false });
        } else {
          this._$state.go('404', null, { location: false });
        }

        callback(res);
      }
    );
  }

  /**
   * Subscribes the client to live updates for the query on the server
   * @param  {[type]} query The query to subscribe to
   */
  subscribeQuery (query, arr, count) {
    // Accumulate list of ids for initial server-side checksum
    let ids = [];
    for (let obj of arr) {
      ids.push(obj._id);
    }

    // Generate query hash
    let queryHash = this._calculateQueryHash(this.Model, query);

    if (!_.isNil(this.queries[queryHash])) {
      return console.log('Already subscribed to query with hash ' + queryHash);
    }

    this.queries[queryHash] = {
      arr: arr,
      model: this.Model,
      query: query,
      count: count,
      updates: 0
    };

    // TODO: move realtime stuff out of the base Store
    if (this.realtime && this.cache) {
      // if realtime and caching is in use
      this._SocketIO.on(queryHash + ':update', this._updateQuery.bind(this, queryHash));

      query = cycle.decycle(query);
      
      this._SocketIO.emit('query.subscribe', { model: this.Model, query: query, ids: ids });
    }
  }

  /**
   * Unsubscribes the client from live updates for the query on the server
   * @param  {[type]} query The query to unsubscribe from
   */
  unsubscribeQuery (query) {
    let queryHash = this._calculateQueryHash(this.Model, query);

    let q = this.queries[queryHash];

    if (_.isNil(q)) {
      return console.log('Query not found: ', query);
    }

    // TODO: move realtime stuff out of the base Store
    if (this.realtime) {
      // if realtime is in use
      this._SocketIO.emit('query.unsubscribe', { model: this.Model, query: q.query });
    }
    delete this.queries[queryHash];
  }

  /**
   * Updates a query object
   * This function triggers a digest cycle
   * @param {String}   queryHash Identification hash of the query to update
   * @param {Object}   data      Object containing updates to be made to the query
   * @param {Function} done      Called when the query has been updating
   * @return {void} Returns via callback in the form (error)
   */
  _updateQuery (queryHash, data, done) {
    let query = this.queries[queryHash];

    let indexesToAdd = [];
    let indexesToRemove = [];

    let removedElements = [];

    // TODO: Could adding and removing elements be done in less operations?
    // Find elements to be removed
    for (let i = 0; i < query.arr.length; i++) {
      // for each cached object relating to the query
      let obj = query.arr[i];
      let index = -1;
      for (let j = 0; j < data.docs.length; j++) {
        // for each updated object
        let o = data.docs[j];
        if (obj._id === o._id) {
          // if the object has been found then mark it to stay
          index = j;
          break;
        }
      }
      if (index === -1) {
        // if the object should be removed
        indexesToRemove.push(i);
      }
    }

    // Remove them
    for (let i of indexesToRemove) {
      removedElements = _.union(removedElements, query.arr.splice(i, 1));
    }

    // Find elements to be added
    _.each(data.docs, (obj, i) => {
      // for each updated document
      let index = _.findIndex(query.arr, (obj2) => {
        // find the object in the query
        return obj._id === obj2._id;
      });
      if (index === -1) {
        // if the object should be added
        indexesToAdd.push(i);
      }
    });

    // Add them
    async.each(indexesToAdd, function addObject(i, addedObject) {
      let itemToAdd = data.docs[i];

      let obj = {};
      if (this._hasObject(itemToAdd._id)) {
        obj = this._getObject(itemToAdd._id);
        query.arr.push(obj); // Insert
        addedObject();
      } else {
        // Otherwise add it to the store
        this._addObject(itemToAdd, obj, () => {
          query.arr.push(obj); // Insert
          addedObject();
        });
      }

    }.bind(this), function eachDone(error) {
      // Set the count
      query.arr.count = data.count;
      // Keep a record that it was updated
      query.updates++;

      if (!this._$rootScope.$$phase) {
        // if it is not during a digest cycle then make one happen
        this._$rootScope.$apply();
      }

      done(error);
    }.bind(this));
  }

  /**
   * Processes a returned Object from the server, when fetched via an array call
   * @param {Object} data The returned Object
   * @param {Array} arr The array we are adding into
   * @param {Function} callback The callback function
   */
  processArrayObject (data, arr, callback) {
    let done = function (err, obj) {
      // Add object into array
      arr.push(obj);
      // Listen to remove event of object and remove from array
      this._$rootScope.$on(data._id + ':remove', (event, data) => {
        let index = arr.indexOf(obj);
        arr.splice(index, 1);
        this._$rootScope.$apply();
      });
      callback(err, obj);
    }.bind(this);

    let obj = { $resolved: true };

    // Check whether object already exists in store
    if (this._hasObject(data._id)) {
      obj = this._getObject(data._id);
      done(null, obj);
    } else {
      // Otherwise add it
      this._addObject(data, obj, done);
    }
  }

  /**
   * Processes a returned array from the server
   *  - instantiates Resources
   *  - adds Resources to cache
   * @param {Array} res Response + data from the server
   * @param {Array} arr Array to populate into
   * @param {Function} callback The callback function
   */
  processArraySuccess (res, arr, callback) {
    arr.$resolved = true;
    arr.count = res.headers('X-Collection-Count');
    async.each(res.data, (data, callback) => {
      this.processArrayObject(data, arr, callback);
    }, callback);
  }
  
  /**
   * Marks arr as $rejected
   * @param {Object} res The response from the server
   * @param {Array} arr The array to mark
   */
  processArrayFailure (res, arr) {
    arr.$rejected = true;
  }

  _newArray (query) {
    let arr = [];
    arr.$resolved = false;
    arr.$rejected = false;

    // Unsubscribe from query
    if (!_.isNil(query)) {
      arr.unsubscribe = () => {
        this.unsubscribeQuery(query);
      };
    }
    return arr;
  }

  _fetchingObject (id) {
    // Use lodash to find matching id
    return _.findIndex(this.fetching, function (m) {
      return m._id === id;
    }) !== -1; // returns Boolean
  }

  _getFetchingObject (id) {
    // Use lodash to find matching id
    return _.find(this.fetching, function (m) {
      return m._id === id;
    });

    // // Return null or data
    // if (index === -1) {
    //   return null;
    // }
    // return this.fetching[index];
  }

  /**
   * Whether the object exists in the store
   */
  _hasObject (id) {
    // Use lodash to find matching id
    return _.findIndex(this.store, function (m) {
      return m._id === id;
    }) !== -1; // returns Boolean
  }

  /**
   * Get the object from the store by its id
   */
  _getObject (id) {
    // Use lodash to find matching id
    let index = _.findIndex(this.store, function (m) {
      return m._id === id;
    });
    // Return null or data
    if (index === -1) {
      return null;
    }
    return this.store[index];
  }

  /**
   * Adds a resource to the Store based on the object and data passed
   * Mutates the object by assigning the new data to it
   * @param {Object}   data Fields to assign to the object/resource
   * @param {Object}   obj  Object to create the resource from
   * @param {Function} done Called when the object has been added to the Store
   * @return {void} Returns via callback when done
   */
  _addObject (data, obj, done) {
    _.extend(obj, data);
    // create the resource instance
    this.newResource(obj, (o) => {
      this.store.push(o);

      this._$rootScope.$on(obj._id + ':remove', (data) => {
        _.remove(this.store, function (m) {
          return m._id === data._id;
        });
      });

      done(null, obj);
    });
  }

  _calculateQueryHash (model, queryObject) {
    // convert the old queryObject structure to the new one
    let query = Object.assign({}, queryObject);
    // ignore the population in the query hash
    delete query.populate;
    // now generate the query hash
    query = cycle.decycle(query);
    return md5(JSON.stringify({ model: model, query: query }));
  }

  /**
   * Searches the collection using an n-gram method and returns data in the same way as get()
   * Note: ngramSearch Mongoose plugin must be used in the schema and search fields should be identified
   * @param  {Mixed}    string   Full search string to generate search substrings for
   * Note: See get() for other parameters
   *
   * Extra options are available when using an options object:
   *  - minLength: Integer of the minimum length a string must be to be search against (defaults to smallest ngram length)
   */
  search (string, options = '', limit = 0, skip = 0, sort = undefined, fields = undefined) {
    let queryOptions = {}
    
    if(_.isString(options)) {
      console.warn('search(query, populate, limit, skip, sort, fields, callback) is deprecated. Please use search(query, options, callback) where options is an object of key values i.e { populate: "foo", limit: 15 }');
      // Construct the query object the old way
      queryOptions = {
        populate: options,
        limit: limit,
        skip: skip,
        sort: sort,
        fields: fields,
      };
    } else {
      // Assigning the options to the query object
      queryOptions = _.assign(queryOptions, options);
      if (!queryOptions.populate) {
        // if populate is not set then use default
        queryOptions.populate = '';
      }
    }

    // set defaults for additional options
    if (typeof queryOptions.minLength === 'undefined') {
      // if minLength is not being used then set to the default
      queryOptions.minLength = MIN_NGRAM_LENGTH;
    }
    
    // setup the original callback
    const callback = arguments[arguments.length - 1];
    let substrings = [];
    if (string && typeof string === 'string' && string.length >= queryOptions.minLength) {
      // if searching some text then calculate the substrings
      substrings = [string]; //this.getSearchStrings(string, queryOptions.minLength);
    }
    if (substrings.length > 0) {
      // if there are ngrams to search with then attach fields onto sort and fields
      queryOptions.sort = { score: { $meta: 'textScore' } };
      // enable the below code to allow other fields to be sorted by too 
      /*if (!sort) {
        // if sort is the default
        sort = { score: { $meta: 'textScore' } };
      } else if (!sort.score) {
        // if sort is sorting some other functions
        sort.score = { $meta: 'textScore' };
      }*/
      // else if sort is using "score" then allow it to be overridden
      if (!queryOptions.fields) {
        // if field is the default
        queryOptions.fields = { score: { $meta: 'textScore' } };
      } else if (!queryOptions.fields.score) {
        // if field is projecting on some other functions
        queryOptions.fields.score = { $meta: 'textScore' };
      }
      // else if field is using "score" then allow it to be overridden
     
      // TODO: skip caching queries that use search as there will be many unqiue queries
      //return this.get({ $text: { $search: substrings.join(' ') } }, populate, limit, skip, sort, fields, callback);
      return this.get({ $text: { $search: substrings.join(' ') } }, queryOptions, callback);
    } else {
      // if not searching some text then fallback to a standard get
      if (!string || _.isString(string)) {
        // if the string is empty then set to an empty object
        string = {};
      }
      // else if the string is an object let it get that object
      //return this.get(string, populate, limit, skip, sort, fields, callback);
      return this.get(string, queryOptions, callback);
    }
  }

  /**
   * Uses an n-gram method to generate an array of search strings to compare with the server
   * @param  {Mixed} string Full search string to generate search substrings for
   * @param  {Integer} minLength Minimum length of a single search string
   * @param  {Integer} maxLength Maximum length of a single search string
   * @return {Array}         Returns an array of substrings
   */
  getSearchStrings (string, minLength = MIN_NGRAM_LENGTH, maxLength = MAX_NGRAM_LENGTH) {
    // base case
    if (!string) {
      return [];
    }
    // first split the strings into words so they remain distinct
    if (Array.isArray(string)) {
      // if the string is an array then join it
      string = string.join(' ');
    }
    let words = string.split(' ');
    let substrings = [];
    // generate substrings
    for (let word of words) {
      // for each word, concatenate the ngrams
      // remove puntuation from the word (blacklisting is preferred to work with internationalised strings)
      word = word.replace(/[.,\/#!@$%\^\*;:{}=\-_`~\(\)\[\]\n\r]/g,"");
      let ngrams = this.makeNGrams(word, minLength, maxLength);
      // for loop is one of the quicker ways to concat without unique checking
      for (let ngram of ngrams) {
        // for each ngram add only unique ones
        if (substrings.indexOf(ngram) === -1) {
          // if the ngram is not already a substring then add it
          substrings.push(ngram);
        }
      }
    }
    return substrings;
  }

  /**
   * Generates n-grams from the string
   * @param  {String}  string    String to make n-grams out of
   * @param  {Integer} minLength Minimum length of a single n-gram
   * @param  {Integer} maxLength Maximum length of a single n-gram
   * @return {Array}             Returns an array of n-grams
   */
  makeNGrams (string, minLength = MIN_NGRAM_LENGTH, maxLength = MAX_NGRAM_LENGTH) {
    let ngrams = [];
    // default maxLength to the length of the string
    if (!maxLength) {
      maxLength = string.length;
    }
    // stop the loop from running forever
    if (maxLength < minLength) {
      maxLength = minLength;
    }
    // make the ngrams
    for (let index = 0; index < string.length; index++) {
      // make sure each character starts an n-gram
      if (string.length - index >= minLength) {
        // if the string length is long enough to generate ngrams
        for (let length = minLength; length <= maxLength; length++) {
          // for each n-gram length tokenise the string
          if (string.length - index >= length) {
            // if the ngram length is long enough for the string
            ngrams.push(string.slice(index, index + length).toLowerCase());
          } else {
            // if the ngram length is too long for the string then the next iteration will be even longer
            break;
          }
        }
      } else {
        // if the string length is not long enough to generate ngrams then the next iteration will be even shorter
        break;
      }
    }
    return ngrams;
  }
}

(function (app) {
  app.service('Store', function ($http, $state, $rootScope, $injector, $timeout, SocketIO) {
    let stores = {};
    return function (options) {
      let modelName = options;
      if (typeof options === 'string') {
        // if using the old way of defining the model then allow but warn
        console.warn(`Using the model name to construct the Store is deprecated, please use options instead, string passed: ${options}`);
        options = {
          model: modelName
        };
      } else {
        // if using options to define the model
        modelName = options.model;
      }
      if (!stores[modelName]) {
        // if the store has not been created then create it now
        stores[modelName] = new Store(options, $http, $state, $rootScope, $injector, $timeout, SocketIO, stores);
      }
      return stores[modelName];
    };
  });
}(angular.module('app.core')));
