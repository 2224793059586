'use strict';

/**
 * Controller for the customer selector
 */
class CustomerSelectorCtrl {
  
  constructor($scope, $element, CustomerStore, Statics) {
    this.$scope = $scope;
    this.CustomerStore = CustomerStore;

    // id of the p42Selected customer
    this.selectedId = '';
    // list of customers allowed to be p42Selected
    this.customers = [];
    this.noCustomers = false;

    this.searchName = '';
    this.query = '';

    // The md-select directive eats keydown events for some quick select logic. Since we have a search input here, we don't need that logic.
    $element.find('input').on('keydown', function(ev) {
        ev.stopPropagation();
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit () {
    if (this.p42Required === undefined) {
      // if p42Required was not specified default to making the form not required
      this.p42Required = false;
    }
    if (this.p42Default) {
      // if the default id is provided then select that customer
      if (this.p42Default.id) {
        // if the customer is an object then use it instead of loading it from the Hub
        this.customers.push(this.p42Default);
        this.p42Default = this.p42Default.id;
        this.selectedId = this.p42Default;
      } else if (typeof this.p42Default === 'string') {
        // if the value is an ObjectId string then load the customer
        this.selectedId = this.p42Default;
        this.CustomerStore.get({ _id: this.selectedId }, { limit: 1 }, (err, customers) => {
          // on error reject
          if (err) return reject(err);
          // on success
          this.customers.push(customers[0]);
          this.$scope.$apply();
        });
      }
    }
    if (this.p42Disabled === undefined) {
      // if p42Disabled was not specified default to making the form not required
      this.p42Disabled = false;
    }
  }

  /**
   * Load list of customers
   */
  loadCustomers () {
    if (!this.noCustomers) {
      return new Promise ((resolve, reject) => {
        if (this.searchName.length >= 3) {
          // if the search name is long enough to provide good results for then search
          this.query = this.searchName;
        } else {
          // if the search name is not long enough
          if (angular.equals(this.query, {})) {
            // if the query has not been changed then do not research
            return resolve(this.customers);
          } else {
            // if the query has been changed then reset and research
            this.query = {};
          }
        }
        this.CustomerStore.search(this.query, { limit: 5 }, (err, customers) => {
          // on error reject
          if (err) return reject(err);
          // on success
          if (customers.length > 0) {
            // if there are customers to chose from then add them to the list
            this.customers = customers;
          } else {
            // if there are no customers make sure we don't do a request every time
            this.noCustomers = true;
          }
          resolve(this.customers);
        });
      });
    }
  }
}

(function (app) {
  app.controller('CustomerSelectorCtrl', CustomerSelectorCtrl);
}(angular.module('app.customerClient')));
