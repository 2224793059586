'use strict';

(function (app) {
  app.factory('RunsheetValue', function (Resource) {

    return class RunsheetValue extends Resource {
      constructor (data, refs, __populate, callback) {
        super({ model: 'RunsheetValue' }, data, refs, __populate, callback);
      }
    };

  });
}(angular.module('app.runsheetValue')));
