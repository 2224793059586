'use strict';

/**
 * Provider function for Toolbar Service
 *
 *  - Allows for addition of toolbar items before service is instantiated
 * Items are in the following format: {
 *  icon: '', // string of the icon location
 *  title: '', // string of the title of the button
 *  index: 0, // optional integer of where to order the item
 *  action: (loadingCallback) => {} // function to call when clicked. Optionally the first unbound argument may be a callback that will show the loading spinner before it is called
 * }
 */
function ToolbarProvider() {
  const items = [];

  this.addItem = function (item) {
    items.push(item);
  };

  this.$get = [function ToolbarFactory() {
    return new ToolbarService(items);
  }];
}

/**
 * Controller for the Toolbar Service
 */
class ToolbarService {

  /**
   * - Assigns items to this
   */
  constructor(items) {
    this._items = items;
  }

  /**
   * Add an item to the items array
   * @param {Object} item
   */
  addItem(item) {
    this._items.push(item);
  }

  /**
   * Return array of items
   * @return {[Object]} The array of items
   */
  getItems() {
    return this._items.sort((a, b) => {
      // for each item sort by the index (no index means 0)
      // if a's index is not defined then set it to zero
      if (!a.index) a.index = 0;
      // if b's index is not defined then set it to zero
      if (!b.index) b.index = 0;
      // a higher index means it shows more to the left
      return a.index < b.index;
    });
  }
}

(function (app) {
  app.provider('Toolbar', ToolbarProvider);
}(angular.module('app.toolbar')));