'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    const getJob = function($stateParams, JobStore) {
      // ui-router requires a promise
      return new Promise((resolve, reject) => {
        if ($stateParams.id === 'new') {
          // if job does not exist create new job
          JobStore.newResource({},(val) => {
            resolve(val);
          });
        } else {
          // if job exists then load it
          JobStore.get({ _id: $stateParams.id }, { populate: 'customer location labTests' }, (err, jobs) => {
            if (err || jobs.length === 0) {
              // on fail
              reject(err || 'No job found');
            } else {
              // on success
              resolve(jobs[0]);
            }
          });
        }
      });
    }

    $stateProvider.state('jobs', {
      url: '/jobs?tab',
      params: {
        tab: 'prep'
      },
      reloadOnSearch: false,
      views: {
        '@': {
          templateUrl: '/html/job/job-list.html',
          controller: 'JobListCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            phases: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('phases', (err, phases) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(phases);
                });
              });
            },
            preservatives: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('preservatives', (err, preservatives) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(preservatives);
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Jobs'
      }
    }).state('jobs.preview', {
      url: '/preview/:id',
      params: {
        id: 'new'
      },
      reloadOnSearch: false,
      views: {
        'preview@jobs': {
          templateUrl: '/html/job/job-view.html',
          controller: 'JobViewCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            job: function ($rootScope, $stateParams, JobStore) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if job does not exist create new job
                  let job = JobStore.newResource();
                  // TODO: fix the resource to stop having to do this
                  job = job.data;
                  resolve(job);
                } else {
                  // if job exists then load it
                  JobStore.get({ _id: $stateParams.id }, { populate: 'customer location labTests runsheets' }, (err, jobs) => {
                    if (err || jobs.length === 0) {
                      // on fail
                      reject(err || 'No job found');
                    } else {
                      // on success
                      resolve(jobs[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
    $stateProvider.state('jobs.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/job/job-edit.html',
          controller: 'JobEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            job: getJob
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Preview'
      }
    });

    $stateProvider.state('searchJobs', {
      url: '/jobs/search',
      templateUrl: '/html/job/job-search.html',
      controller: 'JobSearchCtrl',
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
            phases: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('phases', (err, phases) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(phases);
                });
              });
            },
            preservatives: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('preservatives', (err, preservatives) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(preservatives);
                });
              });
            }
       },
       ncyBreadcrumb: {
        label: 'Search Jobs'
      }
     
    });
   });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('general', {
      title: 'Jobs',
      icon: '/icons/md/action/svg/design/ic_work_24px.svg',
      state: 'jobs',
      params: {},
/*      Removed 2018-04-10 Add Job not required
        items: [{
        title: 'Add Job',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'jobs.edit',
        params: { id: undefined },
        permission: 'job-make'
      }],
*/
      permission: 'job-view'
    });
    SidenavProvider.addItem('general', {
      title: 'Search Jobs',
      icon: '/icons/md/action/svg/design/ic_work_24px.svg',
      state: 'searchJobs',
      params: {},
/*      Removed 2018-04-10 Add Job not required
        items: [{
        title: 'Add Job',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'jobs.edit',
        params: { id: undefined },
        permission: 'job-make'
      }],
*/
      permission: 'job-view'
    });
  });

}(angular.module('app.job', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
