'use strict';

const _ = require('lodash');

(function (app) {

  app.config(function ($stateProvider) {
    $stateProvider.state('dashboard', {
      url: '/dashboard',
      params: {
        view: 'basicWeek'
      },
      views: {
        '@': {
          templateUrl: '/html/singlesignon/dashboard.html',
          controller: 'DashboardCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            subscriptions: function($rootScope, SubscriptionStore) {
              return new Promise((resolve, reject) => {
                SubscriptionStore.get({ '_id': { $in: $rootScope.User.subscriptions } }, 
                  'program.logo program.background', (error, subs) => {
                  // on error
                  if (error) return reject(error);
                  // on success
                  resolve(subs);
                });
              });
            }
          }
        },
        'calendar@dashboard': {
          templateUrl: '/html/calendar/calendar.html',
          controller: 'CalendarCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            programs: function() {
              // disable program selection
              return new Promise((resolve, reject) => resolve(null));
            }
          }
        },
        'blogpost-feed@dashboard': {
          templateUrl: '/html/blogpost/blogpost-feed.html',
          controller: 'BlogFeedCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            programs: function() {
              // disable program selection
              return new Promise((resolve, reject) => resolve(null));
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Dashboard'
      }
    });

    $stateProvider.state('users', {
      url: '/users/:status?tab',
      params: {
        tab: undefined
      },
      reloadOnSearch: false,
      views: {
        '@': {
          templateUrl: '/html/singlesignon/user-list.html',
          controller: 'UserListCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            query: function ($stateParams,$rootScope) {
              let builtQuery = {};
              if (!$stateParams.status) {
                $stateParams.status = 'Approved'
                builtQuery = {status: 'Approved','removed':false};
              } else if ($stateParams.status == 'Draft') {
                builtQuery = {status: 'Draft','removed':false};
              }else if ($stateParams.status == 'Archived') {
                builtQuery = {status:'Approved','removed': true};
              } else {
                builtQuery = {status: 'Approved','removed':false};
              }
              // builtQuery.isActive = true
              if(!$rootScope.User.hasPermission('admin'))
                builtQuery.customer = $rootScope.User.customer

              return builtQuery;         
            },
            pendingUserCount: function ( UserStore) {
              return new Promise((resolve, reject) => {
                UserStore.get({status: 'Draft'}, (err, userCount) => {
                  if (err) {
                    // on fail
                    reject(err);
                  } else {
                    // on success
                    resolve(userCount.length);
                  }
                });
              });
            },            
            programs: function (ProgramStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                // if permission exists then load it
                ProgramStore.get({}, (err, programs) => {
                  if (err || programs.length === 0) {
                    // on fail
                    reject(err || 'No program found');
                  } else {
                    // on success
                    resolve(programs);
                  }
                });
              });
            },
            roles: function (RoleStore,$rootScope) {
              return new Promise ((resolve, reject) => {

                let query = {};
                if(!$rootScope.User.hasPermission('admin'))
                  query = {customerType:$rootScope.User.customerObj.customerType};
                
                RoleStore.get(query, { sort:{'name': 1} }, (err, roles) => {
                  if (err) 
                    resolve([]);
                  else
                    resolve(roles);
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Users'
      }
    });

    $stateProvider.state('users.edit', {
      url: '/:id',
      views: {
        '@': {
          templateUrl: '/html/singlesignon/user-edit.html',
          controller: 'UserEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            user: function ($stateParams, UserStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {

                if ($stateParams.id=='new') {

                  
                  // if user does not exist create new user
                  UserStore.newResource({}, (user) => {
                  
                    resolve(user);
                  });
                } else {
                  // if user exists then load it
                  const options = {
                    populate: 'contact customer signature '
                  };
                  UserStore.get({ _id: $stateParams.id }, options, (err, users) => {

                    if (err || users.length === 0) {
                      // on fail
                      reject(err || 'No users found');
                    } else {
                      // on success
                      resolve(users[0]);
                    }
                  });
                }
              });
            },
            subscriptions: function (SubscriptionStore) {
              return new Promise ((resolve, reject) => {
                SubscriptionStore.get({}, { populate:'program' }, (err, subs) => {

                  if (subs.length>0)
                  {
                    subs.sort( (a, b) => {
                      if (a.program&&b.program)
                        a.program['name'].localeCompare(b.program['name'])
                      }
                    ); // if a is 'less' than b, a moves before
                    resolve(subs);
                  }
                  else
                    resolve([]);
                });
              });
            },
            roles: function (RoleStore,user,$rootScope) {
              return new Promise ((resolve, reject) => {

                let query = {};
                if(!user._id && !$rootScope.User.hasPermission('admin'))
                  query = {customerType:$rootScope.User.customerObj.customerType};
                else if(user!=undefined&&user&&user.customer!=undefined&&user.customer.customerType!=undefined)
                  query = {customerType:user.customer.customerType};

                RoleStore.get(query, { sort:{'name': 1} }, (err, roles) => {
                  if (err) 
                    resolve([]);
                  else
                    resolve(roles);
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit User'
      }
    });
  });

  app.config(function (SidenavProvider) {

  });

}(angular.module('app.singleSignOn', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
