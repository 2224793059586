'use strict';

/**
 * Component that controls the layout of the webusb settings
 *
 * All input is provided through the sidenav provider
 */
(function (app) {
  app.component('p42BalanceSettings', {
    restrict: 'E',
    controller: 'BalanceSettingsCtrl',
    controllerAs: 'vm',
    templateUrl: '/html/balanceCommunicator/balance-settings.component.html'
  });
}(angular.module('app.balanceCommunicator')));
