'use strict';

const _  = require('lodash');

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('samples', {
      url: '/samples?tab',
      params: {
        tab: 'received'
      },
      views: {
        '@': {
          templateUrl: '/html/sample/sample-list.html',
          controller: 'SampleListCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            phases: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('phases', (err, phases) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(phases);
                });
              });
            },
            preservatives: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('preservatives', (err, preservatives) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(preservatives);
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Samples'
      }
    });
    $stateProvider.state('samples.edit', {
      url: '/edit/:id',
      params: {
        id: 'new',
        job: ''
      },
      views: {
        '@': {
          templateUrl: '/html/sample/sample.html',
          controller: 'SampleCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            sample: function ($rootScope, $stateParams, SampleStore, Sample) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if sample does not exist create new sample
                  let sample = SampleStore.newResource();
                  // TODO: fix the resource to stop having to do this
                  resolve(sample);
                } else {
                  // if sample exists then load it
                  SampleStore.get({ _id: $stateParams.id }, { populate: 'job.labTests job.samples' }, (err, samples) => {
                    if (err || samples.length === 0) {
                      // on fail
                      reject(err || 'No sample found');
                    } else {
                      // on success
                      resolve(samples[0]);
                    }
                  });
                }
              });
            },
            job: function ($rootScope, $stateParams, JobStore) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.job === '') {
                  // if the job has not been pre-selected then skip loading it
                  resolve();
                } else {
                  // if sample exists then load it
                  JobStore.get({ _id: $stateParams.job }, { populate: 'labTests samples' }, (err, jobs) => {
                    if (err || jobs.length === 0) {
                      // on fail
                      reject(err || 'No job found');
                    } else {
                      // on success
                      resolve(jobs[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit'
      }
    });
    $stateProvider.state('samples.new', {
      url: '/new',
      params: {
        job: '',
        // default to adding retention samples
        type: 'r'
      },
      views: {
        '@': {
          templateUrl: '/html/sample/sample-new.html',
          controller: 'SampleNewCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            job: function ($rootScope, $stateParams, JobStore) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {                
                // if job exists then load it
                if (!$stateParams.job) {
                  // if the job has not been preloaded then continue
                  return resolve();
                }
                JobStore.get({ _id: $stateParams.job }, (err, jobs) => {
                  if (err) {
                    // on fail
                    reject(err || 'No job found');
                  } else {
                    // on success
                    resolve(jobs[0]);
                  }
                });
              });
            },
          }
        }
      },
      ncyBreadcrumb: {
        label: 'New'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('general', {
      title: 'Samples',
      icon: '/icons/md/image/svg/design/ic_colorize_24px.svg',
      state: 'samples',
      params: {},
      items: [{
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'samples.new',
        permission: 'sample-make'
      },{
        state: 'samples.edit',
        permission: 'state'
      }],
      permission: 'sample-view'
    });
  });

}(angular.module('app.sample', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
