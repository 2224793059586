'use strict';

(function (app) {
  app.factory('FileStore', function (Store, $http, $window) {
    const FileStore = new Store({
      model: 'File'
    });

    //Save file using the controller and forwardToHub function
    FileStore.save = (file, callback) => {
      let saveUrl = FileStore.baseRoute + '/new';
      /*for (var pair of file.entries()) {
        console.log(pair[0]+ ', '+ pair[1]);
      }*/

      const id = file.get('id');
      
      if (id) {
        saveUrl = FileStore.baseRoute + '/save/' + id;
      }
      
      $http.post(saveUrl, file, {
        // stops the data being serialised
        transformRequest: angular.identity,
        // let the server choose the content type
        headers: { 'Content-Type': undefined }
      }).then((res) => {
        // return res.data;
        callback(null, res.data);
      }, (err) => {
        if (err) console.log('Error posting file data:',err);
        // return err;
        callback(err);
      });
    }

    // this section doesn't seem to be in use
    // //TODO: Change so it reflects saveFile?
    // FileStore.getFiles = function (data, callback) {
    //   this._$http({
    //     method: 'POST',
    //     url: FileStore.baseRoute + '/get',
    //     data: data
    //   }).then( (res) => {
    //       callback(null, res.data);
    //     }, (err) => {
    //       if (err) console.log(err);
    //       callback(err);
    //     }
    //   );
    // }

    /**
     * Gets filedata for download
     * @param {Object} file Universe Framework File object
     * @param {Function} callback Called when finished in form (error) => {}
     * @return Returns via callback
     */
    FileStore.downloadFile = function (file = {}, callback) {
      // if the file is an image and has been loaded already, skip 
      if ( !('filename' in file) ) {
        console.log('file store: the filename was undefined:', file);
        callback();
        /*
        odd / browser console error on Print Certificate
        file undefined
        admin-dashboard.controller.js:237 Downloaded PDF
        angular.js:15535 TypeError: Cannot read property 'filename' of undefined
        at Store.FileStore.downloadFile (file.store.js:65)
    */

      }
      if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g) && file.source_image_uri) {
        callback();
      } else { 
        $http({
          method: 'GET',
          url: FileStore.baseRoute + '/files/download/' + file.unique_name
        }).then((res) => {
          if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)) {
            file.source_image_uri = 'data:'+file.mime_type +';base64,'+res.data;
            callback();
          } else { 
            const binaryString = atob(res.data);
            const dataArray = new Uint8Array(binaryString.length);
            for (let b = 0; b < binaryString.length; b++) {
              // for each byte of data get the raw data
              dataArray[b] = binaryString.charCodeAt(b);
            }
            // create blob container for file data
            const blob = new Blob([dataArray], { type: file.mime_type });
            // now download the blob
            if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
              // if on a Microsoft browser
              $window.navigator.msSaveBlob(blob, file.filename);
            } else {
              // if on a non-Microsoft browser then setup download
              const objectUrl = $window.URL.createObjectURL(blob);
              // create download anchor
              const element = $window.document.createElement('a');
              element.href = objectUrl;
              element.download = file.filename;        
              document.body.appendChild(element);
              // activate the download
              element.click();
              // clean up
              document.body.removeChild(element);
              $window.URL.revokeObjectURL(objectUrl);
              callback();
            }
          }
        }, (err) => {
          if (err) console.log(err);
          callback('file error:',err);
        });
      }
    }

    /**
     * Gets filedata for opening a new tab to preview
     * @param {Object} file Universe Framework File object
     * @param {Function} callback Called when finished in form (error) => {}
     * @return Returns via callback
     */
    FileStore.previewFile = function (file, callback) {
      $http({
        method: 'GET',
        url: FileStore.baseRoute + '/files/download/' + file.unique_name
      }).then((res) => {
        if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)) {
          // console.log(res.data)
          file.source_image_uri = 'data:'+file.mime_type +';base64,'+res.data;
          callback();
        } else { 
          const binaryString = atob(res.data);
          const dataArray = new Uint8Array(binaryString.length);
          for (let b = 0; b < binaryString.length; b++) {
            // for each byte of data get the raw data
            dataArray[b] = binaryString.charCodeAt(b);
          }
          // create blob container for file data
          const blob = new Blob([dataArray], { type: file.mime_type });
          // now download the blob
          if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
            // if on a Microsoft browser
            $window.navigator.msSaveBlob(blob, file.filename);
          } else {
            // if on a non-Microsoft browser then setup download
            const objectUrl = $window.URL.createObjectURL(blob);
            //open a new tab for this file
            $window.open(objectUrl)
            callback();
          }
        }
      }, (err) => {
        if (err) console.log(err);
        callback(err);
      });
    }

    return FileStore;
  });

}(angular.module('app.fileshared')));
