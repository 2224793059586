'use strict';

(function(app) {
  app.factory('RunsheetType', function(Resource, $http) {

    return class RunsheetType extends Resource {
      constructor(data, metaData, callback) {
        super({
          model: 'RunsheetType'
        }, data, metaData, callback);
      }

      /**
       * Gets fields based on the labTests
       * This will load all possible fields for the runsheet type in the ordering set in orders
       * Requires labTests and labTests.fields to be populated
       */
      getFields() {
        let fields = _.uniqBy(
          _.flatten(_.map(this.labTests, 'fields')),
          function(field) {
            return field && field._id && field._id.toString();
          }
        );
        // fields = _.sortBy(fields, (f) => {
        //   if (!this.orders.length || !f) {
        //     return 0;
        //   }
        //   let i = this.orders.indexOf(f._id);
        //   if (i !== -1) {
        //     return i;
        //   }
        //   return this.orders.length;
        // });

        //console.log("----------------- this.labTests : ", this.labTests);
        //console.log("----------------- fields : ", fields);
        return fields;
      }

      /**
       * Approves the runsheet type
       * @param {Function} callback Called when done in the format (error, updatedRunsheetType)
       */
      approve(data,callback) {
        $http({
          method: 'POST',
          url: '/api/RunsheetType/approve',
          data: {
            id: this.id,
            userId:data.id
          }
        }).then((res) => {
          callback(null, res.data);
        }, (res) => {
          callback(res);
        });
      }

      /**
       * Copy with new version runsheet type
       * @param {Function} callback Called when done in the format (error, updatedRunsheetType)
       */
      copy(useLatestLabTest, callback) {
        $http({
          method: 'POST',
          url: '/api/RunsheetType/copy',
          data: {
            id: this.id,
            useLatestLabTest: useLatestLabTest
          }
        }).then((res) => {
          callback(null, res.data);
        }, (res) => {
          callback(res);
        });
      }
    };

  });
}(angular.module('app.runsheetType')));
