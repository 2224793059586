'use strict';

/**
 * Internal Components for buttons throughout Universe. Used for consistency.
 *
 * p42Label:            The label to be displayed on the button.
 * p42Function:         Can be used instead of a store if supplied.
 * p42Store:
 * p42PageSize:
 * p42SearchMinLength:  Minimum length of the ngrams used when comparing search terms
 * p42Name:             Name of the form input
 * p42Model:            Angular model to add the value to
 */
(function (app) {
  app.component('p42SelectVirtual', {
    restrict: 'E',
    controller: 'P42SelectVirtualCtrl',
    controllerAs: 'vm',
    transclude: {
      // p42-select-virtual-messages is a container for ng-messages (p42-name must be used)
      messages: '?p42SelectVirtualMessages'
    },
    bindings: {
      p42Format: '<',
      p42Label: '<',
      p42OnChange: '&',
      p42PageSize: '<',
      p42Query: '<',
      p42Order: '<',
      p42Store: '<',
      p42Populate: '<',
      p42Search: '<',
      p42VirtualHeight: '<',
      p42SearchMinLength: '@',
      p42Name: '@',
      p42Model: '<'
    },
    templateUrl: '/html/core/p42SelectVirtual.component.html'
  });
}(angular.module('app.core')));
