'use strict';
// defining jQuery first means Angular will use it instead of JSLite
const jQuery  = require('jquery');
const angular = require('angular');

(function (app) {
  // should always be defined
  app.constant('APP_NAME', 'ION');
  app.constant('APP_VERSION', 'v1.1.8');
  // define a default route
  app.constant('ROOT_STATE', 'jobs');

  // define any themes this application uses below
  app.config(function ($mdThemingProvider) {
    $mdThemingProvider
      .theme('default')
      .primaryPalette('teal')
      // .accentPalette('blue-grey')
      .accentPalette('teal', {
        'default':'A400'
      });
  });

}(angular.module('app', [
  // all Angular modules must be defined below
  require('angular-animate'),
  require('angular-aria'),
  require('angular-material'),
  require('angular-material-data-table'),
  require('angular-messages'),
  require('angular-resource'),
  require('angular-ui-router'),
  require('angular-cookies'),
  require('angular-sanitize'),
  // The order of the app requirements determines the order in the sidenav
  require('mdPickers'),
  'app.core',
  'app.history',
  'app.authclient',
  'app.programservice',
  'app.sidenav',
  'app.toolbar',
  'app.route',
  // 'app.file',
  'app.statics',
  'app.logging',
  'app.userlogin',
  'app.singleSignOn',
   'app.auditTrail',
  // 'app.userclient',
  'app.formView',
  // 'app.file',
  // General
  'app.job',
  'app.batch',
  'app.control',
  'app.balanceCommunicator',
  'app.runsheet',
  'app.runsheetValue',
  'app.runsheetComparison',
  'app.qualityCheckValue',
  'app.report',
  'app.sample',
  // Management
  'app.field',
  'app.labTest',
  'app.qualityCheck',
  'app.runsheetType',
  'app.runsheetTypeRow',
  'app.runsheetTypeRowCell',
  // Library
  'app.labLocation',
  'app.labAsset',
  'app.labChemical',
  //Customer
  'app.customer',
  'app.location',
  'app.contact',
  'app.reportHistory',
  'app.setting',
  'app.fileshared',
  'app.role',
  'app.subscription',
  'app.permission',
  //Development
  'app.program'
])));
