'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('labTests', {
      url: '/management/labTests?tab',
      params: {
        tab: '2'
      },
      views: {
        '@': {
          templateUrl: '/html/labTest/lab-test-list.html',
          controller: 'LabTestListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Lab Tests'
      }
    });

    $stateProvider.state('labTests.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/labTest/lab-test.html',
          controller: 'LabTestCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            labTest: function ($stateParams, LabTestStore, LabTest) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if labTest does not exist create new labTest
                  LabTestStore.newResource({}, (val) => {
                    resolve(val);                    
                  });
                } else {
                  // if labTest exists then load it
                  LabTestStore.get({ _id: $stateParams.id }, { populate: 'fields approvals.user.contact' }, (err, labTests) => {
                    if (err || labTests.length === 0) {
                      // on fail
                      reject(err || 'No labTest found');
                    } else {
                      // on success
                      resolve(labTests[0]);
                    }
                  });
                }
              });
            },
            fields: function (FieldStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                FieldStore.get({}, (err, fields) => {
                  if (err || fields.length === 0) {
                    // on fail
                    reject(err || 'No fields found');
                  } else {
                    // on success
                    resolve(fields);
                  }
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
    
    $stateProvider.state('labTests.dependency', {
      url: '/dependency',
      views: {
        '@': {
          templateUrl: '/html/labTest/lab-test-dependency.html',
          controller: 'LabTestDependencyCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            labTests: function ($stateParams, LabTestStore, LabTest) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                LabTestStore.get({ _id: $stateParams.id, status: 2 }, (err, labTests) => {
                  if (err || labTests.length === 0) {
                    // on fail
                    reject(err || 'No labTest found');
                  } else {
                    // on success
                    resolve(labTests);
                  }
                });
              });
            }
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Dependency Map'
      }
    });

    $stateProvider.state('labTests.dependency2', {
      url: '/dependency2',
      views: {
        '@': {
          templateUrl: '/html/labTest/lab-test-dependency2.html',
          controller: 'LabTestDependency2Ctrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            labTests: function ($stateParams, LabTestStore, LabTest) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                LabTestStore.get({ _id: $stateParams.id, status: 2 }, {populate: "dependencies"}, (err, labTests) => {
                  if (err || labTests.length === 0) {
                    // on fail
                    reject(err || 'No labTest found');
                  } else {
                    // on success
                    resolve(labTests);
                  }
                });
              });
            }
          },
        },
      },
      ncyBreadcrumb: {
        label: 'Dependency list'
      }
    });

  });
             
  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('management', {
      title: 'Lab Tests',
      icon: '/icons/md/action/svg/design/ic_assignment_24px.svg',
      state: 'labTests',
      params: {},
      items: [{
        icon: '/icons/md/action/svg/design/ic_settings_24px.svg',
        state: 'labTests.dependency2',
        params: { id: undefined },
        permission: 'labTest-edit'     
      },{
        icon: '/icons/md/image/svg/design/ic_grain_24px.svg',
        state: 'labTests.dependency',
        params: { id: undefined },
        permission: 'labTest-edit'     
      },{
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'labTests.edit',
        params: { id: undefined },
        permission: 'labTest-make'     
      }],
      permission: 'management-buttons'
    });
  });

}(angular.module('app.labTest', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView',
  'app.statics',
])));
