'use strict';

/**
 * Controller for the location list view
 */
class CustomerLocationListCtrl {
  constructor ($rootScope, $window, LocationStore, $state, $filter) {
    this.$window = $window;
    this.selectedRecords = [];
    this.records = [];
    this.$state = $state;
    this.$rootScope = $rootScope;
    
    this.LocationStore = LocationStore;

    this.user = this.$rootScope.User
    

    this.order = 'name';

    this.fields = [{
      path: 'name',
      name: 'Location Name',
    },{
      path: 'phone',
      name: 'Phone #',
    },{
      path: 'physical',
      name: 'Physical Address',
      transform: (value) => {
        
        if (value && value.city && value.city != '<blank>'){
          return value.city+',' + value.country;
        }
         else {
          return '';
        }
      },
    }];

    if ($rootScope.User.permissions.indexOf('admin') == -1) {
      this.query = {customer: $rootScope.User.customer};
    }

    // if (this.user._id&&!this.$rootScope.User.hasPermission('admin')&&this.user.contact!=undefined)
      //this.query['_id'] = this.user.contact.customer;

  }
  



  removeLocation (location) {
    // delete duplicate pendingLocation if pendingLocationDetailsIndex is defined

    if (location.pendingLocationDetailsIndex >= 0) {
      location.pendingLocationDetails.splice(location.pendingLocationDetailsIndex,1);

      location.save((locationErr, locationData) => {
        if (locationErr) {
          console.error(locationErr);
          this.$rootScope.showToast('Failed to delete location ' + locationData.name);
        } else {
          this.$rootScope.showToast(`Duplicate location "${locationData.name}" deleted`);
          this.$state.reload();
        }
      });
    } else {
      location.remove(true, (err, res) => {
        if (res == "OK") {
          this.$rootScope.showToast(`${ location.name } successfully deleted`);
          this.$state.reload();
        }
      });
    }
  }
}

(function(app) {
  app.controller('CustomerLocationListCtrl', CustomerLocationListCtrl);
}(angular.module('app.customer')));
