'use strict';

(function (app) {
  app.directive('fileModel', function($parse) {

    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        var model = $parse(attrs.fileModel);
        var modelSetter = model.assign;
        let dataURL = null;

        element.bind('change', function(){
          scope.resizeImage({
            file: element[0].files[0],
            maxSize: 640                  // TODO : drive max size of images from env file
          }).then(function (resizedImage) {
            resizedImage.resized = true;
            resizedImage.dataURL = dataURL;
            scope.$apply(function(){
              modelSetter(scope, resizedImage);
            });
          }).catch(function (err) {
            scope.$apply(function(){
              modelSetter(scope, element[0].files[0]);
            });
            console.log('Uploaded file is not an image!');
          });
        });

        // resize images
        scope.resizeImage = function (settings){
          let file = settings.file;
          let maxSize = settings.maxSize;
          let reader = new FileReader();
          let image = new Image();
          let canvas = document.createElement('canvas');
      
          let dataURItoBlob = function (dataURI) {
            let bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            let max = bytes.length;
            let ia = new Uint8Array(max);
            for (let i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new File([ia], settings.file.name, { type: settings.file.type });
          };
      
          // resize preserving the aspect ratio
          let resize = function () {
            let width = image.width;
            let height = image.height;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            dataURL = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataURL);
          };
      
          return new Promise(function (resolve, reject) {
            if (!file.type.match(/image.*/)) {
              reject("Not an image");
            }
      
            reader.onload = function (readerEvent) {
      
              image.onload = function () {
                resolve(resize()); 
              };
              
              image.src = readerEvent.target.result;
            };
      
            reader.readAsDataURL(file);
          });
          
        }
      }
    };

  });
}(angular.module('app.fileshared')));