'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('qualityChecks', {
      url: '/management/qualityChecks',
      views: {
        '@': {
          templateUrl: '/html/qualityCheck/quality-check-list.html',
          controller: 'QualityCheckListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Quality Checks'
      }
    });
    $stateProvider.state('qualityChecks.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/qualityCheck/quality-check.html',
          controller: 'QualityCheckCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            qualityCheck: function ($stateParams, QualityCheckStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if qualityCheck does not exist create new qualityCheck
                  QualityCheckStore.newResource({},(val) => {
                    resolve(val);
                  });
                } else {
                  // if qualityCheck exists then load it
                  QualityCheckStore.get({ _id: $stateParams.id }, (err, qualityChecks) => {
                    if (err || qualityChecks.length === 0) {
                      // on fail
                      reject(err || 'No qualityCheck found');
                    } else {
                      // on success
                      resolve(qualityChecks[0]);
                    }
                  });
                }
              });
            },
            qualityCheckCodes: function (Statics) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                Statics.get('quality_check_codes', (err, codes) => {
                  if (err) {
                    return reject(err);
                  }
                  resolve(codes);
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('management', {
      title: 'Quality Checks',
      icon: '/icons/md/action/svg/design/ic_assignment_turned_in_24px.svg',
      state: 'qualityChecks',
      params: {},
      items: [{
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'qualityChecks.edit',
        params: { id: undefined },
        permission: 'qualityCheck-make'
      }],
      permission: 'management-buttons'
    });
  });

}(angular.module('app.qualityCheck', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
