'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('customers', {
      url: '/customers',
      views: {
        '@': {
          templateUrl: '/html/customerclient/customer-list.html',
          controller: 'CustomerListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Customers'
      }
    });
    $stateProvider.state('customers.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/customerclient/customer-edit.html',
          controller: 'CustomerEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            customer: function ($stateParams, CustomerStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if customer does not exist create new customer
                  CustomerStore.newResource({}, (customer) => {
                    resolve(customer);
                  });
                } else {
                  // if customer exists then load it
                  CustomerStore.get({ _id: $stateParams.id }, (err, customers) => {
                    if (err || customers.length === 0) {
                      // on fail
                      reject(err || 'No customer found');
                    } else {
                      // on success
                      resolve(customers[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Customer'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('hub', {
      title: 'Customers',
      icon: '/icons/md/action/svg/design/ic_assignment_ind_24px.svg',
      state: 'customers',
      params: {},
      permission: 'customer-view',
      items: [{
        title: 'Add Customer',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'customers.edit',
        params: { id: undefined },
        permission: 'customer-edit',
      }]
    });
  });

}(angular.module('app.customerClient', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
