'use strict';
const _         = require('lodash');
const cytoscape     = require('cytoscape');
const cycola        = require('cytoscape-cola');
const cyedgehandles = require('cytoscape-edgehandles');

/**
 * Controller for the Location edit view
 */
class LabTestDependencyCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($document, $window, labTests) {
    $document.bind('keydown', this.handleKeyPress.bind(this));
    $window.addEventListener('resize', () => {
      this.cy.resize();
      this.cy.fit();
      this.cy.center();
    });
    
    this.labTests = labTests;    
  }

  init() {
    this.initCytoscape();
  }
  
  initCytoscape() {
    //Registering extensions

    cycola(cytoscape);
    
    if (typeof cytoscape('core', 'edgehandles') !== 'function') {
     cyedgehandles(cytoscape);
    }
    // cyedgehandles(cytoscape);
 
    // Getting nodes
    this.elements = _.map(this.labTests, (lt) => {
      return {
        group: 'nodes',
        data: lt,
      };
    });

    // Getting edges
    const edges = _.flatMap(this.labTests, (lt) => {
      return _.map(lt.dependencies, (dep) => {
        return {
          data: {
            source: lt.id,
            target: dep,
          }
        };
      });
    });

    this.elements = this.elements.concat(edges);

    this.style = [{
      selector: 'node',
      style: {
        'label' : 'data(unique_name)'
      }
    },{
      selector: 'edge',
      css: {
        'curve-style': 'bezier',
        'target-arrow-shape': 'triangle'
      }
    },{
      selector: '.edgehandles-hover',
      css: {
        'background-color': 'red'
      }
    },{
      selector: '.edgehandles-source',
      css: {
        'border-width': 2,
        'border-color': 'red'
      }
    },{
      selector: '.edgehandles-target',
      css: {
        'border-width': 2,
        'border-color': 'red'
      }
    },{
      selector: '.edgehandles-preview, .edgehandles-ghost-edge',
      css: {
        'line-color': 'red',
        'target-arrow-color': 'red',
        'source-arrow-color': 'red'
      }
    }];
    
    this.layout = {
      name: 'cola',
      infinite: false,
      fit: true,
      maxSimulationTime: 8000,
    }
    this.cy = cytoscape({
      container: document.getElementById('cy'),
      elements: this.elements,
      style: this.style,
      layout: this.layout,
    });

    this.cy.on('layoutready', () => {
      this.cy.center();
      this.cy.fit();
    });
    
    this.setupEdgeHandles();
  }

  setupEdgeHandles() {
    this.cy.edgehandles({
      // edge handle settings
      preview: false, // whether to show added edges preview before releasing selection
      stackOrder: 4, // Controls stack order of edgehandles canvas element by setting it's z-index
      handleSize: 10, // the size of the edge handle put on nodes
      handleHitThreshold: 6, // a threshold for hit detection that makes it easier to grab the handle
      handleIcon: false, // an image to put on the handle
      handleColor: '#ff0000', // the colour of the handle and the line drawn from it
      handleLineType: 'ghost', // can be 'ghost' for real edge, 'straight' for a straight line, or 'draw' for a draw-as-you-go line
      handleLineWidth: 1, // width of handle line in pixels
      handleOutlineColor: '#000000', // the colour of the handle outline
      handleOutlineWidth: 0, // the width of the handle outline in pixels
      handleNodes: 'node', // selector/filter function for whether edges can be made from a given node
      handlePosition: 'middle top', // sets the position of the handle in the format of "X-AXIS Y-AXIS" such as "left top", "middle top"
      hoverDelay: 150, // time spend over a target node before it is considered a target selection
      cxt: false, // whether cxt events trigger edgehandles (useful on touch)
      enabled: true, // whether to start the plugin in the enabled state
      toggleOffOnLeave: true, // whether an edge is cancelled by leaving a node (true), or whether you need to go over again to cancel (false; allows multiple edges in one pass)
      complete: function( sourceNode, targetNodes, addedEntities ) {
        // fired when edgehandles is done and entities are added
        let sourceData = sourceNode.data();
        let targetData = targetNodes[0].data();

        if(!sourceData.dependencies) sourceData.dependencies = [];
        sourceData.dependencies.push(targetData.id);
        sourceData.save();
      },
      edgeType: function(sourceNode, targetNode) {
        // for edges between the specified source and target
        // return element object to be passed to cy.add() for edge
        // NB: i indicates edge index in case of edgeType: 'node'

        let bfs = this.cy.elements().bfs({
          roots: `#${targetNode.data('id')}`,
          directed: true,
          visit: (v, e, u, i, depth) => {
            // v The current node.
            // e The edge connecting the previous node to the current node.
            // u The previous node.
            // i The index indicating this node is the ith visited node.
            // depth How many edge hops away this node is from the root nodes.
            if (v.data('id') === sourceNode.data('id') && depth > 0) {
              return true;
            };
          }
        });

        return (sourceNode.edgesTo(targetNode).parallelEdges().length == 0 && bfs.found.length == 0) ? 'flat' : null;
      }.bind(this),
    });
  }

  handleKeyPress(e) {
    if (e.keyCode == 46) this.deleteEdges();
  }
  
  deleteEdges() {    
    const selectedEdges = this.cy.edges((ele) => {
      return ele.selected();
    });
    
    _.each(selectedEdges, (edge) => {
      let source = edge.source().data();
      let target = edge.target().data();
      
      _.remove(source.dependencies, (i) => {
        return i == target.id;
      });
      
      source.save();
      
    });
    
    this.cy.remove(selectedEdges);
  }
}

(function (app) {
  app.controller('LabTestDependencyCtrl', LabTestDependencyCtrl);
}(angular.module('app.labTest')));
