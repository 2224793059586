'use strict';

(function (app) {
  app.factory('RunsheetTypeStore', function (Store) {
    const RunsheetTypeStore = new Store({
      model: 'RunsheetType'
    });

    return RunsheetTypeStore;
  });

}(angular.module('app.runsheetType')));
