'use strict';

const _ = require('lodash');

(function (app) {
  app.factory('UserStore', function (Store, $http, $rootScope, $location, $cookies) {
    const UserStore = new Store({
      model: 'User',
      ignoreMeta: true
    });

    /**
     * Returns a user resource for the user using the app
     */
    UserStore.myuser = function (callback = function () {}) {
      if ($rootScope.User.logged_in) {
        // if the user is logged in then return the cached version
        return callback(null, $rootScope.User);
      }
      let token = $location.search().token;
      if (token) {
        // if there is a token then parse it back into base64 from url encoding
        token = token.replace(/ /g, '+');
      }
      // if the user needs to be loaded
      $http
        .get('/api/user/myuser', { headers: { 'Authorization': 'Bearer ' + token }})
        .then((res) => {
          // console.log('success',res)
          // on success log the user in (on the front-end)
          this.activateUser(res.data, callback);
        }, (res) => {
          // console.log('error',res)
          // on fail still get a resource
          this.activateUser({}, (error, user) => {
            // Note: Respond with the server response as the error
            callback(res, user);
          });
        });
    };
    
    /**
     * directs user to logout page whilst removing the domains cookies
     */
    UserStore.logout = function (callback = function(){}) {
      // destroy cookies and user details, logout on the sever side and redirect to root, there is no need to wait for the callback
      // NOTE: Callback from logout seems to take a while if hub is busy thus creating a laggy experience, making the process async makes it an instantaneous logout
      // console.log('responsible for logout',$cookies.getAll());
      var cookies = $cookies.getAll();
      angular.forEach(cookies, function (v, k) {
        $cookies.remove(k);
      });
      $rootScope.token = undefined;

      $http.get('/api/user/logout')
        .then(
          (res) => {
            window.location.href = '/';
            this.activateUser({ logged_in: false }, callback);            
          },
          (res) => {
            callback(res);
          }
      );
      // window.location.href = '/';
    };


    /**
     * Authenticates the user and forwards response to requested page
     */
    UserStore.basicLogin = function (email, password, referrer, callback) {
     
      if (referrer) {
        referrer = '?ref=' + referrer; // add referrer to query parametres
      } else {
        referrer = ''; // unset referrer
      }
      $http.get('/api/user/myuser' + referrer, { headers: { 'Authorization': 'Basic ' + btoa(email + ':' + password) } })
        .then(
          (res) => {
            // on success
            if (res.headers('x-cs-auth')) {
              // if there is an authentication header then get the token
              const TOKEN = 1;
              $rootScope.token = res.headers('x-cs-auth').split(' ')[TOKEN];
            }

            this.activateUser(res.data, callback);
          },
          (res) => {
            // on fail still get a user resource
            this.activateUser({}, (error, user) => {
              callback(res, user);
            });
          }
        );
    };

    /**
     * Sets a user to Active
     */
    UserStore.activateUser = function(rawUser, callback) {
      if (typeof rawUser.logged_in === 'undefined') {
        // if login switch has not been set then default to false
        rawUser.logged_in = false;
      }
      this.newResource(rawUser, (user) => {
        // gets a new user resource for myuser
        $rootScope.User = user;
        if (user.logged_in) {
          // if logged in then enable metadata for the store since it can now be received
          this.ignoreMeta = false;
        }
        callback(null, user);
      });
    };

    /**
     * Send user reset password link
     */
    UserStore.resetPassword = function(username, callback) {
      $http({
        method: 'POST',
        url: '/api/user/reset/send',
        data: { username: username }
      })
      .then((res) => {
        // on success
        callback();
      }, (err) => {
        // Note: this will never be hit as the server always return success for security reasons
        callback(err);
      });
    };

    // UserStore.activateNewUser = function(appCode, token , callback) {
    //   $http({
    //     method: 'POST',
    //     url: '/api/activate/user',
    //     data: { 
    //       appCode: appCode,
    //       token: token 
    //     }
    //   })
    //     .then((res) => {
    //       // on success
    //       callback(res);
    //     }, (err) => {
    //       // Note: this will never be hit as the server always return success for security reasons
    //       callback(err);
    //     });
    // };

    /**
     * Updates password supplied by reset password page
     */
    UserStore.changePassword = function (token, newPassword, callback) {
      // Note: indicate it is a password reset token using the "@" prefix
      $http.post('/api/user/reset/change', { password: newPassword }, { headers: { 'Authorization': 'Basic ' + btoa('@' + token + ':') } })
        .then(
          (res) => {
            // on success
            if (res.headers('x-cs-auth')) {
              // if there is an authentication header then get the token
              const TOKEN = 1;
              $rootScope.token = res.headers('x-cs-auth').split(' ')[TOKEN];
            }

            this.activateUser(res.data, callback);
          },
          (res) => {
            // on fail still get a user resource
            this.activateUser({}, (error, user) => {
              if (!error) {
                // if there was no error activating the use then add one for expired token
                error = 'Password reset token expired. Please try again.';
              }
              callback(error);
            });
          }
        );
    };

    /**
     * Updates password supplied by reset password page
     */
    UserStore.changePassword = function (token, newPassword, callback) {
      // Note: indicate it is a password reset token using the "@" prefix
      $http.post('/api/user/reset/change', { password: newPassword }, { headers: { 'Authorization': 'Basic ' + btoa('@' + token + ':') } })
        .then(
          (res) => {
            // on success
            if (res.headers('x-cs-auth')) {
              // if there is an authentication header then get the token
              const TOKEN = 1;
              $rootScope.token = res.headers('x-cs-auth').split(' ')[TOKEN];
            }

            this.activateUser(res.data, callback);
          },
          (res) => {
            // on fail still get a user resource
            this.activateUser({}, (error, user) => {
              if (!error) {
                // if there was no error activating the use then add one for expired token
                error = 'Password reset token expired. Please try again.';
              }
              callback(error);
            });
          }
        );
    };

    /**
     * Returns array of users with requested Role
     */
    UserStore.getUsersByRole = (role) => new Promise( 
      (resolve, reject) => {
        // Getting the Audit
        UserStore.get({status:'Approved'}, { populate:'contact roles' }, (err, users) => {
          if (err) return reject(err);
          // auditors must be audit-admin role users
          const withRoles = users.filter( e => ( e.roles.find( e => e.code === 'audit-admin') ) );
          if (withRoles.length === 0) return reject('Unable to find a User with Audit Admin roles');
          resolve(withRoles);
        });
      });

    return UserStore;
  });

}(angular.module('app.userlogin')));