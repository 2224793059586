'use strict';

(function (app) {

}(angular.module('app.runsheetTypeRow', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
