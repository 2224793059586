'use strict';

(function (app) {
  app.factory('RunsheetStore', function (Store) {
    const RunsheetStore = new Store({
      model: 'Runsheet'
    });

    RunsheetStore.createMultiple = function (runsheetTypes, samples, jobs, callback) {
      let data = {
        runsheetTypes: runsheetTypes,
        samples: samples,
        jobs: jobs,
      };
      
      this._$http({
        method: 'POST',
        url: this.baseRoute + '/createMultiple',
        data: data
      }).then(
        (res) => {
          callback(null, res.data);
        },
        (err) => {
          console.log('failed runsheet creation : ',data);
          callback(err);
        }
      );
    }

    RunsheetStore.approve = function (runsheet) {
      let data = {
        runsheet: runsheet
      };
      this._$http({
        method: 'POST',
        url: this.baseRoute + '/approve',
        data: data
      }).then(
        (res) => {
          console.log(res);
        },
        (err) => {

        }
      );
    }

    return RunsheetStore;
  });

}(angular.module('app.runsheet')));
