
/**
 * Controller for the LabTestDependency2Ctrl
 */
class LabTestDependency2Ctrl {

  /**
   * Assigns injected variables to this
   */
  constructor($document, $window, $mdDialog, labTests) {
    
    this.$mdDialog = $mdDialog;
    this.labTests = labTests; 
    
    this.dependency = [];
    // console.log(this.labTests)
    this.labTests.forEach((labtest) => {
      if (labtest.dependencies.length >0) {
        labtest.dependencies = _.uniqBy(labtest.dependencies, '_id')
        let array = labtest.dependencies.map(labt => { 
          let newObj = {};
          
            if(labtest.version_number==undefined)
              labtest.version_number = 0

            if(labt.version_number==undefined)
              labt.version_number = 0
            // console.log(labt)
            newObj.after = labtest.unique_name+' '+labtest.version_number;
            newObj.afterID = labtest.id;
            newObj.beforeID = labt.id;
            newObj.before = labt.unique_name+' '+labt.version_number;
            if(labt.status==4)
            {
              newObj.before = newObj.before + '(Retired)'
            }


            return newObj;
        })
        this.dependency = this.dependency.concat(array);
      } else {
        this.dependency.push({before:'[ NO DEPENDENCY ]', after: labtest.unique_name+' '+labtest.version_number, beforeID:'', afterID: labtest.id})
      }
    })
    this.dependency = Array.from(new Set(this.dependency))

  }

  editOnClick(id){
    let labtest = this.labTests.find(labtest => labtest.id === id);
    let mapLabtests = this.labTests.map(labtest => {
      let newObj = {};
      newObj.id = labtest.id;
      newObj.unique_name = labtest.unique_name+' '+labtest.version_number;
      // console.log(newObj.unique_name)
      return newObj
    })

    this.$mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: false,
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        labtest:labtest,
        mapLabtests:mapLabtests
      },
      templateUrl: '/html/labTest/edit-dependency-dialog.html',
      controller: function DialogController($mdDialog, $scope, $state, LabTestStore) {
        
        this.$state = $state;
        this.newdependency = this.labtest.dependencies.map( labtest => labtest.id);

        this.add = () => {
          this.newdependency.push("")
        }
        this.removeAllDependencies = (dependencies) => {
          // console.log(this.labtest.dependencies)
          for (var i = this.labtest.dependencies.length - 1; i >= 0; i--) 
          {
            var id = this.labtest.dependencies[i]['_id'];
            this.newdependency = this.newdependency.filter( labtid => labtid !== id);
            $scope.dependencyForm.$setDirty(); 
          }
          this.save()
        }
        this.remove = (id) => {

          this.newdependency = this.newdependency.filter( labtid => labtid !== id);
          $scope.dependencyForm.$setDirty();  
        };

        this.save = () => {
          this.labtest.dependencies = Array.from(new Set(this.newdependency));
          this.labtest.save(() => {
            $mdDialog.hide();
            this.$state.reload();
          });
        };

        this.back = () => {
          $mdDialog.hide();
        };
      },
    });
  }

 
  
}

(function (app) {
  app.controller('LabTestDependency2Ctrl', LabTestDependency2Ctrl);
}(angular.module('app.labTest')));
