'use strict';

(function (app) {
  app.factory('QualityCheck', function ($http, Resource) {

    return class QualityCheck extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'QualityCheck' }, data, metaData, callback);
      }
    };

  });
}(angular.module('app.qualityCheck')));
