'use strict';

// TODO: provide front-end for ngram searching
// TODO: provide front-end for batch actions
/**
 * Component allowing the full form for adding providers to be embedded in another form
 *
 * ionQualityCheckValues: A collection of quality check values
 */
(function (app) {
  app.component('ionQualityCheckValueView', {
    restrict: 'E',
    controller: 'QualityCheckValueViewCtrl',
    controllerAs: 'vm',
    transclude: true,
    bindings: {
      users: '<',
      ionQualityCheckValues: '<',
      ionStatus: '<',
      ionCondition:'<',
      ionValidation: '&',
    },
    templateUrl: '/html/qualityCheckValueView/quality-check-value-view.component.html'
  });
}(angular.module('app.qualityCheckValueView')));
