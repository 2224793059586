'use strict';
const _ = require('lodash');

(function (app) {
  app.factory('Runsheet', function ($http, Resource) {

    return class Runsheet extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'Runsheet' }, data, metaData, callback);
      }

      approve (callback) {
        $http({
          method: 'POST',
          'url': '/api/Runsheet/approve',
          data: {id: this.id}
        }).then((res) => {
          callback(null, res.data);
        }, (res) => {
          callback(res);
        });
      }

      reject (callback) {
        $http({
          method: 'POST',
          'url': '/api/Runsheet/reject',
          data: {id: this.id}
        }).then((res) => {
          callback(null, res.data);
        }, (res) => {
          callback(res);
        });
      }

      reset (callback) {
        $http({
          method: 'POST',
          'url': '/api/Runsheet/reset',
          data: {id: this.id}
        }).then((res) => {
          callback(null, res.data);
        }, (res) => {
          callback(res);
        });
      }

      claim (callback) {
        $http({
          method: 'POST',
          'url': '/api/Runsheet/claim',
          data: {id: this.id}
        }).then((res) => {
          callback(null, this);
        }, (res) => {
          callback(res);
        });
      }

      release(callback){
        $http({
          method: 'POST',
          'url': '/api/Runsheet/release',
          data: {id: this.id}
        }).then((res) => {
          callback(null, this);
        }, (res) => {
          callback(res);
        });
      }

    };

  });
}(angular.module('app.runsheet')));
