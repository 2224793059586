'use strict';

/**
 * Controller for the subscription list view
 */
class SubscriptionListCtrl {

  constructor ($rootScope, $filter, SubscriptionStore) {
    this.SubscriptionStore = SubscriptionStore;
    this.populate = 'program';
    this.fields = [{
      path: 'name',
      name: 'Name'
    },{
      path: 'program',
      name: 'Program',
      transform: (value) => {
        if (!value) return '';
        return value.name;
      }
    }, {
      path: 'active',
      name: 'Currently Active'
    }, {
      path: 'start_date',
      name: 'Start Date',
      hideXs: true,
      transform: (value) => {
        return value ? $filter('date')(value, $rootScope.dateFormat) : 'N/A';
      }
    }, {
      path: 'end_date',
      name: 'End Date',
      hideXs: true,
      transform: (value) => {
        return value ? $filter('date')(value, $rootScope.dateFormat) : 'N/A';
      }
    }, {
      path: 'recur_limit',
      name: 'Recurrences Remaining',
      hideXs: true
    }];
  }

  /**
   * Called when a document is selected
   * @param {Object}  selected  The selected row object.
   * @return  {void}            Assigns the last clicked object the value of the last clicked row.
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  /**
   * Removes the document
   * @param {Object} subscription Subscription to remove.
   * @return  {void}              Assigns the last clicked object the value of the last clicked row.
   */
  deleteSubscription(subscription) {
    subscription.remove(true);
  }
}

(function(app) {
  app.controller('SubscriptionListCtrl', SubscriptionListCtrl);
}(angular.module('app.subscription')));
