'use strict';

(function (app) {
  app.config(function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      // Allow same origin resource loads
      'self',
      // Allow loading from google for the embedded pdf viewer
      'https://docs.google.com/gview?embedded=true&url=**'
    ]);
  });

	app.config(function ($stateProvider) {

    $stateProvider.state('files', {
      url: '/files',
      views: {
        '@': {
          templateUrl: '/html/fileShared/file-list.html',
          controller: 'FileListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Files'
      }
    });

    const baseView = {
      templateUrl: '/html/fileShared/file-view.html',
      controller: 'FileFormCtrl',
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        file: function ($stateParams, FileStore) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
            if (!$stateParams.id) {
              // if file does not exist create new file
              FileStore.newResource({}, resolve);
            } else {
              // if file exists then load it
              FileStore.get({ _id: $stateParams.id }, {}, (err, files) => {

                if (err || files.length === 0) {
                  // on fail
                  reject(err || 'No file found');
                } else {
                  // on success
                  resolve(files[0]);
                }
              });
            }
          });
        },
        program: function (ProgramStore) {
          return new Promise((resolve, reject) => {
            ProgramStore.get({code:"coo"}, {}, (err, programs) => {
              if(err) return reject(err);

              if(programs.length>0)
              	programs = programs[0]
              resolve(programs);
            });
          });
        }
      }
    };

    $stateProvider.state('files.edit', {
      url: '/edit/:id',
      views: {
        '@': baseView,
        'form@files.edit': {
          templateUrl: '/html/fileShared/file-form.html',                
        },
      },
      ncyBreadcrumb: {
        label: 'Edit File'
      }
    });

    // $stateProvider.state('files.view', {
    //   url: '/preview/:id',
    //   params: {
    //     readOnly: true
    //   },
    //   views: {
    //     '@': baseView,
    //     'form@files.view': {
    //       templateUrl: '/html/fileShared/file-form.html',
    //     },
    //   },
    //   ncyBreadcrumb: {
    //     label: 'View File'
    //   }
    // });

  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('User Access', {
      title: 'My Files',
      subtitle: 'View and upload files',
      params: {},
      icon: '/icons/md/file/svg/design/ic_cloud_24px.svg',
      state: 'files',
      permission: 'file-view',
      items: [{
        title: 'Upload File',
        icon: '/icons/md/file/svg/design/ic_cloud_upload_24px.svg',
        state: 'files.edit',
        permission: 'admin'
      }]
    });
  });

  
}(angular.module('app.fileshared', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics'
])));
