'use strict';

(function (app) {
  app.factory('ProgramServiceStore', function (Store, $http) {
    const ProgramServiceStore = new Store({
      model: 'ProgramService'
    });

    ProgramServiceStore.create = function (data, callback) {
      $http({
        method: 'POST',
        url: '/api/programservice/create',
        data: data
      })
      .then(
        function (res) {
          callback(null, res);
        },
        function (res) {
          callback(res);
        }
      );
    };

    return ProgramServiceStore;
  });

}(angular.module('app.programservice')));
