'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('roles', {
      url: '/roles',
      views: {
        '@': {
          templateUrl: '/html/role/role-list.html',
          controller: 'RoleListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Roles'
      }
    });

    $stateProvider.state('roles.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/role/role-edit.html',
          controller: 'RoleEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            roleInfo: function ($stateParams, RoleStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if (!$stateParams.id) {
                  // if role does not exist create new role
                  RoleStore.newResource({}, (role) => {
                    resolve(role);
                  });
                } else {
                  // if role exists then load it
                  // console.log($stateParams.id)
                  RoleStore.get({ _id: $stateParams.id }, {}, (err, roles) => {
                    if (err || roles.length === 0) {
                      // on fail
                      reject(err || 'No role found');
                    } else {
                      resolve(roles[0]);
                      
                    }
                  });
                }
              });
            },
            programs: function (ProgramStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                // if permission exists then load it
                ProgramStore.get({}, { sort: { name: 1 } }, (err, programs) => {
                  if (err || programs.length === 0) {
                    // on fail
                    reject(err || 'No program found');
                  } else {
                    // on success
                    resolve(programs);
                  }
                });               
              });
            },            
            permissions: function (PermissionStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                // if permission exists then load it
                PermissionStore.get({}, { sort: { code: 1 } }, (err, permissions) => {
                  if (err || permissions.length === 0) {
                    // on fail
                    reject(err || 'No permission found');
                  } else {
                    // on success
                    resolve(permissions);
                  }
                });               
              });
            }                        
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Role'
      }
    });

  });

  // app.config(function (SidenavProvider) {
  //   SidenavProvider.addItem('Development', {
  //     title: 'Roles',
  //     subtitle: 'View and edit roles',
  //     icon: '/icons/md/social/svg/design/ic_people_outline_24px.svg',
  //     state: 'roles',
  //     params: {},
  //     permission: 'admin',
  //     items: [{
  //       title: 'Add Role',
  //       icon: '/icons/md/content/svg/design/ic_add_24px.svg',
  //       state: 'roles.edit',
  //       params: { id: undefined },
  //       permission: 'admin'
  //     }]
  //   });
  // });

}(angular.module('app.role', [
  'app.core',
  'app.sidenav',
  'app.route'
])));
