'use strict';

(function (app) {
  app.factory('RunsheetTypeRowCell', function (Resource) {

    return class RunsheetTypeRowCell extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'RunsheetTypeRowCell' }, data, metaData, callback);
      }
    };

  });
}(angular.module('app.runsheetTypeRowCell')));
