'use strict';
const _ = require('lodash');
(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('runsheetTypes', {
      url: '/management/runsheetTypes?tab',
      params: {
        tab: '2'
      },
      views: {
        '@': {
          templateUrl: '/html/runsheetType/runsheet-type-list.html',
          controller: 'RunsheetTypeListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Runsheet Types'
      }
    });
    $stateProvider.state('runsheetTypes.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/runsheetType/runsheet-type.html',
          controller: 'RunsheetTypeCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            runsheetType: function ($rootScope, $stateParams, RunsheetTypeRowStore, RunsheetTypeStore, RunsheetType) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if runsheetType does not exist create new runsheetType
                  RunsheetTypeStore.newResource({
                    name: 'New Runsheet Type',
                    description: 'Runsheet Type description',
                    sample_limit: 53,
                    runsheetNumber:1,
                  }, (val) => {
                    // Saving the runsheetType first cause we need that id
                    val.save((err) => {
                      if(err) return reject(err);
                      $stateParams.id = val.id;
                      resolve(val);
                    })
                  });
                } else {
                  // if runsheetType exists then load it
                  RunsheetTypeStore.get({ _id: $stateParams.id }, { populate: 'labTests.fields qualityChecks_topLeft qualityChecks_topRight qualityChecks_bottomLeft qualityChecks_bottomRight rows.cells' }, (err, runsheetTypes) => {
                    if (err || runsheetTypes.length === 0) {
                      // on fail
                      reject(err || 'No runsheetType found');
                    } else {
                      // on success
                      
                      if(runsheetTypes[0].rows && runsheetTypes[0].rows!=undefined && runsheetTypes[0].rows.length>0) {
                        let filteredRows = _.filter(runsheetTypes[0].rows, (row) => {
                          return (row.removed==false);
                        });  
                        if(filteredRows==undefined || !filteredRows || filteredRows.length==0) {
                          filteredRows = []
                        }
                        runsheetTypes[0].rows = filteredRows
                      }
                      resolve(runsheetTypes[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('management', {
      title: 'Runsheet Types',
      icon: '/icons/md/image/svg/design/ic_filter_none_24px.svg',
      state: 'runsheetTypes',
      params: {},
      items: [{
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'runsheetTypes.edit',
        params: { id: undefined },
        permission: 'runsheetType-make'     
      }],
      permission: 'management-buttons'
    });
  });

}(angular.module('app.runsheetType', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView',
  'app.runsheetTypeRow',
  'app.runsheetTypeRowCell'
])));
