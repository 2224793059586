'use strict';

/**
 * Controller for the program edit view
 */
class ProgramEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, program, roles) {
    this.$state = $state;
    this.program = program;
    this.roles = roles;
  }

  addLogo(file) {
    this.program.logo = file;    
  }

  addBackground(file) {
    this.program.background = file;
  }

  onChangeDisabled() {
    if (this.program.disabled) {
      // if disabled was checked then check no login too
      this.program.no_login = true;
    }
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.submitting = true;
    this.program.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
        this.submitting = false;
      } else {
        // on success redirect
        this.redirect();
      }
    });
  }

  // validates if admin email addresses
  validateChip($chip) {
    if (!$chip) return;

    this.error = {};

    if( !( $chip.indexOf('@') > -1 && $chip.indexOf('.') > -1 )) {
      console.log('whooaaa, you breached the limit yo!', $chip);
      this.program.notifyAdminEmail.pop();
      this.error.notifyAdminEmail = true;
    }

  }

  /**
   * Redirects to the parent state after the user is done with this one
   */
  redirect() {
    this.$state.go('programs');
  }
}

(function (app) {
  app.controller('ProgramEditCtrl', ProgramEditCtrl);
}(angular.module('app.program')));
