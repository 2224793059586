'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('subscriptions', {
      url: '/subscriptions',
      views: {
        '@': {
          templateUrl: '/html/subscription/subscription-list.html',
          controller: 'SubscriptionListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Subscriptions'
      }
    });

    $stateProvider.state('subscriptions.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/subscription/subscription-edit.html',
          controller: 'SubscriptionEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            subscription: function ($stateParams, SubscriptionStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if (!$stateParams.id) {
                  // if subscription does not exist create new subscription
                  SubscriptionStore.newResource({}, (subscription) => {
                    resolve(subscription);
                  });
                } else {
                  // if subscription exists then load it
                  SubscriptionStore.get({ _id: $stateParams.id }, (err, subscriptions) => {
                    if (err || subscriptions.length === 0) {
                      // on fail
                      reject(err || 'No subscription found');
                    } else {
                      // on success
                      resolve(subscriptions[0]);
                    }
                  });
                }
              });
            },
            programs: function(ProgramStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {

                ProgramStore.get({}, (err, programs) => {
                  if (err || programs.length === 0) {
                    // on fail
                    reject(err || 'No programs found');
                  } else {
                    // on success
                    resolve(programs);
                  }
                });
              });
            }            
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{breadcrumb}}'
      }
    });

  });

  // app.config(function (SidenavProvider) {
  //   SidenavProvider.addItem('User Access', {
  //     title: 'Subscriptions',
  //     subtitle: 'View and add subscriptions',
  //     icon: '/icons/md/action/svg/design/ic_history_24px.svg',
  //     state: 'subscriptions',
  //     params: {},
  //     permission: 'subscription-edit',
  //     items: [{
  //       title: 'Add Subscription',
  //       icon: '/icons/md/content/svg/design/ic_add_24px.svg',
  //       state: 'subscriptions.edit',
  //       params: { id: undefined },
  //       permission: 'subscription-make'
  //     }]
  //   });
  // });

}(angular.module('app.subscription', [
  'app.core',
  'app.sidenav',
  'app.route'
])));
