'use strict';

// npm modules
const _ = require('lodash');

/**
 * Controller for the certificate view view
 */
class ContactFormCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $state, ContactStore, contact, customer, $mdDialog) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.customer = customer;
    this.contact = contact;
    this.ContactStore = ContactStore;
    this.$mdDialog = $mdDialog;
    this.user = this.$rootScope.User
    this.makeException = false;
    this.customerTab = false;
    // this.isUserAdmin = (this.$rootScope.User.hasPermission('client-admin') || this.$rootScope.User.hasPermission('ff-admin'));
    this.isUserAdmin = this.$rootScope.User.hasPermission('admin');
    if (this.$rootScope.User.contact._id == this.contact._id)
      this.makeException = true;
    
    this.acceptPhotoTypes = ['image/jpg,image/jpeg,image/png, image/gif'];
    // TODO: It would be nice to remove dependencies on these (don't really think they add value)
    //this.programs = location.programs;
    this.locations = customer.locations;
    
    if ($state.current.name.indexOf('customers') >= 0) {
      this.p42UserDetailsTitle = 'Contact Details';
      this.contactEdit = false;
      this.customerTab = true;
    } else {
      this.p42UserDetailsTitle = 'Users Details';
      this.contactEdit = true;
      this.customerTab = true;
    }
  }
  
  // photoDataUri(value) {
  //   this.contact.photoUri = value;
  // }

  saveContact() {
    this.savingContact = true;   
    if (this.$rootScope.User.contact._id == this.contact._id) {
      // if the user is myuser then prompt for password
      this.$mdDialog.show({
        controller: this.PasswordDialogController,
        templateUrl: '/enterPasswordDialog.html',
        escapeToClose: true,
        clickOutsideToClose: true
      }).then((password) => {
        if (password) {
          // if password was entered then add password to the data so it gets check when saving
          this.contact.challenge = password;
          this.save();
        } 
      }).catch(() => {
        // if saving has been cancelled
        this.savingUser = false;
      });
    } else {
      this.save();
    }
  }

   /**
   * Controller to make the password dialog function correctly
   * @param {Object} $scope    Isolate Angular scope of the dialog
   * @param {Object} $mdDialog MdDialog service that applies to this dialog
   */
  PasswordDialogController($scope, $mdDialog) {
    $scope.cancel = function() {
      $mdDialog.cancel();
    };
    $scope.save = function() {
      $mdDialog.hide($scope.password);
    };
    $scope.change = function() {
      $mdDialog.hide({
        currentPassword: $scope.currentPassword,
        newPassword: $scope.newPassword
      });
    };
  }

  save() {
    this.saving = true;
    const contact = this.ContactStore.newResource(this.contact);

    contact.save((contactErr, contactData) => {
      if (contactErr) {
        console.error(contactErr);
        this.$rootScope.showToast('Failed to save contact ' + contactData.identifier);
      } else {
        this.$rootScope.showToast(`Contact "${contactData.identifier}" saved`);
      }
      this.saving = false;
    });
  }

  agentSearch(searchString) {
    return new Promise((resolve, reject) => {
      if (searchString && searchString.length >= 3) {
        this.CustomerStore.search(searchString,{}, (err, records) => {
          // Difference is used to filter out already existing records
          resolve(_.differenceBy(records, this.contact.agent_from, '_id'));
        });
      } else {
        resolve([]);
      }
    });
  }

  transformAgentChip(chip) {
    return chip;
  }

  contactOnClickAgent() {
    if (!_.isArray(this.contact.agent_from)) this.contact.agent_from = [];
  }


  clickToCustomer() {
    this.$state.go('customers.edit', {id: this.contact.customer.id}, { reload: true });
  }

  clickToUser() {
    this.$state.go('users.edit', {id: this.user._id}, { reload: true });
  }
}

(function (app) {
  app.controller('ContactFormCtrl', ContactFormCtrl);
}(angular.module('app.contact')));
