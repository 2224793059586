'use strict';

const ABSENT = -1;

/**
 * Provider function for Sidenav Service
 *
 *  - Allows for addition of sidenav items before service is instantiated
 */
function SidenavProvider() {
  const items = {};
  const hidden = [];
  // setup defaults for the app title
  const header = {
    logo: '',
    title: 'Universe Framework',
    state: '',
    params: {},
    permission: '',
    badge: '',
    version:''
  };

  this.addItem = function (group, item) {
    addItem(items, group, item);
  };

  this.hideItem = function (item) {
    if (hidden.indexOf(item) === ABSENT) {
      // if the item has not been added to hidden then add it
      hidden.push(item);
    }
  };

  this.$get = [function SidenavFactory() {
    return new SidenavService(header, items, hidden);
  }];
}

/**
 * Controller for the Sidenav Service
 */
class SidenavService {

  /**
   * - Assigns items to this
   */
  constructor(header, items, hidden) {
    this._items = items;
    this._hidden = hidden;
    this._header = header;
  }

  /**
   * Add an item to the items array
   * @param {String} group Group to add to
   * @param {Object} item
   * @return {void} Does not return a value
   */
  addItem(group, item) {
    addItem(this._items, group, item);
  }

  /**
   * Hides an item from being displayed
   * @param {String} item Group name or item title to be hidden
   * @return {void} Does not return a value
   */
  hideItem(item) {
    if (this._hidden.indexOf(item) === ABSENT) {
      // if the item has not been added to hidden then add it
      this._hidden.push(item);
    }
  }

  /**
   * Return array of items in the given group
   * @param  {String} group The group
   * @return {[Object]} The array of items
   */
  getItems(group) {
    if (this._hidden.indexOf(group) !== ABSENT) {
      // if the item has been hidden then return nothing
      return [];
    }
    // otherwise return the filtered items
    return filterItems(this._items[group], this._hidden);
  }

  /**
   * Return object of items for all groups
   * @return {Object} The object of groups and items
   */
  getAllItems() {
    const results = {};
    for (const group in this._items) {
      // for each group
      if (this._hidden.indexOf(group) !== ABSENT) {
        // if the group has been hidden then skip it
        continue;
      }
      results[group] = filterItems(this._items[group], this._hidden);
    }
    return results;
  }

  /**
   * Return array of groups items are added under
   * @return {[String]} The array of groups
   */
  getGroups() {
    const results = [];
    for (const group in this._items) {
      // for each group
      if (this._hidden.indexOf(group) === ABSENT) {
        // if the group has not been hidden then add it
        results.push(group);
      }
    }
    return results;
  }

  /**
   * Return header object
   * @return {Object} The object containing the header data
   */
  getHeader() {
    return this._header;
  }

  /**
   * Sets the title to the header object
   * @param {String} Title of the application
   */
  setTitle(title) {
    if (title) {
      // if the title is defined then set it
      this._header.title = title;
    }
  }

  setVersion(version) {
    if (version) {
      // if the title is defined then set it
      this._header.version = version;
    }
  }
  
  /**
   * Sets the logo to the header object
   * @param {String} Logo Url of the application
   */
  setLogo(logo) {
    if (logo) {
      // if the logo is defined then set it
      this._header.logo = logo;
    }
  }

  /**
   * Sets the logo to the header object
   * @param {String} Logo Url of the application
   */
  setLogoCSS(css) {
    if (css) {
      // if the css is defined then set it
      this._header.logoCSS = css;
    }
  }

  setDefaultGroup(group) {
    if(group) {
      this.defaultGroup = group;
    }
  }
}

/**
 * Helper function to add an item to a group
 * @param {Object} arr
 * @param {String} group The group to add to
 * @param {Object} item  The item to add
 */
function addItem(arr, group, item) {
  if (!arr[group]) {
    arr[group] = [];
  }
  arr[group].push(item);
}

/**
 * Helper function to hide items
 * @param {Array}  items  Object/Array to filter away hidden items for
 * @param {Array}  hidden Array of groups/items to be hidden
 * @return {Mixed}        Returns a collection like the input collection by without hidden values
 *
 * The format for adding items is:
 *  - title: Title to display the link as
 *  - subtitle: Subtitle to display under the title
 *  - icon: Path to an icon to display next to the title
 *  - state: UI Router state to navigate to when activated
 *  - params: UI Router parameter object to use when changing state
 *  - permission: Universe Framework permission code needed to display the link
 *  - items: Array of subitems to show inline with the parent item (same as parent but cannot be nested further)
 */
function filterItems(items, hidden) {
  return items.filter((item) => {
    // for each item check if it is hidden
    return hidden.indexOf(item.title) === ABSENT;
  });
}

(function (app) {
  app.provider('Sidenav', SidenavProvider);
}(angular.module('app.sidenav')));
