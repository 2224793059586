'use strict';

class SelectLabtestDialogCtrl {

  constructor($scope, $state, $mdToast, $mdDialog, $rootScope, RunsheetTypeLabTestStore) {
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.user = $rootScope.User;
    this._RunsheetTypeLabTestStore = RunsheetTypeLabTestStore;

    this.back = () => {
      $mdDialog.hide();
    };
  }
  onUserOptionSelectedYes() {
    this._RunsheetTypeLabTestStore.setlabteststatus('true');
    this.back();
  }
  onUserOptionSelectedNo() {
    this._RunsheetTypeLabTestStore.setlabteststatus('false');
    this.back();
  }
}

(function(app) {
  app.controller('SelectLabtestDialogCtrl', SelectLabtestDialogCtrl);
}(angular.module('app.runsheetType')));
