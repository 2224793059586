'use strict';

/**
 * Component that uploads files to the Hub
 * There are two concepts of a file mentioned in this component:
 *  - The phrase file data is used to mean the actual (binary) file data
 *  - The phrase file record (or just file) is used to mean the file database record (metadata)
 *
 * p42ForceUpload: Forces a new file to be uploaded (doesn't allow updating an existing file)
 * p42Disabled:    Disables the entire form
 * p42MaxFilesize: Identify the maximum size for file upload e.g. 'p42-max-filesize='5'  the maximum size is 5 MB
 * p42AcceptFiles: allowed file types Eg usage:  ['image/*', 'application/xml', 'application/pdf', 'text/csv', 'application/msword','text/plain']
 * p42Model:       The model that this field will be bound to  
 * p42OnSave:      Callback that the data will be returned to upon successful upload so controller can decide on logic
 * p42DataUriOnly: if set to true, extracts data uri of the image and passes it to the p42DataUri callback function - if set to true, files cant be uploaded
 * p42DataUri:     Callback that returns the data_uri on loading image
 * p42Customer:    Company that the file will be assigned to
 * p42PreviewDefault: Whether the preview opens by default or not (use p42-preview instead)
 * p42NamedTransclude: Opt-in to using named transclusion
 * p42Save:        Saves the file record and file data with the Hub
 * p42Data:        Changes the fileData linked to the file record (without changing anything else)
 * p42Preview:     Controls whether or not to show the preview
 * p42UploadButtonLabel: File upload button label, defaults to 'File Upload'
 */
(function (app) {
  app.component('p42FileUpload', {
    restrict: 'E',
    controller: 'FileUploadCtrl',
    transclude: {
      // p42-file-upload-buttons is a place for buttons that affect the upload process
      buttons: '?p42FileUploadButtons'
    },
    bindings: {
      p42ForceUpload: '<',
      p42Disabled:    '<',
      p42MaxFilesize: '<',
      p42AcceptFiles: '<',
      p42Model:       '<',
      p42OnSave:      '&',
      p42Customer:    '<',
      p42DataUriOnly: '<',
      p42DataUri:     '&',
      // Deprecated: please use p42-preview instead
      p42PreviewDefault: '<',
      p42Preview:     '<',
      p42Data:        '<',
      p42Save:        '<',
      // Note: will be deprecated in the future and set to always be true
      p42NamedTransclude: '<',
      p42UploadedbyDisabled: '<',
      p42UploadButtonLabel: '<',
      p42HideCheckbox: '<'
    },
    controllerAs: 'vm',
    templateUrl: '/html/fileShared/file-upload.html'
  });
}(angular.module('app.fileshared')));
