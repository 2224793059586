'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('programservices', {
      url: '/programservices',
      views: {
        '@': {
          templateUrl: '/html/programservice/programservice-list.html',
          controller: 'ProgramServiceListCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            programs: function (ProgramStore) {
              return new Promise((resolve, reject) => {
                ProgramStore.get({}, (err, programs) => {
                  if (err || programs.length === 0) {
                    reject(err || "There were no programs");
                  } else {
                    resolve(programs);
                  }
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Services'
      }
    });

    $stateProvider.state('programservices.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/programservice/programservice-edit.html',
          controller: 'ProgramServiceEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            programservice: function ($stateParams, ProgramServiceStore, ProgramService) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if (!$stateParams.id) {
                  // if programservice does not exist create new programservice
                  ProgramServiceStore.newResource({}, (programservice) => {
                    resolve(programservice);
                  });
                } else {
                  // if programservice exists then load it
                  ProgramServiceStore.get({ _id: $stateParams.id }, (err, programservices) => {
                    if (err || programservices.length === 0) {
                      // on fail
                      reject(err || 'No service found');
                    } else {
                      // on success
                      resolve(programservices[0]);
                    }
                  });
                }
              });
            },
            programs: function (ProgramStore) {
              return new Promise((resolve, reject) => {
                ProgramStore.get({}, (err, programs) => {
                  if (err || programs.length === 0) {
                    reject(err || "No programs found");
                  } else {
                    resolve(programs);
                  }
                })
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Service'
      }
    });

  });

  app.config(function (SidenavProvider) {
    // SidenavProvider.addItem('Administration', {
    //   title: 'Services',
    //   subtitle: 'View and add services',
    //   icon: '/icons/md/editor/svg/design/ic_bubble_chart_24px.svg',
    //   state: 'programservices',
    //   params: {},
    //   permission: 'admin',
    //   items: [{
    //     title: 'Add Service',
    //     icon: '/icons/md/content/svg/design/ic_add_24px.svg',
    //     state: 'programservices.edit',
    //     params: { id: undefined },
    //     permission: 'admin'
    //   }]
    // });
  });

}(angular.module('app.programservice', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics'
])));
