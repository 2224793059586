'use strict';

/**
 * Controller for the program list view
 */
class ProgramListCtrl {

  constructor (ProgramStore) {
    this.ProgramStore = ProgramStore;

    this.fields = [
      {
        path: 'name',
        name: 'Name'
      },
      {
        path: 'code',
        name: 'Code',
        hideXs: true
      },
      {
        path: 'link',
        name: 'Link'
      },
    ];
  }

  /**
   * Called when a document is selected
   * @param {Object}  selected  The selected row object.
   * @return  {void}            Assigns the last clicked object the value of the last clicked row.
   */
  onClick(selected) {
    this.lastClicked = selected;
  }
}

(function(app) {
  app.controller('ProgramListCtrl', ProgramListCtrl);
}(angular.module('app.program')));
