'use strict';

(function (app) {
  app.factory('LabChemicalStore', function (Store, $http) {
    const LabChemicalStore =  new Store('LabChemical');

    LabChemicalStore.create = function (data, callback) {
      $http({
        method: 'POST',
        url: '/api/labChemical/create',
        data: data
      })
      .then(
        function (res) {
          callback(null, res);
        },
        function (res) {
          callback(res);
        }
      );
    };

    return LabChemicalStore;
  });

}(angular.module('app.labChemical')));
