'use strict';

/**
 * Provides a standard way to layout form sections
 *
 * p42Title:       Text to display as the title of the section
 * p42Description: Text to display as the subtitle of the section
 */
(function (app) {
  app.component('p42FormSection', {
    restrict: 'E',
    controller: 'FormSectionCtrl',
    controllerAs: 'vm',
    transclude: true,
    bindings: {
      p42Title: '@',
      p42Description: '@'
    },
    templateUrl: '/html/formview/formSection.component.html'
  });
}(angular.module('app.formView')));