'use strict';

// constants
const GREEN = '#c1ffc0';
const YELLOW = '#fffac0';
const RED = '#fa8072';
const WHITE = '#ffffff';

/**
 * Controller for the certificate list view
 */
class APIListCtrl {

  constructor ($rootScope, $location, $filter, $state, $stateParams, UserStore, APILogEntryStore) {
    this.APILogEntryStore = APILogEntryStore;
    this.$state = $state;

    this.selectedRecords = [];
    this.records = [];
    // sort by most recent first
    this.sort = { createdAt: -1 };
    this.populate = 'user customer';
    this.query = {};

    // setup fields
    const host = $location.host();
    const dateFilter = $filter('date');
    this.fields = [{
      path: 'createdAt',
      name: 'Time of Request',
      transform: (value) => {
        // looks like "25 Dec, 2017 @ 4:21 PM"
        return dateFilter(value, $rootScope.dateFormat+' @ '+ $rootScope.timeFormat);
      }
    }, {
      path: 'customer',
      name: 'Customer',
      transform: (customer) => {
        if(!customer) return '';
        return customer.name;
      }
    }, {
      path: 'user',
      name: 'User',
      transform: (user) => {
        return '';
        return UserStore.name(user);
      }
    }, {
      path: 'method',
      name: 'Method'
    }, {
      path: 'endpoint',
      name: 'Endpoint',
      transform: (path) => {
        return host + path;
      }
    }, {
      path: 'parameters',
      name: 'Parameters',
      transform: (parameters) => {
        let paramStrings = [];
        if (Array.isArray(parameters)) {
          // if there are parameters
          for (let p = 0; p < parameters.length; p++) {
            // for each param concat it to the string
            paramStrings.push(parameters[p].key + '=' + parameters[p].value);
          }
        }
        return paramStrings.join(', ');
      }
    }, {
      path: 'status',
      name: 'Response Code'
    }];
    
    // Setting the tabs
    this.tabs = [{
      name: 'All',
      query: {},
      slug: '',
      active: $stateParams.status === ''
    }, {
      name: 'Success',
      query: { status: { $gte: 200, $lte: 299 } },
      slug: '2xx',
      active: $stateParams.status === '2xx'
    }, {
      name: 'Client Error',
      query: { status: { $gte: 400, $lte: 499 } },
      slug: '4xx',
      active: $stateParams.status === '4xx'
    }, {
      name: 'Server Error',
      query: { status: { $gte: 500, $lte: 599 } },
      slug: '5xx',
      active: $stateParams.status === '5xx'
    }];

    // setup key
    this.logKey = [{
      description: 'Request was successful.',
      color: GREEN
    }, {
      description: 'Request had an error that needs fixing before resending.',
      color: YELLOW
    }, {
      description: 'Server had an error.',
      color: RED
    }];
  }

  /**
   * Returns an ng-class expression based on the status of the log entry
   * @param {Object} log Log entry to return a class for
   * @return {Mixed} Ng-class expression used for styling the rows
   */
  getStyle(log) {
    if (!log.color) {
      // log entries will not change so we only need to create an object once
      let color = WHITE;
      if (log.status) {
        // if there is a status then get its color
        switch (log.status.toString()[0]) {
          case '2':
            color = GREEN;
            break;
          case '4':
            color = YELLOW;
            break;
          case '5':
            color = RED;
        }
      }
      log.color = { 'background-color': color };
    }
    return log.color;
  }

  /**
   * Called when the active tab is changed
   * @param {Object} tab Tab that has been changed
   */
  onTabChange(tab) {
    this.$state.go(this.$state.current, { status: tab.slug }, { notify: false });
  }

}

(function(app) {
  app.controller('APIListCtrl', APIListCtrl);
}(angular.module('app.api')));
