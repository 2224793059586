'use strict';

(function (app) {
  app.factory('Customer', function ($http, $injector, $mdDialog, Resource) {
    return class Customer extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Customer', realtime: false }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.customerClient')));