module.exports = {
  'name': 'fraction',
  'category': 'Type',
  'syntax': [
    'fraction(num)',
    'fraction(num,den)'
  ],
  'description':
    'Create a fraction from a number or from a numerator and denominator.',
  'examples': [
    'fraction(0.125)',
    'fraction(1, 3) + fraction(2, 5)'
  ],
  'seealso': [
    'bignumber', 'boolean', 'complex', 'index', 'matrix', 'string', 'unit'
  ]
};
