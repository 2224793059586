'use strict';

(function (app) {
  app.factory('APILogEntryStore', function (Store, $http) {
    const APILogEntryStore =  new Store({ model: 'APILogEntry', baseRoute: 'api' });
    
    return APILogEntryStore;
  });

}(angular.module('app.api')));
