'use strict';

const socketIO = require('socket.io-client');

/**
 * Provides a Socket IO instance, fires events, sets application realtime state
 */
function SocketIOProvider() {
  
  const socket = socketIO();
  this.socket = socket;

  // get the service to use
  // TODO: change this code to match the rest of the application
  this.$get = ['$mdToast', '$rootScope', function SocketIOFactory($mdToast, $rootScope) {
    socket.on('disconnect', function () {
      // TODO: use $timeout instead of $rootScope.digest
      $rootScope.online = false;
      $rootScope.$digest();

      // when disconnected, try to reconnect in case it was temporary
      socket.once('reconnect', () => {
        $rootScope.online = true;
        $rootScope.$digest();
      });
    });
    return socket;
  }];
}

(function (app) {
  app.provider('SocketIO', SocketIOProvider);
}(angular.module('app.realtime')));