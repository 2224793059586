'use strict';
const async = require('async');
const _ = require('lodash');

/**
 * Controller for the Location edit view
 */
class LabTestCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $scope, $state, $filter, $mdToast, labTest, fields, Statics, RunsheetTypeStore) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$mdToast = $mdToast;
    this.RunsheetTypeStore = RunsheetTypeStore;
    this.labTest = labTest;
    this.user = this.$rootScope.User;
    //console.log(this.labTest);
    this.$scope.breadcrumb = this.labTest.unique_name ? this.labTest.unique_name : 'New';

    // If there are no fields, initialize an empty array
    if (!labTest.fields) labTest.fields = [];

    this.fields = fields;

    Statics.get('preservatives', (err, preservatives) => {
      this.preservatives = preservatives;
    });
    Statics.get('species', (err, species) => {
      this.species = species;
    });
    Statics.get('sample_types', (err, sample_types) => {
      this.sample_types = sample_types;
    });
    Statics.get('hazard_classes', (err, hazard_classes) => {
      this.hazard_classes = hazard_classes;
    });
    Statics.get('products', (err, products) => {
      this.products = products;
    });
    Statics.get('status', (err, status) => {
      this.Status = status;
    });
    
    

    this.saveButton = []
    if (this.labTest.status === 0) {
      this.saveButton = [{
        text: 'Save',
        onClick: this.submit.bind(this),
        class: 'md-accent',
        requireValid: true,
      }];
    } else if (this.labTest.status === 2) {
      this.saveButton = [{
        text: 'Retire',
        onClick: this.retire.bind(this),
        class: 'md-accent',
        requireValid: true,
      }];
    }

    if (this.labTest.status < 3 && this.labTest.approvals.length > 0) {
      this.approvalsDetail = '';
      this.labTest.approvals.forEach(element => {
        if (element.user) {
          this.approvalsDetail = this.approvalsDetail.concat(element.user.given_name + " "+ element.user.surname + '  At: ' + $filter('date')(element.date_approved, 'dd/MM/yyyy') + '\t')
        }
      });
    } else if (this.labTest.status === 4) {
      // this.retiredby =
    }

    this.hasApproved = _.find(this.labTest.approvals, (approval) => {
      return (approval.user.id === this.$rootScope.User.id);
    }) ? true : false;
    console.log('User approved :' + this.hasApproved)

    if (this.labTest.status === 2) {
      this.status = "Approved"
    } else if (this.labTest.status === 4) {
      this.status = "Retired"
    } else {
      this.status = "Draft"
    }

  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.labTest.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('labTests', {
          tab: '0'
        });
      }
    });
  }

  retire() {
    this.RunsheetTypeStore.get({
      'labTests': this.labTest.id,
      status: {
        $lt: 3
      }
    }, (err, runsheetTypes) => {
      if (runsheetTypes.length === 0) {
        this.labTest.status = 4;
        this.labTest.date_retired = Date.now();
        this.labTest.retire_reason = this.$rootScope.User.given_name +" " + this.$rootScope.User.surname ;
        this.labTest.save(() => {
          this.$state.transitionTo('labTests', {
            tab: '4'
          });
        });
      } else {
        let retireError = runsheetTypes.map(x => x.name).join(', ');
        this.$mdToast.show(
          this.$mdToast.simple()
          .textContent('Please remove this labtest in  ' + retireError + '  runsheet Type')
          .action('Got it')
          .hideDelay('10000')
          .highlightAction(true)
          .highlightClass('md-accent')
          .position('bottom right')
        ).then(function(response) {

        });
      }
    })
  }

  /**
   * Calls $approve on this.labTest
   */
  onApproveClick() {
    this.savingStatus = true;
    this.labTest.approve((err, res) => {
      this.savingStatus = false;
      // on fail
      if (err) return
      // on success
      console.log(res)
      if (res.status === 2) {
        this.$state.transitionTo('labTests', {
          tab: '2'
        });
      } else {
        this.$state.transitionTo('labTests', {
          tab: '0'
        });
      }
    });
  }

  /**
   * Calls $unapprove on this.labTest
   */
  onResetClick() {
    this.savingStatus = true;
    this.labTest.unapprove((err, res) => {
      this.savingStatus = false;
      // on error
      if (err) return
      // on success
      this.$state.reload();
    });
  }

  /**
   * Calls copy on this.labtest
   */
  onCopyClick() {
    this.savingStatus = true;
    this.labTest.copy((err, res) => {
      this.savingStatus = false;
      // on error
      if (err) return
      // on success
      this.$state.go('labTests.edit', { id: res.id });
    });
  }

  /**
   * Searches this.fields for names matching the given search text
   * @param {String} searchText What to search for
   * @return {Array} An array of matching Fields
   */
  fieldQuerySearch(searchText) {
    searchText = searchText.toLowerCase();
    let fields = searchText ? this.fields.filter((field) => {
      return field.name.toLowerCase().indexOf(searchText) === 0;
    }) : [];
    fields = _.map(fields, function(field) {
      return field;
    });
    return fields;
  }

  _getFieldById(id) {
    return _.find(this.fields, function(field) {
      return field._id === id;
    });
  }

  _getFieldsByArrayOfIds(arr) {
    if (arr === undefined) return [];

    var newArr = [];
    for (let field_id of arr) {
      if (field_id instanceof Object) continue;
      let field = this._getFieldById(field_id);
      if (field instanceof this.Field) newArr.push(field);
    }
    return newArr;
  }

}

(function(app) {
  app.controller('LabTestCtrl', LabTestCtrl);
}(angular.module('app.labTest')));
