'use strict';

(function (app) {

  app.config(function ($stateProvider) {
    $stateProvider.state('login', {
      params: {
        ref: null,
        toParams: {}
      },
      views: {
        '@': {
          templateUrl: '/html/userlogin/login-container.html'
        },
        'container@login': {
          templateUrl: '/html/userlogin/login.html',
          controller: 'LoginCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Login'
      }
    });
  });

  app.config(function ($stateProvider) {
    $stateProvider.state('login.expired', {
      url: '/login/expired',
      views: {
        '@': {
          
          template:   `<div class="fade layout-fill ng-scope layout-align-gt-xs-center-center layout-align-center-start layout-row flex" layout="row" layout-align="center start" layout-align-gt-xs="center center" layout-fill md-colors='{ "background-color":"primary-800" }' layout-fill="layout-fill" >
                        <md-card class="md-whiteframe-8dp" flex=95 flex-sm=80 flex-gt-md=35>
                          <md-card-content class="md-padding" layout-align="center center">
                            <div layout="row" layout-align="center center" flex=100>
                              <img src="/assets/logo.png"/ width="260">
                            </div>
                            </br>
                            <div layout="row" layout-align="center center" flex=100>
                              <div layout="column" class="layout-column">
                                <span class="md-title ng-binding" aria-hidden="false">Oops, looks like an expired link!</span> 
                              </div>
                            </div> 
                          <md-card-content>
                        </md-card>
                      </div>`,
        }
      },
      ncyBreadcrumb: {
        label: 'Login'
      }
    });
  });

  app.config(function ($stateProvider) {
    $stateProvider.state('login.activated', {
      url: '/login/activated',
      views: {
        '@': {
          template:   `<div class="fade layout-fill ng-scope layout-align-gt-xs-center-center layout-align-center-start layout-row flex" layout="row" layout-align="center start" layout-align-gt-xs="center center" layout-fill md-colors='{ "background-color":"primary-800" }' layout-fill="layout-fill" >
                        <md-card class="md-whiteframe-8dp" flex=95 flex-sm=80 flex-gt-md=35>
                          <md-card-content class="md-padding" layout-align="center center">
                            <div layout="row" layout-align="center center" flex=100>
                              <img src="/assets/logo.png"/ width="260">
                            </div>
                            </br>
                            <div layout="row" layout-align="center center" flex=100>
                              <div layout="column" class="layout-column">
                                <span class="md-title ng-binding" aria-hidden="false">Your Account has been Activated</span> 
                              </div>
                            </div> 
                            </br> 
                            <div layout="row" layout-align="end center">
                              <md-button class="md-raised md-accent md-hue-4" ng-href="/">Login</md-button>
                            </div>
                          <md-card-content>
                        </md-card>
                      </div>`,
        }
      },
      ncyBreadcrumb: {
        label: 'Login'
      }
    });
  });



  app.config(function ($stateProvider) {
    $stateProvider.state('login.reset', {
      url: '/reset/:token',
      views: {
        'container@login': {
          templateUrl: '/html/userlogin/reset.html',
          controller: 'ResetCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Reset Password'
      }
    });
  });

  app.run(function ($window, $state, Toolbar, UserStore) {

   
    // create redirect function
    const logout = () => {
      //remove local storage - exporter IDs
      localStorage.clear(); 

      UserStore.logout((error, noUser) => {
        // redirect to login page that will redirect back to the state logged out from when logged in
        console.log('Go to Login');
        $state.go('login', { ref: null });
        // Note: force a page refresh so any left over data gets wiped (using $state.go as well stops page flashing)
        // $window.location.href = '/';
      });
    };
    
    // add logout button to toolbar
    Toolbar.addItem({
      icon: '/icons/md/action/svg/design/ic_exit_to_app_24px.svg',
      title: 'Logout',
      action: logout
    });

  });

}(angular.module('app.userlogin', [
  'app.core',
  'app.toolbar',
])));
