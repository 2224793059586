'use strict';

/**
 * Controller for the customer selector
 */
class LocationSelectorCtrl {
  
  constructor($scope, $element, LocationStore, Statics) {
    this.$scope = $scope;
    this.LocationStore = LocationStore;

    // id of the p42Selected customer
    this.selectedId = '';
    // list of locations allowed to be p42Selected    
    this.locations = this.p42Default ? [this.p42Default] : [];
    this.noLocations = false;
    this.model = this.p42Default;
    this.searchName = '';
    this.query = this.p42Query;

    // The md-select directive eats keydown events for some quick select logic. Since we have a search input here, we don't need that logic.
    $element.find('input').on('keydown', function(ev) {
        ev.stopPropagation();
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit () {
    if (this.p42Required === undefined) {
      // if p42Required was not specified default to making the form not required
      this.p42Required = false;
    }
    if (this.p42Default) {
      if (typeof this.p42Default === 'string') {
        // if the value is an ObjectId string then load the location
        this.LocationStore.get({ _id: this.selectedId }, { limit: 1 }, (err, locations) => {
          // on error reject
          if (err) return reject(err);
          // on success
          this.model = locations[0];
          this.locations.push(locations[0]);
          this.$scope.$apply();
        });
      }
    }
  }

  /**
   * Load list of locations
   */
  loadLocations () {
    if (!this.noLocations) {
      return new Promise ((resolve, reject) => {
        if (this.searchName.length >= 3) {
          // if the search name is long enough to provide good results for then search
          this.query = this.searchName;
        } else {
          // if the search name is not long enough
          if (angular.equals(this.query, {})) {
            // if the query has not been changed then do not research
            return resolve(this.locations);
          } else {
            // if the query has been changed then reset and research
            this.query = this.p42Query;
          }
        }
        this.LocationStore.search(this.query, { limit: 5 }, (err, locations) => {
          // on error reject
          if (err) return reject(err);
          // on success
          if (locations.length > 0) {
            // if there are locations to chose from then add them to the list
            this.locations = locations;
          } else {
            // if there are no locations make sure we don't do a request every time
            this.noLocations = true;
          }
          resolve(this.locations);
        });
      });
    }
  }
}

(function (app) {
  app.controller('LocationSelectorCtrl', LocationSelectorCtrl);
}(angular.module('app.customerClient')));
