'use strict';

// npm modules
const _ = require('lodash');

/**
 * Controller for the certificate view view
 */
class CustomerLocationCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $state, $stateParams, customer) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    // Setting up the resources for the individual form views
    this.customer = customer;

    // Set the initial tab selection
    if (this.customer._id) {
      // if the customer is not new
      this.selectedLocation = $stateParams.location;
      if (this.selectedLocation === 'new') {
        // if the new location is selected then select the last location
        this.selectedLocation = this.customer.locations.length - 1;
      } else if (!this.customer.locations[this.selectedLocation]) { // if the location selected is not new then load any contacts
        // if the location does not exist then set default
        this.selectedLocation = 0;
      }
    }
  }

  changeLocation(index = 'new') {
    if (index == 'new') {
      // this.$state.go('customers.edit.customer.location', { location: index }, { reload: 'customers.edit.customer.location' });
       // if the location does not exist then load it
       const baseData = {
        name: 'New Location',
        customer: this.customer._id,
        active: true,
        physical: {
          country: 'NZ'
        }
      };

      if (!_.isArray(this.customer.locations)) {
        this.selectedLocation = 0;
        this.customer.locations = [baseData];
      } else {
        this.customer.locations.push(baseData);
        this.selectedLocation = this.customer.locations.length - 1;
      }
    } else { 
      this.selectedLocation = index;
    }

    let stateParam =  { location: this.selectedLocation };
    //default to the first contact if contacts are defined
    if (this.customer && this.customer.locations && 
        this.customer.locations.length > 0 && this.customer.locations[this.selectedLocation] && 
        this.customer.locations[this.selectedLocation].contacts && this.customer.locations[this.selectedLocation].contacts.length > 0) {
        stateParam['contact'] = 0;
        this.$state.go('customers.edit.customer.location.contact', stateParam, { reload: 'customers.edit.customer.location.contact' });
    } else {
      this.$state.go('customers.edit.customer.location', stateParam, { reload: 'customers.edit.customer.location' });
    }    
    // stop the spinner showing because the customer will not reload
    this.$rootScope.spinner = false;
  }

}

(function (app) {
  app.controller('CustomerLocationCtrl', CustomerLocationCtrl);
}(angular.module('app.customer')));
 
