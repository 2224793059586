'use strict';

/**
 * Component that controls the layout of the toolbar
 *
 * All input is provided through the toolbar provider
 */
(function (app) {
  app.component('p42ToolbarView', {
    restrict: 'E',
    controller: 'ToolbarViewCtrl',
    controllerAs: 'vm',
    templateUrl: '/html/toolbar/toolbar-view.component.html',
    bindings: {
      p42Items: '<'
    }    
  }); 
}(angular.module('app.toolbar')));
