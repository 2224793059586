'use strict';

const _ = require('lodash');

(function (app) {
  app.factory('User', function ($http, Resource) {

    return class User extends Resource {
      constructor(data, metadata, callback) {
        super({ model: 'User', realtime: false }, data, metadata, callback);
      }

      /**
       * Helper function used for checking is a user has a permission
       * @param  {Mixed}   permission   String or array of permission strings
       * @param  {Mixed}   restrictedTo String or array of restriction strings
       * @return {Boolean} True if the user has the permission and false otherwise
       */
      hasPermission(permission, restrictedTo) {
        if (!restrictedTo || restrictedTo.length === 0) {
          return _.includes(this.permissions, permission);
        } else {
          const matches  = this.permissions.indexOf(restrictedTo);
          return _.includes(this.permissions, permission) && matches == -1 ;
        }
      }

      /**
       * Shortcut function for getting a user's name
       * @return {String} String of the user's name
       */
      name() {
        
        if (this.username) {
          // if the user has a username then use that
          return this.given_name + ' ' + this.surname;
        } else {
          // otherwise fallback to the id
          return this.id;
        }
      }
    };
  });
}(angular.module('app.userlogin')));
