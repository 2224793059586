'use strict';

/**
 * Controller for the lab assets list view
 */
class QualityCheckListCtrl {

  constructor (QualityCheckStore, $state,$rootScope) {
    this.QualityCheckStore = QualityCheckStore;
    this.$state = $state;
    this.$rootScope = $rootScope;

    this.fields = [{
      path: 'name',
      name: 'Name'
    }, {
      path: 'description',
      name: 'Description'
    }, {
      path: 'type',
      name: 'Type'
    }];

    this.inputTypes = [{
      name: "All Types",
      query:{  },
      slug: "All Types",
    },{
      name: "User Name Input",
      query:{ type : "U" },
      slug: "User",
    },{
      name: "Text Input",
      query:{ type : "TI" },
      slug: "Text Input",
    },{
      name: "Text Area",
      query:{ type : "TA" },
      slug: "Text Area",
    },{
      name: "Numeric Input",
      query:{ type : "NI" },
      slug: "Numeric Input",
    },{
      name: "Date Input",
      query:{ type : "DI" },
      slug: "Date Input",
    },{
      name: "Checkbox",
      query:{ type : "C" },
      slug: "Checkbox",
    }];
      
    this.sort = { name: 1 }
  }

  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('qualityChecks.edit', { id: selected.id });
  }

  removeQualityCheck(qualityCheck) {
    qualityCheck.remove(true);
  }
}

(function(app) {
  app.controller('QualityCheckListCtrl', QualityCheckListCtrl);
}(angular.module('app.qualityCheck')));
