'use strict';

class DeletePenetrationSampleDialogCtrl {

  constructor($scope, $state, $mdToast, $mdDialog, $rootScope, SampleStore) {
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.user = $rootScope.User;
    this.SampleStore = SampleStore;
    this.sampleList = [];
    this.selectedJobRunsheets = [];

    this.deleteFromAll = false;

    this.disablecheckbox = false;

    if (this.jobs.length > 0) {
      this.selectedJob = this.jobs[0];
      this.onJobChange();
    }

    this.back = () => {
      $mdDialog.hide();
    };

  }

  onJobChange() {
    this.sampleList = [];
    this.selectedJobRunsheets = [];

    this.selectedJob.runsheets.forEach((runsheet) => {
      let runsheetFound = false;
      this.selectedJobRunsheets.forEach((jobRunsheet) => {
        if (runsheet._id === jobRunsheet._id)
          runsheetFound = true;
      });
      if (!runsheetFound)
        this.selectedJobRunsheets.push(runsheet);
    });
    this.samples.forEach((sample) => {
      if (sample.job === this.selectedJob.id && (sample.type == "p" || sample.type == "q")) {
        sample.override_sample_name ? sample.name = `${sample.override_sample_name}${sample.type.toUpperCase()}` : sample.name = `T${this.selectedJob.job_id}-${sample.sample_num}${sample.type.toUpperCase()}`;
        this.sampleList.push(sample);
      }
    });
    if (this.sampleList.length > 0) {
      this.selectedSample = this.sampleList[0];
    } else {
      this.selectedSample = '';
    }
  }

  onSampleChange() {}

  deleteSample() {
    let selectedRunsheetList = [];
    for (let i = 0; i < this.selectedJobRunsheets.length; i++) {
      if (this.selectedRunsheets[i])
        selectedRunsheetList.push(this.selectedJobRunsheets[i]._id);
    }
    this.saveLoading = true;
    this.SampleStore.deleteSample(this.selectedJob.id, selectedRunsheetList, this.selectedSample, (err) => {
      this.$state.reload();
      this.$mdDialog.hide();
    })
  }

  updateSample() {
    this.saveLoading = true;
    this.SampleStore.updateSample(this.runsheetID, this.selectedSample, (err) => {
      this.$state.reload();
      this.$mdDialog.hide();
    })
  }

  checkAllRunsheets(){

    if(this.deleteFromAll)
    {
      for(let i=0;i<this.selectedJobRunsheets.length;i++)
      {
        this.selectedRunsheets[i] = true;
      }
      this.disablecheckbox = true;
    }
  }

  checkStatus(status){

    if(!status)
    {
      this.disablecheckbox = false;
      this.deleteFromAll = false;
    }


    console.log(status,this.selectedJobRunsheets.length,Object.keys(this.selectedRunsheets).length);

    if(status && this.selectedJobRunsheets.length == Object.keys(this.selectedRunsheets).length)
    {
      
      let keys = Object.keys(this.selectedRunsheets);
      let flag = true;
      for(let i=0;i<this.selectedJobRunsheets.length;i++){
        if(!this.selectedRunsheets[i])
          flag = false
      }

      if(flag){
        this.disablecheckbox = true;
        this.deleteFromAll = true;
      } 
      else{
        this.disablecheckbox = false;
        this.deleteFromAll = false;
      }
        
    }
  }

}

(function(app) {
  app.controller('DeletePenetrationSampleDialogCtrl', DeletePenetrationSampleDialogCtrl);
}(angular.module('app.runsheet')));
