'use strict';

(function (app) {
  app.factory('ContactStore', function (Store, $http) {
    const ContactStore = new Store({
      model: 'Contact'
    });

    return ContactStore;
  });

}(angular.module('app.contact')));
