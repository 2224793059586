'use strict';

(function (app) {
  app.factory('LabAsset', function ($http, Resource) {

    return class LabAsset extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'LabAsset' }, data, metaData, callback);
      }
    };

  });
}(angular.module('app.labAsset')));
