'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('api', {
      url: '/access',
      params: {
        version: 'v2.0.0'
      },
      views: {
        '@': {
          templateUrl: '/html/api/api-doc.html',
          controller: 'APIDocCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      resolve: {
        apiData: function ($rootScope, $stateParams, APIData) {
          // ui-router requires a promise
          return $rootScope.wrapResolve((resolve, reject) => {
            APIData.get($stateParams.version, (error, data) => {
              // on error
              if (error) return reject(error);
              // on success
              data.formats = [
                'JSON'
              ];
              data.description = 'Access Hub features and data from other programs using the Access API.  Data in the form { ... } means the object can be either referenced by an ObjectId or populated to include the actual data, nested.';
              resolve(data);
            });
          });
        },
        endpoints: function ($rootScope) {
          return $rootScope.wrapResolve((resolve, reject) => {
            resolve([]);
          });
        }
      },   
      ncyBreadcrumb: {
        label: 'API Information'
      }
    });

    $stateProvider.state('api.logs', {
      url: '/logs?status',
      params: {
        status: ''
      },
      views: {
        '@': {
          templateUrl: '/html/api/api-list.html',
          controller: 'APIListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      resolve: {
        data: function ($rootScope) {
          // ui-router requires a promise
          return $rootScope.wrapResolve((resolve, reject) => {
            resolve();
          });
        }
      },   
      ncyBreadcrumb: {
        label: 'Logs'
      }
    });

  });
  
  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('Access API', {
      title: 'Documentation',
      subtitle: 'Access Hub data externally',
      params: {},
      icon: '/icons/md/action/svg/design/ic_info_24px.svg',
      state: 'api',
      permission: 'developer'
    });
    SidenavProvider.addItem('Access API', {
      title: 'Request Log',
      subtitle: 'View requests in real-time',
      params: {},
      icon: '/icons/md/action/svg/design/ic_list_24px.svg',
      state: 'api.logs',
      permission: 'developer'
    });
  });

}(angular.module('app.access', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics'
])));
