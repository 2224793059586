'use strict';

(function (app) {
  app.factory('CustomerStore', function (Store, $http) {
    const CustomerStore = new Store({
      model: 'Customer'
    });

    return CustomerStore;
  });

}(angular.module('app.customer')));
