'use strict';

const _ = require('lodash');
const async = require('async');
const Handsontable = require('handsontable/dist/handsontable.full');

/**
 * Controller for the Location edit view
 */
class RunsheetTypeCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($scope, $state, $mdDialog, $mdToast, $rootScope, QualityCheckStore, RunsheetTypeStore, RunsheetTypeRowStore, RunsheetTypeRowCell, RunsheetTypeRowCellStore, LabTestStore, runsheetType, RunsheetTypeLabTestStore) {
    this.$state = $state;
    this._$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.runsheetType = runsheetType;
    this.user = $rootScope.User;
    $scope.breadcrumb = runsheetType.name ? runsheetType.name : 'New';

    this._QualityCheckStore = QualityCheckStore;
    this._RunsheetTypeStore = RunsheetTypeStore;
    this._RunsheetTypeRowStore = RunsheetTypeRowStore;
    this._RunsheetTypeRowCell = RunsheetTypeRowCell;
    this._RunsheetTypeRowCellStore = RunsheetTypeRowCellStore;
    this._LabTestStore = LabTestStore;
    this._RunsheetTypeLabTestStore = RunsheetTypeLabTestStore;

    this._editCell = {};

    this.labTests = [];
    this.qualityChecks = [];

    // setup formview buttons
    this.updatingFields = false;
    this.buttons = []
    if (this.runsheetType.status === 0) {
      this.buttons = [{
        text: 'Save',
        onClickText: 'Saving',
        onClick: this.submit.bind(this),
        requireValid: true,
        class: 'md-accent',
        loading: () => {
          return this.updatingFields;
        }
      }];
    } else if (this.runsheetType.status === 2) {
      this.buttons = [{
        text: 'Retire',
        onClickText: 'Saving',
        onClick: this.retire.bind(this),
        requireValid: true,
        class: 'md-accent',
        loading: () => {
          return this.updatingFields;
        }
      }];
    }

    // set up search variables
    this.searchLimit = 5;
    this.searchSkip = 0;

    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.runsheetType.revert();
    });

    // set up headers
    this.setupHeaders();


  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    // This is truly a masterpiece of saving...
    this.updatingFields = true;
    // Going through each row
    var rowCount = 0
    async.each(this.runsheetType.rows, (row, callback) => {
      // Going through each cell
      var cellCount = 0
      async.each(row.cells, (cell, cellCallback) => {

        if(cell.type=="E"&&(cell.expression==""||cell.expression==undefined))
        {
          let err = {}
          err.message = cell.name+" has invalid expression ' "+cell.expression+" '.";
          return cellCallback(err)
        }
        
        async.waterfall([
          function loadCell(loadCallback) {
            if (!cell.save) {
              // if the cell is not a resource then make it one
              this._RunsheetTypeRowCellStore.newResource(cell, (newCell) => {
                loadCallback(null, newCell);
              });
            } else {
              // if the cell is a resource just use it
              loadCallback(null, cell);
            }
          }.bind(this)
        ], function cellLoaded(error, cellResouce) {
          cellResouce.runsheetRow = row._id;
          cellResouce.save((err) => {
            cellCallback(err);
          });
        });
        cellCount++;
      }, (cellErr) => {
        // on fail
        if (cellErr) return callback(cellErr);
        // on success
        async.waterfall([
          function loadRow(loadCallback) {
            if (!row.save) {
              // if the row is not a resource then make it one
              this._RunsheetTypeRowStore.newResource(row, (newRow) => {
                loadCallback(null, newRow);
              });
            } else {
              // if the row is a resource just use it
              loadCallback(null, row);
            }
          }.bind(this)
        ], function cellLoaded(error, rowResouce) {
          rowResouce.save((rowErr) => {
            callback(rowErr);
          });
        });
      });
      rowCount++;
    }, (err) => {
      if(err) {
        this.$mdToast.show(this.$mdToast.simple().textContent(err.message).hideDelay('1000').position('bottom right'));
      }else{
        this.$mdToast.show(this.$mdToast.simple().textContent('Runsheet saved successfully').hideDelay('1000').position('bottom right'));
      }

      this.runsheetType.save((error) => {
        this.updatingFields = false;
      });
    });
  }

  retire() {
    this.updatingFields = true;
    if (this.runsheetType.status === 2) {
      this.runsheetType.status = 4; //retire
      this.runsheetType.date_last_modified = new Date();
      this.runsheetType.save((error) => {
        this.updatingFields = false;
        this.$state.go('runsheetTypes', {
          tab: '4'
        }); //Go to retire tab
      });
    }
  }


  onLoad() {
    // Setup handsontable data array
    let container = document.getElementById('runsheet');

    let selectCellEditor = Handsontable.editors.SelectEditor.prototype.extend();
    let RunsheetTypeRowCellStore = this._RunsheetTypeRowCellStore;
    let draft = this.runsheetType.status === 0;

    selectCellEditor.prototype.getValue = function() {
      return this.select.value;
    };

    selectCellEditor.prototype.setValue = function(value) {

    };

    selectCellEditor.prototype.saveValue = function(val, ctrlDown) {
      let newType = val[0][0];
      if (draft && newType !== this.originalValue.type) {
        RunsheetTypeRowCellStore.get({
          _id: this.originalValue._id
        }, {}, (err, cell) => {
          cell[0].type = newType;
          cell[0].save();
          this.originalValue.type = newType;
          this.instance.render();
        })
      }
    };

    selectCellEditor.prototype.prepare = function() {
      // Remember to invoke parent's method
      Handsontable.editors.BaseEditor.prototype.prepare.apply(this, arguments);

      var selectOptions = this.cellProperties.selectOptions;
      var options;

      if (typeof selectOptions == 'function') {
        options = this.prepareOptions(selectOptions(this.row, this.col, this.prop));
      } else {
        options = this.prepareOptions(selectOptions);
      }

      Handsontable.dom.empty(this.select);
  
      for (let option in options) {
        let optionElement = document.createElement('OPTION');
        optionElement.value = options[option].value;
        optionElement.text = options[option].text

        if (Handsontable.dom)
          Handsontable.dom.fastInnerHTML(optionElement, options[option].text);


        this.select.appendChild(optionElement);
      }
    };

    selectCellEditor.prototype.prepareOptions = function(optionsToPrepare) {
      var preparedOptions = {};

      if (Object.prototype.toString.call(optionsToPrepare) === '[object Array]') {
        for (var i = 0, len = optionsToPrepare.length; i < len; i++) {
          preparedOptions[i] = optionsToPrepare[i];
        }
      } else if (typeof optionsToPrepare == 'object') {
        preparedOptions = optionsToPrepare;
      }
      return preparedOptions;
    };
    // Extract the (populated) cells from the rows
    let data = this.runsheetType.rows.map((row) => {
      // const removeRow = {
      //   name: 'Remove',
      //   type: 'remove'
      // };
      // concat so we don't pollute row.cells with the extra cell
      return row.cells
    });

    this.hot = new Handsontable(container, {
      rowHeaderWidth: 100,
      data: data,
      renderer: this.cellRenderer,
      rowHeaders: this.getRowHeader.bind(this),
      colHeaders: this.getColumnHeader.bind(this),
      manualColumnMove: false,
      manualRowMove: false,
      afterRowMove: this.afterRowMove.bind(this),
      afterColumnMove: this.afterColumnMove.bind(this),
      viewportRowRenderingOffset: this.runsheetType.rows.length,
      vm: this,
      cells: (row, col) => {
        let cellProperties = {};
        cellProperties.editor = selectCellEditor;

        cellProperties.selectOptions = [{
          value: 'N',
          text: 'No Input'
        }, {
          value: 'I',
          text: 'Input'
        }, {
          value: 'C',
          text: 'Constant'
        }, {
          value: 'E',
          text: 'Expression'
        }];
        return cellProperties;
      }
    });


  }

  /**
   * Populates each RunsheetTypeRow with RunsheetTypeRowCells
   * @param {Function} callback Called when done in the form (error)
   * @return {void} Returns no value and calls back when done
   */
  //callback
  populateRows(iscallbackReq, callback) {
    //console.log("iscallbackReq", iscallbackReq);
    if (this.runsheetType.labTests.length > 0) {
      // Creating resources for all the cells
      async.map(this.runsheetType.rows, function mapRows(row, rowDone) {
        // for each row get cells
        this.getCells(row, (error, cells) => {
          // now we have the cells convert them to Resources
          async.map(cells, function mapCells(cell, cellDone) {
            // for each cell
            this._RunsheetTypeRowCellStore.newResource(cell, (cellResource) => {
              // now we have the cell Resource
              cellDone(null, cellResource);
            });
          }.bind(this), function cellsDone(cellError, cells) {
            row.cells = cells;
            // concat so we don't pollute row.cells with the extra cell
            rowDone(cellError, cells);
          });
        });
      }.bind(this), function mapDone(error, data) {
        // refresh handsontable
        this.hot.loadData(data);
        this.setupHeaders();
        this.hot.render();
        //console.log("Callback 2", callback);
        //if (this.runsheetType.rows.length > 0)
        if (iscallbackReq) {
          callback(error);
        }
      }.bind(this));
    } else {
      async.eachOf(this.runsheetType.rows, function eachRow(row, rowIndex, callBackRow) {
        row.removed = true;
        this._RunsheetTypeRowStore.newResource(row, (newRow) => {
          newRow.save((rowError, updatedRow) => {
            if (rowError) {
              console.error(rowError);
            }
            callBackRow();
          });
        })
      }.bind(this), function eachOfDone(error) {
        if (error) {
          return;
        }
        this.runsheetType.rows = [];
        // set up headers
        this.setupHeaders();
        this.hot.loadData([]);
        this.hot.render();
        //if (this.runsheetType.rows.length > 0)
        if (iscallbackReq) {
          callback();
        }
      }.bind(this));
    }
  }

  setupHeaders() {
    this.columnHeaders = [];
    this.rowHeaders = [];
    if (this.runsheetType.rows.length > 0) {
      // if there are rows
      _.each(this.runsheetType.rows[0].cells, (cell) => {
        this.columnHeaders.push(cell.name);
      });
      // this.columnHeaders.push('Controls');
      _.each(this.runsheetType.rows, (row) => {
        let name = row.name;
        this.rowHeaders.push({
          name: name,
          id: row._id
        });
      });
    }
  }

  /**
   * Use to display the cells correctly
   */
  cellRenderer(instance, td, row, col, prop, value, cellProperties) {
    if (value) {
      // Getting the viewmodel
      let vm = instance.getSettings().vm;
      // Be warned, here be dragons.
      // This uses some angular hack to get the dialog controller by using the angular.element method
      // combined with document.getElementById method.
      let eButton = document.createElement('button');
      eButton.setAttribute('onclick',
        'angular.element(' +
        'document.getElementById("runsheet-type"))' +
        '.controller()' +
        '.editExpression(' +
        'angular.element(' +
        'document.getElementById("runsheet-type"))' +
        '.controller()' +
        '.hot.getDataAtCell(' + row + ',' + col + ')' +
        ')');

      if(value.type=="E"&&(value.expression==undefined||value.expression==""))
      {

        eButton.setAttribute("style","color:red;")
        td.setAttribute("style","background-color:red;")
      }

      let cButton = document.createElement('button');
      cButton.setAttribute('onclick',
        'angular.element(' +
        'document.getElementById("runsheet-type"))' +
        '.controller()' +
        '.editConstant(' +
        'angular.element(' +
        'document.getElementById("runsheet-type"))' +
        '.controller()' +
        '.hot.getDataAtCell(' + row + ',' + col + ')' +
        ')');

      if (vm.runsheetType.status === 2) {
        // if the runsheet type is approved
        cButton.appendChild(document.createTextNode('Constant'));
        eButton.appendChild(document.createTextNode('Expression'));
      } else {
        // if the runsheet type is not approved
        cButton.appendChild(document.createTextNode('EditConstant'));
        eButton.appendChild(document.createTextNode('EditExpression'));
      }

      // // button for removing rows
      // let removeButton = document.createElement('button');
      // removeButton.setAttribute('onclick',
      //     'angular.element(' +
      //    'document.getElementById("runsheet-type"))' +
      //    '.controller()' +
      //    '.removeRow(' + row + ')'
      // );
      // if (vm.runsheetType.status === 2) {
      //   // if the runsheet is approved then disable the button
      //   removeButton.setAttribute('disabled', true);
      // }
      // removeButton.appendChild(document.createTextNode('Remove'));

      switch (value.type) {
        case 'N':
          td.innerHTML = 'No Input';
          break;
        case 'I':
          td.innerHTML = 'Input';
          break;
        case 'C':
          td.innerHTML = cButton.outerHTML;
          break;
        case 'E':
          td.innerHTML = eButton.outerHTML;
          break;
          // case 'remove':
          //   td.innerHTML = removeButton.outerHTML;
          //   break;
        default:
          td.innerHTML = value.type;
      }
    }
    return td;
  }

  getColumnHeader(i) {
    if (this.hot) {
      if (this.runsheetType.rows.length === 0) return i;
      return this.columnHeaders[i];
    }
    return this.columnHeaders[i];
  }

  getRowHeader(i) {
    if (!this.runsheetType.rows[i] || !this.rowHeaders[i]) return i;
    return this.rowHeaders[i].name;
  }

  afterRowMove(rows, target) {
    return;
    if (this.runsheetType.status === 2) {
      // if the runsheet is approved then do not update
      return;
    }

    this.updatingFields = true;
    // Getting the data rows
    this.dataRows = this.hot.getData();

    // Creating temp rows
    let tempRows = [];
    for (let r = 0; r < this.dataRows.length; r++) {
      tempRows[r] = this.dataRows[r][0].runsheetRow;
    }

    // Check the ordering
    let hasChanged = false;
    for (let r = 0; r < tempRows.length; r++) {
      // for each row check if the ordering is correct
      if (tempRows[r] !== this.runsheetType.rows[r]._id) {
        // if the rows have been reordered
        hasChanged = true;
        break;
      }
    }
    if (!hasChanged) {
      // if the order has not changed then trigger a double click on that row
      return this.renameRow(target - 1, () => {
        this.updatingFields = false;
      });
    }

    // Updating the rows
    this.runsheetType.rows = tempRows;
    if (this.runsheetType.id) {
      // if the runsheet has already been saved then update
      this.runsheetType.save((error, updatedRunsheetType) => {
        // populate the rows that were depopulated above
        this._RunsheetTypeStore._populate(this.runsheetType, this._RunsheetTypeStore.refs, 'rows.cells', (err) => {
          if (error) {
            // on error log and stop
            console.error(error);
            this.updatingFields = false;
            return;
          }

          // Updating the row headers
          // Creating an index object
          const indexObject = _.reduce(this.rowHeaders, function(result, row) {
            result[row.id] = row;
            return result
          }, {});

          // Sorting
          this.rowHeaders = _.map(_.clone(tempRows), (id) => {
            return indexObject[id];
          });

          // forcing a render
          this.hot.render();
          this.updatingFields = false;
        });
      });
    }
  }

  afterColumnMove(cols, target) {
    if (this.runsheetType.status === 2) {
      // if the runsheet is approved then do not update
      return;
    }

    this.updatingFields = true;
    // Getting the data rows
    this.dataRows = this.hot.getData();

    async.eachOf(this.dataRows, function eachRow(row, rowIndex, rowCallback) {
      // only get the data rows (not the control buttons)
      this.runsheetType.rows[rowIndex].cells = this.dataRows[rowIndex].slice(0, -1);
      if (this.runsheetType.id) {
        // if the runsheet has already been saved then update
        this.runsheetType.rows[rowIndex].save(rowCallback);
      }
    }.bind(this), function eachOfDone(error) {
      if (error) {
        // on error log and stop
        console.error(error);
        this.updatingFields = false;
        return;
      }

      // Getting the new column headers
      _.each(this.runsheetType.rows[0].cells, (cell) => {
        this.columnHeaders.push(cell.name);
      });

      // Updating the column headers
      this.hot.updateSettings({
        colHeaders: this.columnHeaders,
      });

      // Forcing a render
      this.hot.render();
      this.updatingFields = false;
    }.bind(this));
  }

  /**
   * Changes a row's name
   * @param {Number} rowIndex Row index to change to name for (Should be 0-based index)
   * @param {Function} done Called when done changing name
   */
  renameRow(rowIndex, done) {
    const row = this.runsheetType.rows[rowIndex];
    if (row.type === 'Control') {
      // if the row is a control row then allow editing
      // setup dialog
      const dialog = this._$mdDialog.prompt()
        .title('Set Name for Row #' + (rowIndex + 1))
        .placeholder('Name of control row')
        .ok('Save')
        .cancel('Cancel')
        .initialValue(this.runsheetType.rows[rowIndex].name);
      // activate dialog
      this._$mdDialog.show(dialog).then((newName) => {
        this.rowHeaders[rowIndex].name = newName;
        this.hot.render();
        row.name = newName;
        row.save(done);
      });
    } else {
      // if the row is a sample row
      done();
    }
  }

  /**
   * Adds a new RunsheetTypeRow
   * @param {String} type The type of the new row
   * @param {Boolean} loading Variable to update loading status
   */
  addRow(type, loading) {
    this.updatingFields = true;
    loading = true;

    let name = '';
    let controlRows = 0;
    let sampleRows = 0;
    this.runsheetType.rows.forEach(row => {
      if (row.type === 'Control') {
        controlRows++
      } else if (row.type === 'Sample') {
        sampleRows++
      }
    });

    if (type === 'Control' && controlRows === 0) {
      name = 'Blank';
    } else if (type === 'Control' && controlRows > 0) {
      name = 'Reference' + controlRows;
    } else if (type === 'Sample') {
      name = 'Sample' + (sampleRows + 1);
    }
    const data = {
      name: name,
      runsheetType: this.runsheetType._id,
      cells: [],
      type: type
    };
    // TODO: clean up generating new rows by pulling cell generation out of populateRows
    this._RunsheetTypeRowStore.newResource(data, (newRow) => {
      // the row needs an id before we create cells for it so save it
      newRow.save((rowError, updatedRow) => {
        if (rowError) {
          // on error log and quit
          console.error(rowError);
          loading = false;
          this.updatingFields = false;
          return;
        }
        // An success add the row
        this.runsheetType.rows.push(updatedRow);
        // Add the cells to each row and re-render handsontable
        this.populateRows(true, (populateError) => {
          // Now cells have been generated save them
          async.map(updatedRow.cells, function saveCell(cell, cellCallback) {
            cell.save(cellCallback);
          }, function mapDone(cellError, updatedCells) {
            // Checking if the runsheet type has an column order (from another row)
            // We want to get the second element as we're pushing a new row
            if (this.runsheetType.rows.length > 0) {
              // It really shouldn't matter which row we get as they should hopefully all be in order
              let column_order = _.map(this.runsheetType.rows[0].cells, 'name');
              // Reorder cells
              updatedCells = _.sortBy(updatedCells, (cell) => {
                return _.indexOf(column_order, cell.name);
              });
              console.log(_.map(updatedCells, 'name'));
            }

            // Save the cells against the row
            updatedRow.cells = updatedCells;
            updatedRow.save((error) => {
              // Save the additions
              this.runsheetType.save((error) => {
                loading = false;
                this.updatingFields = false;
              });
            });
          }.bind(this));
        });
      });
    });
  }

  /**
   * Removes specified RunsheetTypeRow
   * @param {String} type The type of the new row
   * @param {Boolean} loading Variable to update loading status
   */
  // removeRow (rowIndex) {
  //   this.updatingFields = true;
  //   // Getting the data rows
  //   const data = this.hot.getData();

  //   // remove the row
  //   const removeRow = data.splice(rowIndex, 1)[0];
  //   if (removeRow.length === 0 || !removeRow[0].runsheetRow) {
  //     // if the row does not have a cell then only remove it from handsontable
  //     // Note: this should only happen if the runsheetType data is messed up
  //     this.hot.loadData(data);
  //     this.rowHeaders.splice(rowIndex, 1);
  //     this.hot.render();
  //     this.updatingFields = false;
  //     return;
  //   }
  //   // if the row needs to be removed from the server
  //   const removeRowResource = this.runsheetType.rows.find((row) => {
  //     return row._id === removeRow[0].runsheetRow;
  //   });
  //   removeRowResource.remove(true, (error) => {
  //     if (error) {
  //       // if the row was not removed then re-add it
  //       data.splice(rowIndex, 0, removeRow);
  //       this.setupHeaders();
  //     } else {
  //       // if the row was remove then remove the header
  //       this.rowHeaders.splice(rowIndex, 1);
  //     }
  //     // refresh handsontable
  //     this.hot.loadData(data);
  //     this.hot.render();
  //     this.updatingFields = false;
  //   });
  // }

  removeRowDialog() {
    this._$mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: false,
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        runsheetTypeRows: this.runsheetType.rows
      },
      template: `<md-dialog id="cell-constant-dialog">` +
        `  <md-dialog-content class="md-dialog-content" layout="column">` +
        `     <h2 class="md-title">Delete a Row</h2>` +
        `     <md-input-container>` +
        `       <label>Select a row</label>` +
        `       <md-select name='remove_row' ng-model="vm.remove_row_id" aria-label="remove row">` +
        `          <md-option ng-repeat='row in vm.runsheetTypeRows', value='{{ row.id }}')> {{ row.name?row.name:row.type }} </md-option>` +
        `       </md-select>` +
        `     </md-input-container>` +
        `  </md-dialog-content>` +
        `  <md-dialog-actions>` +
        `    <md-button ng-click="vm.back()" class="md-primary md-raised">Back</md-button>` +
        `    <md-button ng-click="vm.save()" class="md-primary md-raised" ng-if="vm.remove_row_id">Delete</md-button>` +
        `  </md-dialog-actions>` +
        `</md-dialog>`,
      controller: function DialogController($mdDialog, $state, RunsheetTypeRowStore) {

        this.back = () => {
          $mdDialog.hide();
        };
        this.save = () => {
          if (this.remove_row_id) {
            RunsheetTypeRowStore.removeRow(this.remove_row_id, (response) => {
              console.log(response);
              $state.reload();
            });
          };
          $mdDialog.hide();
        };
      }
    });
  }

  /**
   * Called from the template, returns all cells for a given row
   * @param {RunsheetTypeRow} row The row to get cells for
   * @param {Function} callback Called when done in format (error, cells)
   * @return {void} Returns array of RunsheetTypeRowCells via callback
   */
  getCells(row, callback) {
    let cells = [];
    let fields = this.runsheetType.getFields();
    // console.log('fields',fields)
    async.eachOf(fields, function eachField(field, index, fieldDone) {
      // for each field get the cell associated with it
      this.getCell(row, field, index, (error, cell) => {
        cells.push(cell);
        fieldDone(error);
      });
    }.bind(this), function fieldsDone(error) {
      callback(error, cells);
    });
  }

  /**
   * Returns a cell if it exists, otherwise creates a temporary cell
   * @param {RunsheetTypeRow} row The parent Row
   * @param {Field} field The associated Field
   * @param {Integer} index Index of the cell in parent row
   * @return {void} Returns cell via callback
   */
  getCell(row, field, index, callback) {
    if (row.cells && row.cells[index]) {
      return callback(null, row.cells[index]);
    }
    const data = {
      runsheetRow: row._id,
      field: field._id,
      name: field.name,
      type: field.input_type,
      expression: '',
      constant: field.constant,
      decimal_places: field.decimal_places
    };
    this._RunsheetTypeRowCellStore.newResource(data, (cell) => {
      callback(null, cell);
    });
  }

  /**
   * Returns cells in this.runsheetType
   * @return {Array} An Array of the cells
   */
  cells() {
    return _.flatten(_.map(this.runsheetType.rows, 'cells'));
  }

  /**
   * Hides the $mdDialog
   */
  hide() {
    this._$mdDialog.hide();
  }

  /**
   * Cancels the $mdDialog
   */
  cancel() {
    this._$mdDialog.cancel();
  }

  approveBtnDisable(){
    var userApprove = false
    if(this.runsheetType.approvals!= undefined && this.runsheetType.approvals.length>0)
    {
      this.runsheetType.approvals.forEach((elem,index)=>{
        if(elem.user==this.user.id)
          userApprove = true
      })
    }
    return this.updatingFields || !this.user.hasPermission("runsheetType-approve") || userApprove
  }
  onApproveClick() {
    this.savingStatus = true;
    this.runsheetType.approve(this.user,(err) => {
      this.hot.render();
      let toast = this.$mdToast.simple()
        .textContent(this.runsheetType.name + ' approved')
        .position('bottom right');
      if (err) {
        toast = this.$mdToast.simple()
          .textContent('Unable to approve ' + this.runsheetType.name)
          .position('bottom right');
      } else {
        this.$mdToast.show();
        this.savingStatus = false;
        this.$state.go('runsheetTypes', {
          tab: '2'
        }); //Go to Approve tab
      }
    });
  }

  onCopyClick() {
    //SHOW DIALOG
    this._$mdDialog.show({
      clickOutsideToClose: false,
      escapeToClose: false,
      bindToController: true,
      controllerAs: 'vm',
      locals: {
        runsheetType: this.runsheetType,
      },
      templateUrl: '/html/runsheetType/runsheet-type-lab-test-dialog.html',
      controller: 'SelectLabtestDialogCtrl',
    }).then(()=>{
      // console.log('TESTING', this._RunsheetTypeLabTestStore.getlabteststatus());
      this.savingStatus = true;
      this.runsheetType.copy(this._RunsheetTypeLabTestStore.getlabteststatus(), (err, res) => {
        this.hot.render();
        let toast = this.$mdToast.simple()
          .textContent(this.runsheetType.name + ' reset')
          .position('bottom right');
        if (err) {
          toast = this.$mdToast.simple()
            .textContent('Unable to reset ' + this.runsheetType.name)
            .position('bottom right');
        } else {
          this.$mdToast.show();
          this.savingStatus = false;
          // this.$state.reload();
          this.$state.go('runsheetTypes.edit', { id: res.id });
        }
      });
    });




  }

  /**
   * Searches this.qualityChecks for names matching the given search text
   * @param {String} searchText What to search for
   * @return {Array} An array of matching QualityChecks
   */
  qualityCheckSearch(searchText) {
    searchText = searchText.toLowerCase();

    if (searchText.length >= 3) {
      // if the searchText is long enough to give meaningful results
      // Note: md-autocomplete requires the results to be wrapped in a promise
      return new Promise((resolve, reject) => {
        this._QualityCheckStore.localSearch(searchText, error => {
          reject(error)
        }, qualityChecks => {
          resolve(qualityChecks)
        })

      });
    } else {
      // if the searchText is not long enough then return the existing results
      return this.qualityChecks;
    }
  }

  /**
   * Searches this.labTests for names matching the given search text
   * @param {String} searchText What to search for
   * @return {Array} An array of matching LabTests
   */
  labTestSearch(searchText) {
    searchText = searchText.toLowerCase();
    const teststatus = 2;
    if (searchText.length >= 3) {
      // if the searchText is long enough to give meaningful results then get the results
      // Note: md-autocomplete requires the results to be wrapped in a promise
      return new Promise((resolve, reject) => {
        this._LabTestStore.localSearch(searchText, teststatus, error => {
          reject(error)
        }, labTests => {
          resolve(labTests)
        })
      });
    } else {
      // if the searchText is not long enough then return the existing results
      return this.labTests;
    }
  }

  /**
   * Calls $edit() on given cell and sets this._editCell
   * @param {RunsheetTypeRowCell} cell The cell to edit
   */
  editCell(cell) {
    cell.edit();
    this._editCell = cell;
  }

  /**
   * Opens a new dialog to edit the specified cells constant
   *
   * @param {RunsheetTypeRowCell} cell The RunsheetTypeRowCell to edit
   */
  editConstant(cell) {
    let _this = this;
    this._$mdDialog.show({
      clickOutsideToClose: false,
      escapeToClose: false,
      bindToController: true,
      locals: {
        cell: cell,
        edit: this.runsheetType.status !== 2
      },
      template: `<md-dialog id="cell-constant-dialog">` +
        `   <md-toolbar class='md-hue-2'> ` +
        `   <div class="md-toolbar-tools"> ` +
        `     <h2 class="md-title">Constant</h2> ` +
        `     <span flex></span> ` +
        `     <md-button class="md-icon-button" ng-click="vm.back()"> ` +
        `       <md-icon md-svg-src="/icons/md/navigation/svg/design/ic_close_24px.svg" aria-label="Close dialog"></md-icon> ` +
        `     </md-button> ` +
        `  </div>` +
        `  </md-toolbar>` +
        `  <md-dialog-content class="md-dialog-content" layout="column">` +
        `     <md-input-container>` +
        `       <label>Enter Constant</label>` +
        `       <input ng-model="vm.cell.constant" ng-disabled="!vm.edit">` +
        `     </md-input-container>` +
        `  </md-dialog-content>` +
        `  <md-dialog-actions>` +
        `    <md-button ng-click="vm.back()" class="md-primary md-raised">Back</md-button>` +
        `    <md-button ng-click="vm.save()" class="md-primary md-raised" ng-if="vm.edit">Save</md-button>` +
        `  </md-dialog-actions>` +
        `</md-dialog>`,
      controller: function DialogController($mdDialog) {
        /** Call cell.$revert() and $mdDialog.hide() */
        this.back = () => {
          //cell.$revert();
          $mdDialog.hide();
        };

        
        /** Call $mdDialog.hide() */
        this.save = () => {          
          _this._RunsheetTypeRowCellStore.newResource(cell, (newCell) => {
            newCell.save();
            $mdDialog.hide();
          });

          //
          //$mdDialog.hide();
        };
      },
      controllerAs: 'vm'
    });
  }

  /**
   * Opens a new dialog to edit the specified cells constant
   *
   * @param {RunsheetTypeRowCell} cell The RunsheetTypeRowCell to edit
   */
  editExpression(cell) {
    let id = cell.id;
    // event.target.setAttribute("style", "color: red;");
    this._$mdDialog.show({
      clickOutsideToClose: true,
      escapeToClose: false,
      bindToController: true,
      templateUrl: '/html/runsheetType/expression-dialog.html',
      locals: {
        runsheetTypeId: this.runsheetType.id,
        edit: this.runsheetType.status !== 2
      },
      controller: 'ExpressionDialogCtrl',
      controllerAs: 'vm',
      resolve: {
        allRunsheetTypes: function(RunsheetTypeStore, $rootScope) {
          return $rootScope.wrapResolve((resolve, reject) => {

            RunsheetTypeStore.get({}, (err, allRunsheetTypes) => {
              if (err) {
                reject(err)
              } else {
                resolve(allRunsheetTypes)
              }
            })

          })
        },
        cell: function(RunsheetTypeRowCellStore, $rootScope) {
          return $rootScope.wrapResolve((resolve, reject) => {
            RunsheetTypeRowCellStore.get({
              '_id': id
            }, {
              populate: 'runsheetRow'
            }, (err, cell) => {
              if (err) {
                reject(err)
              } else {
                resolve(cell[0])
              }
            })

          })
        }
      }
    });
  }
}

(function(app) {
  app.controller('RunsheetTypeCtrl', RunsheetTypeCtrl);
}(angular.module('app.runsheetType')));
