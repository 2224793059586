'use strict';

(function (app) {
  app.factory('LabStandardStore', function (Store, $http) {
    const LabStandardStore =  new Store('LabStandard');

    LabStandardStore.create = function (data, callback) {
      $http({
        method: 'POST',
        url: '/api/labStandard/create',
        data: data
      })
      .then(
        function (res) {
          callback(null, res);
        },
        function (res) {
          callback(res);
        }
      );
    };

    return LabStandardStore;
  });

}(angular.module('app.labStandard')));
