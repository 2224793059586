'use strict';

const toposort = require('toposort');

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('reports', {
      url: '/reports?tab',
      params: {
        tab: '0'
      },
      views: {
        '@': {
          templateUrl: '/html/report/report-list.html',
          controller: 'ReportListCtrl',
          controllerAs: 'vm',
          bindToController: true,
        }
      },
      ncyBreadcrumb: {
        label: 'Reports'
      }
    });
    $stateProvider.state('reports.view', {
      url: '/view/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/report/report.html',
          controller: 'ReportCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            report: function ($rootScope, $stateParams, ReportStore, Report) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if report does not exist create new report
                  let report = ReportStore.newResource();
                  // TODO: fix the resource to stop having to do this
                  resolve(report);
                } else {
                  // if report exists then load it
                  ReportStore.get({ _id: $stateParams.id }, { populate: 'fields job.runsheets.values job.samples job.labTests job.location job.customer.locations job.signatoryCo' }, (err, reports) => {
                    
                    if (err || reports.length === 0) {
                      // on fail
                      reject(err || 'No report found');
                    } else {
                      // on success
                      resolve(reports[0]);
                    }
                  });
                }
              });
            },
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('general', {
      title: 'Reports',
      icon: '/icons/md/editor/svg/design/ic_insert_drive_file_24px.svg',
      state: 'reports',
      params: {},
      permission: 'report-view'
    });
  });

}(angular.module('app.report', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
