'use strict';

/**
 * Controller for the Job edit view
 */
var scope;
var customers;
var customerclick;
var locations;
var $http;
var priorities=[];
var prioritytrue;
var locationtrue;
var customerexist;
var customernotexist;
var jobCustomer;
class JobEditCtrl {
  /**
   * Assigns injected variables to this
   */
   constructor($rootScope,$scope, $state, job, Statics,$mdDialog, auditTrailStore,AuditTrailService) {
     this.scope=$scope
     this.$rootScope = $rootScope;
     this.customerclick=false;
     this.$mdDialog = $mdDialog
     this.auditTrailStore = auditTrailStore;
     this.AuditTrailService = AuditTrailService;
    prioritytrue=0;
    locationtrue=0;
    this.priorities=[
      {
      value:0,
      name:'Normal'},
      {
      value:3,
      name:'Medium'
      },
      {
      value:5,
      name:'High'
      }]
   
    this.$state = $state;
    this.job = job;
    this.customerexist=false;
    this.customernotexist=false

    

    this.Statics = Statics;
    // if(this.job.customer)
    // {
    // this.customerexist=true;
    // }
    // else{
    this.getcustomers(true);
    // }


      
    // setup preservatives select
    this.preservatives = this.job.preservative ? [this.job.preservative] : [];
    // setup sample type select
    this.sampleTypes = this.job.sample_type ? [this.job.sample_type] : [];
    // setup test type select
    this.testTypes = this.job.test_type ? [this.job.test_type] : [];
    // setup species select
    this.species = this.job.species ? [this.job.species] : [];
    // setup hazard select
    this.hazardClasses = this.job.hazard_class ? [this.job.hazard_class] : [];
    this.products = this.job.product ? [this.job.product] : [];
    this.loadProducts()

    // Items for the toolbar view
    this.items = [{
      label: 'Add Samples',
      icon: '/icons/md/image/svg/design/ic_colorize_24px.svg',
      click: $state.go.bind($state, 'samples.new', { job: this.job.id }),
      class: 'md-accent'
    }];

    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.job.revert();
    });
  }
resetprevious(){
  this.customernotexist=false;
  this.customerexist=true;
  }
getcustomers(status){

 
  this.customerexist=status;
  this.customernotexist=!status;
  

  return new Promise((resolve, reject) => {
    this.job.getcustomers(error => {
      reject(error)
    }, data => {    
    
      this.customers=data;
      this.signcustomers = this.customers.filter((customer)=>{
        return customer.customerType =='CIB' || customer.customerType =='CACB';
      });
      resolve(data)
    })
  });


}
assignToMe(){
  this.job.signatoryCo = this.$rootScope.User.customer;

 
}
getlocations(id)
{ 
 this.customerclick=true
  return new Promise((resolve, reject) => {

 
    this.job.getlocations(id,error => {
      reject(error)
    }, data => {
     

   this.locations=data;
      this.locationtrue=1;
      this.locations=data;
     
    
      resolve(data)
    })
  });
}
  /**
   * Loads the list of preservatives
   */
  loadPreservatives() {
    if (this.preservatives.length <= 1) {
      // if the preservatives have not yet been loaded then load them
      this.Statics.get('preservatives', (err, preservatives) => {
        this.preservatives = preservatives;
      });
    }
  }

  /**
   * Loads the list of sample types
   */
  loadSampleTypes() {
    if (this.sampleTypes.length <= 1) {
      // if the sample types have not yet been loaded then load them
      this.Statics.get('sample_types', (err, sampleTypes) => {
        this.sampleTypes = sampleTypes;
      });
    }
  }

  /**
   * Loads the list of test types
   */
  loadProducts() {
    if (this.products.length <= 1) {
      // if the sample types have not yet been loaded then load them
      this.Statics.get('products', (err, products) => {
        this.products = products;
      });
    }
  }

  /**
   * Loads the list of test types
   */
  loadTestTypes() {
    if (this.testTypes.length <= 1) {
      // if the sample types have not yet been loaded then load them
      this.Statics.get('test_types', (err, testTypes) => {
        this.testTypes = testTypes;
      });
    }
  }

  /**
   * Loads the list of species
   */
  loadSpecies() {
    if (this.species.length <= 1) {
      // if the species have not yet been loaded then load them
      this.Statics.get('species', (err, species) => {
        this.species = species;
      });
    }
  }

  /**
   * Loads the list of hazard classes
   */
  loadHazardClasses() {
    if (this.hazardClasses.length <= 1) {
      // if the hazard classes have not yet been loaded then load them
      this.Statics.get('hazard_classes', (err, hazardClasses) => {
        this.hazardClasses = hazardClasses;
      });
    }
  }

  /**
   * Applies the selected customer to the record
   */
  selectCustomer(selected) {
    this.job.client = selected;
    this.job.customerName = 'Project 42';
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {

    //Put Validations here and turn off error message
    
    if(this.job.customer && this.job.location){
      this.job.isError = false;
      this.job.errMessage = "";
    }
    this.job.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        let data = {
            job:this.job,
            action:"Update Job",
            transitionTo:'jobs.preview',
            entityType : 'job'
          }
         this.AuditTrailService.createAuditTrail(data);
        // on success redirect
      }
    });
  }
}

(function (app) {
  app.controller('JobEditCtrl', JobEditCtrl);
}(angular.module('app.job')));
