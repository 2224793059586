'use strict';

(function (app) {
  app.factory('Job', function ($http, Resource, $injector) {

    return class Job extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'Job' }, data, metaData, callback);
      }

      matchingLabTests (callback = function(){}) {
        let LabTestStore = $injector.get('LabTestStore');
        
        let arr = LabTestStore._newArray();
        $http({
          method: 'GET',
          url: '/api/Job/matchingLabTests/' + this._id,
        })
        .then(
          (res) => {
            LabTestStore.processArraySuccess(res, arr);
            callback(null, arr);
          },
          (res) => {
            LabTestStore.processArrayFailure(res, arr);
            callback(res);
          }
        );
        return arr;
      }

      matchingLocation(callback = function(){}) {
        $http({
          method: 'GET',
          url: '/api/Job/matchingLocations/' + this._id,
        })
        .then(
          (res) => {
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }
     
      getcustomers(error, callback){
        $http.get('/api/Job/fetchCustomers').then(function(res){
        callback(res.data.customers);
      }).catch(function(err){
        error(err)
      });
    }
    getlocations(id,error,callback) {
        $http({
          method: 'GET',
          url: '/api/Job/getlocations/'+id,
        })
        .then(function(res){
          callback(res.data.locations);
        }).catch(function(err){
          error(err)
        });
      }
      fetchCustomers(callback){
        $http({
          method: 'GET',
          url: '/api/Job/fetchCustomers/',
        })
        .then(
          (res) => {
           
            callback(res.data);
          }
          
        );
      }

      samplesReceived (callback = function(){}) {
        $http({
          method: 'GET',
          url: '/api/Job/samplesReceived/' + this._id,
        })
        .then(
          (res) => {
            // TODO: remove the manual phase change in favour of realtime
            //this.phase = 3;
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }

      readyTesting (callback = function(){}) {
        $http({
          method: 'GET',
          url: '/api/Job/readyTesting/' + this._id,
        })
        .then(
          (res) => {
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }

      createReport (callback = function(){}) {
        $http({
          method: 'GET',
          url: '/api/Job/createReport/' + this._id,
        })
        .then(
          (res) => {
            // TODO: remove the manual phase change in favour of realtime
            // this.phase = 5;
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }

      deleteRunsheets (callback = function(){}) {
        $http({
          method: 'GET',
          url: '/api/Job/deleteRunsheets/' + this._id,
        })
        .then(
          (res) => {
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }
      deleteRunsheetsForAllJobs (callback = function(){}) {
        $http({
          method: 'GET',
          url: '/api/Job/deleteRunsheetsForAllJobs/' + this._id,
        })
        .then(
          (res) => {
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }

    };

  });
}(angular.module('app.job')));

 
  