'use strict';

/**
 * Controller for the lab locations list view
 */
class LabLocationListCtrl {

  constructor (LabLocationStore) {
    this.LabLocationStore = LabLocationStore;
    this.order = { '$natural': 1 };    
 
    this.fields = [{
      path: 'location',
      name: 'location'
    }];

  }

  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  removeLabLocation(labLocation) {
    labLocation.remove(true);
  }
}

(function(app) {
  app.controller('LabLocationListCtrl', LabLocationListCtrl);
}(angular.module('app.labLocation')));
