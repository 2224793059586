'use strict';

/**
 * Controller for the labStandards view
 */
class LabStandardsCtrl {

  /**
   * - Assigns injected variables to this
   * - Calls _query()
   * - Watches for stateChange and unsubscribes labStandards array
   */
  constructor (LabStandardStore) {
    this.LabStandardStore = LabStandardStore;

    this.fields = [{
      path:'lsNumber',
      name: ''
    }, {
      path:'standard',
      name: ''
    }, {
      path:'manufacturerLotNumber',
      name: ''
    }, {
      path:'expiryDate',
      name: ''
    }];
  }
  
  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  removeLabAsset(labAsset) {
    labAsset.remove(true);
  };
}

(function(app) {
  app.controller('LabStandardsCtrl', LabStandardsCtrl);
}(angular.module('app.labStandard')));
