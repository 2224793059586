'use strict';

(function (app) {

  app.config(function ($locationProvider) {
    // use hashbang as a fallback if HTML5 is not supported
    $locationProvider.hashPrefix('!');
    // use HTML5 routing/history
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false
    });
  });

  // add a nice "not found" error page
  app.config(function ($urlRouterProvider, $stateProvider) {
    // setup redirect from root to default state
    /* Note: There are issues using a root redirect in UI Route which is why a function is used
     * See https://github.com/angular-ui/ui-router/issues/2692 for more information
     */
    $urlRouterProvider.when('/', function initialRedirect($injector) {
      // $state and ROOT_STATE are not available until runtime so inject them
      const $state = $injector.get('$state');
      const defaultState = $injector.get('ROOT_STATE');
      // redirect manually so no loops happen
      return $state.go(defaultState);
    });
    // if no URL matches then redirect to the not found page
    $urlRouterProvider.otherwise(function($injector, $location){
      var $state = $injector.get("$state");
      $state.go('404', null, { location: false });
    });
    //$urlRouterProvider.otherwise('/404');
    $stateProvider.state('404', {
      url: '/404',
      templateUrl: '/html/route/not-found.html',
      ncyBreadcrumb: 'Not Found'
    });
    $stateProvider.state('403', {
      url: '/403',
      templateUrl: '/html/route/access-denied.html',
      ncyBreadcrumb: 'Access Denied'
    });
  });

  // on error it is better that the user can see something is not working
  // than a half-working page which might mess up data
  app.run(function ($rootScope, $state) {
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      if(typeof $state.current.permission !== 'undefined') {
        if(!$rootScope.User.hasPermission($state.current.permission)) {
          $state.go('404', null, { location: false });
        }
      }
    });

    $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {
      if (error.status == 403){
        $state.go('403', null, { location: false });
      } else {
        $state.go('404', null, { location: false });
      }
    });
  });

}(angular.module('app.route', [
  require('angular-ui-router')
])));