'use strict';

class SettingDialogCtrl{

	constructor($scope, $state, $mdToast, $mdDialog, $rootScope, $timeout,$http, settingStore,WebUSB) {
    this.$state = $state;
    this.$http = $http;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.user = $rootScope.User;
    this.settingStore = settingStore;
    this.timberAPISwitch = true;
    this.settings = {};
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    //Balance Settings
    this.WebUSB = WebUSB;
    this.$timeout = $timeout;

    this.log = [];
    
    // Listen for webusb data for connecting balance
    this.$scope.$on('webusbDataIn', ($event, data) => {
      this.log.push({
        data: data,
        source: 'balance',
      });
      this.$timeout(() => {
        this.$scope.$apply();
      });
    });

    // Settings
    this.printMode = 1;
  }

  $onInit() {
    this.settingStore.get({}, {}, (err, settings) => {
      if(settings.length > 0){
      this.settings = settings[0];
      this.timberAPISwitch = this.settings.timberAPISwitch;
      this.$scope.$applyAsync();
      }
    });
  }

  save(){
    this.settings.timberAPISwitch = this.timberAPISwitch;
    this.settings.date_modified = new Date();
    if(this.settings.id!=undefined)
    {
      this.settings.save();
    }
    else
    {
       this.settingStore.newResource(this.settings, (data) => {
        this.settings = data;
        data.save();
      })  
    }
    this.$rootScope.showToast('Timber Portal Settings Saved');
  }
  //Balance Settings
  connect() {
    this.WebUSB.requestDevice('arduino', (error, device) => {
      if (error) {
        // on error
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Failed to connect balance: ' + error.message)
            .position('bottom right')
        );
        //callback(error);
        console.log(error);
      } else {
        // on success
        this.device = device;
        this.balanceConnected = true;
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Balance connected')
            .position('bottom right')
        );

        // Start listening for data
        this.WebUSB.getData();
        this.loadSettings();
      }
    });
  }
  
  sendCommand(command) {
    const textEncoder = new TextEncoder();
    this.WebUSB.sendData(textEncoder.encode(command));
    this.log.push({
      data: command,
      source: 'client'
    });    
  }

  loadSettings() {
    this.updatePrintMode();
  }
  
  
  updatePrintMode() {
    const command = `P${ this.printMode }`;
    this.sendCommand(command);
  }

  onInputSubmitted() {
    this.sendCommand(this.commandToSend);

    this.log.push({
      data: this.commandToSend,
      source: 'client'
    });
    
    this.commandToSend = null;    
  }
}
(function (app) {
    app.controller('SettingDialogCtrl', SettingDialogCtrl);
  }(angular.module('app.job')));