'use strict';

(function (app) {
  app.factory('reportHistoryStore', function (Store, $http) {
    const reportHistoryStore = new Store({
      model: 'reportHistory'
    });
    return reportHistoryStore;
  });
  
}(angular.module('app.reportHistory')));
