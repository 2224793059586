'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    
  }); 
  
}(angular.module('app.batch', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics'
])));
