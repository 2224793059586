'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('labStandards', {
      url: '/library/standards',
      views: {
        '@': {
          templateUrl: '/html/labStandard/lab-standard-list.html',
          controller: 'LabStandardsCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Standards'
      }
    });

    $stateProvider.state('labStandards.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/labStandard/lab-standard.html',
          controller: 'LabStandardCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            labStandard: function ($stateParams, LabStandardStore, LabStandard) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if (!$stateParams.id) {
                  // if labStandard does not exist create new labStandard
                  let labStandard = new LabStandard({}, LabStandardStore.refs, (e, d) => {});
                  // TODO: fix the resource to stop having to do this
                  labStandard = labStandard.data;
                  resolve(labStandard);
                } else {
                  // if labStandard exists then load it
                  LabStandardStore.get({ _id: $stateParams.id }, (err, labStandards) => {
                    if (err || labStandards.length === 0) {
                      // on fail
                      reject(err || 'No labStandard found');
                    } else {
                      // on success
                      resolve(labStandards[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Standard'
      }
    });

  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('library', {
      title: 'Standards',
      icon: '/icons/md/action/svg/design/ic_description_24px.svg',
      state: 'labStandards',
      params: {},
      items: [{
        title: 'Add Standard',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'labStandards.edit',
        params: { id: undefined },
        permission: 'asset-make'
      }],
     permission: 'asset-view'
    });
  });

}(angular.module('app.labStandard', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.labLocation',
  'app.statics',
  'app.listView',
  'app.formView'
])));

