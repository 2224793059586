'use strict';

// constants
// ten seconds in milliseconds
const TEN_SECONDS = 10000;

/**
 * Controller for the login
 */
class LoginCtrl {

  constructor(APP_NAME, $rootScope, $location, $mdToast, $state, $stateParams, UserStore, Statics) {
    this.referrer = APP_NAME;
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$mdToast = $mdToast;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.UserStore = UserStore;
    this.message = 'Login';
    this.Statics = Statics;
  }

  /**
   * Attempt a basic login, and handle validation err
   */
  onSubmitClick() {
    // stop flashing the dashboard
    this.$rootScope.redirecting = true;
    this.message = 'Logging in';
    this.loggingIn = true;
    const username = this.username.toLowerCase();
    if (!this.resetClicked) {
      // if the user is logging in
      this.UserStore.basicLogin(username, this.password, this.$stateParams.ref, (err, res) => {
        this.loggingIn = false;
        if (err) {
          // on fail
          this.message = 'Failed to login';
        } else {
          // on success redirect to the place the user was going
          // remove user information in case the user logs out and does not refresh
          this.password = '';
          this.username = '';
          // refresh the ready promise
          this.$rootScope.ready = new Promise((resolve, reject) => {
            resolve(this.$rootScope.User);
          });
          // redirect
          if(this.referrer == 'Certificate Of Origin') {
            this.$state.go('redirect');
          } else {
            this.$state.go(this.$stateParams.ref, this.$stateParams.toParams);
          }
        }
      });
    } else {
      // if the user is resetting password
      this.UserStore.resetPassword(username, (error) => {
        if (!error) {
          // on success
          this.$mdToast.show(
            this.$mdToast.simple()
            .textContent('Check your email for further instructions.')
            .hideDelay(TEN_SECONDS)
            .position('bottom right')
          );
        }
        this.loggingIn = false;
      });
    }
  }

  redirectToRegisteration(){
    if(this.referrer === 'Hub'){
      // get login api params to redirect to hub
      this.Statics.get('single_sign_on', (error, env) => {
        const protocol = env.login_secure ? 'https://' : 'http://';
        this.$state.go('login.register.customer',{refURL: null, appCode:env.app_code}); 
      });
      
    }
    else {
      // get login api params to redirect to hub
      this.Statics.get('single_sign_on', (error, env) => {
        const protocol = env.login_secure ? 'https://' : 'http://';
        window.location = protocol + env.login_service + '/register/company?refURL=' + env.login_redirect+'&appCode='+ env.app_code;
      });
      //window.location = "";
    }
  }
}


(function(app) {
  app.controller('LoginCtrl', LoginCtrl);
}(angular.module('app.userlogin')));
