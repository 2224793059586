'use strict';

/**
 * Controller for the role list view
 */
class RoleListCtrl {

  constructor (RoleStore,UserStore,$rootScope) {
    this.RoleStore = RoleStore;
    this.UserStore = UserStore;
    this.$rootScope = $rootScope
    this.fields = [{
      path: 'name',
      name: 'Name'
    }, {
      path: 'code',
      name: 'Code',
      hideXs: true
    }, {
      path: 'description',
      name: 'Description'
    }];
  }

  /**
   * Called when a document is selected
   * @param {Object}  selected  The selected row object.
   * @return  {void}            Assigns the last clicked object the value of the last clicked row.
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  /**
   * Removes the document
   * @param {Object} role Role to remove.
   * @return  {void}              Assigns the last clicked object the value of the last clicked row.
   */
  deleteRole(role) {
    if(role!=undefined)
    {
      this.UserStore.get({roles:{$in:[role._id]}},function(err,user){
        if(!user||user.length==0)
        {
          role.remove(true);
          this.$rootScope.showToast('Role Deleted Successfully');
        }
        else
          this.$rootScope.showToast('This Role is already attached with users');
      }.bind(this))
    }
  }
}

(function(app) {
  app.controller('RoleListCtrl', RoleListCtrl);
}(angular.module('app.role')));
