'use strict';

// npm modules
const _ = require('lodash');

/**
 * Controller for the certificate view view
 */
class LocationFormCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $state, Statics, location, LocationStore) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Statics = Statics;

    this.location = location;
    this.LocationStore = LocationStore;

    // Load ISO countries (default to NZ)
    this.countries = [{
      code: 'NZ',
      name: 'New Zealand'
    }];
    
    
    if ( this.$state.params.appCode == 'hub') {
      this.hubRegistration = true;
    }
    else {
      this.hubRegistration = false;
    }
  }

  sameAddressChanged() {
    if (this.location) {
      if (this.location.postal) {
        // if postal address is used then remove it
        delete this.location.postal;
      } else {
        // if postal address is not used then add it
        this.location.postal = {};
      }
    }
  }


  clickToCustomer() {
    this.$state.go('customers.edit', {id: this.location.customer.id}, { reload: true });
  }

  saveLocation() {
    
    this.saving = true;
    this.location.services = _.flatMap(this.servicesSelected, (services) => {
      return services;
    });
    console.log("THIS IS CALLED SECOND!!!!", this.location);
    const id = this.location._id;
    const location = this.LocationStore.newResource(this.location);
    location.save((locationErr, locationData) => {
      console.log("THIS IS CALLED SECOND!!!!", locationData);
      if (locationErr) {
        console.error(locationErr);
        this.$rootScope.showToast('Failed to save location ' + locationData.name);
      } else {           
        this.$rootScope.showToast(`Location "${locationData.name}" saved`);
        // if the location is new then update the URL with the id
        this.$state.go('locations', { location: 0 }, { reload: 'locations' });
        
      }
      this.saving = false;
    });
  }



  /**
   * Load list of countries from server
   */
  loadCountries () {
    if (this.countries.length === 1) {
      // if the data has not already been loaded then load it
      return new Promise ((resolve, reject) => {
        this.Statics.get('countries', (err, data) => {
          if (err) return reject(err);
          this.countries = data;
          resolve(data);
        });
      });
    }
  }
}

(function (app) {
  app.controller('LocationFormCtrl', LocationFormCtrl);
}(angular.module('app.location')));
