'use strict';

(function (app) {
  app.factory('Customer', function ($http, $injector, $mdDialog, Resource) {
    return class Customer extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Customer' }, data, metadata, callback);
      }

      /**
       * Get the location given the index or id
       * @param {Mixed} indexOrId Number/string of the index or string of the id
       * @return {Mixed} Returns the element that matches the index or id
       */
      getLocation(indexOrId) {
        if (!Array.isArray(this.locations) || this.locations.length === 0) {
          // if there are no locations to get
          return null;
        }
        // if there are locations to get
        const int = parseInt(indexOrId);
        // Note: parseInt('5b...') === 5, which is why we check if the values are still equal
        if (int !== NaN && int == indexOrId) {
          // if finding by index
          if (this.locations[indexOrId]) {
            // if the index was found
            return this.locations[indexOrId];
          }
          // if the index was not found
          return null;
        }
        // if finding by id
        for (let i = 0; i < this.locations.length; i++) {
          // for each location
          if (this.locations[i]._id === indexOrId) {
            // if the location matches
            return this.locations[i];
          }
        }
      }
    };
  });
}(angular.module('app.customer')));
