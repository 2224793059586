'use strict';

(function (app) {
  app.factory('ControlStore', function (Store, $http) {
    const ControlStore =  new Store({
      model: 'Control'
    });

    return ControlStore;
  });

}(angular.module('app.control')));
