'use strict';

(function (app) {
  app.factory('LocationStore', function (Store, $http) {
    const LocationStore =  new Store({
      model: 'Location',
      realtime: false,
      ignoreMeta: true
    });

    // Setting the baseroute
    LocationStore.baseRoute = '/api/customer/location';
    
    return LocationStore;
  });

}(angular.module('app.customerClient')));
