'use strict';

/**
 * Component allowing the full form for adding contacts to be embedded in another form
 *
 * p42Roles:      Array of role types (defaults to empty array) (any of 'operator', 'deputy', 'accredited')
 * p42Default:    Contact id of Contact resource to set as the default selected value
 * p42Customer:   Customer id to get contacts from and create contacts against
 * p42Location:   Location id to get contacts from and create contacts against
 * p42Label:      Text to display as the label for the form
 * p42Required:   Whether a contact must be created or selected (defaults to false)
 * p42Editable:   Whether a new contact can be created (defaults to true)
 * p42SelectOnly: Whether a new contact can only be selected and will not stay saved against the form (defaults to false)
 * p42Selected:   Callback function containing an object with the key id and the value the id of the selected/created contact
 */
(function (app) {
  app.component('p42ContactForm', {
    restrict: 'E',
    controller: 'ContactFormCtrl',
    controllerAs: 'vm',
    bindings: {
      p42Label: '@',
      p42Required: '<',
      p42Editable: '<',
      p42SelectOnly: '<',
      p42Roles: '<',
      p42Default: '<',
      p42Customer: '<',
      p42Location: '<',
      p42Selected: '&'
    },
    templateUrl: '/html/customerclient/contact-form.component.html'
  });
}(angular.module('app.customerClient')));