'use strict';

/**
 * Component that controls the layout of the toolbar
 *
 * All input is provided through the toolbar provider
 */
(function (app) {
  app.component('p42Toolbar', {
    restrict: 'E',
    controller: 'ToolbarCtrl',
    controllerAs: 'vm',
    templateUrl: '/html/toolbar/toolbar.component.html'
  });
}(angular.module('app.toolbar')));
