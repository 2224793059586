'use strict';

/**
 * Controller for the toolbar directive
 */
class ToolbarViewCtrl {

  constructor($mdMedia, $mdSidenav, $scope, Toolbar) {
    this.$mdMedia = $mdMedia;
    this._$mdSidenav = $mdSidenav;
  }

  /**
   * Toggles the main App sidenav
   */
  toggleSidenav() {
    this._$mdSidenav('sidenav').toggle();
  }

  /**
   * Activates the click event for the button
   * @param {Object} item Button item
   */
  click(item) {
    // if click has not been setup
    if (!item.click) return;
    // if click has been setup
    if (item.click.length > 0) {
      // if click has an argument then it is a callback for showing the loading
      item.loading = true;
    }
    // fire the actual item click
    item.click(() => {
      // callback has been fired so stop loading
      item.loading = false;
    });
  }
}

(function (app) {
  app.controller('ToolbarViewCtrl', ToolbarViewCtrl);
}(angular.module('app.toolbar')));
