'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('labChemicals', {
      url: '/library/chemicals',
      views: {
        '@': {
          templateUrl: '/html/labChemical/lab-chemical-list.html',
          controller: 'LabChemicalsCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Chemicals'
      }
    });

    $stateProvider.state('labChemicals.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/labChemical/lab-chemical.html',
          controller: 'LabChemicalCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            labChemical: function ($stateParams, LabChemicalStore, LabChemical) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if (!$stateParams.id) {
                  // if labChemical does not exist create new labChemical
                  let labChemical = new LabChemical({}, LabChemicalStore.refs, (e, d) => {});
                  // TODO: fix the resource to stop having to do this
                  labChemical = labChemical.data;
                  resolve(labChemical);
                } else {
                  // if labChemical exists then load it
                  LabChemicalStore.get({ _id: $stateParams.id }, (err, labChemicals) => {
                    if (err || labChemicals.length === 0) {
                      // on fail
                      reject(err || 'No labChemical found');
                    } else {
                      // on success
                      resolve(labChemicals[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Chemical'
      }
    });

  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('library', {
      title: 'Chemicals',
      icon: '/icons/md/editor/svg/design/ic_format_color_fill_24px.svg',
      state: 'labChemicals',
      params: {},
      items: [{
        title: 'Add Chemical',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'labChemicals.edit',
        params: { id: undefined },
        permission: 'chemical-make'
      }],
     permission: 'chemical-view'
    });
  });

}(angular.module('app.labChemical', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.labLocation',
  'app.statics',
  'app.formView',
  'app.listView'
])));
