'use strict';

/**
 * Internal Components for buttons throughout Universe. Used for consistency.
 *
 * p42Label:            The label to be displayed on the button.
 * p42Icon:             The icon to be displayed inside the button, next to the label.
 * p42Class:            The class to be applied to the button (md-warn, md-primary, etc.)
 * p42Raised:           Opt out to disable md-raised class on buttons, backwards compatible. Any value not true or null will disable class.
 * p42Click:            Callback function to process when the button is pressed, changes loading state.
 * p42Loading:          Changes icon to use loading spinner - spinner reverts once client indicates so.
 * p42Disabled:         Whether the button should be disabled
 * p42Tooltip:          The tooltip to display for the button (if enabled). Takes an object param with the properties 'direction', and 'text'.
 * p42Type:             Type of button the p42Button should be
 *
 * p42Label, p42Icon, p42Class are all one-way bindings to allow dynamic changing of labels (Can be overridden using "text value").
 * Save button is triggered -> Change p42Label variable to 'Saving', then back once saving is completed.
 *
 * P42 Tooltip Example:
 *
 * p42-tooltip='{ direction: "top", text: "Tooltip text" }'
 */
(function (app) {
  app.component('p42Button', {
    restrict: 'E',
    controller: 'P42ButtonCtrl',
    controllerAs: 'vm',
    transclude: true,
    bindings: {
      p42Label: '<',
      p42Icon: '<',
      p42Class: '<',
      p42Raised: '<',
      p42Click: '&',
      p42Loading: '<',
      p42Disabled: '<',
      p42Tooltip: '<',
      p42Id: '<',
      p42Type: '@'
    },
    templateUrl: '/html/core/p42Button.component.html'
  });
}(angular.module('app.core')));
