'use strict';

/**
 * Controller for the permission list view
 */
class PermissionListCtrl {

  constructor ($state, $stateParams, PermissionStore, programs) {
    this.$state = $state;
    this.PermissionStore = PermissionStore;

    this.populate = 'program';
    
    this.fields = [{
      path: 'name',
      name: 'Name'
    }, {
      path: 'code',
      name: 'Code',
      hideXs: true
    }, 
    // {
    //   path: 'program',
    //   name: 'Program',
    //   transform: (programs) => {
    //     return _.reduce(programs, (programString, program) => {
    //       if (!programString) {
    //         if (program && program.name) return program.name;
    //         console.log('missing program: ', program); 
    //         return false;
    //       } else {
    //         return `${programString}, ${program.name}`
    //       }
    //     },null);
    //   }
    // },
     {
      path: 'description',
      name: 'Description'
    }];

    this.options = [{
      name: 'All Programs',
      filter: '',
      query: {},
      active: !$stateParams.tab
    }].concat(programs.map((program) => {
      return {
        name: program.name,
        filter: program.name,
        query: {
          program: program._id
        },
        slug: program.code,
        active: $stateParams.tab === program.code
      }
    }));
  }

  /**
   * Called when a document is selected
   * @param {Object}  selected  The selected row object.
   * @return  {void}            Assigns the last clicked object the value of the last clicked row.
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  /**
   * Removes the document
   * @param {Object} permission Permission to remove.
   * @return  {void}              Assigns the last clicked object the value of the last clicked row.
   */
  deletePermission(permission) {
    permission.remove(true);
  }

  /**
   * Changes the selected program in the state parameter
   * @param {Object} program Custom objects as set up in constructor
   */
  changeProgram(program) {
    this.$state.go(this.$state.current, { tab: program.slug });
  }
}

(function(app) {
  app.controller('PermissionListCtrl', PermissionListCtrl);
}(angular.module('app.permission')));
