'use strict';

/**
 * Controller for the Location edit view
 */
class SampleNewCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, $stateParams, SampleStore, JobStore, job) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.SampleStore = SampleStore;
    this.JobStore = JobStore;

    this.numberOfSamples = 1;

    this.samples = [];
    this.jobs = [];
    if (job) {
      // if job is set then add it to the list
      this.jobs.push(job);
      this.job = job;
      this.populateLabTests();
    }
    
    this.filter = {job_id: ''};
    this.searchTerm = '';
    
    this.updateSampleCount();
  }

  updateFilter() {
    this.filter = {
      job_id: this.searchTerm,
    };
  }

  clearSearchTerm() {
    this.searchTerm = '';
    this.filter = {
      job_id: this.searchTerm,
    };
  }
  
  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    // remove override sample names that are not set
    this.samples = this.samples.map((sample) => {
      if (!sample.override_sample_name) {
        delete sample.override_sample_name;
      }
      return sample;
    });
    // save the samples
    this.SampleStore.createMultiple(this.job, this.samples, (err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('samples');
      }
    });    
  }
  
  /**
   * Loads the list of jobs
   */
  loadJobs() {
    if (this.jobs.length <= 1) {
      return new Promise((resolve, reject) => {
        // if the jobs have not yet been loaded then load them
        this.JobStore.get({ phase: { $lte: 4 } }, { sort: { job_id: 1 } }, (err, jobs) => {
          if (err) return reject(err);
          this.jobs = jobs;
          resolve();
        });
      });
    }        
  }

  updateSampleCount() {
    // Checking if we're adding or removing elements
    if(this.samples.length > this.numberOfSamples) {
      // Removing elements
      this.samples.splice(this.numberOfSamples, this.samples.length - this.numberOfSamples);
    } else if (this.samples.length < this.numberOfSamples){
      // Adding elements
      for(let i = this.samples.length; i < this.numberOfSamples; i++) {
        let offset = 1;
        let type = 'r';
        if (this.samples.length > 0) {
          // if there is a sample then offset this sample by it
          offset = this.samples[0].sample_num;
          // default the type to the same as the previous sample
          if (this.samples[this.samples.length - 1].type === 'p') {
            type = 'p';
            this.populateSamples();
          }
        } else {
          // if there is not a sample then pick a useful defaults
          if (this.job && Array.isArray(this.job.samples)) {
            // if there is a job then pick a useful default offset
            offset += this.job.samples.length;
          }
          if (this.$stateParams.type === 'p') {
            // if the type is penetration
            type = 'p';
            this.populateSamples();
          }
        }
        this.samples.push(this.SampleStore.newResource({
          sample_num: i + offset,
          received: true,
          type: type,
        }));
      }
    }
  }

  onTypeChange(newType) {
    if (newType === 'p') {
      // if penetration has been selected then load samples for relating to
      this.populateSamples();
    }
  }

  populateSamples() {
    if (this.job && this.samples.length > 0 && Array.isArray(this.job.samples) && !this.job.samples[0]._id) {
      // if there are samples that have not been populated then populate them
      this.JobStore._populate(this.job, this.JobStore.refs, 'samples', (err) => {
        if (!err) {
          // if the samples were populated
          // should penetration samples need to be linked with retention samples when they are first created, put logic here
        }
      });
    }
  }

  populateLabTests() {
    if (this.job) {
      // if the job is valid
      if (this.job.phase > 2) {
        // if the phase requires that the status is setup
        this.JobStore._populate(this.job, this.JobStore.refs, 'labTests', (err) => {
          if (!err) {
            // if the labTests were populated
            this.job.labTests.forEach((labTest) => {
              // for each labtest
              this.samples.forEach((sample) => {
                // for each sample set the received default
                sample.getStatus(labTest).received = sample.received;
              });
            });
          }
        });
      }
      if (this.samples.length > 0 && Array.isArray(this.job.samples)) {
        // if there are samples then update the default sample numbers
        this.samples[0].sample_num = this.job.samples.length + 1;
        this.updateSampleCount();
      }
    }
  }
}

(function (app) {
  app.controller('SampleNewCtrl', SampleNewCtrl);
}(angular.module('app.sample')));
