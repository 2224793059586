'use strict';

/**
 * Controller for the programservice edit view
 */
class ProgramServiceEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, programservice, programs) {
    this.$state = $state;
    this.programservice = programservice;
    this.programs = programs;
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.submitting = true;
    this.programservice.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
        this.submitting = false;
      } else {
        // on success redirect
        this.redirect();
      }
    });
  }

  /**
   * Redirects to the parent state after the user is done with this one
   */
  redirect() {
    this.$state.go('programservices');
  }
}

(function (app) {
  app.controller('ProgramServiceEditCtrl', ProgramServiceEditCtrl);
}(angular.module('app.programservice')));
