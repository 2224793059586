'use strict';

/**
 * Creates a APIData service that fetches API data from the server and processes it client-side
 */
function APIData($http, $q) {

  class APIData {

    constructor() {
    }

    get(api, callback) {
      $q.all([
        // get the project API data
        $http.get('/api/api/getProjectSchema'),
        // get the actual API data
        $http.get('/api/api/getAPISchema')
      ]).then((response) => {
        // on success process the data
        const groups = {};
        const versions = [];
        for (const endpoint of response[1].data) {
          // for each endpoint add it to its grouping
          if (!groups[endpoint.group]) {
            // if there is no grouping then add one
            groups[endpoint.group] = [];
          }
          // add the endpoint to its grouping
          groups[endpoint.group].push(endpoint);
          // add the version
          if (endpoint.version && versions.indexOf(endpoint.version) === -1) {
            // if the version has not been added then add it
            versions.push(endpoint.version);
          }
        }
        callback(null, {
          api_project: response[0].data,
          api_data: groups,
          versions: versions,
          formats: []
        });
      }).catch((error) => {
        // on error
        callback(error);
      });
    }
  }

  return new APIData();
}

(function (app) {
  app.factory('APIData', APIData);
}(angular.module('app.api')));

