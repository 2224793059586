'use strict';

/**
 * Controller for the location form
 */
class LocationFormCtrl {
  
  constructor(CustomerStore, $timeout, $element) {
    this.CustomerStore = CustomerStore;

    // model for the most recent service added (or not yet added)
    this.service = '';
    // id of the selected location
    this.selectedId = '';
    // location to display info for
    this.location = {};
    // list of locations allowed to be selected
    this.locations = [];
    this.noLocations = false;
    // whether the location using the physical address as the postal address
    this.sameAddress = true;

    // wait until the DOM has loaded
    $timeout(() => {
      // The md-select directive eats keydown events for some quick select logic.
      // Since we have a search input here, we don't need that logic.
      $element.find('md-select-header').find('input').on('keydown', function(ev) {
          ev.stopPropagation();
      });
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit() {
    if (this.p42Editable === undefined) {
      // if p42Editable was not specified default to making the location editable
      this.p42Editable = true;
    }
    if (this.p42Required === undefined) {
      // if p42Required was not specified default to making the form not required
      this.p42Required = false;
    }
    if (this.p42Default !== undefined) {
      // if the default id is provided then select that location
      if (this.p42Default.id) {
        // if the location is an object then use it instead of loading it from the Hub
        this.locations.push(this.p42Default);
        this.location = this.p42Default;
        this.p42Default = this.p42Default.id;
        this.selectedId = this.p42Default;
      } else if (typeof this.p42Default === 'string') {
        // if the value is an ObjectId string then load the locations
        this.loadLocations().then((done) => {
          this.selectedId = this.p42Default;
          // same as select location without sending the id
          const found = this.locations.find((element, index, array) => {
            return element.id === this.selectedId;
          });
          if (found) {
            
              // only update the customer if it was found
            //TODO: Revise naming convention?
            this.customer = found;
            
            //Some small checks before assigning a location - ensuring the data matches up
            if(found.name && found.physical) {
                this.location = found;
            }
            // if only the physical address is used then check the sameAddress checkbox
            this.sameAddress = this.location.physical && !this.location.postal;
          }
        });

      }
    }
  }

  /**
   * Load list of locations
   */
  loadLocations () {
    if (!this.noLocations && this.locations.length === 0) {
      return new Promise ((resolve, reject) => {
        let query = { _id: this.p42Customer };
        this.CustomerStore.get(query, (err, customers) => {
          // on error reject
          if (err) return reject(err);
          // on success
          // customers should only have one customer with one location (at most)
          if (customers.length > 0 && Array.isArray(customers[0].locations) && customers[0].locations.length > 0) {
            // if there are locations to chose from then add them to the list
            this.locations = customers[0].locations;
          } else {
            // if there are no locations make sure we don't do a request every time
            this.noLocations = true;
          }
          resolve(this.locations);
        });
      });
    }
  }

  /**
   * Adds the location to the database and adds it to the manual
   */
  saveLocation () {
    if (this.p42SelectOnly !== undefined) {
      // if the form is not select only then set to false
      this.p42SelectOnly = false;
    }
    // add required hidden fields
    this.location.customer = this.p42Customer;
    // now save the location
    this.CustomerStore.saveLocation(this.location, (err, updatedLocation) => {
      if (err) return console.error('save location error:',err);
      this.location = updatedLocation;
      this.selectedId = updatedLocation.id;
      // update select options
      this.locations.push(updatedLocation);
      // output the location
      this.selectLocation();
    });
  }

  /**
   * Populates the form with the selected location's information
   */
  selectLocation () {
    const location = this.locations.find((element, index, array) => {
      if (element.id === this.selectedId) {
        this.p42Selected({ id: element.id });
        return true;
      }
      this.p42Selected({ id: false });
      return false;
    });
    if (!this.p42SelectOnly && location) {
      // if the form selection is allowed to persist then set the location
      this.location = location;
    } else {
      // if the form is not allowed to persist then unselect the id as the output has been sent
      this.selectedId = '';
    }
  }
}

(function (app) {
  app.controller('LocationFormCtrl', LocationFormCtrl);
}(angular.module('app.customerClient')));
