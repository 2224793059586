'use strict';
const _ = require('lodash');

/**
 * Controller for the fileshard directive
 */
class FileUploadCtrl {

  constructor($rootScope, $scope, $state, $http, $location, $mdToast, FileStore, $parse, UserStore) {
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$scope = $scope;
    this.$state = $state;
    this.$location = $location;
    this.FileStore = FileStore;
    this.UserStore = UserStore;
    this.$parse = $parse;
    this.$mdToast = $mdToast;
    this.User = $rootScope.User;

   

    // whether to force a file upload
    this.forceUpload = false;
    // whether the form is disabled
    this.disabled    = false;
    // whether to show the preview (hide by default)
    this.showPreview = false;
    // set up users for picking the authour
    this.author = this.User._id;
    this.users = [];

    // initialise the file
    this.clearFile();

    // // NOTE: this code has been ported from Container Weigh and not fully implemented
    // if (this.file.path && this.file.type !== 'image' && !this.hasSupport()) {
    //   // if file support is not detected
    //   this.$mdDialog.show({
    //     parent: angular.element(document.body),
    //     clickOutsideToClose: true,
    //     fullscreen: true,
    //     template:  `<md-dialog class="md-padding" aria-label="Download ${this.file.extension.toUpperCase()}">` +
    //                `  <md-dialog-content style="overflow: hidden;">` +
    //                `      <h2>It appears you don't have a ${this.file.extension.toUpperCase()} plugin for this browser.</h1>` +
    //                `      <p>You can <a href="${this.file.path_absolute}" target="_blank">click here</a> to download the ${this.file.extension.toUpperCase()} file.</p>` +
    //                `    </object>` +
    //                `  </md-dialog-content>` +
    //                `</md-dialog>`
    //   });
    //   // fallback for PDF support
    //   // use Google doc viewer API
    //   //this.pdfPath = this.$location.protocol() + '://docs.google.com/gview?embedded=true&url=' + this.file.path_absolute;
    // }

    // parse the file when it is selected
    $scope.$watch(() => {
      // watch the file
      return this.model;
    }, (newValue, oldValue) => {
      // when the file to upload changes then parse the file to form data
      if (this.model) this.parseFile();
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit () {
    if (this.p42Disabled === undefined) {
      this.disabled = 'false';
    } else {
      this.disabled = 'true';
    }
    if (this.p42ForceUpload === undefined) {
      this.forceUpload = 'false';
    } else {
      this.forceUpload = 'true';
    }
    if (this.p42PreviewDefault !== undefined) {
      this.p42Preview = this.p42PreviewDefault;
    }
    // default if not defined
    if(!this.p42AcceptFiles){
      this.p42AcceptFiles = ['image/*', 'application/xml', 'application/pdf', 'text/csv', 'application/msword','text/plain']
    }
    // convert to a comma seperated list
    this.p42AcceptFiles = this.p42AcceptFiles.join(',');
  }

  $onChanges(changes) {
    if (changes.p42Model && this.p42Model) {
      // if the file record is being replaced then parse the update
      this.clearFile();
      if (this.p42Model.filename) {
        // if the new model has data then use it otherwise require the user to click "upload"
        this.model = this.p42Model;
      }
    }
    if (changes.p42Data && this.p42Data) {
      // if the fileData of the file record is being replaced then only refresh fileData
      this.clearFileData();
      this.model = this.p42Data;
    }
    if (changes.p42Save && this.p42Save) {
      // if changes should be saved then do so
      this.uploadToHub();
    }
  }

  /**
   * Only load users when the user clicks on the select box
   */
  loadUsers() {
    if (!this.noUsers && this.users.length === 0) {
      // if we need to query the server for users
      return new Promise ((resolve, reject) => {
        this.UserStore.get({ _id: { $ne: this.User._id } }, { populate: 'contact' }, (err, users) => {
          // on error reject
          if (err) return reject(err);
          // on success
          if (users.length > 0) {
            // if there are users to chose from then add them to the list
            this.users = users;
          } else {
            // if there are no locations make sure we don't do a request every time
            this.noUsers = true;
          }
          resolve(this.users);
        });
      });
    }
  }

  /**
   * Checks whether the browser has PDF support
   */
  // NOTE: This code has been ported from Container Weigh and not fully integrated
  /*hasSupport () {
    const mimes = navigator.mimeTypes;
    for (let i = 0, i_len = mimes.length; i < i_len; i++) {
      // for each mime type supported
      if (mimes[i].type === this.file.mime) {
        // return true if this file's mime type is supported
        return true;
      }
    }
    return false;
  }*/

  /**
   * Parses the file to extract metadata from it and gets it in a format to send to the server
   */
  parseFile () {
    // console.log("parseFile")
    
    if(this.$rootScope.GoodCertFormCtrl!=undefined)
      this.$rootScope.GoodCertFormCtrl.filePreviousNextBtnShow = false;
    
    if(this.$rootScope.GoodFormCtrl!=undefined)
    {
      this.$rootScope.GoodFormCtrl.filePreviousNextBtnShow = false;  
      this.$rootScope.GoodFormCtrl.setButtons()
    }
    // Note: Model might be a Hub file or a browser file
    if (this.model) {
      // send data uri to the calling controller 
      if(this.model.mime_type){
        this.clearFile();
        return;
      }
      //If this file is oversize, clear the file and then popup a toast
      if (this.p42MaxFilesize && this.model.size > this.p42MaxFilesize * 1024 * 1024 || !this.model.type.match(/.(jpeg|jpg|png|doc|docx|csv|xsl|xslx|pdf)/i)) {
        this.clearFile();
        
        if(!this.$rootScope.makeException)
          this.$rootScope.showToast('Only Images and Documents are allowed for file uploads and must be less than 20mb in size. Accepted file types include: jpeg, jpg, png, doc, docx, csv, xsl, xslx & pdf');
        
        return;
      }

      // Applicable to image uploads only
      if(this.p42DataUriOnly && this.model.type.match(/.(jpg|jpeg|png|gif)$/i) ) {
        this.p42DataUri({value: this.model.dataURL});
      }

      // only parse the file if it is set
      this.data = new FormData();
      if (this.model._id) {
        // if the file is a Hub file
        Object.assign(this.file, this.model);
      } else {
        // if the file is a browser file
        this.data.append('file', this.model);
        let name = this.model.name.split('.');
        this.file.name = name[0];
        this.file.extension = name.pop();
        // a preview cannot be used until the files goes to the server
        delete this.file.data_uri;
      }
      if (!this.file.tags) {
        // angular material chips requires md-chips to be an array
        this.file.tags = [];
      }
    }
  }

  /**
   * Clears upload file form
   */
  clearFile () {
    
    if(this.$rootScope.GoodCertFormCtrl!=undefined)
      this.$rootScope.GoodCertFormCtrl.filePreviousNextBtnShow = true;
    
    if(this.$rootScope.GoodFormCtrl!=undefined)
    {
      this.$rootScope.GoodFormCtrl.filePreviousNextBtnShow = true; 
      this.$rootScope.GoodFormCtrl.setButtons()
    }
    // console.log("clearFile")
    
    this.clearFileData();
    this.file = {};
    // angular material requires md-chips to be an array
    if (!this.file.tags){
      this.file.tags = [];
    }
    // the native file model for the file, pre-upload
    this.model = null;
  }

  /**
   * Clears the fileModel and associated file data
   */
  clearFileData () {
    // raw browser FormData model
    this.data = new FormData();
    if (this.file) {
      // if the file is set then clear the data used to preview
      this.file.path_absolute = '';
      this.file.data_uri = '';
    }
  }

  /**
   * Uploads the image to the Hub
   */
  uploadToHub () {

    if(this.$rootScope.GoodCertFormCtrl!=undefined)
      this.$rootScope.GoodCertFormCtrl.filePreviousNextBtnShow = true;

    if(this.$rootScope.GoodFormCtrl!=undefined)
    {
      this.$rootScope.GoodFormCtrl.filePreviousNextBtnShow = true; 
      this.$rootScope.GoodFormCtrl.setButtons()
    }
    // console.log("uploadToHub")
    
    if (this.file.name) {
      // Compile the raw formData so that the request can be sent unserialised
      if (this.file.id) {
        // if the file is being updated then preserve the id
        this.data.append('id', this.file.id);
      }
      this.data.append('name', this.file.name);
      // public is boolean so use true or false (otherwise 'undefined' would be true)
      this.data.append('public', !!this.file.public);

      // tags are held as an array of strings - so pass to formdata as such (like a checkbox)
      for (let t in this.file.tags) this.data.append('tags', this.file.tags[t]);

      this.data.append('extension', this.file.extension);
      if (this.file.description) {
        // only add the description is it was written
        this.data.append('description', this.file.description);
      }

      if (this.p42Customer) {
        this.data.append('customer', this.p42Customer.id);
      }

      if (this.author && this.author !== this.User._id) {
        this.data.append('author', this.author);
      }

      //Save the file and then provide callback for controller to define behaviour
      this.FileStore.save(this.data, (err, newFile) => {
        this.update(newFile);
      });
    } else {
      // no file selected, so go back to file list
      this.clearFile;
      this.$state.reload();
    }
  }

  /*
  Binds the value to the p42-on-save binding in HTML via callback
  */
  update(value) {
    this.p42OnSave({value: value});
    this.clearFile();
  }

}
(function (app) {
  app.controller('FileUploadCtrl', FileUploadCtrl);
}(angular.module('app.fileshared')));
