'use strict';

(function (app) {

  app.config(function ($httpProvider) {
    // setup interceptor for showing errors
    $httpProvider.interceptors.push(function($q, $location, $rootScope) {
      let canceller = $q.defer();
      return {
        // when a response from the server is received
        'responseError': function(rejection) {
          // for error responses show the error to the user
          if (rejection.data) $rootScope.showError(rejection.data);
          else $rootScope.showError(`General Error, please check logs`);
          //console.error(`general error: ${rejection.status}`);
          //console.error(`data: ${rejection.data}`);
          canceller.resolve(`general error: ${rejection.status}`); 
          // pass the error through the application
          return $q.reject(rejection);
        }
      };
    });
  });

  app.run(function (Toolbar, ErrorHandler) {
    // add help button to toolbar
    Toolbar.addItem({
      icon: '/icons/md/action/svg/design/ic_help_24px.svg',
      title: 'Help',
      index: 1,
      action: ErrorHandler.showHelp.bind(ErrorHandler)
    });
  });

}(angular.module('app.logging', [
  'app.core',
  'app.toolbar'
])));