'use strict';

/**
 * Controller for the lab assets list view
 */
class JobListCtrl {

  constructor($rootScope, $state, $mdMedia, $stateParams, $filter, JobStore, phases, preservatives) {
    this.$state = $state;
    this.$mdMedia = $mdMedia;
    this.JobStore = JobStore;
    this.user = $rootScope.User;

    this.populate = 'customer runsheets';
    this.order = { date_last_modified: -1 };
    this.searchMultiple = ['Job'];

    let presOptions = preservatives.map((preservative) => {
      // for each preservative add a dropdown item for the list view
      // TODO: make selection persistent using $stateParams
      return {
        name: preservative,
        query: { preservative: preservative }
      };
    });
    presOptions = [{
      name: 'Any',
      query: {},
      active: true
    }].concat(presOptions);
    this.preservatives = presOptions;

    this.fields = [{
      path: 'job_id',
      name: 'Job Identifier',
      transform: (value,job) => {
        let val = "";
        if(job.isError){
          console.log(job.errMessage);
          val = '<span style="color:orange" title="' + job.errMessage + '">' + value + '</span>'
        }
        else
        {
          val = '<span> ' + value + '</span>'
        }

        return val;
      }
    },
    {
      path: 'priority',
      name: 'P',
      transform: (value) => {
        let Prinfo = "";
        if (value){
          if (value === 3)
            Prinfo = "<span class='priority-medium'>P</span>";
          else if (value === 5)
            Prinfo = "<span class='priority-high'>P</span>";
        }
        return Prinfo;
      }
    }, 
    {
      path: 'phase,runsheets',
      name: 'R',
      transform: (phase, job) => {
        let RSinfo = "";
        if (job.phase == 4 && job.runsheets.length == 0) {
          RSinfo =  "";
        } else if (job.phase == 4 && job.runsheets.length > 0) {
          let runsheetstocheck = _.uniqBy(job.runsheets, 'runsheetType');
          let needApprovedRunsheets = runsheetstocheck.length;
          let count = 0;
          runsheetstocheck.forEach(runsheet => {
          if ( runsheet && runsheet.jobsApproved.indexOf(job.id.toString()) > -1) {
              count = count + 1;
            }
          });
          if (count > 0 &&  count >= needApprovedRunsheets) {
            RSinfo = "<span class='runsheet-approved'>R</span>";
          } else {
            RSinfo = "<span class='runsheet-progress'>R</span>";
          }
        } else {
          RSinfo =  "";
        }
        return RSinfo;
      }
    }, 
    {
      path: 'customer',
      name: 'Customer',
      transform: (customer, job) => {
        let customerName="";
        if (customer){
          if (customer.short_name && customer.short_name.length > 0 )
            customerName = customer.short_name +" - ";
          //customerName = customerName +  customer.name;
        }else{
          //customerName = "site: "+job.site;
        }
        customerName = customerName +  job.site;
        return customerName;
      }
    },
    {
      path: 'preservative',
      name: 'Preservative',
      enableFilter: true,
      filterOptions: this.preservatives
    },
    {
      path: 'samples',
      name: 'Samples',
      transform: (value) => {
        return value.length;
      }
    }, {
      path: 'date_added',
      name: 'Date Added',
      transform: (value) => {
        return $filter('date')(value, 'dd/MM/yyyy');
      }
    }];

    this.phases = phases.map((phaseObject) => {
      // for each phase object mutate it in a way that it will work with the listView's tabs
      phaseObject.query = { phase: phaseObject.phase };
      phaseObject.active = $stateParams.tab === phaseObject.slug;
      return phaseObject;
    });
  }

  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('jobs.preview', { id: selected.id }, { notify: true });
  }

  onTabChange(tab) {
    this.$state.go(this.$state.current, { tab: tab.slug }, { notify: false });
  }

}

(function (app) {
  app.controller('JobListCtrl', JobListCtrl);
}(angular.module('app.job')));
