'use strict';

/**
 * Controller for the programservice list view
 */
class ProgramServiceListCtrl {

  constructor (ProgramServiceStore, programs, ProgramService, SettingsStore, $rootScope) {
    this.$rootScope = $rootScope;
    this.ProgramService = ProgramService;
    this.ProgramServiceStore = ProgramServiceStore;
    this.SettingsStore = SettingsStore;
    this.populate = 'program';
    this.programs = programs;
    this.tabs = [];
    this.dropdown = [{
      name: 'All Programs',
      query: {}
    }];

    this.fields = [
      {
        path: 'name',
        name: 'Name'
      },
      {
        path: 'program',
        name: 'Program',
        transform: this.getProgramName.bind(this)
      },
      {
        path: 'description',
        name: 'Description'
      },
    ];

    this.programs.forEach((program) => {
      this.dropdown.push( 
        {
          name: program.name,
          slug: program.code,
          query: { program: program._id }
        }
      );
    });
  }

  /**
   * Called when a document is selected
   * @param {Object}  selected  The selected row object.
   * @return  {void}            Assigns the last clicked object the value of the last clicked row.
   */
  onClick(selected, removeOp = null, saveProgramSetting = null) {
    console.log('DEBUG: selected:', selected);
    this.lastClicked = selected;
    if (removeOp) {
      this.remove();
    }

    if (saveProgramSetting) {
      this.saveDefaultProgram();
    }
  }

  /**
   *  Remove a program service based on the last clicked row.
   *  @return {void}            Deletes the program service.
   */
  remove() {
    this.ProgramService.remove( { id: this.lastClicked._id}, (err) => {
    });
  }

  /*
    Returns program name from program object
  */
  getProgramName(program) {
    return program.name;
  }

}

(function(app) {
  app.controller('ProgramServiceListCtrl', ProgramServiceListCtrl);
}(angular.module('app.programservice')));
