'use strict';

/**
 * Controller for the customer list view
 */
class CustomerListCtrl {

  constructor ($rootScope, $window, CustomerStore, query, $state, $filter, counts) {
    this.$window = $window;
    this.selectedRecords = [];
    this.records = [];
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.counts = counts;
    
    this.CustomerStore = CustomerStore;
    this.query = query;
    this.populate = 'locations.programs locations.services locations.contacts createdByUserId';
    this.searchMultiple = ['Location', 'Contact'];

    this.user = this.$rootScope.User
    

    // selects only a few fields from the database
    // this.select = 'name locations';
    this.order = 'name';
    if (this.query.status.match(/draft/i)  && this.query["pendingCustomerDetails.0"].$exists) { 
      this.selectedTabIndex = 2;
      this.fields = [{
        path: 'name',
        name: 'Company Name',
      }, {
        path: 'locationName',
        name: 'Main Location'
      }, {
        path: 'username',
        name: 'Created By'
      }, {
        path: 'creationTime',
        name: 'Creation Time',
        transform: (date) => {
          if (!date) return '';
          return  $filter('date')(date, $rootScope.dateFormat);
        }
      },{
        path: 'customerType',
        name: 'Type',
        transform: (value, record) => {
          if (value) {
            if(value=='CIB')
              value = "Certificate Issuance Body";
            else if(value == 'SCT')
              value = "Subcontractor";
            else
              value = "Client";
          }
          else
            value = ''

          return value
        },
        enableFilter: true,
        filterOptions: [{
          name: "All",
          query: { status:this.query.status},
        },{
          name: "Client",
          query: { status:this.query.status,customerType: "CLT"},
        },{
          name: "Certificate Issuance Body",
          query: { status:this.query.status,customerType: "CIB"},
        },{
          name: "Subcontractor",
          query: { status:this.query.status,customerType: "SCT"},
        }]
      }];
    } else  if (this.query.status.match(/draft/i) ) { 
      this.selectedTabIndex = 1;
      this.fields = [{
        path: 'name',
        name: 'Company Name',
      }, {
        path: 'locations',
        name: 'Main Location',
        transform: this.getMainLocation.bind(this)
      }, {
        path: 'locations',
        name: 'Programs (Services)',
        transform: this.mapLocationsToPrograms.bind(this)
      }, {
        path: 'createdByUserId',
        name: 'Created By',
        transform: this.getUsername.bind(this)
      }, {
        path: 'locations',
        name: 'Contacts',
        transform: this.mapMainContact.bind(this)
      },{
        path: 'customerType',
        name: 'Type',
          transform: (value, record) => {
          if (value) {
            if(value=='CIB')
              value = "Certificate Issuance Body";
            else if(value == 'SCT')
              value = "Subcontractor";
            else 
              value = "Client";
          }
          else
            value = ''

          return value
        },
        enableFilter: true,
        filterOptions: [{
          name: "All",
          query: {status:this.query.status },
        },{
          name: "Client",
          query: { status:this.query.status,customerType: "CLT"},
        },{
          name: "Certificate Issuance Body",
          query: {status:this.query.status, customerType: "CIB"},
        },{
          name: "Subcontractor",
          query: { status:this.query.status,customerType: "SCT"},
        }]
      }];
    }
    else { 
      this.selectedTabIndex = 0;
      this.fields = [{
        path: 'name',
        name: 'Company Name',
      }, {
        path: 'locations',
        name: 'Main Location',
        transform: this.getMainLocation.bind(this)
      }, {
        path: 'locations',
        name: 'Contacts',
        transform: this.mapMainContact.bind(this)
      },{
        path: 'customerType',
        name: 'Type',
       transform: (value, record) => {
          if (value) {
            if(value=='CIB')
              value = "Certificate Issuance Body";
            else if(value == 'SCT')
              value = "Subcontractor";
            else 
              value = "Client";
          }
          else
            value = ''

          return value
        },
        enableFilter: true,
        filterOptions: [{
          name: "All",
          query: {status:this.query.status },
        },{
          name: "Client",
          query: { status:this.query.status,customerType: "CLT"},
        },{
          name: "Certificate Issuance Body",
          query: {status:this.query.status, customerType: "CIB"},
        },{
          name: "Subcontractor",
          query: { status:this.query.status,customerType: "SCT"},
        }]
      }];
    }

    this.CustomerStore.get({_id:this.user.customer},(err,customers)=>{

      if(customers[0].customerType !== 'CIB'){ //if logged in user has a customer type != CIB

        if (this.user._id && !this.$rootScope.User.hasPermission('admin') && this.user.contact!=undefined )
          this.query['_id'] = this.user.customer;
      }
    });

    

  }
  
  /** 
   * flattens the data structure for pending customers 
   * i.e. converts an array of objects of array of objects => Array of objects for listView display
   * @param {Array} records Array of customer objects with an array of pendingDuplicate Customers
   * @return {String} Return an array of pending customers
   */
  modelRecordsForListView(records) {
    let recordsCopy = angular.copy(records);
    // flatten records array i.e. rolling pending customes to records level
    _.each(recordsCopy, (record, rowIdx) => {
      _.each(record.pendingCustomerDetails, (pendingCustomer, idx) => {
        if (idx > 0) {
          let newRecord =  angular.copy(record);
          newRecord.pendingCustomerDetailsIndex = idx; 
          records.splice(rowIdx+1, 0, newRecord);
        }
      });
    });

    // create an array of data for listView rendering
    _.each(records, (record, rowIdx) => {
      record.tableColumnData = [];
      if (record.pendingCustomerDetails.length > 0) {
        record.pendingCustomerDetailsIndex = !record.pendingCustomerDetailsIndex ? 0 : record.pendingCustomerDetailsIndex;
        _.each(this.fields, (field) => {
          if (!field.hidden) {
            let value = record.pendingCustomerDetails[record.pendingCustomerDetailsIndex][field.path];
            if (field.transform) {
              // if there is a transformation function then transform the value
              value = field.transform(value, record);
            } 
            record.tableColumnData.push(value);
          }
        });
      }
    });
  }

  onRecordChange(records) {
    this.records = records;
  }
  
  /**
   * Called when a document is selected
   */
  onClick(selected) {
    // bad idea to add it to the rootScope, revist and fix this later
    this.$rootScope.pendingCustomerIndex = selected.pendingCustomerDetailsIndex;
    this.lastClicked = selected;
  }

  /**
   * Converts an array of locations with populated main contacts into
   * a human readable list of names, if no main contact is defined, 
   * populates the first contact that was created for a speecific location
   * @param {Array} locations Array of Location objects with populated services
   * @return {String} Return description string
   */
  mapMainContact(locations) {
    if (!locations || locations.length <= 0) {
      return '';
    }

    let contacts = [];
     // Iterating through each location
    _.each(locations, (location) => {
      // Adding each service to the programs object
      if (location.contacts && location.contacts.length > 0) {
        let mainContacts = location.contacts.filter(function(contact) {
          return contact.main_contact;
        });
        
        if (!mainContacts || mainContacts.length <= 0) {
          contacts.push( location.name + ': <a href="mailto:'+location.contacts[0].email +'">'+location.contacts[0].email +'</a>');
        } else {
          _.each(mainContacts, (contact) => {
            contacts.push(location.name + ': <a href="mailto:'+contact.email +'>'+contact.email +'</a>' );
          });
        }
        contacts.push('<br/>');
      }      
    });
    
    return contacts.length > 0 ? contacts.join(' ') : '';
  }


  /**
   * Converts an array of locations with populated services into
   * a human readable list of names
   * @param {Array} locations Array of Location objects with populated services
   * @return {String} Return description string
   */
  mapLocationsToPrograms(locations) {
    if (!locations || locations.length <= 0) {
      return '';
    }

    let programs = {};
     // Iterating through each location
    _.each(locations, (location) => {
      // Adding each service to the programs object
      _.each(location.services, (service) => {
        // Edge case where service is null...
        if (_.isNil(service)) return;
        // Making sure there is an array to push service names to
        if (_.isArray(programs[service.program])) {
          programs[service.program].push(service.name);
        } else {
          programs[service.program] = [service.name];
        }
      });
    });

    let programNames = _
      .chain(locations)
      .flatMap('programs')
      .uniqBy('_id')
      .map((program) => {
        if (programs[program._id]) {
          return `${program.name} (${programs[program._id].join(', ')})`;
        } else {
          return program.name;
        }
      })
      .value()
      .sort();

    return programNames.join(', ');
  }

  /**
   * Gets the username of the user who created the customer account
   * @param {Array} locations Array of Location objects
   * @return {String} Returns address string of main location
   */

  getUsername(user) {
    if (!user) {
      return '';
    }
    return user.username;
  }

  /**
   * Gets the main location address, given all the customer's locations
   * @param {Array} locations Array of Location objects
   * @return {String} Returns address string of main location
   */
  getMainLocation(locations) {
    if (!locations || locations.length <= 0) {
      return '';
    }

    for (const location of locations) {
      if ( !location || !location.physical ) { return '';}
      // for each location skip any not active
      //if (!location.active) continue;

      let locationString = '';
      if (location.physical.unit) {
        locationString += location.physical.unit;
      }

      if (location.physical.street) {
        locationString += ' '+  location.physical.street;
      }

      if ( location.physical.city) {
        locationString += ' '+ location.physical.city;
      }
 
      // treat the first active location as the main one
      return locationString;
    }
  }

  /**
   * Gets the suburbs of non-main locations, given all the customer's locations
   * @param {Array} locations Array of Location objects
   * @return {String} Returns address string of other locations
   */
  getOtherLocations(locations) {
    if (!locations || locations.length <= 0) {
      return '';
    }

    let pastMain = false;
    const cities = [];
    for (const location of locations) {
      // for each location
      if (pastMain) {
        // if the location is not the main one
        if (location.active && location.physical && location.physical.city) {
          // if the location has a city then add it
          cities.push(location.physical.city);
        }
      } else {
        // if the location is the main one then skip
        pastMain = true;
      }
    }
    return cities.join(', ');
  }

  removeCustomer (customer) {
    // delete duplicate pendingCustomer if pendingCustomerDetailsIndex is defined

    if (customer.pendingCustomerDetailsIndex >= 0) {
      customer.pendingCustomerDetails.splice(customer.pendingCustomerDetailsIndex,1);

      customer.save((customerErr, customerData) => {
        if (customerErr) {
          console.error(customerErr);
          this.$rootScope.showToast('Failed to delete customer ' + customerData.name);
        } else {
          this.$rootScope.showToast(`Duplicate customer "${customerData.name}" deleted`);
          this.$state.reload();
        }
      });
    } else {
      customer.remove(true, (err, res) => {
        if (err==null) {
          this.$rootScope.showToast(`${ customer.name } successfully deleted`);
          this.$state.reload();
        }
      });
    }
  }

  editCustomer(record) {
    console.log(record.locations);
    if (record.locations && record.locations.length > 0 &&
        record.locations[0].contacts && record.locations[0].contacts.length > 0
      ) {
      this.$state.go('customers.edit.customer.location.contact', { id: record.id, location: 0, contact: 0 });
    } else if (record.locations && record.locations.length > 0 && record.locations[0] ) {
      this.$state.go('customers.edit.customer.location', { id: record.id, location: 0 });
    } else {
      this.$state.go('customers.edit.customer', { id: record.id });
    }
  }

  onClick(selected) {
    this.lastClicked = selected;
  }
}

(function(app) {
  app.controller('CustomerListCtrl', CustomerListCtrl);
}(angular.module('app.customer')));
