'use strict';

const async = require('async');

/**
 * Controller for the user edit view
 */
class UserEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $scope, $http, $state, $window, $mdDialog, $mdToast, CustomerStore, LocationStore, FileStore, ContactStore, roles, subscriptions, UserStore, user, ProgramStore) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$window = $window
    this.$http = $http;
    this.ProgramStore = ProgramStore;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.CustomerStore = CustomerStore;
    this.LocationStore = LocationStore;
    this.ContactStore = ContactStore;
    this.UserStore = UserStore;
    this.roles = roles;
    this.user = user;
    this.makeException = false;
    this.savingPhoto = false;
    this.FileStore = FileStore;
    this.$rootScope.fileUploadToast = false;
    this.isUserAdmin = (this.$rootScope.User.hasPermission('client-admin') || this.$rootScope.User.hasPermission('ff-admin'));
    
    if (this.$rootScope.User.hasPermission("admin")) {
      this.customerQuery = {status: "Approved"}
    } else {
      this.customerQuery = {status: "Approved", _id: this.$rootScope.User.customer}
    }

    this.dublicateUser = false;

    if (this.user._id == this.$rootScope.User._id)
      this.makeException = true;

    this.subscriptions = subscriptions;
    this.acceptPhotoTypes = ['image/jpg,image/jpeg,image/png, image/gif'];
    // setup user's populated data
    if (user.contact) {
      // if the user is populated
      this.contact = user.contact;
      // any location will do so take the first
      if(this.contact!=undefined)
        this.location = user.contact.locations[0];
    }
    if(this.isUserAdmin&&this.user._id&&this.user._id!=this.$rootScope.User._id)
    {
      this.contact = this.$rootScope.User.contact;
      this.location = this.$rootScope.User.customerObj.locations[0];
      this.customer =  this.$rootScope.User.customerObj
    }
    else
    {
      if(this.isUserAdmin)
      {
        this.contact = this.$rootScope.User.contact;
        this.location = this.$rootScope.User.customerObj.locations[0];
        this.customer =  this.$rootScope.User.customerObj
      }
      else
        this.customer =  this.user.customer 
    }
    this.setSignatureUri(this.user.signature);
    // setup search fields
    this.sort = { name: 1 };
  }

  photoUserDataUri(value) {
    this.user.photoUri = value;
  }

  $onInit() {
    this.ProgramStore.get({ code: 'coo',disabled:false }, { populate: '' }, (err, program) => {
      // console.log(program);
      this.program = program[0]
    });
  }

  updateForactivateOrDeactivateUser() {
    this.user.updateForActivateOrDeactivate(true, this.user , (error, results) => {
      if (!error) {
        // on success
        this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(this.user.username + ' has been updated successfully.')
          .hideDelay(10000)
          .position('bottom right')
        );
        this.$window.location.reload();
      } else {
         // on fail
         this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(this.user.username + ' can\'t be updated due to some unexpected outcomes.')
          .hideDelay(10000)
          .position('bottom right')
        );
      }
    });
    
  }
  
  setSignatureUri(signature)
  {
    
    if (signature!=undefined && typeof signature=='object')
    {
      var file = signature
      this.FileStore.previewFile(file, (err) => {
        if (err) return console.error(err);

        if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)) {
          
          if (file.source_image_uri)
            this.user.signatureUri = file.source_image_uri          
        } 
      });
    }
  }

  isUniqueUser() {
    this.dublicateUser = false;
    if(this.user && this.user.username && this.user.username.length > 0){
      this.UserStore.get({ username:  this.user.username}, {}, (err, dubUser) => {
        if (err) return reject(err);
        if (dubUser && dubUser.length > 0) {
          this.dublicateUser = true;
        }
      });
    }
  }
  
  /**
   * Format's the customer for display
   * @param {Object} customer Customer to format
   */
  formatCustomer(customer) {
    // on fail
    if (!customer) return '';
    // on success
    let name = customer.name;
    if (customer.trading_as) {
      // if the customer is trading differently
      name += ' (' + customer.trading_as + ')';
    }
    return name;
  }

  /**
   * Called when a customer is selected
   * @param {Object} customer Customer selected
   */
  selectCustomer(customer) {
    delete this.contact;
    delete this.location;
    if (customer) {
      // if the customer was selected
      this.$scope.form.customer.$setValidity('required', true);
        
        //this.locationQuery = { _id: { $in: customer.locations } };
        this.customer = customer;
        // console.log(this.customer)
        if(Array.isArray(customer.locations) && customer.locations.length > 0){
          // if the customer has locations
          this.location = customer.locations[0]; 
          this.$scope.form.customer.$setValidity('locations', true);
        } else {
          // if the customer has no locations
          this.$scope.form.customer.$setValidity('locations', false);
        }      
    } else {
      // if a customer was not selected
      this.$scope.form.customer.$setValidity('required', false);
    }
  }
  clickToCustomer() {
    var customerID = false;
    if (this.user.customer != 'undefined' && this.user.customer && this.user.customer.id 
        && this.user.customer.id != 'undefined') {
      customerID = this.user.customer.id;
    } else if (this.user.customer != 'undefined' && this.user.customer) {
      customerID = this.user.customer;
    }
    if (customerID) {
      this.$state.go('customers.edit', {id: customerID}, { reload: true });
    }
  }

  /**
   * Format's the location for display
   * @param {Object} location Location to format
   */
  formatLocation(location) {
    // on fail
    if (!location) return '';
    // on success
    return location.name;
  }

  
  /**
   * Format's the contact for display
   * @param {Object} contact Contact to format
   */
  formatContact(contact) {
    // on fail
    if (!contact) return '';
    // on success
    return contact.name;
  }

  /**
   * Called when a contact is selected
   * @param {Object} contact Contact selected
   */
  selectContact(contact) {
    this.contact = contact;
    // Note: $setValidity must take a boolean

    if(this.$scope.form.contact!=undefined)
      this.$scope.form.contact.$setValidity('required', !!contact);
  }

  deleteUser(){
    this.user.remove(true, (err, res) => {
      if (err) {
        this.$rootScope.showError(`User Deletion Failed`);
      } else {
        this.$rootScope.showToast(`User successfully deleted`);

        this.$window.location.href = "/users/Approved";
      } 
    })
  }

  /**
   * Adds an uploaded signature file to the user
   * @param {Object} file Hub file object
   */
  addSignature(file) {
    if(file.extension === 'jpeg' || file.extension === 'png' || file.extension === 'jpg') {
      this.user.signature = file._id;   
      this.setSignatureUri(file); 
    } else {
      this.$rootScope.showError(`File Extensions must be jpeg|png|jpg`);
      return false;
    }
  }

  saveUser(event) {
    this.savingUser = true;
    //this.user.status = 'Approved';
    if (this.$rootScope.User._id === this.user._id) {
      // if the user is myuser then prompt for password
      this.$mdDialog.show({
        controller: this.PasswordDialogController,
        templateUrl: '/passwordDialog.html',
        targetEvent: event,
        escapeToClose: true,
        clickOutsideToClose: true
      }).then((password) => {
        if (password) {
          // if password was entered then add password to the data so it gets check when saving
          this.user.challenge = password;
          this.contact.challenge = password;
          this.submit();
        } else {
          // if no password was entered then do nothing
          this.savingUser = false;
        }
      }).catch(() => {
        // if saving has been cancelled
        this.savingUser = false;
      });
    } else {
      // if the user is not myuser

      // Check if the user account is being activated currently
      if (this.user.status == 'Draft') {
        // Check if subscriptions and roles have been defined
        if (this.user.subscriptions && this.user.subscriptions.length > 0 && this.user.roles && this.user.roles.length > 0) {
          this.user.status = 'Approved';
        }
      } else if (!this.user.status && !this.user._id) {  // getting created from within HUB
        this.user.status = 'Approved';
      }
  
      if (!this.user.id)
      {
        this.user.createdAt = new Date();
        this.user.updatedAt = new Date();
        this.user.createdBy = this.$rootScope.User.id;
      }
      
      this.submit();
    }
  }

  /**
   * Controller to make the password dialog function correctly
   * @param {Object} $scope    Isolate Angular scope of the dialog
   * @param {Object} $mdDialog MdDialog service that applies to this dialog
   */
  PasswordDialogController($scope, $mdDialog) {
    $scope.cancel = function() {
      $mdDialog.cancel();
    };
    $scope.save = function() {
      $mdDialog.hide($scope.password);
    };
    $scope.change = function() {
      $mdDialog.hide({
        currentPassword: $scope.currentPassword,
        newPassword: $scope.newPassword
      });
    };
  }

  /**
   * Submits the form, saving the records
   */
  submit() {

    this.$rootScope.fileUploadToast = false;
    this.user.contact = this.contact;
    this.user.customer = this.customer._id;
    const contact = this.ContactStore.newResource(this.contact);
    const user = this.UserStore.newResource(this.user);

    let queryUser = {signatureUsed:this.user.signatureUsed,_id:{$ne:this.user.id}};
    if(!this.user.signatureUsed ||this.user.signatureUsed==undefined || this.user.signatureUsed=='')
      queryUser = {username:''}

    this.UserStore.get(queryUser,function(err,checkUser){
      if(checkUser && checkUser.length>0)
      {
        this.$rootScope.showToast('Unable to save user `signature code` already used. Please change it.'); 
        this.savingUser = false;
      }
      else{

        async.parallel( [
          // save the contact
          // function(callback) {
          //   contact.save(callback);
          // }, // save the user
          function(callback) {
            var listRoles = [];
            
            let validCustomer = ['CLT','CIB'];

            if(this.roles && user.roles && this.roles.length>0&& user.roles.length>0)
            {
              validCustomer.forEach(function(value,indx){
                // console.log(this.user.customer)
                if(value===this.customer.customerType)
                {
                  this.roles.forEach(function(role,roleIndex){
                    if(role._id!=undefined && role._id)
                    {
                      // console.log('role._id',role._id)
                      user.roles.forEach(function(usrRole,Rindex){
                        // console.log('usrRole',usrRole)
                        if(usrRole && usrRole==role._id){
                          listRoles.push(usrRole);
                        }
                      })
                    }
                  }.bind(this))
                }
              }.bind(this));
            }
            // console.log('listRoles',listRoles)
            
            user.roles = listRoles;

            if(!user._id)
              user.isActive = true;

            this.ContactStore.get({ customer: this.customer._id }, { populate: '' }, (err, contacts) => {
              if (err) {
                console.log(err);
              } else {
                if(contacts && contacts.length > 0){
                  user.contact = contacts[0]._id;
                }
              }
            
              user.save((err , addUser)=>{
                if(err){
                  console.log(err);
                }
                this.user = addUser ;
                callback()
              });
            });
            // console.log(this.user , this.user.id);
          }.bind(this)
        ], function doneParallel(error) {
          // remove password info
          delete this.user.challenge;
          if (this.contact)
            delete this.contact.challenge;
          // unset loading variable
          this.savingUser = false;
          if (error) {
            // on error show the error
            console.error(error);
            this.savingPhoto = false;
          } else {
            // console.log(this.savingPhoto,'inside')
            if (!this.savingPhoto)
              this.redirect();

            this.savingPhoto = false;
          }
        }.bind(this))
      }
    }.bind(this))
    //var thisvalue = this;
  }

  /**
   * Redirects to the parent state after the user is done with this one
   */
  redirect(cancel) {
    // on success redirect
    if(this.user.id && !cancel){
      this.$state.go('users.edit' , {id : this.user.id} , {reload : true});
      this.$rootScope.showToast('User information is saved successfully'); 
    }else 
      this.$state.go('users');  
  }

  /**
   * Sends password reset email to the user's contact email address
   */
  changeViaEmail() {
    this.savingUser = true;
    this.UserStore.resetPassword(this.user.username, (error) => {
      if (!error) {
        // on success show toast
        const toast = this.$mdToast
          .simple()
          .textContent('Password reset email sent to ' + this.user.username)
          .position('bottom right');
        this.$mdToast.show(toast);
      }
      this.savingUser = false;
    });
  }

  // TODO: possibly move this into a service?
  /**
   * Make a request to reset the user's password via email
   * @param {String} id ObjectId of the user to reset the password for
   * @param {Function} callback Callback called when done in format (error, data)
   */
  resetPassword(id, callback) {
    this.$http({
      method: 'GET',
      url: '/api/User/password/' + id
    })
    .then((res) => {        
      callback(null, res);
    })
    .catch(callback);
  }

  /**
   * Opens a dialog allowing the user to change an existing password
   */
  changeViaHub() {
    // set up data
    this.savingUser = true;
    const toast = this.$mdToast
      .simple()
      .textContent('Password changed')
      .position('bottom right');
    const options = {
      controller: this.PasswordDialogController,
      templateUrl: '/passwordChangeDialog.html',
      targetEvent: event,
      escapeToClose: true,
      clickOutsideToClose: true
    };
    // change password
    this.$mdDialog
      .show(options)
      .then((results) => {
        if (results.currentPassword && results.newPassword) {
          // if passwords were entered then change password
          this.changePassword(this.user._id, results.currentPassword, results.newPassword, (error, res) => {
            if (!error) {
              // on success show toast
              this.$mdToast.show(toast);
            }
            this.savingUser = false;
          });
        } else {
          // if no password was entered then do nothing
          this.savingUser = false;
        }
      })
      .catch(() => {
        // if saving has been cancelled
        this.savingUser = false;
      });
  }

  // TODO: possibly move this into a service?
  /**
   * Make a request to change the user's password
   * @param {String} id ObjectId of the user to change the password for
   * @param {String} currentPassword Current password for logged-in user
   * @param {String} newPassword New password for the user being updated
   * @param {Function} callback Callback called when done in format (error, data)
   */
  changePassword(id, currentPassword, newPassword, callback) {
    this.$http({
      method: 'POST',
      url: '/api/User/password/' + id,
      data: {
        challenge: currentPassword,
        password: newPassword
      }
    })
    .then((res) => {        
      callback(null, res);
    })
    .catch(callback);
  }

  photoDataUri(value) {
    this.contact.photoUri = value;
    this.savingPhoto = true;
    this.saveUser()
    // console.log(this.savingPhoto,'outside')
    
  }
}

(function (app) {
  app.controller('UserEditCtrl', UserEditCtrl);
}(angular.module('app.singleSignOn')));
