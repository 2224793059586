'use strict';

/**
 * Controller for the fields list view
 */
class FieldListCtrl {

  constructor (FieldStore, labTestsSelector, $state,$rootScope,$scope) {
    console.log($rootScope,$scope);

    this.$rootScope = $rootScope;
    this.labTests = labTestsSelector;
    this.FieldStore = FieldStore;
    this.$state = $state;
    this.fields = [
      {
        path: 'name',
        name: 'Name'
      }, {
        path: 'report_name',
        name: 'Report Name'
      },
      {
        path: 'description',
        name: 'Description'
      },
      {
        path: 'decimal_places',
        name: 'Decimal Places',
        transform: (value) => {
          if (value == 0) {
            return '0'
          } else if (value > 0) {
            let string = value + ' (0.';
            for (let i=0;i<value;i++) {
              string = string + '0';
            }
            return string + ')' ;
          } else {
            return value;
          }
        }
      },
      {
        path: 'input_type',
        name: 'Type',
        transform: (value) => {
          if (value === 'E') {
            return 'Expression'
          } else if (value === 'I') {
            return 'Input';
          } else if (value === 'C') {
            return 'Constant';
          } else if (value === 'N') {
            return 'No Input';
          } else {
            return value;
          }
        }
      },
      {
        name: 'Runsheet',
        tpl:'/runsheet_visibility.html'
      },
      {
        name: 'Report',
        tpl:'/report_visibility.html'
      },
      {
        name: 'Summary',
        tpl:'/summary_visibility.html'
      },
      {
        name: 'Summary Visibility',
        tpl:'/sample_visibility.html'
      },
    ];

    this.sort = {
      name: 1 
    }
  }

  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('fields.edit', { id: selected.id });
  }

  removeField(field) {
    field.remove(true);
  }
}

(function(app) {
  app.controller('FieldListCtrl', FieldListCtrl);
}(angular.module('app.field')));
