'use strict';

(function (app) {
  app.factory('LabTestStore', function (Store,$http) {
    const LabTestStore = new Store({
      model: 'LabTest',
    });

    LabTestStore.localSearch= function(searchText, status, error, labtests){
        $http.post('/api/LabTest/search',{searchText:searchText, status: status}).then(function(docs){
          labtests(docs.data);
      }).catch(function(err){
        error(err)
      })
    }
  
    return LabTestStore;
  });

}(angular.module('app.labTest')));
