'use strict';

/**
 * Provides a standard template for laying out forms
 *
 * p42Form:    Angular form object to validate the form for
 * p42Buttons: Array of button objects for displaying buttons (DEPRECATED in favour of transclusion slots)
 * p42Submit:  Function to call when the submit button is clicked (DEPRECATED in favour of transclusion slots)
 * p42NamedTransclude: Opt-in to using named transclusion
 *
 * Button object are defined as follows (but should no longer be used)
 * {
 *   text: String, // text to display on the button
 *   onClickText: String, // (optional) text to change the value to when button is clicked
 *   onClick: Function, // function to call when clicked
 *   loading: Boolean, // variable to watch to see if form has been submited
 *   requireValid: Boolean // (optional) whether the button requires to form to be valid
 *   class: String // (optional) ng-class compatible expression (defaults to 'md-accent')
 * }
 */
(function (app) {
  app.component('p42FormView', {
    restrict: 'E',
    controller: 'FormViewCtrl',
    controllerAs: 'vm',
    transclude: {
      // p42-form-view-content is a slot for the p42-form-sections and form inputs to go
      content: '?p42FormViewContent',
      // p42-list-view-buttons is a place for button that affect the form-view to go
      buttons: '?p42FormViewButtons'
    },
    bindings: {
      p42Form: '<',
      // @DEPRECATED in favour of named transclusion slots
      p42Buttons: '<',
      p42Submit: '&',
      // Note: will be deprecated in the future and set to always be true
      p42NamedTransclude: '<'
    },
    templateUrl: '/html/formview/formView.component.html'
  });
}(angular.module('app.formView')));
