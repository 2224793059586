'use strict';

const _ = require('lodash');

/**
 * Controller for the sidenav directive
 */
class ListViewCtrl {

  constructor($cookies, $scope, $rootScope, $transclude, $timeout,$location, $mdMedia, $anchorScroll) {
    this.$cookies = $cookies;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$transclude = $transclude;
    this.$timeout = $timeout;
    this.$mdMedia = $mdMedia;
    this.$anchorScroll = $anchorScroll;
    this.$location = $location;
    // setup list body
    // list of records to display
    this.records = [];

    // setup search
    this.showSearch = true;
    this.showDropdown = true;

    // setup key
    this.showKey = true;

    // pagination setup
    // whether to hide the pagination footer
    this.hidePagination = true;
    // how many records are available to view
    this.count = 0;

    // batch actions setup
    // list of selected records
    this.selected = [];
    this.rowSelect = false;
    this.selectedFilter = '';
    // setup realtime update counter
    this.updates = 0;
  }

  /**
   * Set defaults for input values
   */
  $onInit() {
    // md-data-table requires the paginate function to be bound this way
    this.paginate = this.getRecords.bind(this);
    this.onSelect = this.onSelect.bind(this);

    // this is used to keep the 'no results found' message hidden until we get a response
    this.loaded = false;

    this.listViewElement = angular.element(document.querySelector('.list-view'))[0];
    this.dateSearch = false;
    this.$scope.$watch(this.watchMedia.bind(this), this.changeMedia.bind(this));

    if (this.p42Query === undefined) {
      // if the query is not defined then default to all records
      this.query = {};
    } else {
      // if the query is defined then use it
      this.query = this.p42Query;
    }
    // remember the query
    this.previousQuery = _.cloneDeep(this.p42Query);

    // parse the limit
    const initialLimit = this.p42Limit;
    this.p42Limit = parseInt(this.p42Limit);
    if (isNaN(this.p42Limit)) {
      // if the limit is not valid then use default
      this.p42Limit = parseInt(this.$cookies.get('query-limit')) || 15;
    }
    // remember the limit
    this.previousLimit = this.p42Limit;

    // parse the page
    this.p42Page = parseInt(this.p42Page);
    if (isNaN(this.p42Page)) {
      // if the page is not valid then use default
      this.p42Page = 1;
    }

    // handle tabs
    if (this.p42Tabs) {
      // if tabs are in use then hide the search bar by default
      this.showSearch = true;
      this.showKey = false;
    }

    // hangle toggling
    if (!this.p42DisableToggleSearch){
      this.showSearch = false;
      this.showKey = false;
    }

    // TODO: merge dropdown and dropdownTabs into a single API
    // handle dropdown
    if (this.p42Dropdown) {
      // if tabs are in use then hide the search bar by default
      this.showSearch = false;
      this.showKey = false;
      this.dropdownQuery = {};
    }

    if (this.p42Showdropdown == false) {
      this.showDropdown = false;
      this.showSearch = true;
    }
    // handle dropdown tabs
    if (this.p42DropdownTabs) {
      // select the default option (if any)
      this.selectedFilter = this.p42DropdownTabs.find((filter) => filter.active);
      if (this.selectedFilter && this.selectedFilter.query) {
        // if a filter has been selected then apply it
        this.query = this.selectedFilter.query;
      }
    }

    // handle search
    if (this.p42Search) {
      // if search is defined then run it
      if (this.search) this.search();
      this.showKey = false;
    }

    // handle action buttons
    if (this.p42ActionHeader) {
      this.$scope.p42ActionHeader = this.p42ActionHeader;
    }
    if (this.p42Fields && this.p42Fields.find((field) => field.enableDate )) this.dateSearch = true;

    // by this point data may or may not have been loaded
    if (!this.p42Tabs && !this.p42Search && !initialLimit) {
      // if data has not been loaded
      // Note: if using tabs the data will be loaded when the selected tab is initialised
      // actually get the records now we know what to query
      this.getRecords(this.p42Page, this.p42Limit, true);
      this.oldRecords = this.records;
    }

    // Note: the following will be removed in the future
    if (!this.p42NamedTransclude) {
      // To appease this warning add p42-named-transclude=true to your p42-list-view, then use the slots defined in listView.component.js
      console.warn('Please use named transclusion slots with p42-list-view (opt in with p42-named-transclude).');
    }
    this.$rootScope.p42Query = this.query

  }

   /**
   * Called when on each digest cycle
   */
  $doCheck() {
    // Note: angular cannot be used for this comparison because query might contain $-prefixed keys
    if (!_.isEqual(this.p42Query, this.previousQuery)) {
      // set date in change filter
      this.dateChange();

      // if the query is changed then update it
      if (this.p42DropdownTabs) {
        this.query = _.merge({}, this.p42Query, this.selectedFilter.query);
      } else {
        this.query = this.p42Query;
      }
      // reset to the first page
      this.p42Page = 1;
      // reset the search term
      //if (this.p42Search) this.p42Search = '';
      // remember the query
      // Note: this.query cannot be used to remember as that might change if the search term changes
      this.previousQuery = _.cloneDeep(this.p42Query);
      this.getRecords(this.p42Page, this.p42Limit, true);
    }    
  }

  watchMedia() {
    return this.$mdMedia('xs');
  }

  changeMedia(isXs) {
    this.$scope.mediaXs = isXs;
  }

  isEmpty(obj) {
    for (var i in obj) if (obj.hasOwnProperty(i)) return false;
    return true;
  };

  /**
   * Gets the field name from the path given the store
   * If there is a populated document then use the name of the field that stores the reference
   */
  getFieldName(fieldObject) {
    const path = fieldObject.path ? fieldObject.path : fieldObject;
    if (fieldObject.name) {
      // if the field is overridden in the name then use that
      return fieldObject.name;
    }
    // Note: the below code will run more slowly so it is preferable to include the name in the field object
    if (this.p42Store.paths[path]) {
      // if the field does not include a referenced document then use the field's name
      if (this.p42Store.paths[path].name) {
        // if the name is defined then return it
        return this.p42Store.paths[path].name
      }
      // else fall through and guess the name
    } else {
      // if the field does include a referenc then use the name of the field that stores the reference
      let fields = path.split('.');
      // Note: recursion would be more DRY but iteration is faster and less prone to error
      while (fields.length > 1) {
        // while there are still paths to check then check them
        // remove the final field in the path
        fields = fields.slice(0, -1);
        const field = fields.join('.');
        if (this.p42Store.paths[field] && this.p42Store.paths[field].name) {
          // if the field that stores the references has been reached then return
          return this.p42Store.paths[field].name
        }
      }
    }
    // if no field name was found then guess the name
    return path.split('.').pop();
  }

  /**
   * Get the field given the record and field value
   */
  getValue(record, fieldObject) {
    if (!record || !fieldObject) {
      // if invalid arguments are passed then default to blank
      return '';
    }
    // Checking if the path was set and getting the value
    // Default to the record if no path was supplied
    let value = fieldObject.path ? record.getValue(fieldObject.path) : record;
    if (fieldObject.transform) {
      // if there is a transformation function then transform the value
      value = fieldObject.transform(value, record);
    }
    return value;
  }

  /**
   * Gets called the sort order get changed
   * @param {Object} order Mongoose sort object to sort by
   */
  onReorder(order) {
    // update sorting
    this.p42Order = order;
    // reset pager to first page
    this.p42Page = 1;
    // refresh the records
    this.getRecords(this.query.page, this.query.limit,false);
  }

  /**
   * Gets called when a row is clicked
   * @param {Resource} selected Instance of the Resource that was selected
   */
  onRowSelect(selected, $event) {
    this.p42RowClicked({ selected: selected, $event: $event });
  }

  /**
   * Gets called when a row is clicked
   * @param {Resource} selected Instance of the Resource that was selected
   */
  onSelect(selected) {
    this.p42Selected({ selected: selected });
  }

  /**
   * Gets records from the store
   * @param {Number} page Number of the page to display results for
   * @param {Number} limit How many results to show on a single page
   */
  getRecords(page, limit, pullRecords) {
    this.selected = [];
    // Note this a hack, for some reason file upload list view doesnt work without a limit
    if (this.p42Store.Model == 'File') { 
      this.p42BackendSearch = true;
    }

    if (pullRecords || this.p42BackendSearch ){ 
      // if the page is defined then get the records
      // md-data-table must use a promise for its loading bar
      this.loading = this.$rootScope.wrapResolve((resolve, reject) => {
        // build select list from p42Fields list
        this.p42Select = [];
        _.each(this.p42Fields, (field) => {
          if (field.path){
            this.p42Select.push(field.path);
          }
        });

        // Creating the options object to pass into the search
        const options = {
          populate: this.p42Populate,
          select: this.p42Select.join(' '), // create a space separated list for mongoose to process
          sort: this.p42Order,
          preserveOrder: true
        }

        // search the database and enable pagination i.e. pull a few records in one go 
        // bi-passes angular pagination and filtering
        if (this.p42BackendSearch) {
          options.limit = this.p42Limit;
          options.skip = this.p42Limit * (page -1);

          if (this.p42SearchMultiple) {
            // if searching over multiple collections
            options.models = this.p42SearchMultiple;
          }
        }

        if(this.$rootScope.adminDashboardList!=undefined && this.$rootScope.adminDashboardList === 1 &&this.query.type!=undefined && typeof this.query.type == 'string' ){
          options.sort = {'uniqueName':-1}
        }
        this.$rootScope.p42Query = this.query
        this.queryHash = this.p42Store.search(this.query, options, (err, records, count) => {
          if (err) {
            // on fail
            reject(err);
            this.loaded = true;
          } else {
            this.records = records;
            if(this.records.length>0)
            {
              let _this = this
              let selectedExporter = localStorage.getItem( 'exporter');
              if(selectedExporter!=undefined && selectedExporter && this.$rootScope.User.hasPermission('freight-forwarder'))
              {
                this.records.forEach(function(elem,index){
                  if(elem.id==selectedExporter)
                    this.records[index].selectedExporter = 1
                  else
                    this.records[index].selectedExporter = 0 
                },this) 
              }

            }


            // clearing event listeners
            _.each(this.listeners, (r) => {
              // Removing the listener
              r();
            });
            this.listeners = [];
            
            // if a specific data modeller function is defined for the listView, use it. 
            // if not use the generic approach 
            if (this.p42DataModeller) {
              this.p42ModelRecords({records: records});
            } else {
              // prepare list view records
              // add counter for timeout retries waiting for p42fields
              let waitCounter = 0;
              _.each(this.records, (record, rowIdx) => {
                record.tableColumnData = [];
                let writeRecords = () => {
                  waitCounter++;
                  if (this.p42Fields) {
                    _.each(this.p42Fields, (field) => {
                      record.tableColumnData.push(this.getValue(record, field));
                    });
                  } else {
                    setTimeout( () => {
                      if (waitCounter < 10) writeRecords();
                      return this.$rootScope.showError(`Timeout waiting for records, please try again. (${waitCounter})`);
                      }
                    , 100);
                  }
                }
                writeRecords();
              });
            }

            this.p42OnRecordChange({records: this.records});
            resolve();
            this.loaded = true;
            this.hidePagination = this.p42Limit >= count;
            this.count = count;
            this.p42Displayed({ documents: records });
            this.p42Count({ count: count });
            // Scrolling to the top
            this.listViewElement.scrollTop = 0;
          }
        });
      });
    } else {
      this.$location.hash('top');
      this.$anchorScroll();
    }
  }

  rowStyle(record){
    if(record.selectedExporter)
      return "background-color: grey;"
  }

  /**
   * Toggles the search box
   */
  toggleSearch() {
    this.showSearch = !this.showSearch;
    if (!this.showSearch) {
      this.backendSearch(true)
      // if the search is not shown then clear the search term
      this.p42Search = '';
      this.hidePaginationTop = false;
    } else { // hide top pagination
      this.hidePaginationTop = true;
    }
  }

  /**
   * Top Right one of Dropdown filtering -backend
  */
  dropdownFiltering(filter) {
    // Ignore searching if backend searching is not enabled
    if (!this.p42BackendSearch){
      return;
    }
    // if query is blank pull all data
    if (Object.keys(filter.query).length <= 0) {
      this.p42Query = angular.copy(filter.query);
    } else { 
      var obj = this.p42Query;
      angular.forEach(filter.query, function (value, key) {
        if (filter.query.hasOwnProperty(key)) {
          obj[key] = value;
        }
      });
    }
    this.$rootScope.p42Query = this.query
    this.dateChange();
  }

  dateChange() {
    const status = ['SUBMITTED', 'APPROVED', 'DECLINED', 'WITHDRAWN'];
    if (this.dateSearch && this.p42Query.status && status.indexOf(this.p42Query.status) >= 0) {
      this.p42Query[this.setPath(this.p42Query.status)] = { $gte : this.p42Controller.startDate, $lte : this.p42Controller.endDate };
    }
    this.$rootScope.p42Query = this.query
  }

  setPath(string) {
    return 'date' + string.charAt(0) + string.slice(1).toLowerCase();
  }

  /**
   * table header Dropdown filtering -backend
  */
  searchBackend() {
    // Ignore searching if backend searching is not enabled
    if (!this.p42BackendSearch) {
      return;
    }
    if(this.queryCopy==undefined)
      this.queryCopy = {}
    var obj = angular.copy(this.queryCopy); //this.p42Query;
    angular.forEach(this.columnFilters, function (filter) {
      if (Object.keys(filter.query).length > 0) {
        angular.forEach(filter.query, function (value, key) {
          if (filter.query.hasOwnProperty(key)) {
            obj[key] = value;
          }
        });
      }
    });

    // factor in text seached
    if (this.p42Search && this.p42Search !== '') { 
      obj.$text = {};
      obj.$text.$search = this.p42Search;
    }

    this.p42Query = obj;
    this.$rootScope.p42Query = this.query
  }

  /**
   * Assigns a new program tab index when navigation occurs, as well as handles initial permission fetching.
   * @param {Number} tab Tab object to change the tab to
   */
  onChangeTab(tab) {
    if (this.currentTab && this.currentTab.name && this.currentTab.name == tab.name) {
      // if the tab is already actice
      return;
    }
    this.currentTab = tab;
    this.p42BeforeTabChange({ tab: tab });
    this.p42Query = tab.query;

    if (this.p42Query === this.previousQuery) {
      // if the query is already active then do not activate again
      return;
    }
    // reset search
    this.p42Search = '';

    // clone init query
    this.queryCopy = angular.copy(this.p42Query);
    // reset filters
    this.columnFilters = {};
    this.applySelectedFilter = '';
    this.dateChange();
    if (this.p42DropdownTabs) {
      this.tabQuery = tab.query;
      this.query = _.merge({}, this.tabQuery, this.selectedFilter.query);
    } else {
      this.query = tab.query;
    }

    this.$rootScope.p42Query = this.query
    // remember the query
    this.previousQuery = _.cloneDeep(this.p42Query);
    // default to the first page when changing tabs
    this.getRecords(1, this.p42Limit, true);
    this.p42TabChange({ tab: tab });

  }


  backendSearch(clearSearch) {
    // Ignore searching if backend searching is not enabled
    if (!this.p42BackendSearch){
      return;
    }

    if (clearSearch) {
      this.query = {}; //changed here
      this.p42Search = '';
    } else {
      this.query.$text = {};
      this.query.$text.$search = this.p42Search;
    }
    this.$rootScope.p42Query = this.query
    this.getRecords(this.p42Page, this.p42Limit, true);
  }


  /**
   * Called when the preview is selected
   */
  onPreviewClick (file, event) {
    // dont read the image if already read before
    if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g) && file.source_image_uri) {
      file.showPhotoDialog(file, event);
    } else { 
      this.p42Store.downloadFile(file, (err) => {
        if (err) console.error(err);
        else console.log('File downloaded successfully');
        
        if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)) {
          file.showPhotoDialog(file, event);
        } 
      });
    }
  }
}

(function(app) {
  app.controller('ListViewCtrl', ListViewCtrl);
  //ng-bind-html filters out ng-click so compile the code instead
  app.directive('compile', ['$compile', function ($compile) {
    return function(scope, element, attrs) {
      scope.$watch(
        function(scope) {
          return scope.$eval(attrs.compile);
        },
        function(value) {
          element.html(value);
          $compile(element.contents())(scope);
        }
      )
    };
  }]);

  app.filter('dropDownFilter', function () {
    return function(records, filter) {
      if (!filter || Object.keys(filter).length < 1) return records;
      var result = [];
      angular.forEach(records, function (record) {
        let containsData = [];
        angular.forEach(filter, function (isfiltered, colIdx) {
          if (isfiltered && isfiltered.name && (record.tableColumnData.indexOf(isfiltered.name) > -1) ) {
            containsData.push(1);
          }
          else if (isfiltered && Object.keys(isfiltered.query) < 1) { // handles displaying of 'All' options 
            containsData.push(1);
          }
          else {
            containsData.push(0);
          }
        });
        const sum = containsData.reduce((a, b) => a + b, 0);
        if (sum == Object.keys(filter).length) { 
          result.push(record);
        }
      });
      return result;
    };
  });

  app.directive('focusMe', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
      //scope: true, // optionally create a child scope
      link: function (scope, element, attrs) {
        var model = $parse(attrs.focusMe);
        scope.$watch(model, function (value) {
          if (value === true) {
            $timeout(function () {
              element[0].focus();
            });
          }
        });
      }
    };
  }]);
}(angular.module('app.listView')));
