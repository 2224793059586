'use strict';

(function (app) {
  app.factory('Batch', function (Resource) {
    return class Batch extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Batch' }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.batch')));
