'use strict';

class AddPenetrationSampleDialogCtrl{

	constructor($scope, $state, $mdToast, $mdDialog, $rootScope, SampleStore) {
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.user = $rootScope.User;
    this.SampleStore = SampleStore;
    this.sample = { type : 'p'};

    if(this.jobs.length > 1) {
      this.job = this.jobs[0];
      this.jobChange();
    }

    this.back = () => {
        $mdDialog.hide();
    };

  }

  updateSampleCount(){
    if (this.numberOfSamples > this.samples.length) {
      let i =0;
      while ( i < this.numberOfSamples - this.samples.length) {
        let newsample = {
          type: this.sample.type[0],
          status: [],
          received: true,
          sample_num: this.sample.type === 'q0' ? 0 :this.job.samples.length +1 + this.samples.length,
        };
        this.samples.push(newsample);
      }
    } else if (this.numberOfSamples < this.samples.length){
      this.samples.splice(this.numberOfSamples, (this.samples.length - this.numberOfSamples));
    } else {
      return
    }
  }

  jobChange() {
    this.samples = [];
    this.numberOfSamples = 1;
    //get jobObject inside batch object
    let batchJobObject = _.find(this.batch.jobs, (batch_job) => {
      if (batch_job.job.toString() == this.job.id.toString()) {
        return true;
      }else {
        return false;
      }
    });
    //filtering out approved labtests for this particularly job
    this.job.labTests = this.job.labTests.filter(labtest => batchJobObject.approvedLabtests.indexOf(labtest.id.toString()) === -1);
    if (this.job.labTests.length > 1) {
      //remove duplicate labtest
      this.job.labTests = _.uniqBy(this.job.labTests, '_id');
      this.job.labTests = this.job.labTests.sort((x,y) => {return x.dependencies.length - y.dependencies.length});
    }
    this.updateSampleCount();
  }

  typeChange() {
    this.samples = [];
    this.numberOfSamples = 1;
    this.updateSampleCount();
  }

  save(){
    this.saveLoading = true;
    for (let i=0;i<this.job.labTests.length;i++) {
      if (this.labtests[i]) {
        // this.sample.status.push({ labTest : this.job.labTests[i].id })
        this.samples.map((sample) => sample.status.push({ labTest : this.job.labTests[i].id }));
      }
    }

    this.SampleStore.createMultiple(this.job.id, this.samples, (err) => {
      this.$state.reload();
      this.$mdDialog.hide();
    })
  };

}

(function (app) {
    app.controller('AddPenetrationSampleDialogCtrl', AddPenetrationSampleDialogCtrl);
  }(angular.module('app.runsheet')));
