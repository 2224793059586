'use strict';

// npm modules
const _ = require('lodash');

/**
 * Controller for the certificate view view
 */
class CustomerFormCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $state, $stateParams, customer, pendingDuplicateCustomerLocation) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    // Setting up the resources for the individual form views
    this.customer = customer;
    this.leads = [{
      name: "Google Search",
    }, {
      name: "Facebook",
    }, {
      name: "NZ Herald Online",
    }, {
      name: "Email/Newsletter",
    }, {
      name: "Existing Client",
    }, {
      name: "MPI",
    }, {
      name: "CBAFF Member",
    }, {
      name: "Other",
    }];
    if (!this.customer.departments || this.customer.departments.length <= 0) {
      this.customer.departments =[null]
    }
    
    // console.log(this);
    if ($rootScope.pendingCustomerIndex >= 0 && 
        customer.pendingCustomerDetails.length > 0 && 
        customer.pendingCustomerDetails[$rootScope.pendingCustomerIndex]) { // render duplicate pending request
      
      // locations will be defined only while viewing pending customer details
      this.originalCustomerRecord = angular.copy(customer);
      this.customer = customer.pendingCustomerDetails[$rootScope.pendingCustomerIndex];
      this.customer.locations = pendingDuplicateCustomerLocation;
      this.duplicateCustomerRecord = angular.copy(this.customer);
      this.viewDuplicateButton = 'View Original Record';
      this.mode = 'duplicate';
      this.button = 'MERGE CUSTOMER RECORD';
    } else {
      this.mode = 'original';
      if (this.customer.status.match(/draft/i)) {
        this.button = 'APPROVE CUSTOMER RECORD';
      } else {
        this.button = 'SAVE CUSTOMER RECORD';
      }
    }

    // Set the initial tab selection
    if (this.customer._id) {
      // if the customer is not new
      this.selectedLocation = $stateParams.location;
      if (this.selectedLocation === 'new') {
        // if the new location is selected then select the last location
        this.selectedLocation = this.customer.locations.length - 1;
      } else if (!this.customer.locations[this.selectedLocation]) { // if the location selected is not new then load any contacts
        // if the location does not exist then set default
        this.selectedLocation = 0;
      }

      // if the location does exist then set up the contacts
      this.selectedContact = $stateParams.contact;
      if (this.selectedContact === 'new') {
        // if the new contact is selected then select the last contact
        this.selectedContact = this.customer.locations[this.selectedLocation].contacts.length - 1;
      } else if (this.customer.locations 
                  && this.customer.locations[this.selectedLocation] 
                  && this.customer.locations[this.selectedLocation].contacts
                  &&  !this.customer.locations[this.selectedLocation].contacts[this.selectedContact]) {
        // if the contact does not exist then set default
        this.selectedContact = 0;
      }
    }
  }

  redirectToOriginal() {
    if (this.mode == 'duplicate') {
      this.customer = this.originalCustomerRecord;
      this.viewDuplicateButton = 'View Duplicate Record';
      this.mode = 'original';
    } else {
      this.customer = this.duplicateCustomerRecord;
      this.viewDuplicateButton = 'View Original Record';
      this.mode = 'duplicate';
    }

    if (this.customer.status.match(/draft/i) && this.mode == 'duplicate' && this.$rootScope.pendingCustomerIndex >= 0) {
      this.button = 'MERGE CUSTOMER RECORD';
    } else {
      this.button = 'SAVE CUSTOMER RECORD';
    }
  }
  
  saveCustomer() {
    this.saving = true;
    const id = this.customer._id;

    let customer = {};
    if (this.$rootScope.pendingCustomerIndex >= 0 && 
      this.mode == 'duplicate') {
                 
      // Merge location and contacts of the duplicate record and with original customer
      let locations = angular.copy(this.customer.locations);      
      _.forEach(locations, (location) => {
        this.originalCustomerRecord.locations.push(location);
      });
      
      this.originalCustomerRecord.pendingCustomerDetails.splice(this.$rootScope.pendingCustomerIndex, 1); 

      if (this.originalCustomerRecord.pendingCustomerDetails.length > 0) {
        this.originalCustomerRecord.status = 'Draft';
      } else {
        this.originalCustomerRecord.status = 'Approved';
      }
      // copy across customer details from the duplicate record
      this.originalCustomerRecord.createdByUserId   = this.customer.createdByUserId;
      this.originalCustomerRecord.creationTime      = this.customer.creationTime;
      this.originalCustomerRecord.freight_forwarder = this.customer.freight_forwarder;
      this.originalCustomerRecord.name              = this.customer.name;
      this.originalCustomerRecord.trading_as        = this.customer.trading_as;
      
      
      customer = this.originalCustomerRecord;
    } else {
      customer = this.customer;
      customer.status = 'Approved';
    }

    customer.save((customerErr, customerData) => {
      if (customerErr) {
        console.error(customerErr);
        this.$rootScope.showToast('Failed to save customer ' + customerData.name);
      } else {
        this.$rootScope.showToast(`Customer "${customerData.name}" saved`);
        console.log(customerData)
        if (!id) {
          // if the customer is new then update the URL with the id
          this.$state.go('customers.edit.customer', { id: customerData._id });
        } else if ( customerData.status == 'Approved' ) {
          location.reload();
        }
      }
      this.saving = false;
    });
  }

  changeLocation(index = 'new') {
    if (index == 'new') {
      // this.$state.go('customers.edit.customer.location', { location: index }, { reload: 'customers.edit.customer.location' });
       // if the location does not exist then load it
       const baseData = {
        name: 'New Location',
        customer: this.customer._id,
        active: true,
        physical: {
          country: 'NZ'
        }
      };

      if (!_.isArray(this.customer.locations)) {
        this.selectedLocation = 0;
        this.customer.locations = [baseData];
      } else {
        this.customer.locations.push(baseData);
        this.selectedLocation = this.customer.locations.length - 1;
      }
    } else { 
      this.selectedLocation = index;
    }

    let stateParam =  { location: this.selectedLocation };
    //default to the first contact if contacts are defined
    if (this.customer && this.customer.locations && 
        this.customer.locations.length > 0 && this.customer.locations[this.selectedLocation] && 
        this.customer.locations[this.selectedLocation].contacts && this.customer.locations[this.selectedLocation].contacts.length > 0) {
        stateParam['contact'] = 0;
        this.$state.go('customers.edit.customer.location.contact', stateParam, { reload: 'customers.edit.customer.location.contact' });
    } else {
      this.$state.go('customers.edit.customer.location', stateParam, { reload: 'customers.edit.customer.location' });
    }    
    // stop the spinner showing because the customer will not reload
    this.$rootScope.spinner = false;
  }

  changeContact(index = 'new') {
    if (index == 'new') {

      // if the contact does not exist then load it
      const baseData = {
        identifier: 'New Contact',
        customer: this.customer._id,
        active: true,
        all_programs: true,
        locations: [this.customer.locations[this.selectedLocation].id]
      };
      // Adding the default contact to locations
      if (!_.isArray(this.customer.locations[this.selectedLocation].contacts)) {
        this.customer.locations[this.selectedLocation].contacts = [baseData];
        this.selectedContact = 0;
      } else {
        this.customer.locations[this.selectedLocation].contacts.push(baseData);
        this.selectedContact = this.customer.locations[this.selectedLocation].contacts.length - 1;
      }
      
    } else {
      this.selectedContact = index;
    }

    this.$state.go('customers.edit.customer.location.contact', { contact: this.selectedContact }, { reload: 'customers.edit.customer.location.contact' });
    
    // stop the spinner showing because the customer and location will not reload
    this.$rootScope.spinner = false;
  }
 
}

(function (app) {
  app.controller('CustomerFormCtrl', CustomerFormCtrl);
}(angular.module('app.customer')));
 
