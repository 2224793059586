'use strict';

/**
 * Controller for the lab assets list view
 */
class LabAssetListCtrl {
  constructor ($filter, LabAssetStore) {
    this.LabAssetStore = LabAssetStore;

    this.fields = [{
      name: 'Asset Identifier',
      path: 'equipment_id'
    }, {
      name: 'Make',
      path: 'product.make'
    }, {
      name: 'Model',
      path: 'product.model'
    }, {
      name: 'Serial',
      path: 'product.serial'
    }, {
      name: 'Location',
      path: 'location.location'
    }, {
      name: 'Maintenance Frequency',
      path: 'maintenance.frequency',
      transform: (value) => {
        return value + ' days';
      }
    }, {
      path: 'maintenance.next_maintenance',
      transform: (value) => {
        return $filter('date')(value, 'dd/MM/yyyy');
      }
    }];
    this.populate = 'location';

    this.sort = {
      equipment_id: 1
    }
  }

  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }

  /**
   * Called when a user deletes an object
   */
  removeLabAsset(labAsset) {
    labAsset.remove(true);
  }
}

(function(app) {
  app.controller('LabAssetListCtrl', LabAssetListCtrl);
}(angular.module('app.labAsset')));
