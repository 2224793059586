'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('permissions', {
      url: '/permissions?tab',
      params: {
        tab: undefined
      },
      reloadOnSearch: false,
      views: {
        '@': {
          templateUrl: '/html/permission/permission-list.html',
          controller: 'PermissionListCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            programs: function (ProgramStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                // if permission exists then load it
                ProgramStore.get({}, (err, programs) => {
                  if (err || programs.length === 0) {
                    // on fail
                    reject(err || 'No program found');
                  } else {
                    // on success
                    resolve(programs);
                  }
                });               
              });
            }            
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Permissions'
      }
    });

    $stateProvider.state('permissions.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/permission/permission-edit.html',
          controller: 'PermissionEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            permission: function ($stateParams, PermissionStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if (!$stateParams.id) {
                  // if permission does not exist create new permission
                  PermissionStore.newResource({}, (permission) => {
                    resolve(permission);
                  });
                } else {
                  // if permission exists then load it
                  PermissionStore.get({ _id: $stateParams.id }, (err, permissions) => {
                    if (err || permissions.length === 0) {
                      // on fail
                      reject(err || 'No permission found');
                    } else {
                      // on success
                      resolve(permissions[0]);
                    }
                  });
                }
              });
            },
            programs: function (ProgramStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                // if permission exists then load it
                ProgramStore.get({}, (err, programs) => {
                  if (err || programs.length === 0) {
                    // on fail
                    reject(err || 'No program found');
                  } else {
                    // on success
                    resolve(programs);
                  }
                });               
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Permission'
      }
    });

  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('Security Config', {
      title: 'Permissions',
      subtitle: 'View and add permissions',
      icon: '/icons/md/hardware/svg/design/ic_security_24px.svg',
      state: 'permissions',
      params: {},
      permission: 'admin',
      items: [{
        title: 'Add Permission',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'permissions.edit',
        params: { id: undefined },
        permission: 'admin'
      }]
    });
    SidenavProvider.addItem('Security Config', {
      title: 'Roles',
      subtitle: 'View and edit roles',
      icon: '/icons/md/social/svg/design/ic_people_outline_24px.svg',
      state: 'roles',
      params: {},
      permission: 'admin',
      items: [{
        title: 'Add Role',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'roles.edit',
        params: { id: undefined },
        permission: 'admin'
      }]
    });

    // SidenavProvider.addItem('APIs', {
    //   title: 'Request Log',
    //   subtitle: 'Real-time API Requests',
    //   params: {},
    //   icon: '/icons/md/action/svg/design/ic_list_24px.svg',
    //   state: 'api.logs',
    //   permission: 'admin'
    // });

    // SidenavProvider.addItem('APIs', {
    //   title: 'Information',
    //   subtitle: 'API Documentation',
    //   params: {},
    //   icon: '/icons/md/action/svg/design/ic_info_24px.svg',
    //   state: 'api',
    //   permission: 'admin'
    // });
  });

}(angular.module('app.permission', [
  'app.core',
  'app.sidenav',
  'app.route'
])));
