'use strict';

/**
 * Controller for the generic form view
 */
class FormViewCtrl {
  
  constructor($location, $anchorScroll, $timeout) {
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    this.$timeout = $timeout;

    // TODO: remove this in favour of transclusion
    this.clicked = false;
    this.defaultSave = false;
  }

  $onInit() {
    // TODO: remove this in favour of transclusion
    if (!this.p42Buttons) {
      // if buttons are not defined then use a save button as default
      this.defaultSave = true;
      this.p42Buttons = [{
        text: 'Save',
        onClickText: 'Saving',
        onClick: this.p42Submit,
        requireValid: true,
        class: 'md-accent',
        loading: () => {
          return this.clicked;
        }
      }];
    }

    // Note: the following will be removed in the future
    if (!this.p42NamedTransclude) {
      // To appease this warning add p42-named-transclude=true to your p42-form-view, then use the slots defined in formView.component.js
      console.warn('Please use named transclusion slots with p42-form-view (opt in with p42-named-transclude).');
    }
  }

  /**
   * Calls a buttons onClick function
   */
  onClick(button) {
    // TODO: remove this in favour of transclusion
    this.clicked = true;

    button.clicked = true;
    button.onClick();

    // TODO: remove this in favour of transclusion
    if (this.defaultSave) {
      // pretend that default saving takes three seconds
      this.$timeout(() => {
        this.clicked = false;
      }, 3000);
    }
  }

  /**
   * Assists the user in finding issues stopping the form from being submitted
   */
  checkForm() {
    if (this.p42Form.$invalid) {
      this.p42Form.$setSubmitted();
      this.$location.hash('top');
      this.$anchorScroll();
    }
  }
}

(function(app) {
  app.controller('FormViewCtrl', FormViewCtrl);
}(angular.module('app.formView')));