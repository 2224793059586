'use strict';

const moment  = require('moment');

(function (app) {
  app.config(function ($stateProvider) {
    // Runsheet list view
    $stateProvider.state('runsheets', {
      url: '/runsheets?tab',
      params: {
        tab: 'incomplete'
      },
      views: {
        '@': {
          templateUrl: '/html/runsheet/runsheet-list.html',
          controller: 'RunsheetListCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            preservatives: function ($rootScope, Statics) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                Statics.get('preservatives', (err, preservatives) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(preservatives);
                });
              });
            }
          },
        }
      },
      ncyBreadcrumb: {
        label: 'Runsheets'
      }
    });

    // Runsheet create view
    $stateProvider.state('runsheets.new', {
      url: '/new/:id',
      views: {
        '@': {
          templateUrl: '/html/runsheet/runsheet-new.html',
          controller: 'RunsheetNewCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            jobID: function ($stateParams, JobStore) {
              return $stateParams.id;
            },
            jobs: function ($stateParams, JobStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                JobStore.get({ phase: 4, runsheets: { $size: 0 }}, { populate: 'samples', sort: { "job_id" : 1 }, fields: "id job_id plant preservative samples" },  (err, jobs) => {
                  if (err) {
                    reject(err || 'No Jobs found');
                  } else {
                    resolve(jobs);
                  }
                });
              });
            },
            jobs_hasRunSheets: function ($stateParams, JobStore) {
                // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if ($stateParams.id ==='all') {
                  JobStore.get({ phase: 4, 'runsheets.0': { $exists: true }}, { populate: 'samples', sort: { "job_id" : 1 } },  (err, jobs) => {
                    if (err) {
                      reject(err || 'No Jobs found');
                    } else {
                      resolve(jobs);
                    }
                  });
                } else {
                  resolve([]);
                }
              });
            },
          }
        },
      },
      ncyBreadcrumb: {
        label: 'Creation'
      }
    });

    // Runsheet edit view
    $stateProvider.state('runsheets.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/runsheet/runsheet.html',
          controller: 'RunsheetCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            // Note: runsheetInfo is an array of the runsheet and its offset
            runsheetInfo: function ($rootScope, $stateParams, RunsheetStore, Runsheet) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if runsheet does not exist create new runsheet
                  RunsheetStore.newResource({}, (runsheet) => {
                    resolve([runsheet, 0]);
                  });
                } else {
                  // if runsheet exists then load it
                  RunsheetStore.get({ _id: $stateParams.id }, { populate: 'qualityChecks_topLeft qualityChecks_topRight qualityChecks_bottomLeft qualityChecks_bottomRight complete_by.contact claimed_by.contact' }, (err, runsheets) => {
                    if (err || runsheets.length === 0) {
                      reject(err || 'No runsheet found');
                    } else {
                      resolve([runsheets[0], '']);
                    }
                  });
                }
              });
            },
            users: function ($rootScope, UserStore) {
              return $rootScope.wrapResolve((resolve, reject) => {
                UserStore.get({}, { populate: 'contact' }, (err,users) => {
                  if (err || users.length === 0) {
                    // on fail
                    reject(err || 'No users found');
                  } else {
                    // on success
                    // users.push({
                    //   id: 'NA',
                    //   _id: 'NA',
                    //   contact: {
                    //     name : "N/A (Not Applicable)"
                    //   }
                    // })
                    // console.log(users);
                    resolve(users);
                  }
                });
              });
            },
            runsheetType: function ($rootScope, RunsheetTypeStore, runsheetInfo) {
              return $rootScope.wrapResolve((resolve, reject) => {
                let runsheetTypeId = runsheetInfo[0].runsheetType;
                RunsheetTypeStore.get({ _id: runsheetTypeId }, { populate: 'labTests.fields rows.cells' }, (err,runsheetTypes) => {
                  if (err || runsheetTypes.length === 0) {
                    // on fail
                    reject(err || 'No RunsheetType found');
                  } else {
                    // on success
                    resolve(runsheetTypes[0]);
                  }
                });
              });
            },
            samples: function ($rootScope, SampleStore, runsheetInfo) {
              return $rootScope.wrapResolve((resolve, reject) => {
                let sampleId = runsheetInfo[0].samples;
                SampleStore.get({ _id: sampleId }, (err, samples) => {
                  if (err || samples.length === 0) {
                    // on fail
                    reject(err || 'No Sample found');
                  } else {
                    // on success
                    resolve(samples);
                  }
                });
              });
            },
            values: function ($rootScope, RunsheetValueStore, runsheetInfo) {
              return $rootScope.wrapResolve((resolve, reject) => {
                let runsheetValueIds = runsheetInfo[0].values;
                RunsheetValueStore.get({ _id: {$in:runsheetValueIds} }, (err,runsheetValues) => {
                  if (err || runsheetValues.length === 0) {
                    // on fail
                    reject(err || 'No RunsheetValue found');
                  } else {
                    // on success
                    resolve(runsheetValues);
                  }
                });
              });
            },
            jobs: function ($rootScope, JobStore, runsheetInfo) {
              return $rootScope.wrapResolve((resolve, reject) => {
                JobStore.get({ runsheets : runsheetInfo[0]._id }, { populate: 'runsheets' }, (err, jobs) => {
                  if (err || jobs.length === 0) {
                    // on fail
                    reject(err || 'No Jobs found');
                  } else {
                    // on success
                    resolve(jobs);
                  }
                });
              });
            },
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('general', {
      title: 'Runsheets',
      icon: '/icons/md/action/svg/design/ic_view_list_24px.svg',
      state: 'runsheets',
      params: {},
      items: [{
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'runsheets.new',
        permission: 'runsheet-make'
      },{
        state: 'runsheets.edit',
        permission: 'state'
      }],
      permission: 'runsheet-view'
    });
  });

}(angular.module('app.runsheet', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView',
  'app.qualityCheckValueView',
  'app.webusb'
])));
