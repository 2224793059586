'use strict';


/**
 * Controller for the Account view
 */
class LabChemicalCtrl {



  constructor ($state) {
    this.$state = $state;
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.labChemical.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('labChemicals');
      }
    });
  }

}

(function(app) {
  app.controller('LabChemicalCtrl', LabChemicalCtrl);
}(angular.module('app.labChemical')));
