'use strict';

const _ = require('lodash');

/**
 * Creates a StaticsStore service that fetches static data from the server and caches it client-side
 */
function Statics($http) {

  class StaticsStore {

    constructor() {
      this.statics = {};
    }

    /**
     * Gets the static corresponding to the key from the server if not already cached client-side
     * and returns it via the callback function
     * @param  {String}   key      The key for the static data to be retrieved from the server
     * @param  {Function} callback A callback function
     */
    get(key, callback) {
      if (_.isNil(key)) {
        return callback(null, undefined);
      }

      let value = this.statics[key];
      
      
      if (!_.isNil(value)) {
        return callback(null, value);
      }

      $http({
        method: 'GET',
        url: '/api/statics/get/' + key
      })
        .then(
          (res) => {
            value = res.data;
            this.statics[key] = value;
            callback(null, value);
          },
          (res) => {
            callback(res);
          }
        );
    }

    /**
     * Gets the static corresponding to the key from the hub if not already cached client-side
     * and returns it via the callback function
     * @param  {String}   key      The key for the static data to be retrieved from the hub
     * @param  {Function} callback A callback function
     */
    getFromHub(key, callback) {
      if (_.isNil(key)) {
        return callback(null, undefined);
      }

      let value = this.statics[key];

      if (!_.isNil(value)) {
        return callback(null, value);
      }

      $http({
        method: 'GET',
        url: '/api/statics/hub/' + key
      })
        .then(
          (res) => {
            value = res.data;
            this.statics[key] = value;
            callback(null, value);
          },
          (res) => {
            callback(res);
          }
        );
    }
  }

  let store = null;

  return (function () {
    if (_.isNil(store)) {
      store = new StaticsStore();
    }
    return store;
  })();
}

(function (app) {
  app.factory('Statics', Statics);
}(angular.module('app.statics')));

