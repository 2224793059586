'use strict';

(function (app) {
  app.factory('Permission', function (Resource) {
    return class Permission extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Permission' }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.permission')));
