'use strict';

const _ = require('lodash');
const moment = require('moment');

/**
 * Controller for the certificate view view
 */
class CustomerFileUploadCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $scope, $state, FileStore, customer) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;

    // Setting up the stores
    this.FileStore = FileStore;
    
    // Setting up the resources for the individual form views
    this.customer = customer;

    // Query to only fetch files for this customer
    this.query = {
      customer: this.customer._id,
    };

    // Setting up the fields to display thumbnails
    this.fields = [
      {
        name: 'File Name',
        tpl:'/file_thumbnail.html'
      }
    ];
  }
}

(function (app) {
  app.controller('CustomerFileUploadCtrl', CustomerFileUploadCtrl);
}(angular.module('app.customer')));
