'use strict';

const _ = require('lodash');

/**
 * Controller for the sidenav directive
 */
class SidenavCtrl {
  
  constructor($rootScope, $state, Sidenav,$timeout, APP_NAME,APP_VERSION) {
    // State to check the current state
    this.$state = $state;
    // bind the rootScope so the User can be accessed
    this.$rootScope = $rootScope;
    // user that permissions are checked against
    this.user = $rootScope.User;
    // setup the title based on the app
    Sidenav.setTitle(APP_NAME);
    Sidenav.setVersion(APP_VERSION);

    //app name
    this._app = APP_NAME;
    
    // groups is a list of groups in string form
    this.groups = Sidenav.getGroups();
    // adds the getter to get the item arrays by
    this.items = Sidenav.getAllItems();
    // header is an object containing the header data for the sidenav
    this.header = Sidenav.getHeader();
    // bind the provider getItems to the controller
    this.getItems = Sidenav.getItems.bind(Sidenav);
    // default opening group index
    this.defaultGroupIndex = -1;
    this.defaultGroupName = '';
    
    // wait for the user to be loaded before checking permissions
    $rootScope.ready.then(() => {  
      // Checking if the group has visible elements
      this.groups = _.map(this.groups, (group) => {
        let visible = _.map(this.items[group], (item) => {

          if(item.state=="users.edit"&&item.params!=undefined )
            item.params.id = this.$rootScope.User.id;

          if(item.state=="customers.edit"&&item.params!=undefined && !this.$rootScope.User.hasPermission('admin'))
            item.params.id = this.$rootScope.User.contact.customer;


          item.visible = true;
          if(item.condition){
            let condition = eval(item.condition);
            
            if(condition==undefined)
              condition = false

            item.visible = condition;
            // console.log(item.condition,condition,$rootScope.User)
          }
          // Create a RegExp to use for highlighting the sidenav
          item.stateRegExp = new RegExp('^'+item.state);

          // Checking if there are permissions to check against
          if (item.permission || item.restriction) {
            item.visible = this.user.hasPermission(item.permission, item.restriction) && item.visible;
            // Checking if the user has the permission
            return item.visible;
          } else {
            // There's no permission needed so show it
            return item.visible;
          }
        });
        
        return {
          name: group,
          visible: visible.includes(true),
        };  
      });

    })

    //find the group name that matches current state
    $rootScope.$on('$stateChangeSuccess', () => { 
      _.forEach(this.groups, (group) => {
        if (group.visible) { 
          _.forEach(this.items[group.name], (item) => {
            if (this.$state.current.name === item.state && item.visible) {
              this.defaultGroupName = group.name;
              return
            } else if ( item.items !== undefined && this.$state.current.name === item.items[0].state && item.visible ) {
              this.defaultGroupName = group.name;
              return
            } else if ( item.items !== undefined && item.items.length > 1  && this.$state.current.name === item.items[1].state && item.visible) {
              this.defaultGroupName = group.name;
              return
            }
          });
        }
        //Iterate the group index that match current index
        let visibles = [];
        for (let i=0;i<this.groups.length;i++) {
          // console.log(this.groups[i])
          if (this.groups[i].visible) {
            visibles.push(i);
            if (this.groups[i].name === this.defaultGroupName) {
              this.defaultGroupIndex = i;
              return;
            }
          }
        }
      })
      
      //if there is no visible group match current index, it would open the first visible group
      if (this.defaultGroupIndex === -1) {
        this.defaultGroupIndex = 0;
      }
    });
    
    //update sidenav badge by groupName index and updated number
    this.$rootScope.$on('sidenav-badge-event', (event, args) => {
      this.updateBadge(args.groupName, args.index, args.number);
    });

  }

  goto() {
    window.location.href="/";
  }
  
  isMatch(currentItem) {  
      if (this.$state.current.name === currentItem.state) {
        return true
      } else if ( currentItem.items !== undefined && this.$state.current.name === currentItem.items[0].state ) {
        return true
      } else if ( currentItem.items !== undefined && currentItem.items.length > 1  && this.$state.current.name === currentItem.items[1].state ) {
        return true
      }
      return false
  }

  updateBadge(groupName,index,number) {
    this.items[groupName][index].badge = number;
  }
  
  isRouteAllowed(state, params) {

    if(this._app === 'ION') //skip everything and go to next state
    {
      this.$state.go(state, params);
      return;
    }

    let exporter = localStorage.getItem( 'exporter');
    
    // just bypassing not overwriting exporter it will not exporter
    if(state=='users.edit' || state=='users' ||state=='api'||state=='exporters' || state=='exporters.edit' || state=='customers.edit' || state=='contacts' || state=='locations')
      exporter = 1;

    if(this.$rootScope.isSideNavVisible || exporter || this.user.hasPermission('admin')) {
      //console.log('inroute')
      this.$state.go(state, params)
    } else {
      this.$rootScope.showToast(`Please Select Exporter First`);
      //console.log('out route')
    }
  }

  // https://www.outsystems.com/blog/flip-60-fps-animations-flip-em-good.html
  toggleGroup(event) {
    // FIRST
    let elem = event.target.parentElement.nextSibling;
    let svg = event.target.children[0];
    if (!elem) {
      // if the target is an icon
      elem = event.target.parentElement.parentElement.nextSibling;
      svg = event.target;
    }
    if (elem.classList.contains('expanded')) {
      // Get initial size and position relative to the viewport
      let collapsed = elem.getBoundingClientRect();

      // LAST
      
      // Apply a class to control transition when element is expanding
      elem.classList.add('transition');
      svg.classList.add('transition');
      // Remove the initial class
      elem.classList.remove('expanded');
      svg.classList.remove('expanded');
      // Add the final class
      elem.classList.add('collapsed');
      svg.classList.add('collapsed');
      // Get final size and position relative to the viewport
      let expanded = elem.getBoundingClientRect();
      
      // INVERT
      
      // Save the values between intial state and final state
      // Use subtractions when manipulating positions to apply in transforms
      let invertedTop = expanded.top - collapsed.top;
      let invertedLeft = expanded.left - collapsed.left;
      // Use divisions when manipulating sizes to apply in scale
      let invertedWidth = expanded.width / collapsed.width;
      let invertedHeight = expanded.height / collapsed.height;

      // PLAY

      // Use transform origin to control the way the animation occurs
      elem.style.transformOrigin = 'top left';
      // Do the magic here, apply your saved values in transform style
      elem.style.transform = 'translate(' + invertedLeft + 'px, ' + invertedTop + 'px) scale(' + invertedWidth + ', ' + invertedHeight + ')';
      // Wait for the next frame so all the styles are changend
      requestAnimationFrame(function(){
        // Add the class to run the transition
        elem.classList.add('transition');
        svg.classList.add('transition');
        // Clear styles
        elem.style.transform = '';
        // On transitionEnd remove the classes used control transitions
        elem.addEventListener('transitionend', function handler(){
          elem.style.transformOrigin = '';
          elem.classList.remove('transition');
          svg.classList.remove('transition');
          elem.classList.remove('collapsing');
          svg.classList.remove('collapsing');
          // Remove the eventListener
          elem.removeEventListener('transitionend', handler);
        });
      }); 
    } else if (elem.classList.contains('collapsed')){
      // Get initial size and position relative to the viewport
      let collapsed = elem.getBoundingClientRect();

      // LAST
      
      // Apply a class to control transition when element is expanding
      elem.classList.add('transition');
      svg.classList.add('transition');
      // Remove the initial class
      elem.classList.remove('collapsed');
      svg.classList.remove('collapsed');
      // Add the final class
      elem.classList.add('expanded');
      svg.classList.add('expanded');
      // Get final size and position relative to the viewport
      let expanded = elem.getBoundingClientRect();
      
      // INVERT
      
      // Save the values between intial state and final state
      // Use subtractions when manipulating positions to apply in transforms
      let invertedTop = collapsed.top - expanded.top;
      let invertedLeft = collapsed.left - expanded.left;
      // Use divisions when manipulating sizes to apply in scale
      let invertedWidth = collapsed.width / expanded.width;
      let invertedHeight = collapsed.height / expanded.height;

      // PLAY

      // Use transform origin to control the way the animation occurs
      elem.style.transformOrigin = 'top left';
      // Do the magic here, apply your saved values in transform style
      elem.style.transform = 'translate(' + invertedLeft + 'px, ' + invertedTop + 'px) scale(' + invertedWidth + ', ' + invertedHeight + ')';
      // Wait for the next frame so all the styles are changend
      requestAnimationFrame(function(){
        // Add the class to run the transition
        elem.classList.add('transition');
        svg.classList.add('transition');
        // Clear styles
        elem.style.transform = '';
        // On transitionEnd remove the classes used control transitions
        elem.addEventListener('transitionend', function handler(){
          elem.style.transformOrigin = '';
          elem.classList.remove('transition');
          svg.classList.remove('transition');
          elem.classList.remove('expanding');
          svg.classList.remove('expanding');
          // Remove the eventListener
          elem.removeEventListener('transitionend', angular.noop);
        });
      });       
    }
  }

  /**
   * Checks to see if the user exists by comparing against an empty object and checking the property length. It is done in here as angular parsing has issues with all of JS's built-in functions.
   * @return {boolean}    Returns a boolean to determine whether there is a user logged in or the user is using a public page.
   */
  userExists() {
    if (Object.is(this.$rootScope.User, {}) || Object.keys(this.$rootScope.User).length === 0) {
      return false;
    } else {
      return true;
    }
  }
}

(function (app) {
  app.controller('SidenavCtrl', SidenavCtrl);
}(angular.module('app.sidenav')));
