'use strict';

/**
 * Controller for the customer edit view
 */
class CustomerEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, customer) {
    this.$state = $state;
    this.customer = customer;
  }

  /**
   * Updates the customer bound to the view
   */
  updateCustomer(id) {
    this.$state.go('customers.edit', { id: id });
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.customer.save((err) => {
      if (err) {
        // on error show the error
        console.error('save customer error:',err);
      } else {
        // on success redirect
        this.$state.transitionTo('customers');
      }
    });
  }
}

(function (app) {
  app.controller('CustomerEditCtrl', CustomerEditCtrl);
}(angular.module('app.customerClient')));
