'use strict';

(function (app) {
  app.factory('Field', function (Resource) {

    return class Field extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'Field' }, data, metaData, callback);
      }
    };

  });
}(angular.module('app.field')));
