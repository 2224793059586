'use strict';

/**
 * Controller for the API documentation view
 */
class APIDocCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor(apiData, $stateParams) {
    this.apiData = apiData;
    // console.log(apiData)
    this.data = {};
    if(apiData!=undefined && apiData.formats != undefined && apiData.formats && 
      apiData.formats.length>1)
      delete apiData.formats[1]
    // setup defaults
    if ($stateParams.format) {
      // if set the use it
     
      this.format = $stateParams.format;
    } else {
      // if not set then default to JSON
      this.format = 'JSON';
    }
    if ($stateParams.version) {
      // if set the use it
      this.version = $stateParams.version;
    } else {
      // if not set then default to last added
      this.version = apiData.versions[apiData.versions.length - 1];
    }
  }

  updateAPI(group,index) {
    this.data = this.apiData.api_data[group][index];
    this.activeGroup = group;
  }

}

(function (app) {
  app.controller('APIDocCtrl', APIDocCtrl);
}(angular.module('app.api')));
