'use strict';

/**
 * Controller for the offline component
 * Controls whether or not the offline message shows
 */
class OfflineCtrl {
  
  constructor(APP_NAME) {
    // get the name to display in the message
    this.appName = APP_NAME;
  }

  /**
   * Set defaults for input values
   */
  $onInit() {
    if (!this.p42Watch) {
      // if p42Watch was not specified default to hiding the offline message
      this.p42Watch = false;
    } else {
      // if p42Watch was specified then set it to true
      this.p42Watch = true;
    }
  }

  /**
   * Gets called when an input binding changes
   */
  $onChanges() {
    this.$onInit();
  }
}

(function (app) {
  app.controller('OfflineCtrl', OfflineCtrl);
}(angular.module('app.realtime')));