'use strict';

(function (app) {
  app.factory('SampleStore', function (Store) {
    const SampleStore = new Store({
      model: 'Sample'
    });

    SampleStore.createMultiple = function (job, samples, callback) {
      let data = {
        job: job,
        samples: samples,
      };

      this._$http({
        method: 'POST',
        url: this.baseRoute + '/createMultiple',
        data: data
      }).then(
        (res) => {
          callback(null, res.data);
        },
        (err) => {
          callback(err);
        }
      );
    }

    SampleStore.deleteSample = function (jobID, runsheetIDs, sample, callback) {
      let data = {
        runsheetIDs: runsheetIDs,
        jobID: jobID,
        sample: sample,
      };

      this._$http({
        method: 'POST',
        url: this.baseRoute + '/deleteSample',
        data: data
      }).then(
        (res) => {
          callback(null, res.data);
        },
        (err) => {
          callback(err);
        }
      );
    }

    SampleStore.updateSample = function (runsheetID, sample, callback) {
      let data = {
        runsheetID: runsheetID,
        sample: sample,
      };

      this._$http({
        method: 'POST',
        url: this.baseRoute + '/updateSample',
        data: data
      }).then(
        (res) => {
          callback(null, res.data);
        },
        (err) => {
          callback(err);
        }
      );
    }

    return SampleStore;
  });

}(angular.module('app.sample')));
