'use strict';

/**
 * Controller for the quality check edit view
 */
class QualityCheckCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope,$scope, $state, qualityCheck, qualityCheckCodes, Statics) {
    this.$state = $state;
    this.qualityCheck = qualityCheck;
    this.qualityCheckCodes = qualityCheckCodes;
    this.user = $rootScope.User;
    $scope.breadcrumb =  qualityCheck.name?qualityCheck.name:'New';

    Statics.get('temperature', (err, temperature) => {
      this.key = temperature;
    });

    this.clearKey = function (){
      this.qualityCheck.key = null;
    }

    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.qualityCheck.revert();
    });
  }




  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.qualityCheck.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('qualityChecks');
      }
    });
  }
}

(function (app) {
  app.controller('QualityCheckCtrl', QualityCheckCtrl);
}(angular.module('app.qualityCheck')));
