'use strict';

(function (app) {
  app.factory('PermissionStore', function (Store) {
    const PermissionStore = new Store({
      model: 'Permission'
    });

    return PermissionStore;
  });

}(angular.module('app.permission')));
