'use strict';

(function (app) {
  app.factory('RunsheetComparisonStore', function (Store, $http) {
    const RunsheetComparisonStore = new Store({
      model: 'RunsheetComparison'
    });

       
    return RunsheetComparisonStore;
  });

}(angular.module('app.runsheetValue')));
