'use strict';

(function (app) {
  app.factory('QualityCheckValueStore', function (Store) {
    const QualityCheckTypeStore = new Store({
      model: 'QualityCheckValue'
    });
    return QualityCheckTypeStore;
  });

}(angular.module('app.qualityCheckValue')));
