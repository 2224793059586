'use strict';

//const _ = require('lodash');

(function (app) {
  app.factory('LabTest', function ($http, Resource) {

    return class LabTest extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'LabTest' }, data, metaData, callback);
      }

      approve (callback) {
        $http({
          method: 'POST',
          'url': '/api/LabTest/approve',
          data: {_id: this.id}
        })
          .then((res) => {
            callback(null, res.data);
          }, (res) => {
            callback(res);
          });
      }

      unapprove (callback) {
        $http({
          method: 'POST',
          'url': '/api/LabTest/unapprove',
          data: {_id: this.id}
        })
          .then((res) => {
            callback(null, res.data);
          }, (res) => {
            callback(res);
          });
      }

      copy (callback) {
        $http({
          method: 'POST',
          'url': '/api/LabTest/copy',
          data: {_id: this.id}
        })
          .then((res) => {
            callback(null, res.data);
          }, (res) => {
            callback(res);
          });
      }

    };

  });
}(angular.module('app.labTest')));
