'use strict';
// Note: the following constants should match the height given in core/client/base.scss
// how high the search input is (must match .select-search .select-search-header height)
const SELECT_SEARCH_INPUT_HEIGHT = 48;
// how high the select search container is (must match .select-search md-content._md max-height)
const SELECT_SEARCH_HEIGHT = 240;

class P42SelectVirtualCtrl {

  /**
   *  Assigns $scope to the controller
   *  @returns {void}   Assigns component bindings to controller binded variables.
   */
  constructor($scope, $timeout, $rootScope) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.MAX_VIRTUAL_HEIGHT = SELECT_SEARCH_HEIGHT - SELECT_SEARCH_INPUT_HEIGHT;
    this.virtualHeight = this.MAX_VIRTUAL_HEIGHT + 'px';
    
  }

  /**
   *  Run once component is instantiated to bind component bindings to controller.
   *  @returns {void}   Assigns component bindings to controller binded variables.
   */
  $onInit () {
    if (this.p42Search) {
      // if search is being used then setup default search variables
      this.searchName = '';
      this.searchData = [];
      this.searchNumbers = 0;
    }
    
    if (!this.p42Format) {
      // if a custom format is not used then use the identity
      this.p42Format = function (arg) {
        return arg;
      };
    }

    if(this.p42Store) {
      if (this.p42Query === undefined) {
        // if the query is not defined then default to all records
        this.query = {};
      } else {
        // if the query is defined then use it
        this.query = this.p42Query;
      }

      this.loading = false;

      this.loading = this.$rootScope.wrapResolve( (resolve, reject) => {
        // Setting up the options
        let options = {
          //limit: this.PAGE_SIZE,
          //skip: this.PAGE_SIZE * page,
          populate: this.populate,
          minLength: this.minLength
        };
        if (typeof this.query !== 'string') {
          // if not searching then then add custom ordering
          options.sort = this.p42Order;
        }

        this.p42Store.search(this.query, options, (err, data, count) => {
          // on fail
          if (err) return reject();
          this.p42dropDown = data;
          resolve();
        });
      });

    }
  }


  /**
   *  Called when the md-select is closed
   *  @returns {void}   Calls the p42OnChange function with the model
   */  
  onSelectClose() {
    this.p42OnChange({model: this.p42Model});
  }

}

(function(app) {
  app.controller('P42SelectVirtualCtrl', P42SelectVirtualCtrl);
}(angular.module('app.core')));
