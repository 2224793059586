'use strict';

import { Certificate } from "crypto";


// constants
const ABSENT = -1;

/**
 * Controller for the customer view view
 */
class CustomerViewCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $scope,$mdDialog, $state,$http, $stateParams,userAttached,UserStore, customertypes,  $window, Statics, customer,/*feesettings invoicehistory*/ LocationStore, ContactStore) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$window = $window;
    this.$http = $http;
    this.userAttached = userAttached;
    this.customertypes = customertypes;

    this.$mdDialog = $mdDialog;
  
    this.UserStore = UserStore;
    this.Statics = Statics;
    this.customer = customer;
    this.choice = false;
    if(!this.customer.customerType)
      this.customer.customerType = "CLT";

    // this.feesettings = feesettings;
    // this.invoicehistory = invoicehistory;
    this.LocationStore = LocationStore;
    this.ContactStore = ContactStore;
    this.$scope.breadcrumb = this.customer.name?this.customer.name:'New';
    this.selectedIndex = $stateParams.index
    
    if(this.customer.fee_profile!=undefined && this.customer.fee_profile &&
      this.customer.fee_profile.length>0)
      this.previousCustomerProfileId = this.customer.fee_profile[(this.customer.fee_profile.length - 1)].profileId

    // console.log(this.customer.fee_profile.length,'constructor')
    if (customer.locations.length > 0) {
      this.location = this.customer.locations[0];
      if (this.location.contacts.length > 0) {
        this.contact = this.location.contacts[0];
      }
      this.loadCountries();

      
      // this.invoiceData = this.invoicehistory;
    }
    
    this.userPopulate = 'roles'
    this.userQuery = {customer : this.customer._id}
    this.userSort = {given_name : -1}
    this.userFields = [{
      path: 'given_name',
      name: 'Name',
      transform(given_name,user){
        let fullName = given_name;
        if(user.surname!=undefined)
          fullName+=' '+user.surname;

        return fullName;
      }
    }, {
      path: 'username',
      name: 'User Name/Email'
    }, {
      path: 'roles',
      name: 'Roles',
      transform: (roles, user) => {
        let roleName = {},roleNames = [],finalRoles = ''

        if(roles!=undefined&&roles && roles.length>0)
        {
          try{
            roles.forEach(function(role,index){
              console
              if(role && role.code!=undefined && role.code)
              {
                roleName[role.name]=1  
              }
            })  
            roleNames = Object.keys(roleName)
            finalRoles = roleNames.join(', ')

          }catch(e)
          {
            
          }
        }
        return finalRoles;
      }
    },{

      path: 'isActive',
      name: 'Status',
      transform: (isActive) => {
        let finalStatus;
        if(isActive===true)
          finalStatus = 'Active';
        else
          finalStatus = 'Inactive';

        return finalStatus;
      }
    }];
      
    this.leads = [
      { name: "Google Search" }, 
      { name: "Facebook" }, 
      { name: "NZ Herald Online" }, 
      { name: "Email/Newsletter" }, 
      { name: "Existing Client" }, 
      { name: "MPI" }, 
      { name: "CBAFF Member" },
      { name: "Other"}
    ];
    this.acceptPhotoTypes = ['image/jpg,image/jpeg,image/png, image/gif'];

    this.detailButtons = [{
      text: this.customer.id?'Save Customer detail':'Create customer', 
      onClick: this.saveCustomer.bind(this),
      class: 'md-accent',
      requireValid: true,
      disabled: this.loading || this.disableCustomerField(),
    }]

    this.sendInvoiceBtn = [{
      text: 'Send Invoice', 
      onClick: this.sendInvoice.bind(this),
      class: 'md-accent',
      // requireValid: true,
      disabled: !this.$rootScope.User.hasPermission('admin'),
    }]

    this.locationButtons = [{
      text: 'Save location',
      onClick: this.saveLocation.bind(this),
      class: 'md-accent',
      requireValid: true,
      disabled: this.loading || this.disableCustomerField()
    }];

    this.contactButtons = [{
      text: 'Save contact',
      onClick: this.saveContact.bind(this),
      class: 'md-accent',
      requireValid: true,
      disabled: this.loading || this.disableCustomerField()
    }]

  }
  $onInit() {

    this.feeProfile = {};
    

    if(this.customer.fee_profile!=undefined && this.customer.fee_profile &&
      this.customer.fee_profile.length>0)
    {
      const currentFeeProfile = this.customer.fee_profile[(this.customer.fee_profile.length - 1)].profileId
      this.feeProfile.profileId = currentFeeProfile
    }
    // console.log(this.customer.fee_profile.length,'on init')

  }

  preview(user){
    if(user.id!=undefined)
      this.$state.go('users.edit', { status: 'Approved' , id: user.id });
    
  }

  sendInvoice() {
    let id = this.customer.id;
    this.$http({
      method: 'GET',
      url: '/api/customer/sendInvoice/' + id,
    }).then((res) => {
      if (res) {
        if (res.data.message!=undefined && res.data.message)
          this.$rootScope.showToast(res.data.message);
        else
        {
          let dataInvoice = res.data;
          this.invoiceData.push(dataInvoice);
        }
      }
    }, (err) => {
      console.log(err);
    });
  }

  approveCustomer() {
    this.customer.status = 'Approved';
    this.customer.save();
    this.$state.go('customers', { status: 'Approved' });
  }

  disableCustomerField() {
    let user = this.$rootScope.User;
    if (user.hasPermission('admin') || user.hasPermission('client-admin') || user.hasPermission('ff-admin') )
      return false
    else
      return true
  }

  saveCustomer() {
    this.loading = true;
    let user = this.$rootScope.User;
    console.log(this.customer);
    
    if (!this.customer.id) {
      if (user.hasPermission('admin'))
        this.customer.status = "Approved";
      else
        this.customer.status = "Draft";

      // console.log('new customer')
      if(this.feeProfile.profileId!=undefined)
      {
        delete this.feeProfile._id
        this.customer.fee_profile[0] = this.feeProfile;
      }
      
      this.customer.createdAt = new Date();
      this.customer.updatedAt = new Date();
      this.customer.createdBy = user._id;
            
      this.customer.save((err , data)=> {
        if (err) {
          console.log('Customer error:',err);
        }
        if (user.hasPermission('admin')) {
          this.$state.go('customers.edit', { id:data.id, status: 'Approved' })
        } else {
          this.$state.go('customers.edit', { id:data.id, status: 'Draft' })
        }
        this.$rootScope.showToast(`Customer is Registered Successfully`);

      });

    } else {
      // console.log(this.feeProfile.profileId,this.previousCustomerProfileId)

      if(this.previousCustomerProfileId!=undefined && this.feeProfile.profileId != this.previousCustomerProfileId)
      {
        this.previousCustomerProfileId = this.feeProfile.profileId
        this.feeProfile.assignedBy = user._id
        delete this.feeProfile._id
        this.customer.fee_profile.push(this.feeProfile);
        // console.log(this.customer.fee_profile,'in profile condition')
      }
      
      if(this.customer.fee_profile.length==0)
      {
        this.feeProfile.assignedBy = user._id
        delete this.feeProfile._id
        this.customer.fee_profile.push(this.feeProfile);
        // console.log(this.customer.fee_profile,'first profile condition')
      }
      // console.log(this.customer.fee_profile.length,'outside save')
      // console.log(this.customer.fee_profile,'full profiles')
      this.customer.save((err,data) => {
        // console.log(data.fee_profile.length,'inside save')
        if (err) {
          console.log('Customer error:',err);
        }
        this.$state.reload();
      });
    }
  }


  
  checkLocationName() {
    let locationNames = this.customer.locations.map(location => location.name);
    locationNames.splice(this.locationIndex,1);
    if (locationNames.indexOf(this.location.name) > -1) {
      this.locationExisted = true;
      this.$scope.locationForm.location.$invalid = true;
      this.$scope.locationForm.$invalid = true;
    } else {
      this.locationExisted = false;
      this.$scope.locationForm.location.$invalid = false;
      this.$scope.locationForm.$invalid = false;
    }
  }
  
  newLocation() {
    this.LocationStore.newResource({ }, (location) => {
      if(this.customer.locations.length == 0)
        location.reportingLocation = true;
      location.customer = this.customer.id;
      this.customer.locations = this.customer.locations.filter(location => location.name);
      this.customer.locations.push(location);
      this.locationIndex = this.customer.locations.length - 1;
      this.location = this.customer.locations[this.customer.locations.length - 1];

      this.contact = {};
      this.loadCountries();
    })
  }
  
  saveLocation() {
    this.loading = true;

    if(this.customer.locations.length == 1)
      this.location.reportingLocation = true;
    this.LocationStore.newResource(this.location, (location) => {
      //new location, save id in customer.locations as well
      
      if(location.reportingLocation)
      {
        // Find if there is any other location that has reportingLocation set to true
        let myDesiredLoc = {};
        for(let i=0;i<this.customer.locations.length;i++)
        {
          let loc = this.customer.locations[i];
          if(loc.reportingLocation && loc._id != location._id){
            myDesiredLoc = loc;
            break;
          }

        }

        if(myDesiredLoc.reportingLocation)
        {
          myDesiredLoc.reportingLocation = false;
          this.LocationStore.newResource(myDesiredLoc, (location1) => {
            location1.save();
          });
        }
      }
      if (!location.id) {
        this.location.createdAt = new Date().toISOString();
        this.location.updatedAt = new Date().toISOString();
        this.location.createdBy = this.$rootScope.User._id;

        location.save((err, location) => {
          
          this.customer.locations = this.customer.locations.filter(location => location.id);
          this.customer.locations.push(location);
          this.saveCustomer();
        })
      } else {
        
        //Updating existing location 
        location.save();
        this.$state.go('customers.edit', {index: this.selectedIndex}, { reload: true });
        // this.$state.reload();
      }
    })
  }
  onLocationChange(){
    if(this.location.reportingLocation)
    // Ask whether the user wants to change the location 
    {
      //Find locations from exisiting locations that has reportingLocation set to true 
      for(let i=0;i<this.customer.locations.length;i++)
      {
        let loc = this.customer.locations[i];
        let check = loc._id == this.location._id;
        if(loc.reportingLocation && !check)
        {
          //Ask the question and then break 
          var confirm = this.$mdDialog.confirm()
          .title(`Customer already has ${loc.name} as Reporting location.Replace that location?`)
          .ok('Yes')
          .cancel('No');

          this.$mdDialog.show(confirm).then(()=> {
            console.log('Yes');
          }, ()=> {
            console.log('No');
            this.location.reportingLocation = false;
          });

        }
      }
    }

  }
  
  newContact() {
    this.ContactStore.newResource({ identifier : ''}, (contact) => {
      contact.customer = this.customer.id;
      this.location.contacts = this.location.contacts.filter(contact => contact.identifier || contact.given_name || contact.surname );
      this.location.contacts.push(contact);
      this.contact = this.location.contacts[this.location.contacts.length - 1];
    })
  }
  
  saveContact() {
    this.loading = true;
    this.ContactStore.newResource(this.contact, (contact) => {
      //new contact, save id in location.contacts as well
      if (!contact.id) {
        if (!contact.identifier) {
          contact.identifier = contact.given_name + ' ' + contact.surname;
        }
        contact.save((err, contact) => {
          this.location.contacts = this.location.contacts.filter(contact => contact.id);
          this.location.contacts.push(contact);
          this.saveLocation();
        });
      } else {
        contact.save();
        this.$state.go('customers.edit', {index: 2}, { reload: true });
        // this.$state.reload();
      }
    })
  }
  
  photoDataUri(value) {
    this.contact.photoUri = value;
  }
  
  sameAddressChanged() {
    if (this.location) {
      if (this.location.postal) {
        // if postal address is used then remove it
        delete this.location.postal;
      } else {
        // if postal address is not used then add it
        this.location.postal = {};
      }
    }
  }
  
  changeLocation(index) {
    this.locationIndex = index;
    this.location = this.customer.locations[index];
    this.contact = this.location.contacts[0];
  }
  
  changeContact(index) {
    this.contact = this.location.contacts[index];
  }
  
  loadCountries () {
    return new Promise ((resolve, reject) => {
      this.Statics.get('countries', (err, data) => {
        if (err) return reject(err);
        this.countries = data;
        resolve(data);
      });
    });
  }
}

(function (app) {
  app.controller('CustomerViewCtrl', CustomerViewCtrl);
}(angular.module('app.customer')));
