'use strict';

/**
 * Controller for the samples list view
 */
class SampleListCtrl {

  constructor($rootScope, $state, $mdMedia, $stateParams, $filter, JobStore, CustomerStore, phases, preservatives) {
    this.$state = $state;
    this.$mdMedia = $mdMedia;
    this.JobStore = JobStore;
    //this.CustomerStore = CustomerStore;

    this.populate = 'samples';
    this.order = { date_last_modified: -1 };
    
   

    this.actionHeader = '/sampleListActionHeader.html';
    
    
    let presOptions = preservatives.map((preservative) => {
      return {
        name: preservative,
        query: { preservative: preservative }
      };
    });
    presOptions = [{
      name: 'Any',
      query: {},
      active: true
    }].concat(presOptions);
    this.preservatives = presOptions;
    

    this.fields = [{
      name: 'Job',
      tpl: '/sampleListJobNumber.html'
    },{
      name: 'hidden Jobs for job number searching',
      hidden: true,
      transform: (record) => {
        return record.job_id;
      }
    },{
      name: 'Client',
      path: 'customerName',
    },{
      path: 'preservative',
      name: 'Preservative',
      enableFilter: true,
      filterOptions: this.preservatives
    },{
      path: 'date_added',
      name: 'Job Added Date',
      transform: (value) => {
        return $filter('date')(value, $rootScope.dateFilter);
      }
    }];
    /*
    ,{
      path: 'phase,samples',
      //name: 'samples',
      //tpl: '/sampleNumbers.html',
      transform: (job) => {
        this.samples = job.samples;
        
        let retentionCounter = 0;
        let penetrationCounter = 0;
        let qcCounter = 0;
        
        for (var i = 0; i < this.samples.length; i++) {
          if (this.samples[i].type === "r") {
            retentionCounter++;
          }
          else if (this.samples[i].type === "p") {
            penetrationCounter++;
          }
          else if (this.samples[i].type === "q") {
            qcCounter++;
          }
        }          
        
       console.log("samplerec------------");

        let samplerec = {
          ret: retentionCounter,
          pen: penetrationCounter,
          qc: qcCounter
        }
        console.log("samplerec", samplerec);

        return samplerec;          
      }
    }];
    */

  this.phases = phases.map((phaseObject) => {
    phaseObject.query = { phase: phaseObject.phase };
    phaseObject.active = $stateParams.tab === phaseObject.slug;
    return phaseObject;
  });
    
  }

  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('job.view', { id: selected.id });
  }
  
  onTabChange(tab) {
    this.$state.go(this.$state.current, { tab: tab.slug }, { notify: false });
  }

  sampleR(samples) {
    var retentionCounter = 0;
    for (var i = 0; i < samples.$parent.record.samples.length; i++) {
      if (samples.$parent.record.samples[i].type === "r") {
        retentionCounter++;
      }
    }
    return retentionCounter;
  }

  sampleP(samples) {
    var penetrationCounter = 0;
    //console.log("samples", samples);
     for (var i = 0; i < samples.$parent.record.samples.length; i++) {
      if (samples.$parent.record.samples[i].type === "p") {
        penetrationCounter++;
      }
    } 
    return penetrationCounter;
  }

  sampleQc(samples) {
    var qcCounter = 0;
     for (var i = 0; i < samples.$parent.record.samples.length; i++) {
      if (samples.$parent.record.samples[i].type === "q") {
        qcCounter++;
      }
    }
    return qcCounter;
  }
}

(function (app) {
  app.controller('SampleListCtrl', SampleListCtrl);
}(angular.module('app.sample')));
