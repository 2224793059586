'use strict';

(function (app) {

  app.config(function ($stateProvider) {

    $stateProvider.state('labAssets', {
      url: '/library/assets',
      views: {
        '@': {
          templateUrl: '/html/labAsset/lab-asset-list.html',
          controller: 'LabAssetListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Assets'
      }
    });

    $stateProvider.state('labAssets.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/labAsset/lab-asset.html',
          controller: 'LabAssetCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            status: function (Statics) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                Statics.get('status', (err, phases) => {
                  // on error
                  if (err) return reject(err);
                  // on success
                  resolve(phases);
                });
              });
            },
            labAsset: function ($stateParams, LabAssetStore, $rootScope) {
              // ui-router requires a promise
              return $rootScope.wrapResolve((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if labAsset does not exist create new labAsset
                  LabAssetStore.newResource({}, (val) => {
                    resolve(val);
                  });
                } else {
                  // if labAsset exists then load it
                  LabAssetStore.get({ _id: $stateParams.id }, { populate: 'location' }, (err, labAssets) => {
                    if (err || labAssets.length === 0) {
                      // on fail
                      reject(err || 'No labAsset found');
                    } else {
                      // on success
                      resolve(labAssets[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Asset'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('library', {
      title: 'Assets',
      icon: '/icons/md/action/svg/design/ic_build_24px.svg',
      state: 'labAssets',
      params: {},
      items: [{
        title: 'Add Asset',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'labAssets.edit',
        params: { id: undefined },
        permission: 'asset-make'
      }],
      permission: 'asset-view'
    });
  });

}(angular.module('app.labAsset', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.labLocation',
  'app.listView',
  'app.formView'
])));
