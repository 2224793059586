'use strict';

/**
 * Controller for the Location edit view
 */
class RunsheetNewCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($scope, $state, $stateParams, $mdDialog, JobStore, RunsheetStore, LabTestStore, RunsheetTypeStore, jobs, jobs_hasRunSheets,jobID,AuditTrailService) {
    this.$scope = $scope;
    this.$state = $state;
    this.jobID = jobID;
    this.JobStore = JobStore;
    this.RunsheetStore = RunsheetStore;
    this.LabTestStore = LabTestStore;
    this.RunsheetTypeStore = RunsheetTypeStore;
    this.selectedJobs = [];
    this.$mdDialog = $mdDialog;
    this.jobs = jobs.concat(jobs_hasRunSheets);
    this.preservative = '';
    this.allpreservative = false;
    this.loading = false;
    this.runsheets = []
    this.AuditTrailService = AuditTrailService;
    

    //Assign selected job in job selector
    if ($stateParams.id !== '' && $stateParams.id !== 'all') {
      this.selectedJobs.push(jobs.filter(job => job.id === $stateParams.id)[0]);
      this.onChange();
    }
  }

  onSampleChange() {
    //Get list of selected and total samples and display in sample message
    let selectedSampleCount = (this.selectedSamples) ? this.selectedSamples.length : 0;
    let totalSampleCount = (this.samples) ? this.samples.length : 0;
    this.sample_message = "Selected (" + selectedSampleCount + "/" + totalSampleCount + ") samples";
  }

  onChange() {
    if (!this.allpreservative) {
      //filtering job by preservative if one of job is selected
      if (this.selectedJobs && this.selectedJobs.length > 0) {
        this.filterJobByPreservative();
      } else {
        this.jobs.forEach(job => job.disabled = false);
        return
      }
    } else {
      this.preservative = "";
      this.jobs.forEach(job => job.disabled = false);
    }
    // Getting the samples
    this.samples = _.flatMap(this.selectedJobs, (job) => {
      _.each(job.samples, (sample) => {
        sample.job_id = job.job_id;
        sample.disabled = sample.status.filter(status => status.received).length === 0
      });
      return job.samples;
    });

    //filtering out the sample with non-labtest received status
    let all_sample_number = this.samples.length;
    this.filtered_samples = this.samples.filter(sample => !sample.disabled);
    let remain_sample_number = this.filtered_samples.length;
    this.sample_message = "Selected (" + remain_sample_number + "/" + all_sample_number + ") samples";
    this.selectedSamples = _.flatMap(this.filtered_samples, '_id');
  }

  filterJobByPreservative() {
    this.preservative = this.selectedJobs[0].preservative;
    this.jobs.forEach(job => {
      job.preservative === this.preservative ? "" : job.disabled = true;
    })
  }

  onCheckChange() {
    this.allpreservative ? "" : this.selectedJobs = [];
    this.preservative = "";
    this.onChange();
  }

  // auditDialog(options)
  // {
  //   this.$mdDialog.show({
  //     clickOutsideToClose: true,
  //     escapeToClose: false,
  //     bindToController: true,
  //     controllerAs: 'vm',
  //     locals: options,
  //     templateUrl: '/html/auditTrail/audittrail-dialog.html',
  //     controller: 'AuditTrailDialogCtrl',
  //   });
  // }

  checkLabTestsBeforeCreate() {
    this.loading = true;
    let labtests = _.flatMap(this.selectedJobs, 'labTests');
    labtests = Array.from(new Set(labtests));
    this.LabTestStore.get({
      "_id": labtests
    }, {}, (err, labtests) => {
      let unapprovedlabtests = labtests.filter(labtest => labtest.status !== 2);
      // console.log(unapprovedlabtests)
      if (unapprovedlabtests.length === 0) {
        this.checkRunSheetTypesBeforeCreate(labtests)
      } else {
        this.$scope.$apply(() => {
          this.labtestsError = "PLEASE APPROVE LABTEST: " + unapprovedlabtests.map(labtest => labtest.unique_name).join(" ");
        });
      }
    });
  }

  checkRunSheetTypesBeforeCreate(labtests) {
    this.RunsheetTypeStore.get({
      "status": 2
    }, {}, (err, runsheetTypes) => {
      let existedLabtests = _.flatMap(runsheetTypes, 'labTests');
      let unExistedLabtests = labtests.filter(labtest => existedLabtests.indexOf(labtest.id) === -1);
      // console.log(unExistedLabtests);
      if (unExistedLabtests.length === 0) {
        this.createMultiple();
      } else {
        this.$scope.$apply(() => {
          this.runsheetTypesError = "NO LABTEST FOUND IN ALL APPROVED RUNSHEET TYPE: " + unExistedLabtests.map(labtest => labtest.unique_name).join(" ");
        });
      }
    });
  }

  createMultiple() {
   
    // Set loading to true
    this.progressbar = true;
    this.loading = true;
    this.RunsheetStore.createMultiple(
      this.selectedRunsheetTypes,
      this.selectedSamples,
      _.map(this.selectedJobs, '_id'), (err, runsheets) => {
        if (err) {
          this.progressbar = false;
          console.log(err);
          //this.$scope.$apply(() => {
          //  this.labtestsError = "LabTest Dependency Error: ";
          //});
        }
        // Set runsheets to the newly created runsheets
        this.runsheets = runsheets;
        this.runsheets.forEach((rsheet,index)=>{
          this.runsheets[index].processCompleted = true;
        })
        this.AuditTrailService.createAuditTrail({
          runsheet:this.runsheets,
          // jobID:this.jobID,             // Change here...Send all the job selected ID's
          jobIDS: _.map(this.selectedJobs, '_id'),
          action:"Create Runsheet",
          entityType : 'runsheet',
          createMultiple:true
        });
        
        // this.loading = false;
        this.progressbar = false;
      });

      

  }
}

(function(app) {
  app.controller('RunsheetNewCtrl', RunsheetNewCtrl);
}(angular.module('app.runsheet')));
