'use strict';

(function (app) {
  app.factory('LabLocation', function (Resource) {

    return class LabLocation extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'LabLocation' }, data, metaData, callback);
      }
    };

  });
}(angular.module('app.labLocation')));
