'use strict';

const pad = require('pad-left');

(function (app) {
  app.factory('History', function ($http, $injector, $mdDialog, $rootScope, Resource) {
    return class History extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'History' }, data, metadata, callback);
      }
      
    };
  });
}(angular.module('app.history')));
