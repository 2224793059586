'use strict';
(function (app) {
    app.config(function ($stateProvider) {
        $stateProvider.state('settings', {
            url: '/settings',
            views: {
            '@': {
                templateUrl: '/html/setting/setting-dialog.html',
                controller: 'SettingDialogCtrl',
                controllerAs: 'vm',
                bindToController: true
            }
            },
            ncyBreadcrumb: {
            label: 'Settings'
            }
        });
    });
    app.config(function (SidenavProvider) {
        SidenavProvider.addItem('Settings', {
         title: 'My Profile',
         subtitle: 'View and edit profile',
         icon: '/icons/md/social/svg/design/ic_person_24px.svg',
         state:'users.edit',
         params: { id: undefined },
         // permission: 'admin'
        });
        SidenavProvider.addItem('Settings', {
        title: 'Users',
        subtitle: 'View and edit users',
        icon: '/icons/md/social/svg/design/ic_group_24px.svg',
        state: 'users',
        condition: '($rootScope.User.hasPermission("admin") || $rootScope.User.isUserAdmin)',
        params: {
          status: 'Approved',
        },
        // permission: 'user-view',
        items: [{
          title: 'Add User',
          icon: '/icons/md/content/svg/design/ic_add_24px.svg',
          state: 'users.edit',
          params: { id: 'new' },
          permission: 'admin'
          }]
        });
        SidenavProvider.addItem('Settings', {
          title: 'Settings',
          subtitle: 'Settings',
          icon: '/icons/md/action/svg/design/ic_settings_24px.svg',
          state: 'settings',
          permission: 'admin'
        });

    });
}
(angular.module('app.setting', [
   require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics'
])));
