'use strict';

(function (app) {
  app.factory('ContactStore', function (Store, $http) {
    const ContactStore =  new Store({
      model: 'Contact',
      realtime: false,
      ignoreMeta: true
    });

    // Setting the baseroute
    ContactStore.baseRoute = '/api/customer/contact';
    
    return ContactStore;
  });

}(angular.module('app.customerClient')));
