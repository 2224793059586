'use strict';

/**
 * Controller for the Location edit view
 */
class FieldCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope,$scope, $state, field) {
    this.$state = $state;
    this.field = field;
    this.user = $rootScope.User;
    console.log(this.user);
    $scope.breadcrumb =  field.name?field.name:'New';
    
    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.field.revert();
    });

  }

  selectChanged() {
    if (this.field.input_type === "N") {
      this.field.runsheet_visibility = false;
      this.field.report_visibility = false;
      delete this.field.decimal_places;
      delete this.field.detection_limit;
    } else if (this.field.input_type === "C") {
      this.field.runsheet_visibility = true;
    }
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.field.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('fields');
      }
    });
  }
}

(function (app) {
  app.controller('FieldCtrl', FieldCtrl);
}(angular.module('app.field')));
