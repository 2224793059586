'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('fields', {
      url: '/library/fields',
      views: {
        '@': {
          templateUrl: '/html/field/field-list.html',
          controller: 'FieldListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      resolve: {
        labTestsSelector: function (LabTestStore) {
          // ui-router requires a promise
          return new Promise((resolve, reject) => {
            LabTestStore.get({ }, { }, (err, labtests) => {
              if (err) {
                reject(err || 'No labtests found');                        
              } else {
                let types = [];
                types.push({
                  name: 'All labtests',
                  query:{},
                  slug: 'all'
                })
                _.forEach(labtests, (labtests) => {
                  types.push({
                    name: labtests.unique_name,
                    query:{ '_id' : { $in: labtests.fields } },
                    slug: labtests.unique_name
                  })
                })
                resolve(types);
              }
            });
          });
        }
      },
      ncyBreadcrumb: {
        label: 'Fields'
      }
    });
    $stateProvider.state('fields.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/field/field.html',
          controller: 'FieldCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            field: function ($stateParams, FieldStore, Field) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if field does not exist create new field
                  FieldStore.newResource({}, (val) => {
                    resolve(val);
                  });                  
                } else {
                  // if field exists then load it
                  FieldStore.get({ _id: $stateParams.id }, (err, fields) => {
                    if (err || fields.length === 0) {
                      // on fail
                      reject(err || 'No field found');
                    } else {
                      // on success
                      resolve(fields[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: '{{ breadcrumb }}'
      }
    });
  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('management', {
      title: 'Fields',
      icon: '/icons/md/editor/svg/design/ic_text_fields_24px.svg',
      state: 'fields',
      params: {},
      items: [{
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'fields.edit',
        params: { id: undefined },
        permission: 'field-make',     
      }],
      permission: 'management-buttons'
    });
  });

}(angular.module('app.field', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
