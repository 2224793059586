'use strict';

const _ = require('lodash');
const moment = require('moment');

/**
 * Controller for the certificate view view
 */
class CustomerFileListCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($stateParams, FileStore, $state, $rootScope) {
    // Setting up the stores
    this.FileStore = FileStore;
    this.$rootScope = $rootScope;
    this.customer = {
      id: $stateParams.id
    };
    this.$state =  $state;
    // Query to only fetch files for this customer
    this.query = {
      customer: this.customer.id,
    };
    
    // Setting up the fields to display thumbnails
    this.fields = [
      { 
        name: 'Preview',
        //tpl:  '/html/fileShared/file-preview.html'
        transform: (file) => {
          return '<div layout="row">'+
                    '<a href="" ng-click="vm.onPreviewClick(record, $event)">' +
                      '<img class="show-transparency file-preview" flex="none" src="'+ file.data_uri +'" alt="'+file.filename+'"/>'+
                    '</a>'+
                  '</div>';
          
        }
      }, {
        path: 'name',
        name: 'Filename'
      }, {
        path: 'extension',
        name: 'Extension',
        hideXs: true
      }, {
        path: 'description',
        name: 'Description'
      }
    ];
  }

  removeFile(file) {
    file.__meta.options.baseRoute += '/delete';
    file.remove(true, (err, res) => {
        this.$rootScope.showToast(`${ file.filename } successfully deleted`);
        this.$state.reload();
    });
  }

  addFile(value) {
    //this.$state.go('customers.edit', {index: 3});
    this.$state.reload();
  }

  onRecordChange(records) {
    this.records = records;
  }
  
  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }
  disableCustomerField() {
    let user = this.$rootScope.User;
    if (user.hasPermission('admin') || user.hasPermission('client-admin') ||user.hasPermission('ff-admin'))
      return false
    else
      return true
  }


}

(function (app) {
  app.controller('CustomerFileListCtrl', CustomerFileListCtrl);
}(angular.module('app.customer')));
