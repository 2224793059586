'use strict';

/**
 * Component that displays a message when the user is offline
 *
 * p42Watch: Boolean to watch for when the site goes offline
 */
(function (app) {
  app.component('p42Offline', {
    restrict: 'E',
    controller: 'OfflineCtrl',
    controllerAs: 'vm',
    bindings: {
      p42Watch: '<'
    },
    templateUrl: '/html/realtime/offline.component.html'
  });
}(angular.module('app.realtime')));