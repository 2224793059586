'use strict';

/**
 * Controller for the customers list view
 */
class CustomerListCtrl {

  constructor (CustomerStore) {
    this.CustomerStore = CustomerStore;
    this.populate = 'locations locations.services';
  }
  
  $onInit() {
    this.fields = [{
      path: 'name',
      name: 'Name'
    }, {
      path: 'locations',
      name: 'Region',
      //Template for the tooltip over the house
      tpl: '/region.html'
    }, {
      path: 'locations',
      name: 'Contact',
      transform: (value) => {
        // transform the array of contacts into a CSV of locations
        let contacts = value.map(l => l.contacts)[0][0];

        if(contacts) {
          let returnStatement = contacts.given_name + ' ' + contacts.surname;
          returnStatement = (contacts.phone) ? returnStatement + ' - ' + contacts.phone: returnStatement ;
          return returnStatement;
        }
        return '';
      }
    }, {
      path: 'locations',
      name: 'Services',
      transform: (value, record) => {
        //Retrieve first index as the obj is 0 -> 0
        let services  = value.map(l => l.services)[0];
        if(services) {
          let serviceCodes = [];
          for(var i = 0; i < services.length; i++) {
            serviceCodes.push(`${ services[i].name } (${ services[i].code })`);
          }
          return (serviceCodes && serviceCodes.length > 0) ? serviceCodes.join(', ') : '' ;
        }
        return '';
      }
    }]
  }

  /**
   * Deletes the selected row and refreshes the page.
   *
   * @params {Object} Parent selected element (whole row from list-view)
   * @returns {void}
   */
  deleteCustomer(selected) {
    this.CustomerStore.remove(selected, (err, result) => {
      if (err) console.log('delete customer error',err);
    });

  }

  /**
   * Called when a document is selected
   */
  onClick(selected) {
    this.lastClicked = selected;
  }
}

(function(app) {
  app.controller('CustomerListCtrl', CustomerListCtrl);
}(angular.module('app.customerClient')));
