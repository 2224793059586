'use strict';

/**
 * Controller for the Dashboard view
 */
class DashboardCtrl {

  constructor($scope, $timeout, $window, $http, $q, $cookies, $mdDialog, $rootScope, $mdMedia, UserStore, SubscriptionStore, BlogPostStore/*, BlogStore*/, subscriptions) {
    this.$scope = $scope;
    this.$http = $http;
    this.$q = $q;
    this.$cookies = $cookies;
    this.$window = $window;
    this.$mdMedia = $mdMedia;
    this.subscriptions = subscriptions;
    this.$window = $window;
    this.BlogPostStore = BlogPostStore;
    this.$timeout = $timeout;
    this.$mdDialog = $mdDialog;
    this.user = $rootScope.User;
    
    
    $scope.$watch(this.watchMediaXs.bind(this), this.changeMediaXs.bind(this));
    $scope.$watch(this.watchMediaSm.bind(this), this.changeMediaSm.bind(this));

    this.query = {
      order: {date_created: -1},
      limit: parseInt($cookies.get('query-limit')) || 1,
      page: 1
    };

    this.posts = [];
    this.currentPostIndex = 0;
    this.maxCount = 1;

    //Check if the user has permissions to access the blog
    if ($rootScope.User.permissions.indexOf('blog-view') > -1) { 
      this.getBlogs (this.query.page, this.query.limit);
    }

    // Check if the user has permissions to access the calendar
    if ($rootScope.User.permissions.indexOf('calendar-view') > -1) { 
      this.calendarView = true; 
    }
    else {
      this.calendarView = false; 
    }
  }

  $onInit() {
    this.initTiles();
  }

  watchMediaSm() {
    return this.$mdMedia('sm');
  }

  changeMediaSm(isSm) {
    this.$scope.mediaSm = isSm;
  }

  watchMediaXs() {
    return this.$mdMedia('xs');
  }

  changeMediaXs(isXs) {
    this.$scope.mediaXs = isXs;
  }

  initTiles() {
    // Loop over logged in users subscriptions
    this.programs = [];
    this.expiredPrograms = [];

    // when duplicate subscriptions exist (removed true and false) system creates duplicates which breaks program icon load
    // this prevents duplicates in program list
    this.unique = []; 

    for (let sub of this.subscriptions) {

      // make sure exists
      if (sub) {
        if (!sub.program.visible || this.unique.includes(sub.program.code)) {
          // if the program should not be shown on the dashboard then skip it
          continue;
        }
        if (sub.program.background && sub.program.background.hasOwnProperty('data_uri')) {
          // if there is a background then show it via CSS
          sub.program.background.css_link = 'url(' + sub.program.background.data_uri + ')';
        }
        if (!sub.active || sub.program.disabled) {
          // if program is not active disable it
          sub.program.disabled = true;
          sub.program.color = 'grey';
        }
        // add program to the dashboard
        this.programs.push(sub.program);
        this.unique.push(sub.program.code);
      }
    }
  }


  visitProgram(program) {
    if (!program.disabled) {
      // if the program is enabled
      let link = program.link;
      if (!program.no_login) {
        // if the program can use Hub login then add token
        link += '?token=' + this.user.token;
      }

      this.$http.get('/api/user/logout');
      this.$window.location.href = link;

     
    }
  }

  renderMarkdown(post, preview) {
    // incase there was a rendering issue with undefined HTML
    let content = post.content;
    if (!content) {
      content = '';
    }

    // If the post is preview (not single view), substring the results to avoid long entries
    if (this.type === 'preview' || preview) {        
      content = (content.length > 250) ? content.substring(0,247) + '...' : content;
      content = this.$window.markdownit().render(content);
    } else {
      content = this.$window.markdownit().render(content);
      // transclude images into the blog if not being previewed
      if (post.imageUriS && post.imageUriS.length > 0) {
        
        post.imageUriS.forEach((img, index) => {
          if (img) {
            let stringReplace = '[Image-'+index +']';
            content =  content.replace(stringReplace, '<br/><img src=\"'+ img + '\"/>');
          }
        });
      }
    }
   
    //add a line break after • to show a pretty output
    content = content.replace(/•/g, '</br>•');
    return content;
  }

  getBlogs (page, limit) {
    
    if (page > 0 && !this.posts[page] && this.currentPostIndex < this.maxCount ) {
      this.loading = new Promise((resolve, reject) => {
        this.BlogPostStore.get( {}, 'author author.contact', limit, limit * (page -1), this.query.order, (err, post, count) => {
          if (err) { // on fail
            reject(err);
          } else {
            //persistant data
            this.$timeout(function nextDigest() {
              // update data on the next digest so the template shows changes
              this.query.page = page;
              this.query.limit = limit;
              this.posts.push(post[0]);
              this.maxCount = count; 
              resolve(post);
            }.bind(this));
          }
        });
      });
    } else if (this.currentPostIndex >= this.maxCount) {
      this.currentPostIndex = 0;   // reset
    }
  } 


  openPost($event,post) { 
    post.display = this.renderMarkdown(post, false).toString();
    this.$mdDialog.show({
      controller: ['$scope', '$mdDialog', function($scope, $mdDialog) {
        $scope.post = post;
        console.log(post)
        $scope.hide = function() {
          $mdDialog.hide();
        };
    
        $scope.cancel = function() {
          $mdDialog.cancel();
        };   
      }],
      template: '<md-dialog style="max-width:700px;">'+
                    '<img align="center" style="max-width: 800px; max-height: 300px; zoom: 90%" src="/assets/ivsblog.png"/> '+
                 
                    '<md-dialog-content style=\"padding:10px;word-break: break-word;  color: #434547;  font-family: Verdana, Geneva, sans-serif;font-size: 15px; line-height: 150%; text-align: left;\"> '+
                      '<h1 style="color: #0099cc !important;" > Welcome </h1>'+
                      '<h2 style="color: #0099cc !important;" > {{ post.title }} </h2> <small> {{post.dateCreated |date}} By {{ post.author.contact.name}} </small> <hr/>'+
                      '<div ng-bind-html=\"post.display\" ></div>'+ 
                    ' </md-dialog-content>' + 
                  '<md-dialog-actions layout=\"row\">' +
                    '<md-button ng-click=\"hide()\">' +
                    'Close' +
                    '</md-button>' +
                  '</md-dialog-actions>'+                
                '</md-dialog>',
      targetEvent: $event,
      clickOutsideToClose:true,
      fullscreen: false // Only for -xs, -sm breakpoints.
    });
  }
}


(function(app) {
  app.controller('DashboardCtrl', DashboardCtrl);
}(angular.module('app.singleSignOn')));
