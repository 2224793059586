'use strict';

(function (app) {
  app.factory('ProgramService', function ($http, $injector, $mdDialog, Resource) {
    return class ProgramService extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'ProgramService' }, data, metadata, callback);
      }
    };
  });
}(angular.module('app.programservice')));
