'use strict';

(function (app) {
  app.factory('ReportStore', function ($http, Store,$window) {
    const ReportStore = new Store({
      model: 'Report'
    });

    ReportStore.generatePDF = (report, html, callback) => {
      let data = {
        report: report,
        html: html,
      };
      
      $http.post('/api/Report/generatePDF', data, {responseType:'arraybuffer'})
      .then(
        (res) => {
          var file = new Blob([res.data], {type: 'application/pdf'});
          var fileURL = window.URL.createObjectURL(file);
          var fileName = 'AnalysisReport_R' + report.job.job_id + '-T' + report.job.job_id + '-' + report.job.charge_number;
          var a = document.createElement("a");
          a.href = fileURL;
          a.download = fileName;
          a.click();
          callback();
        },
        (res) => {
          callback(res);
        }
      );
    };

    ReportStore.downloadPDF = (report, callback) => {
      const _id = report._id;
      
      return $http.get('/api/report/downloadPDF/' + _id)
        .then(
          (res) => {
            const fileName = 'AnalysisReport_R' + report.job.job_id + '-T' + report.job.job_id + '-' + report.job.charge_number + '.pdf';
            const binaryString = atob(res.data);
            const dataArray = new Uint8Array(binaryString.length);
            for (let b = 0; b < binaryString.length; b++) {
              // for each byte of data get the raw data
              dataArray[b] = binaryString.charCodeAt(b);
            }
            // create blob container for file data
            const blob = new Blob([dataArray], { type: 'application/pdf' });
            // now download the blob
            if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
              // if on a Microsoft browser
              $window.navigator.msSaveBlob(blob, fileName);
            } else {
              // if on a non-Microsoft browser then setup download
              const objectUrl = $window.URL.createObjectURL(blob);
              // create download anchor
              const element = $window.document.createElement('a');
              element.href = objectUrl;
              element.download = fileName;
              document.body.appendChild(element);
              // activate the download
              element.click();
              // clean up
              document.body.removeChild(element);
              $window.URL.revokeObjectURL(objectUrl);
              callback();
            }
          },
          (res) => {
            callback(res);
          }
        );
    };
    
    ReportStore.approve = (report, callback) => {
      let data = {
        id: report.id,
      };
      
      $http.post('/api/Report/approve', data)
        .then(
          (res) => {
            callback(null, res.data);
          },
          (res) => {
            callback(res);
          }
        );
    };

    ReportStore.unapprove = (report, callback) => {
      let data = {
        id: report.id,
      };
      
      $http.post('/api/Report/unapprove', data)
        .then(
          (res) => {
            callback(null, res.data);
          },
          (res) => {
            callback(res);
          }
        );
    };

    ReportStore.updateReport = (report, callback) => {
      let data = {
        id: report.id,
        customerName: report.customerName,
        site: report.site,
        // line1: report.line1,
        // line2: report.line2,
        // line3: report.line3,
        // line4: report.line4,
        address:report.address,
        date_registered: report.date_registered,
        date_analyzed : report.date_analyzed,
        date_reported: report.date_reported,
        ianz: report.ianz,
        comments: report.comments,
        summary_method_field: report.summary_method_field
      };
      if(!report.pdf)
        data.pdf = report.pdf;
      //console.log("report.store --> data:", data);

      $http.post('/api/Report/updateReport', data)
        .then(
          (res) => {
            callback(null, res.data);
          },
          (res) => {
            callback(res);
          });
    };
    
    return ReportStore;
  });

}(angular.module('app.report')));
