'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('labLocations', {
      url: '/library/locations',
      views: {
        '@': {
          templateUrl: '/html/labLocation/lab-location-list.html',
          controller: 'LabLocationListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Locations'
      }
    });

    $stateProvider.state('labLocations.edit', {
      url: '/edit/:id',
      params: {
        id: 'new'
      },
      views: {
        '@': {
          templateUrl: '/html/labLocation/lab-location.html',
          controller: 'LabLocationCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            labLocation: function ($stateParams, LabLocationStore, LabLocation) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if ($stateParams.id === 'new') {
                  // if labLocation does not exist create new labLocation
                  LabLocationStore.newResource({}, (val) => {
                    resolve(val);
                  });
                } else {
                  // if labLocation exists then load it
                  LabLocationStore.get({ _id: $stateParams.id }, (err, labLocations) => {
                    if (err || labLocations.length === 0) {
                      // on fail
                      reject(err || 'No labLocation found');
                    } else {
                      // on success
                      resolve(labLocations[0]);
                    }
                  });
                }
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Location'
      }
    });
  });

  // app.config(function (SidenavProvider) {
  //   SidenavProvider.addItem('library', {
  //     title: 'Locations',
  //     icon: '/icons/md/communication/svg/design/ic_location_on_24px.svg',
  //     state: 'labLocations',
  //     params: {},
  //     items: [{
  //       title: 'Add Location',
  //       icon: '/icons/md/content/svg/design/ic_add_24px.svg',
  //       state: 'labLocations.edit',
  //       params: { id: undefined },
  //       permission: 'admin-a'     
  //     }],
  //     permission: 'admin-a'     
  //   });
  // });

}(angular.module('app.labLocation', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.listView',
  'app.formView'
])));
