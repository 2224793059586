'use strict';

const _ = require('lodash');

const jsDiff = require('diff');

const stringSplice = function stringSplice(str, index, count, add = '') {
  if (!str) str = '';
  return str.slice(0, index) + add + str.slice(index + count);
}

/**
 * The Resource service returns a class which constructs a realtime resource object
 */
function Resource ($http, $rootScope, $mdDialog, $injector, SocketIO, Store) {

  class ResourceService {
    constructor(options, data, metadata) {
      // Setup Meta
      this.__meta = {
        options: {
          // if using options to define the model
          model: options.model,        
          // if baseRoute option is set then use that otherwise use the model name
          baseRoute: '/api/' + (options.baseRoute ? options.baseRoute : options.model),
          // if realtime option is set then use that otherwise default to enabled
          realtime: options.realtime !== undefined ? options.realtime : true,
          ignoreUpdates: false,
        },
        refs: metadata.refs,
        paths: metadata.paths,
      };

      // Setup the data
      this._setupStructure(data);
      
      if(this.__meta.options.realtime) {
        this._setupRealTime();
      }

      return this;
    }

    clone() {
      let cloneData = _.cloneDeep(this);
      if (this.__meta.options.realtime) {
        // We need to rebind the SocketIO functions
        cloneData._setupRealTime();
      }
      return cloneData;
    }
    
    /**
     * Sets up the realtime functions for the resource
     */
    _setupRealTime() {
      SocketIO.emit('model.subscribe', { id: this._id });

      SocketIO.on(this._id + ':update', this._realTimeUpdate.bind(this));

      SocketIO.on('disconnect', () => {
        SocketIO.once('reconnect', () => {
          SocketIO.emit('model.subscribe', { id: this._id });          
          //SocketIO.emit('version.check', { id: this._id, version: this.version, 'modelName': this._Model }, this._populate.bind(this));
        });
      });
    }

    /**
     * Updates this Resource with changes being made in real time
     * Using the History module to store diffs improves realtime functionality
     * @param {Object} data Data to update with (this may come in the forms of diffs or full data)
     */
    _realTimeUpdate (data) {
      // Modifying data from Socket.IO event
      if (this.__meta && this.__meta.options.ignoreUpdates === false) {
        // if opted-in to realtime updates
        const store = $injector.get(this.__meta.options.model + 'Store');
        store._depopulateObject(this, store.refs, function depopulate(error, populatedData) {
          // copy the updated data to the Resource
          if (this.__meta.paths && this.__meta.paths.history) {
            // if using the history module to optimise realtime
            if (this.history_version != data.doc_version) {
              // if there are changes to make
              this.history_version = data.doc_version;
              // Checking if this document has been deleted
              if (data.diff && data.diff.removed && data.diff.removed.new) {
                // Remove the resource
                this._deconstruct();
                // since the resource has been removed skip updating
                return;
              } else {
                this._applyDiff(data);
              }
            }
          } else {
            // if using realtime without individual diffs
            _.assign(this, data);
          }
          // add any populated fields
          // TODO: This could be improved by recalculating which fields need to be populated.
          store._repopulateObject(this, populatedData);
          // make a digest cycle run
          if (!$rootScope.$$phase) {
            $rootScope.$apply();
          }
          this.emit('update', this);

          // Firing onUpdateEvents
          _.each(this.__meta.onUpdateEvents, (e) => {
            e(data);
          });
        }.bind(this));
      }
    }

    _applyDiff(data) {
      // Data
      this._traverseDiff(this, this.__meta.shadow, data.diff);
    }
    
    _traverseDiff(obj, shadow, diff) {
      _.each(diff, (value, key) => {
        if(value[0] && value[0].type === 'SPLICE') {
          // Splicing the String
          let original = _.clone(shadow[key]);
          
          let clientClone = _.clone(obj[key]);
          
          let lastIndex = 0;
          if(_.isArray(obj[key])) {
            // if the field is an array then add the values
            _.each(value, (v) => {                
              if(v.new) {
                Array.prototype.splice.apply(shadow[key], [v.index, v.count].concat(v.new));
              } else {
                Array.prototype.splice.apply(shadow[key], [v.index, v.count]);
              }
            });
          } else {
            // if the field is not an array then assign normally
            _.each(value, (v) => {                
              shadow[key] = stringSplice(shadow[key], v.index, v.count, v.new);
            });
          }
          
          let clientDiff = [];
          let shadDiff = [];

          if(_.isArray(obj[key])) {
            clientDiff = jsDiff.diffArrays(original, clientClone, {
              comparator: (left, right) => {
                return _.isEqual(left, right);
              }
            });
            shadDiff = jsDiff.diffArrays(original, shadow[key], {
              comparator: (left, right) => {
                return _.isEqual(left, right);
              }
            });
          } else {
            clientDiff = jsDiff.diffChars(original, clientClone);
            shadDiff = jsDiff.diffChars(original, shadow[key]);
          }
          
          let i = 0;
          _.each(clientDiff, (d) => {
            d.index = i;
            if(!d.removed) i += d.count;
            if(d.added) d.count = 0;
          });

          i = 0;
          _.each(shadDiff, (d) => {
            d.index = i;
            if(!d.removed) i += d.count;
            if(d.added) d.count = 0;
          });

          clientDiff = _.reverse(clientDiff);
          shadDiff = _.reverse(shadDiff);
          
          // Filter
          clientDiff = _.filter(clientDiff, (d) => {
            return (d.added || d.removed);
          }) || [];
          
          shadDiff = _.filter(shadDiff, (d) => {
            return (d.added || d.removed);
          }) || [];            
          
          let newDiff = [];
          
          let stringIndex = obj[key].length + shadow[key].length;

          // Combining diffs
          while((clientDiff.length > 0 || shadDiff.length > 0) && stringIndex >= 0) {
            if(shadDiff.length > 0 && shadDiff[0].index >= stringIndex) {
              let sDiff = shadDiff.pop();
              sDiff.shadow = true;
              newDiff.push(sDiff);
            }
            if(clientDiff.length > 0 && clientDiff[0].index >= stringIndex) {
              let cDiff = clientDiff.pop();
              cDiff.client = true;
              newDiff.push(cDiff);
            }

            stringIndex = Math.min((shadDiff[0]) ? shadDiff[0].index : stringIndex,
                                   (clientDiff[0]) ? clientDiff[0].index : stringIndex);              
          }
          
          let clientOffset = 0;
          let shadOffset = 0;

          newDiff = _.sortBy(newDiff, ['index', 'removed']);

          // TODO: Refactor this into a nicer and cleaner function
          if(_.isArray(obj[key])) {
            _.each(newDiff, (v) => {
              if(v.added) {
                if(v.client) {
                  Array.prototype.splice.apply(original, [v.index + shadOffset, 0].concat(v.value))
                  clientOffset += v.count;
                } else {
                  Array.prototype.splice.apply(original, [v.index + clientOffset, 0].concat(v.value))
                  shadOffset += v.count;
                }
              } else if(v.removed) {
                if(v.client) {
                  Array.prototype.splice.apply(original, [v.index + shadOffset, v.count])
                  clientOffset -= v.count;
                } else {
                  Array.prototype.splice.apply(original, [v.index + clientOffset, v.count])
                  shadOffset -= v.count;
                }
              }
            });
          } else {
            _.each(newDiff, (v) => {
              if(v.added) {
                if(v.client) {
                  original = stringSplice(original, v.index + shadOffset, 0, v.value);                  
                  clientOffset += v.count;
                } else {
                  original = stringSplice(original, v.index + clientOffset, 0, v.value);
                  shadOffset += v.count;
                }
              } else if(v.removed) {
                if(v.client) {
                  original = stringSplice(original, v.index + shadOffset, v.count, '');
                  clientOffset -= v.count;
                } else {
                  original = stringSplice(original, v.index + clientOffset, v.count, '');                  
                  shadOffset -= v.count;
                }
              }
            });
          }

          // Updating the object and the shadow
          obj[key] = original;
          
          return;
        } else if(value && value.type === 'SET') {
          // Splicing the string
          if(value.new) {
            obj[key] = value.new;
          } else {
            delete obj[key];
          }
          return;
        } else if(obj[key]) {
          this._traverseDiff(obj[key], shadow[key], diff[key]);
        }
      });
    };
    
    _deconstruct () {
      // TODO: move realtime stuff out of the base Resource
      if (this.realtime) {
        // if realtime is in use then unsubscribe from server
        SocketIO.emit('model.unsubscribe', { id: this._id });
      }
      // Emit on rootScope to remove from store/arrays/etc
      $rootScope.$emit(this._id + ':remove', this);
    }

    
    decycle (data, visitedIds = []) {
      let toVisit = [];
      _.forEach(data, (val, key) => {
        // If it's a function there's no point processing it
        if(_.isFunction(val)) return;

        // Checking if it's the _id of the object
        if(key === '_id') {
          visitedIds.push(val);
        } else if (_.isObject(val) && !_.isArray(val)) {
          // Checking if we've seen this before
          const foundId = _.find(visitedIds, (id) => {
            return id === val._id
          });
          
          if(foundId) {
            data[key] = val._id;
          } else {
            toVisit.push(val);
          }
        } else if(_.isArray(val)) {
          if(val.length > 0) toVisit.push(val);
        }
      });

      _.forEach(toVisit, (node) => {
        this.decycle(node, visitedIds);
      });
    }
    
    save (callback = function(){}) {
      // get the data ready for saving
      let data = (this instanceof Object) ? this.clone() : JSON.parse(this.decycle(this));
      let id = this._id;
      // If the object has not been created in the database
      if (!id) {
        // Request the object be created
        id = 'new';
      }

      // remove populated fields from being saved
      const store = $injector.get(this.__meta.options.model + 'Store');
      store._depopulateObject(data, store.refs, function depopulate(error, populatedData) {
        // on error
        if (error) return callback(error);

        // on success start the saving process
        this.__meta.options.ignoreUpdates = true;
        this.history_version += 1;

        // remove metadata
        delete data.__meta;

        // Request the a mongoose.toObject version of the model
        $http({
          method: 'POST',
          url: this.__meta.options.baseRoute + ((id === 'new') ? '' : '/save')  + '/' + id,
          data: data
        })
          .then(function success(res) {
            res.data.hasRefs = true;

            // add any populated fields
            store._repopulateObject(res.data, populatedData);

            // Assigning values            
            this._assignData(this, res.data);
            
            // TODO: programatically convert date fields using a list from the server
            if (!$rootScope.$$phase) {
              $rootScope.$apply();
            }
            
            this.__meta.options.ignoreUpdates = false;
            
            callback(null, this);
          }.bind(this))
          .catch(function err(res) {
            callback(res);
          });

      }.bind(this));
    }

    remove (showWarningDialog = false, callback = function(){}) {
      if (showWarningDialog) {
        const confirm = $mdDialog.confirm()
            .title('Delete record?')
            .ariaLabel('Delete record')
            .ok('Delete')
            .cancel('Cancel');
        $mdDialog.show(confirm).then(
          () => this._removeReq(callback),
          () => $rootScope.showToast(`Delete Cancelled`)
        );
      } else {
        this._removeReq(callback);
      }
    }
    
    updateForActivateOrDeactivate (showWarningDialog, user, callback = function(){}) {
      if (showWarningDialog) {
        let state = ''

        if(user.isActive === true){
          state = 'Deactivate';
        } else {
          state = 'Activate';
        }
          
          const confirm = $mdDialog.confirm()
            .title(state+' User ?')
            .ariaLabel(state) 
            .ok(state)
            .cancel('Cancel');
        $mdDialog.show(confirm).then(
          () => this._updateIsActive(callback),
          () => $rootScope.showToast(`Updation Cancelled`)
        );
      }
    }

    _removeReq (callback) {
      $http(
        {
          method: 'DELETE',
          url: this.__meta.options.baseRoute + '/' + this._id,
          data: {}
        }
      )
        .then(
          (res) => {
            callback(null, res.data);
          },
          (res) => {
            callback(res);
          }
        );
    }

    _updateIsActive (callback) {
      this.isActive = !this.isActive
      $http(
        {
          method: 'POST',
          url: this.__meta.options.baseRoute + '/save/' + this._id,
          data: this
        }
      )
      .then(
        (res) => {
          callback(null, res.data);
        },
        (res) => {
          callback(res);
        }
      );
    }


    deleteFile (fileid, callback) {
      const data = {
        id: this._id,
        fileid: fileid
      };

      $http(
        {
          method: 'POST',
          url: this.__meta.options.baseRoute + '/deleteFile',
          data: data
        }
      )
        .then(
          (res) => {
            callback(null, res.data);
          }
        );
    }

    showPhotoDialog(photo, ev) {
      $mdDialog.show({
        locals:{photo: photo},
        controller: this.DialogController,
        controllerAs: 'vd',
        template: '<md-dialog aria-label=\'Close dialog\' ng-click=\'vm.close()\'><md-toolbar class="md-toolbar-tools"><h2>{{photo.filename}}</h2> </md-toolbar> <md-dialog-content layout="row"  layout-align="center" style="padding:5px;"> <p ng-if="!photo.source_image_uri"> Image not found!</p> <img ng-if="photo.source_image_uri" style="max-width:640px; max-height:640px" ng-src=\'{{ photo.source_image_uri }}\' alt=\'{{ photo.filename }}\'/> </md-dialog-content></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
      })
      .then(function(answer) {
        
      }, function() {
  
      });
    };

    DialogController($scope, $mdDialog, photo){
      $scope.photo = photo;
      $scope.cancel = function() {
        $mdDialog.cancel();
      };
    }

    /**
     * Helper function to assign data retrieved from a http save request
     */
    _assignData(oldData, newData) {
      this._removeKeys(oldData, newData);
      _.merge(oldData, newData);
    }
    
    _removeKeys(oldData, newData) {
      for (let key in oldData) {
        if(!newData) continue;

        // Check for array or object
        if (_.isObject(oldData[key])) {
          this._assignData(oldData[key],newData[key]);
        }

        // Check for populated object
        // TODO: should iterate over refs instead of keys
        if (oldData[key] && typeof oldData[key] === 'object' && oldData[key].constructor !== Array && typeof newData[key] === 'string') {
          delete newData[key];
        }        
      }      
    }
    
    /**
     * Sets up the structure of the resource
     * Requires metadata is loaded as well as references
     * Differs from _populate as _populate sets up structure related to the class and data, but does not look at the model schema
     * This method looks at the model schema and bases the structure of the object off that
     * This method is not a direct replacement for _populate
     * Converts data to be more useful data types by looking at the model schema
     */
    _setupStructure(data) {
      // Setup data
      _.assign(this, data);
      // Removing the __meta object so it won't be cloned
      delete data.__meta;

      // Setting up a shadow copy of the data for use with realtime and revert
      this.__meta.shadow = _.cloneDeep(data);

      // Setup structure for fields (affects shadow as well)
      this.setPaths(this.__meta.paths);

      this.__meta.eventListeners = this.__meta.eventListeners ? this.__meta.eventListeners : {};
      
      if (!$rootScope.$$phase) {
        $rootScope.$apply();
      }
    }

    setRefs(refs) {
      this.__meta.refs = refs;
    }
    
    /**
     * Sets up the path structure of an object (i.e. date strings become data objects, etc.)
     * Note: this affects the shadow as well as the object
     * @param {Array} paths Array of path objects defining the structure of the model
     * @returns {void} Returns after mutating the object and it's shadow
     */
    setPaths(paths) {
      // give the paths a default structure based on the model's schema
      for (const path in paths) {
        // for each path setup the structure
        // path array is a list of nested fields
        const pathArray = path.split('.');
        // setup object to assign the field to
        let assignTo = this;
        let assignToShadow = this.__meta.shadow;
        for (let f = 0; f < pathArray.length; f++) {
          // for each field setup the object
          if (f < pathArray.length - 1) {
            // if the field is not the field at the end of the path
            if (!assignTo[pathArray[f]]) {
              // if the path does not exist then assign it an empty object
              assignTo[pathArray[f]] = {}
              // if the object does not have the path then neither will the shadow
              assignToShadow[pathArray[f]] = {};
            }
            // move down one level
            assignTo = assignTo[pathArray[f]];
            assignToShadow = assignToShadow[pathArray[f]];
          } else {
            // if the field is at the end of the path
            if (!assignTo[pathArray[f]]) {
              // if the path does not exist then assign it a default
              switch (this.__meta.paths[path].type) {
                case 'Boolean':
                  assignTo[pathArray[f]] = false;
                  assignToShadow[pathArray[f]] = false;
                  break;
                case 'Number':
                  assignTo[pathArray[f]] = 0;
                  assignToShadow[pathArray[f]] = 0;
                  break;
                case 'String':
                  assignTo[pathArray[f]] = '';
                  assignToShadow[pathArray[f]] = '';
                  break;
                case 'Array':
                  assignTo[pathArray[f]] = [];
                  assignToShadow[pathArray[f]] = [];
                  break;
                case 'Date': // don't assign a new date if data is not defined
                  assignTo[pathArray[f]] = null;
                  assignToShadow[pathArray[f]] = null;
                  break;
              }
            } else {
              // if the path does exist then convert its type (if not already done by JSON.parse)
              switch (this.__meta.paths[path].type) {
                case 'Date':
                case 'DateNull':
                  assignTo[pathArray[f]] = new Date(assignTo[pathArray[f]]);
                  assignToShadow[pathArray[f]] = new Date(assignToShadow[pathArray[f]]);
                  break;
              }
            }
          }
        }
      }
    }
    
    /**
     * Gets the field of the corresponding path
     * @param {String} Path of the field to retrieve
     * @return {Mixed} Return the last defined value
     */
    getValue(path) {
      path = path.split('.');
      let obj = this;
      for (const p of path) {
        // for each field name in the path go down one level
        if (!obj) {
          // if the value is undefined then exit early and return undefined
          break;
        }
        obj = obj[p];
      }
      return obj;
    }
    
    on (event, fn) {
      if (!this.__meta.eventListeners[event]) {
        this.__meta.eventListeners[event] = [];
      }
      this.__meta.eventListeners[event].push(fn);
    }

    off (event, fn) {
      if (!this.__meta.eventListeners[event]) {
        return;
      }
      let i = this.__meta.eventListeners[event].indexOf(fn);
      this.__meta.eventListeners[event].splice(i, 1);
    }

    emit (event, data) {
      _.each(this.__meta.eventListeners[event], function (fn) {
        fn(data);
      });
    }
    
    addOnUpdateEvent(event) {
      console.warn('resource.addOnUpdateEvent is deprecated, use resource.on instead');
      this.on('update', event);
    }

    revert() {
      _.assign(this, this.__meta.shadow);
    }
    
  }  
  return ResourceService;
}

(function (app) {
  app.service('Resource', Resource);
}(angular.module('app.core')));
