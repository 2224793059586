'use strict';

const httpStatus = require('http-status-codes');

(function (app) {

  app.config(function ($httpProvider) {
    // setup interceptor for setting the Authorization header for each request
    $httpProvider.interceptors.push(function($q, $rootScope, $window) {
      return {
        // when a response from the server is received
        'responseError': function(rejection) {
          if (rejection.status === httpStatus.UNAUTHORIZED && $rootScope.User.logged_in) {
            // if the user is logged in front-end but not back-end then refresh (without re-requesting page)
            $window.location.reload(false);
          }
          // pass the response on as usual
          return $q.reject(rejection);
        }
      };
    });
  });

}(angular.module('app.authclient', [

])));