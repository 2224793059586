'use strict';

class P42ButtonCtrl {
  
  constructor($cookies, $scope, $rootScope) {
    this.$cookies = $cookies;
    this.$scope = $scope;
    this.$rootScope = $rootScope;

    // Button data
    this.label = '';
    this.class = '';
    this.icon = '';
    this.loading = false;
    this.disabled = false;
  }

  /**
   *  Run once component is instantiated to bind component bindings to controller.
   *  @returns {void}   Assigns component bindings to controller binded variables.
   */
  $onInit () {

    // Provide defaults for visible if not provided or allow override
    if (this.p42Visible === false) {
      this.visible = false;
    } else {
      this.visible = null;
    }

    // Provide defaults for disabled if not provided or allow override
    if (this.p42Disabled === true) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }

    this.label = this.p42Label;
    this.class = this.p42Class;
    this.raised = typeof this.p42Raised != 'undefined' ? this.p42Raised : null;
    this.icon = this.p42Icon;
    this.tooltip = this.p42Tooltip;
  }

  /**
   *  Checks the p42Loading condition every change event and changes loading state if necessary.
   *  @returns {void}   Changes loading state if the condition is appropriate.
   */
  $onChanges(changesObj) {
    if (changesObj.p42Loading) {
      this.loading = changesObj.p42Loading.currentValue;
    }
    if (changesObj.p42Label) {
      this.label = changesObj.p42Label.currentValue;
    } 
    if (changesObj.p42Disabled) {
      this.disabled = changesObj.p42Disabled.currentValue;
    }
    if (changesObj.p42Class) {
      this.class = changesObj.p42Class.currentValue;
    }
    if (changesObj.p42Raised) {
      this.raised = changesObj.p42Raised.currentValue;
    }
    if (changesObj.p42Icon) {
      this.icon = changesObj.p42Icon.currentValue;
    }
    if (changesObj.p42Tooltip) {
      this.tooltip = changesObj.p42Tooltip.currentValue;
    }
  }

  /**
   *  Simply emits the data of the button once it has been clicked.
   *  @returns {void}   Returns the button data via callback.
   */
  onClicked () {
    this.p42Click({ clicked: this });
  }
}

(function(app) {
  app.controller('P42ButtonCtrl', P42ButtonCtrl);
}(angular.module('app.core')));
