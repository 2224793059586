'use strict';

/**
 * @DEPRECATED Could be removed at any time
 * Please use p42-form-view or md-card instead
 */
class CardsCtrl {
  constructor() {
    this.title = "";
    this.subsections = 0;
    this.nocaps = 'false';

    this.decoratedTitle = () => this.nocaps === 'false' ? angular.uppercase(this.title) : this.title;
  }
}


(function (app) {
  app.directive('cards', function () {
    return {
      restrict: 'E',
      scope: {},
      controller: CardsCtrl,
      controllerAs: 'ctrl',
      transclude: {
        'content': 'cardsContent',
        'subcontent': '?cardsSubcontent',
        'subsubcontent': '?cardsSubsubcontent',
      },
      bindToController: {
        title: '@title',
        description: '@description',
        subsections: '@subsections',
        convertCaps: '@nocaps'
      },
      templateUrl: '/html/core/cards.html',
    };
  });
}(angular.module('app.core')));