'use strict';

const ABSENT = -1;

/**
 * Provider function for Toolbar Service
 *
 *  - Allows for addition of toolbar items before service is instantiated
 * Items are in the following format: {
 *  icon: '', // string of the icon location
 *  title: '', // string of the title of the button
 *  index: 0, // optional integer of where to order the item
 *  action: () => {} // function to call when clicked
 * }
 */
function ToolbarProvider() {
  const items = [];
  const hidden = [];

  this.addItem = function (item) {
    items.unshift(item);
  };

  this.hideItem = function (item) {
    if (hidden.indexOf(item) === ABSENT) {
      // if the item has not been added to hidden then add it
      hidden.unshift(item);
    }
  };

  this.$get = [function ToolbarFactory() {
    return new ToolbarService(items, hidden);
  }];
}

/**
 * Controller for the Toolbar Service
 */
class ToolbarService {

  /**
   * - Assigns items to this
   */
  constructor(items, hidden) {
    this._items = items;
    this._hidden = hidden;
  }

  /**
   * Add an item to the items array
   * @param {Object} item
   */
  addItem(item) {
    this._items.unshift(item);
  }

  /**
   * Return array of items
   * @return {[Object]} The array of items
   */
  getItems() {
    return this._items.filter((item) => {
      // for each item remove the hidden ones
      return this._hidden.indexOf(item.title) === ABSENT;
    }).sort((a, b) => {
      // for each item sort by the index (no index means 0)
      // if a's index is not defined then set it to zero
      if (!a.index) a.index = 0;
      // if b's index is not defined then set it to zero
      if (!b.index) b.index = 0;
      // a higher index means it shows more to the left
      return a.index < b.index;
    });
  }
}

(function (app) {
  app.provider('Toolbar', ToolbarProvider);
}(angular.module('app.toolbar')));