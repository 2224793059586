'use strict';

(function (app) {
  app.factory('LocationStore', function (Store, $http) {
    const LocationStore = new Store({
      model: 'Location'
    });

    return LocationStore;
  });

}(angular.module('app.location')));
