'use strict';

/**
 * Controller for the Location edit view
 */
class SampleCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, sample, job, JobStore) {
    this.$state = $state;
    this.sample = sample;
    this.JobStore = JobStore;
    this.job = job;
    
    //console.log(this.sample);
    

    if (job && !sample.job) {
      // if the job has been pre-loaded then assign it
      this.sample.job = job;
    }

    this.jobs = [];
    if (this.sample.job && this.sample.job.id) {
      // if the job has been populated then add it to the job list
      this.jobs.push(this.sample.job);
    }
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    
    let tab = "prep";
    if ( this.sample.job.phase == 3 ) tab = "received";
    else if ( this.sample.job.phase == 4 ) tab = "testing";
    else if ( this.sample.job.phase == 5 ) tab = "report";
    else if ( this.sample.job.phase == 6 ) tab = "final";
    
    //console.log(" tab :", tab);
    

    this.sample.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
          if (this.sample.job.id){
            this.$state.transitionTo('jobs.preview', {id: this.sample.job.id, tab: tab } );
          }else{
            this.$state.transitionTo('jobs');
          }
      }
    });
  }

  /**
   * Loads the list of jobs
   */
  loadJobs() {
    if (this.jobs.length <= 1) {
      // if the jobs have not yet been loaded then load them
      this.JobStore.get({}, (err, jobs) => {
        this.jobs = jobs;
      });
    }
  }
}

(function (app) {
  app.controller('SampleCtrl', SampleCtrl);
}(angular.module('app.sample')));
