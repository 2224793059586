'use strict';

// const _ = require('lodash');

/**
 * Controller for the Report view
 */
class ReportCtrl {

  /**
   * - Assigns injected variables to this
   * - Fetches the Report defined by query paremeter: report_id
   */
  constructor($rootScope, $scope, $state, $mdDialog, $mdToast, $window, $timeout, ReportStore, CustomerStore, report , $http, auditTrailStore, reportHistoryStore, JobStore, FileStore) {
    this.$window = $window;
    this.reportHistoryStore = reportHistoryStore;
    this.JobStore = JobStore
    this.$http = $http;
    this.$scope = $scope;
    this.$http = $http;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.ReportStore = ReportStore;
    this.auditTrailStore = auditTrailStore;
    this.user = $rootScope.User;
    this.FileStore = FileStore;

    this.setSignatureUri(this.user.signature);
    this.report = report;

    // console.log(this.report.job.samples);

    this.currentReport = report;
    this.reportVersionsList = []
    this.$rootScope = $rootScope;

    this.historyReport = false;

    // Do some manipulations here 
    if(this.report.job.customer){
      let locations = this.report.job.customer.locations;

      if(locations.length > 1 ){
        for(let i = 0; i < locations.length; i++){
          let loc = locations[i];
          if(loc.reportingLocation)
          {
            this.report.job.location = locations[i]; //Use the reporting location
            break;
          }
        }
      }
      else{
        //only one location , so use that as reporting location 
        this.report.job.location = locations[0];
      }
    }
    
    if(this.report.address.length == 0)
      this.report.address = "";
    
    if(this.report.address.length == 0 && this.report.job.location && this.report.job.location.physical){

      this.report.address = "";
      this.report.address += (this.report.job.location.physical.unit && this.report.job.location.physical.unit.length>0 ? this.report.job.location.physical.unit +" " :"") +  this.report.job.location.physical.street + ",\n";

      this.report.address += this.report.job.location.physical.suburb && this.report.job.location.physical.suburb.length > 0 ? this.report.job.location.physical.suburb + ",\n":"";

      this.report.address += (this.report.job.location.physical.city && this.report.job.location.physical.city.length>0 ? this.report.job.location.physical.city +" " :"") + this.report.job.location.physical.postcode + ",\n"; 
      this.report.address += (this.report.job.location.physical.region && this.report.job.location.physical.region.length>0 ? this.report.job.location.physical.region + ",":"" ) + this.report.job.location.physical.country;

      this.onSaveClick(null,false);
    }
    

    //Setting the address thing 
    // if(!this.report.postal_address.line1 && this.report.job.location && this.report.job.location.physical)
    //  this.report.postal_address.line1 = this.report.job.location.physical.unit +" " +  this.report.job.location.physical.street;
   
    // if(!this.report.postal_address.line2 && this.report.job.location && this.report.job.location.physical)
    //  this.report.postal_address.line2 = this.report.job.location.physical.suburb;

    // if(!this.report.postal_address.line3 && this.report.job.location && this.report.job.location.physical)
    //  this.report.postal_address.line3 = this.report.job.location.physical.city +" " + this.report.job.location.physical.postcode; 

    // if(!this.report.postal_address.line4 && this.report.job.location && this.report.job.location.physical)
    //  this.report.postal_address.line4 = this.report.job.location.physical.region + "," + this.report.job.location.physical.country;
    

    
    // console.log(this.report.postal_address.line1);

    if(this.report.parentId != undefined && this.report.parentId){
      this.fetchReportVersionsList();
    }
    
    //filtering out duplicated fields
    this.report.fields = _.uniqBy(report.fields, '_id')
    this.samples = this.report.job.samples.filter(sample => sample.type !== 'q');
    
    this.samples.sort((x, y) => {
      return x.sample_num - y.sample_num
    });

    this.user = $rootScope.User;
    this.$scope.breadcrumb = 'T' + report.job.job_id;
    //console.log(report)
    
    this.auditTrail = []
    this.showAudit = false
    this.getAuditTrails()

    this.isApproveButtonDisable = true;
    this.isIanzToggleButtonDisable = true;
    
    if (this.report.status == 0) {
      this.isApproveButtonDisable = false;
      this.isIanzToggleButtonDisable = false;
    } else if (this.report.status == 2) {
      this.isApproveButtonDisable = true;
      this.isIanzToggleButtonDisable = true;
    }
    
    this.isGenerateXmlButtonDisable = true;
    this.isPrintDisable = true;
    this.isSaveButtonDisable = true;

    this.toggleBtnEnable = {
      label: 'Toggle IANZ Logo',
      icon: '/icons/md/action/svg/production/ic_change_history_48px.svg',
      class: 'md-accent md-raised',
      click: this.toggleIANZEnable.bind(this),
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else if (this.report.status == 0 && this.user.hasPermission('report-edit')) {
          return false;
        }
        else if (this.user.hasPermission('edit-approved-reports') && this.report.status == 2) {
          return false;
        } 
        else if (this.report.status == 2 && this.user.hasPermission('report-edit')) {
          return true;
        } 

        else if (!this.user.hasPermission('report-edit')) {
          return true;
        } else {
          return false;
        }
        //return !this.user.hasPermission('report-edit');
      },
      id: 'rsButtonEnable',
    }

    this.toggleBtnDisable = {
      label: 'Toggle IANZ Logo',
      icon: '/icons/md/action/svg/production/ic_change_history_48px.svg',
      class: 'md-warn md-raised',
      click: this.toggleIANZDisable.bind(this),
      disabled: () => {
        
        if (this.historyReport && !this.user.hasPermission('edit-approved-reports') ) {
          return true;
        } else if (this.report.status == 0 && this.user.hasPermission('report-edit')) {
          return false;
        } 
        else if (this.user.hasPermission('edit-approved-reports') && this.report.status == 2) {
          return false;
        } 
        // else if (this.report.status == 2 && this.user.hasPermission('report-edit')) {
        //   return true;
        // } 

        else if (this.user.hasPermission('report-edit')) {
          return true;
        } else {
          return false;
        }
        //return !this.user.hasPermission('report-edit');
      },
      id: 'rsButtonDisable'
    };


    this.approveButton = {
      label: 'Approve',
      icon: '/icons/md/navigation/svg/production/ic_check_48px.svg',
      class: 'md-accent md-raised',
      click: this.approve.bind(this),
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else {
          return (this.isApproveButtonDisable || !this.user.hasPermission('report-approve'));
        }
      }
    }

    this.unapproveButton = {
      label: 'Copy',
      icon: '/icons/md/navigation/svg/production/ic_close_48px.svg',
      class: 'md-warn md-raised',
      click: this.unapprove.bind(this),
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else {
          return !this.user.hasPermission('report-edit');
        }
      }
    };
    this.generatePDF = {
      label: 'Generate PDF',
      icon: '/icons/md/action/svg/production/ic_print_48px.svg',
      click: this.onPrintClick.bind(this),
      class: 'md-accent md-raised',
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else if (this.report.status == 0) {
          return this.isPrintDisable;
        } else {
          this.isPrintDisable = false;
          return this.isPrintDisable;
        }
      }
    };
    this.downloadPDF = {
      label: 'Download PDF',
      icon: '/icons/md/action/svg/production/ic_print_48px.svg',
      click: this.onPrintClick.bind(this),
      class: 'md-accent md-raised',
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else if (this.report.status == 0) {
          return this.isPrintDisable;
        } else {
          this.isPrintDisable = false;
          return this.isPrintDisable;
        }
      }
    };

    this.saveButton = {
      label: 'Save',
      icon: '/icons/md/av/svg/production/ic_note_48px.svg',
      click: this.onSaveClick.bind(this),
      class: 'md-accent md-raised',
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else if (this.report.status == 0 || (this.user.hasPermission('edit-approved-reports') && this.report.status == 2) ) {
          return this.isSaveButtonDisable;
        } else {
          this.isSaveButtonDisable = true;
          return this.isSaveButtonDisable;
        }
      },
      id: 'saveButton',
    }

    this.toggleButton = {};
    this.ianzToggleButton = {};
    this.togglePdfButton = {};

    if (report.status === 0) {
      _.extend(this.toggleButton, this.approveButton);
    } else {
      _.extend(this.toggleButton, this.unapproveButton);
    }

    if (this.report.ianz) {
      _.extend(this.ianzToggleButton, this.toggleBtnDisable);
    } else {
      _.extend(this.ianzToggleButton, this.toggleBtnEnable);
    }
    if (this.report.pdf)
      _.extend(this.togglePdfButton, this.downloadPDF);
    else
      _.extend(this.togglePdfButton, this.generatePDF);
      

    let tab = this.report.status === 0 ? 'report' : 'final';
    // Items for the toolbar view
    this.items = [{
      label: 'Back to Job T' + report.job.job_id,
      click: $state.go.bind($state, 'jobs.preview', { id: report.job._id, tab: tab }),
      class: 'md-accent md-raised'
    },
    this.ianzToggleButton,
    /* {
      label: 'Toggle IANZ Logo',
       icon: '/icons/md/action/svg/production/ic_change_history_48px.svg',
       class: 'md-accent md-raised',
       click: this.toggleIANZ.bind(this),
       types: 'checkbox',
       disabled: () => {
        if (this.report.status == 0) {
          return this.isSaveButtonDisable;
        } 
         return !this.user.hasPermission('report-edit');

       },
       id: 'rsButton',
    }, */
    this.saveButton, 
    this.togglePdfButton,
    // {
    //   label: 'Generate PDF',
    //   icon: '/icons/md/action/svg/production/ic_print_48px.svg',
    //   click: this.onPrintClick.bind(this),
    //   class: 'md-accent md-raised',
    //   disabled: () => {
    //     if (this.historyReport) {
    //       return true;
    //     } else if (this.report.status == 0) {
    //       return this.isPrintDisable;
    //     } else {
    //       this.isPrintDisable = false;
    //       return this.isPrintDisable;
    //     }
    //   }
    // }, 
    {
      label: 'Generate XML',
      icon: '/icons/md/av/svg/production/ic_note_48px.svg',
      click: this.onGenerateXMLClick.bind(this),
      class: 'md-accent md-raised',
      disabled: () => {
        if (this.historyReport) {
          return true;
        } else if (this.report.status == 0) {
          return this.isGenerateXmlButtonDisable;
        } else {
          this.isGenerateXmlButtonDisable = false;
          return this.isGenerateXmlButtonDisable;
        }
      }
    }, this.toggleButton];

    this.rsButtons = [];

    _.each(report.job.runsheets, (rs) => {
      let element = {
        label: 'Runsheet ' + rs.name,
        //click: $state.go.bind($state, 'runsheets.edit', { id: rs._id }),
        id: rs._id,
        class: 'md-accent',
      };
      this.rsButtons.push(element);
    });

    /** @type {Boolean} Boolean value indicating whether the Report is loading */
    this.loading = true;

    let report_id = $state.params.report_id;

    this.now = new Date();

    this.onReportLoad(this.report);
    this.getReportName();

    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.report.revert();
    });
  }

  formatDate(date)
  {
    
    let current_datetime = new Date(date)
    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes()
    return formatted_date
  }

  createAuditTrail(option) {
    
    var postData = { 
      entityType:'report',
      entityId:this.report._id,
      entityName:this.report.job_id,
      userID:this.user._id,
      userName:this.user.given_name+ " "+ this.user.surname,
      action:option.action,
      actionMessage:option.auditMessage
    };
    this.auditTrailStore.create(postData, (err,data) => {
        if(err){
          console.log(err,data)
        }
    })
   
  }
  
  getAuditTrails(){
    this.auditTrailStore.get({entity_id:this.report.id }, { populate: ""}, (err, auditTrails) => {
      if(err)
        console.log(err)
      else
        this.auditTrail = auditTrails
    })
    
  }

  fetchReportVersionsList(){
    this.reportVersionsList = [];
    this.currentVersion = 0;
    //console.log("this.report.job_id :", this.report.job_id);
    //console.log("this.report.job.id :", this.report.job.id);
    
    this.reportHistoryStore.get({job:this.report.job.id }, { populate: ""}, (err, reportHistorys) => {
      if(err)
        console.log(err)
      else
      {
        if(reportHistorys.length>0)
        {  
          var _this = this
          reportHistorys.forEach(function(reportHistory){
            
            _this.reportVersionsList.push({id:reportHistory.id,versionNumber:reportHistory.versionNumber});
            _this.currentVersion = reportHistory.version;
          
          });
          this.reportVersionsList.push({id:'Current Report',versionNumber:this.report.versionNumber});
          if(this.reportVersionsList.length>1)
            this.reportVersionsList = _.orderBy(this.reportVersionsList, [reportHistory => reportHistory.versionNumber], ['desc']);
        }
       
      }
    })
  }

  reportVersionDisplay(version){
    let prettyVersion = '';
    
    if(version.id != 'Current Report'){
      if(version.versionNumber == 0)
        prettyVersion = "Generated Report (V0)";
      else if(version.versionNumber == 1)
        prettyVersion = "Original Report (V1)";
      else
        prettyVersion = "Ammended Report (V"+version.versionNumber+")";
    }   
    else{
      prettyVersion = version.id+" (V"+version.versionNumber+")";
    }
    return prettyVersion;
  }


  fetchHistoryReport(version){   
    //console.log(" fetchHistoryReport --> currentVersion :", version.versionNumber);
    //console.log(" fetchHistoryReport --> version.id :", version.id);
    let reportAddress = this.report.address; // Save this for later use 
    this.currentVersion = version.versionNumber
    
    if(version.id =='Current Report'){
      this.report = this.currentReport;
      this.isApproveButtonDisable = false;
      this.historyReport = false;
      //console.log(" fetchHistoryReport --> this.historyReport :", this.historyReport);
    } 
    else 
    {
      if(version.id!=undefined)
      {

        this.reportHistoryStore.get({_id:version.id }, { populate: ""}, (err, reportHistory) => {
          if(err)
            console.log(err)
          else
          {
            

            if(reportHistory.length>0)
            {
              this.report = reportHistory[0];
              console.log('parentId check',this.report.parentId);
              this.report.address = reportAddress;
              this.historyReport = true;
              // options 1 we can get job from jobstore but it will require query
              this.JobStore.get({_id:this.report.job},{}, (err, jobData) => {
                if(jobData.length>0){
                  this.report.job = jobData[0]
                  this.$scope.$applyAsync();
                }
              })

              // options 2 we can get job from current report without any query but 
              // there can be a persistency problem 
              // if(this.currentReport.job.id==this.report.job)
              //   this.report.job = this.currentReport.job
            }
           
          }
        })

      }
       
    }
    
    
  }
  /**
   * Test: name in the Summary of Methods section
   */
  getReportName() {
    if (this.report.job.labTests.length > 1) {
      let labTests = this.report.job.labTests;
      labTests.sort((x, y) => { return y.dependencies.length - x.dependencies.length })
      this.testName = labTests[0].report_name;
    } else {
      this.testName = this.report.job.labTests[0].report_name;
    }
  }

  getValue(sample, field, runsheetnametofilter) { 
    // console.log(sample, field)

    if(typeof runsheetnametofilter == 'undefined')
      runsheetnametofilter = '';

    runsheetnametofilter = runsheetnametofilter.toLowerCase();
    
    let value1 = _.filter(this.values, function (value) {
      var runsheetname = value.runsheetnamee.toLowerCase();

      
      if((runsheetname.search('ctb-gl') != -1 || runsheetname.search('ctb-s') != -1)) {
        if (value.sample && value.sample == sample._id && value.cell.field == field._id && runsheetname.search(runsheetnametofilter) != -1) {

          if(sample.sample_identifier == 'PLY20' && field._id.toString() == '5ce35aee63107a07782e2ad5') { 
            //console.log('PLY20 =====>', value.value, sample._id);
          }
          
          return true;
        }
      } else {
        if (value.sample && value.sample == sample._id && value.cell.field == field._id) {

          if(sample.sample_identifier == 'PLY20' && field._id.toString() == '5ce35aee63107a07782e2ad5') { 
            //console.log('PLY20 =====>', value.value, sample._id);
          }
          
          return true;
        }
      }


      return false;
    });

    //console.log('==**', value1);
    if(!value1 || (Array.isArray(value1) && value1.length == 0)) 
    {
      return null;
    } 

    if(value1 && (Array.isArray(value1) && value1.length > 0) ) {
      value1 = value1[0];
    }



    let value = _.filter(this.values, function (value) {
      var runsheetname = value.runsheetnamee.toLowerCase();

      if((runsheetname.search('ctb-gl') != -1 || runsheetname.search('ctb-s') != -1)) {
        if (value.sample && value.sample == sample._id && value.cell.field == field._id && !value.static && runsheetname.search(runsheetnametofilter) != -1) {
          return true;
        }
      } else {
        if (value.sample && value.sample == sample._id && value.cell.field == field._id && !value.static) {
          return true;
        }
      }


      return false;
    });
    
    if(value && (Array.isArray(value) && value.length > 0) ) {
      value = value[0];
    }

    
    if(sample.type == 'r'){
      // console.log('Yo man...this is my 3 decimal_places sample');
      return value && value.value > field.detection_limit ? value.value.toFixed(field.decimal_places) : '< '+field.detection_limit;
    }
    else
    {
      // console.log(field);


      var d_p = field.decimal_places;
      var d_limit = field.detection_limit;

      if(field.decimal_places_penetration != 0)
        d_p = field.decimal_places_penetration;

      if(field.detection_limit_penetration && field.detection_limit_penetration != 0)
        d_limit = field.detection_limit_penetration;

      // console.log('Limits',field.name,d_p,d_limit);

      return value && value.value > d_limit ? value.value.toFixed(d_p) : '< '+ d_limit;
    }
    // if(value.value == 1.4420000000000002)
    //   value.value = 0.000001;
    // console.log(value && value.value,field.detection_limit);
    
  }

  /**
   * Called once report has loaded
   * @param {Report} report The Report resource
   */
  onReportLoad(report) {
    // Get the retention/penetration status
    let hasRetention = false;
    let hasPenetration = false;
    for (const sample of this.report.job.samples) {
      // for all samples run checks
      if (sample.type === 'r') {
        hasRetention = true;
      } else if (sample.type === 'p') {
        hasPenetration = true;
      }
      if (hasRetention && hasPenetration) {
        // if both types were found then exit early
        break;
      }
    }

    // Getting the report fields
    this.report_fields = _.filter(report.fields, (field) => {    
      return field.report_visibility;
    });

    // console.log(this.report_fields);
    // Sorting the report fields
    this.report_fields.sort((x, y) => {
      return x.report_weight - y.report_weight
    });

    // console.log(this.report_fields);

    // Getting the summary fields
    this.summary_fields = _.filter(report.fields, (field) => {

      if (!field.retention_visibility && field.penetration_visibility && !hasPenetration) {
        return false;
      }

      return field.summary_visibility;
    });

    // Sorting the summary fields
    this.summary_fields.sort((x, y) => {
      return x.report_weight - y.report_weight
    })
    // Getting the summary fields
    // console.log(this.summary_fields);
    this.summary_fields = this.report.summary_method_field;

    // Getting all the runsheet values
    this.values = [];
    this.glueLineTest =  _.filter(report.job.labTests, (labtest) => {

      if (labtest.unique_name.toLowerCase().indexOf('glueline')>-1) {
        return labtest;
      }
      else {
        return false;
      }

    });

    this.surfaceTest =  _.filter(report.job.labTests, (labtest) => {

      if (labtest.unique_name.toLowerCase().indexOf('surface')>-1) {
        return labtest;
      }
      else {
        return false;
      }

    });
    // console.log(this.surfaceTest)
    this.densityTest =  _.filter(report.job.labTests, (labtest) => {

      if (labtest.unique_name.toLowerCase().indexOf('density')>-1) {
        return labtest;
      }
      else {
        return false;
      }

    });

    // console.log(this.densityTest)

    report.job.runsheets.forEach(runsheet => {
      if (runsheet.jobsApproved.indexOf(report.job.id) > -1) {
        //console.log(runsheet._id, '==> 2', runsheet.name);
        //console.log(runsheet.values);


        runsheet.values = _.map(runsheet.values, function(element) {
          return _.extend({}, element, {
              runsheetnamee: runsheet.name
          });
        });

        
        this.values = this.values.concat(runsheet.values)
      }
    })
    //console.log("after concatination");
    //console.log(this.values);
    //console.log("report", this.report_fields)
    //console.log("summary", this.summary_fields)
    this.loading = false;
  }

  /**
   * Concatenates Report content, styles, scripts into a string and sends to the PDFStore for PDF creation
   * Shows a dialog on success
   */
  onPrintClick(doneLoading) {
    /** @type {Boolean} Boolean value indicating whether we are generating a PDF */
    this.printLoading = true;

    if (this.report.pdf) {
      this.$rootScope.showToast('Downloading report PDF... please wait');
      this.ReportStore.downloadPDF(this.report, (err) => {
        this.printLoading = false;
        if (err) {
          this.$rootScope.showToast('Sorry, we are currently unable to download your report PDF.');
        }
        doneLoading();
      });
    }
    else
    {
      let origin = window.location.origin;
      let head = '<base href="' + origin + '/">' + document.getElementsByTagName('head')[0].innerHTML;
      let html = '<html>' +
        '<head>' + head + '</head>' +
        '<body>' + document.getElementById('report-container').outerHTML + '</body>' +
        '</html>';
      this.ReportStore.generatePDF(this.report, html, (err, pdf) => {
        this.createAuditTrail({action:'Generate PDF Report',auditMessage:'Generate PDF Report'})
        this.printLoading = false;
        _.extend(this.togglePdfButton, this.downloadPDF);
        doneLoading();
      });
    }
  }

  /**
   * Calls report.$generateXML
   * Calls xml.$showDialog on success
   */
  onGenerateXMLClick(doneLoading) {
    /** @type {Boolean} Boolean value indicating whether we are generating XML */
    this.generatingXML = true;
    this.report.generateXML((err, res) => {
      this.createAuditTrail({action:'Generate XML Report',auditMessage:'Generate XML Report'})
      //copy from file store
      // create blob container for file data 
      const blob = new Blob([res.data.file], { type: res.data.mime_type });
      // now download the blob
      if (this.$window.navigator && this.$window.navigator.msSaveOrOpenBlob) {
        // if on a Microsoft browser
        this.$window.navigator.msSaveBlob(blob, res.data.name);
      } else {
        // if on a non-Microsoft browser then setup download
        const objectUrl = this.$window.URL.createObjectURL(blob);
        // create download anchor
        const element = this.$window.document.createElement('a');
        element.href = objectUrl;
        element.download = res.data.name;
        document.body.appendChild(element);
        // activate the download
        element.click();
        // clean up
        document.body.removeChild(element);
        this.$window.URL.revokeObjectURL(objectUrl);
        this.generatingXML = false;
        doneLoading();
      }
    });
  }

  showRunsheetDialog() {
    this.$mdDialog.show({
      templateUrl: 'rsButtons-dialog.tmpl.html',
      locals: {
        items: this.rsButtons
      },
      controller: ['$scope', '$mdDialog', 'items', function ($scope, $mdDialog, items) {
        $scope.items = items;
        $scope.closeDialog = function () {
          $mdDialog.hide();
        }
      }],
      clickOutsideToClose: true,
      openFrom: '#rsButton',
      closeTo: '#rsButton',
    });
  }

  approve() {
    if (!this.historyReport){
      this.ReportStore.approve(this.report, (err, report) => {
        //this.toggleButton = this.unapproveButton;
        this.createAuditTrail({action:'Approve Report',auditMessage:'Approve Report'})
        this.isIanzToggleButtonDisable = false;
        _.extend(this.toggleButton, this.unapproveButton);
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Report is approved')
            .position('bottom right')
        );
      });
    }else{
      console.log("Historical data can not be saved ");
    }
  }


  unapprove() {
    if (!this.historyReport){
      this.ReportStore.unapprove(this.report, (err, report) => {
        //this.toggleButton = this.approveButton;
        //this.reportVersion.push(this.report.versionNumber-1);
        this.createAuditTrail({action:'UnApprove & Copy Report',auditMessage:'UnApprove & Copy Report'})
        this.fetchReportVersionsList();
        this.isPrintDisable = true;
        this.isGenerateXmlButtonDisable = true;
        this.isIanzToggleButtonDisable = true;
        this.isApproveButtonDisable = false;
        _.extend(this.toggleButton, this.approveButton);
         _.extend(this.togglePdfButton, this.generatePDF);
        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Report is copied')
            .position('bottom right')
        );
      });
    }else{
      console.log("Historical data can not be saved ");
    }
  }

  // toggleIANZ() {
  //   this.report.ianz = !this.report.ianz;
  //   this.report.save();
  // }

  toggleIANZEnable() {
    if (!this.historyReport || (this.user.hasPermission('edit-approved-reports') && this.report.status == 2) ){
      this.createAuditTrail({action:'Toggle IANZ Logo - Enabled',auditMessage:'Toggle IANZ Logo - Enabled'})
      this.report.ianz = !this.report.ianz;
      this.isSaveButtonDisable = false;
      this.isPrintDisable = true;
      this.isGenerateXmlButtonDisable = true;
      _.extend(this.ianzToggleButton, this.toggleBtnDisable);
      this.$mdToast.show(
        this.$mdToast.simple()
          .textContent('IANZ Logo is enabled')
          .position('bottom right')
      );
    }else{
      console.log("Historical data can not be saved ");
    }
  }

  toggleIANZDisable() {
    if (!this.historyReport  || (this.user.hasPermission('edit-approved-reports') && this.report.status == 2)){
      this.createAuditTrail({action:'Toggle IANZ Logo - Disabled',auditMessage:'Toggle IANZ Logo - Disabled'})
      this.report.ianz = !this.report.ianz;
      this.isSaveButtonDisable = false;
      this.isPrintDisable = true;
      this.isGenerateXmlButtonDisable = true;
      _.extend(this.ianzToggleButton, this.toggleBtnEnable);
      this.$mdToast.show(
        this.$mdToast.simple()
          .textContent('IANZ Logo is disabled')
          .position('bottom right')
      );

    }else{
      console.log("Historical data can not be saved ");
    }
  }


  getSamplesNumbers(field) {
    if (field.retention_visibility && field.penetration_visibility) {
      return '1-' + this.samples.length;
    } else if (field.retention_visibility && !field.penetration_visibility) {
      return '1-' + this.samples.filter(sample => sample.type === 'r').length;
    } else if (!field.retention_visibility && field.penetration_visibility) {
      let startNum = this.samples.findIndex(sample => sample.type === 'p') + 1;
      if (startNum === this.samples.length) {
        return startNum;
      } else {
        return startNum + '-' + this.samples.length;
      }
    }
  }

  update(field = 'report') {
    if (!this.historyReport){
      this.report.save((err, data) => {
        if (err) return this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Error saving ' + field)
            .position('bottom right')
        );

        this.$mdToast.show(
          this.$mdToast.simple()
            .textContent(field[0].toUpperCase() + field.substring(1) + ' updated')
            .position('bottom right')
        );
      });
    }else{
      console.log("Historical data can not be saved ");
    }
  }

  /** On Save  click */
  onSaveClick(callback,showToast=true) {
    
    if (!this.historyReport && this.report.status == 0) {
      // this.loading = true;
      var data = {
        id: this.report._id,
        customerName: this.report.customerName,
        site: this.report.site,
        // line1: this.report.postal_address.line1,
        // line2: this.report.postal_address.line2,
        // line3: this.report.postal_address.line3,
        // line4: this.report.postal_address.line4,
        address: this.report.address,
        date_registered: this.report.date_registered,
        date_analyzed: this.report.date_analyzed,
        date_reported: this.report.date_reported,
        ianz: this.report.ianz,
        comments: this.report.comments,
        summary_method_field: this.summary_fields ? this.summary_fields : this.report.summary_method_field
      }
      //console.log("report.controller --> data 1:", data);
      this.updateReport(data,showToast,callback);
    }

    else if(this.report.status == 2 && this.user.hasPermission('edit-approved-reports'))
    {
      // this.loading = true;
      var data = {
       id: this.report._id,
        customerName: this.report.customerName,
        site: this.report.site,
        // line1: this.report.postal_address.line1,
        // line2: this.report.postal_address.line2,
        // line3: this.report.postal_address.line3,
        // line4: this.report.postal_address.line4,
        address: this.report.address,
        date_registered: this.report.date_registered,
        date_analyzed: this.report.date_analyzed,
        date_reported: this.report.date_reported,
        ianz: this.report.ianz,
        comments: this.report.comments,
        summary_method_field: this.summary_fields ? this.summary_fields : this.report.summary_method_field,
        pdf:null
      }

      this.updateReport(data,showToast,callback);

    }
  }

  updateReport(data,showToast,callback){
    this.ReportStore.updateReport(data, (err, report) => {

        if(report.status == 2)
          this.createAuditTrail({action:'Save Report',auditMessage:'Modified Approved Report By KTP'});
        else
          this.createAuditTrail({action:'Save Report',auditMessage:'Save Report'});
        this.loading = false;
        this.isApproveButtonDisable = false;
        this.isSaveButtonDisable = true;
        this.isIanzToggleButtonDisable = true;
        this.isPrintDisable = true;
        this.isGenerateXmlButtonDisable = true;
        if(showToast)
        {
          if(!data.pdf)
            _.extend(this.togglePdfButton, this.generatePDF);
          this.$mdToast.show(
          this.$mdToast.simple()
            .textContent('Report has been updated successfully')
            .position('bottom right')
          );
        }

        callback();
        

      });
  }
  
  enableSaveButton() {

    if (!this.historyReport  || (this.user.hasPermission('edit-approved-reports') && this.report.status == 2)) {
      this.isSaveButtonDisable = false;
      this.isIanzToggleButtonDisable = true;
      this.isApproveButtonDisable = true;
      this.isPrintDisable = true;
      this.isGenerateXmlButtonDisable = true;

    }
  }

  setSignatureUri(signature)
  {
    
    if (signature!=undefined && typeof signature=='object')
    {
      var file = signature
      this.FileStore.previewFile(file, (err) => {
        if (err) return console.error(err);

        if (file.filename.toLowerCase().match(/\.(jpeg|png|jpg)/g)) {
          
          if (file.source_image_uri)
            this.user.signatureUri = file.source_image_uri          
        } 
      });
    }
  }

  existsGLLabTstFields(field) {
    var fieldExists = false;
    this.glueLineTest.forEach(labTest => {
        if (labTest.fields.indexOf(field) >= 0) {
            fieldExists = true;
        }
    })
    this.densityTest.forEach(labTest => {
      if (labTest.fields.indexOf(field) >= 0) {
          fieldExists = true;
      }
    })
    return fieldExists;
  }

  existsSurLabTstFields(field) {
    var fieldExists = false;
    this.surfaceTest.forEach(labTest => {
        if (labTest.fields.indexOf(field) >= 0) {
            fieldExists = true; 
        }
    })
    this.densityTest.forEach(labTest => {
      if (labTest.fields.indexOf(field) >= 0) {
          fieldExists = true;
      }
    })
    return fieldExists;
  }

}

(function (app) {
  app.controller('ReportCtrl', ReportCtrl);
}(angular.module('app.report')));
