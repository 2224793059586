'use strict';

const moment = require('moment');
let todaysdate;


/**
 * Controller for the QualityCheckValueView directive
 */
class QualityCheckValueViewCtrl {
  
  
  constructor($rootScope, $scope, $mdToast, QualityCheckValue, QualityCheckValueStore) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$mdToast = $mdToast;

    this.QualityCheckValue = QualityCheckValue;
    this.QualityCheckValueStore = QualityCheckValueStore;


    this.$rootScope.$on('check-QCvalidation-event', (event, args) => {
      if(this.$scope.qcform && this.$scope.qcform.$setSubmitted()){
        this.$scope.qcform.$setSubmitted();
      }
      this.ionValidation({values : this.$scope.qcform.$valid });
    });
  }

  /**
   * Set defaults for input values
   */
  $onInit() {
    this.myDate = new Date();
    this.maxDate = new Date(
      this.myDate.getFullYear(),
      this.myDate.getMonth(),
      this.myDate.getDate()
    );

  }

  /**
   * Called when on each digest cycle
   */

  assignToMe(qualityCheckValue) {
    qualityCheckValue.value = this.$rootScope.User._id;
    qualityCheckValue.updated_at = new Date();
    this.saveQualityCheckValue(qualityCheckValue);  
  }

  assignToUser(qualityCheckValue)
  {
    qualityCheckValue.updated_at = new Date();
    this.saveQualityCheckValue(qualityCheckValue);  
  }
  
  /**
   * Saves the QualityCheckValue
   * @param {QualityCheckValue} qualityCheckValue A QualityCheckValue resource
   */
 
  saveQualityCheckValue(qualityCheckValue) {
    if(!qualityCheckValue.updated_at)
      qualityCheckValue.updated_at = new Date();
    let qcv = new this.QualityCheckValue(qualityCheckValue, this.QualityCheckValueStore.refs, angular.noop);
   // this.formatQCDate(updated_at);
  
  // qualityCheckValue = new Date();
 //  return moment(todaysdate).format('DD/MM/YY');
 
   //moment(this.todaysdate).format('DD/MM/YY');
    qcv.save((err, data) => {
      if(err) {
         this.$mdToast.show(this.$mdToast.simple()
          .textContent('Error updating quality check "'+ this.QualityCheckValue.name + '"')
          .position('bottom right'));
      }
    });
  }

  // formatQCDate(qualityCheckValue) {
  //   this.todaysdate = new Date();
  //   qualityCheckValue = new Date();
  //   return moment(todaysdate).format('DD/MM/YY');
    
  // }
}

(function(app) {
  app.controller('QualityCheckValueViewCtrl', QualityCheckValueViewCtrl);
}(angular.module('app.qualityCheckValueView')));