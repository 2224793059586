"use strict";

class ErrorHandler {

  constructor($mdDialog) {
    this.$mdDialog = $mdDialog;
  }

  /**
   * Pop up a dialog letting the user know how to resolve errors
   */
  showHelp() {
    const alert = this.$mdDialog.alert()
      .title('Having trouble?')
      .textContent('If you are experiencing an issue with this software, please contact the Project 42 helpdesk on (07) 903 5168, or send us a message at support@project42.nz.')
      .ok('Got it!');
    this.$mdDialog.show(alert);
  }
}

(function (app) {
  app.service('ErrorHandler', ErrorHandler);
}(angular.module('app.logging')));