'use strict';

// constants
// ten seconds in milliseconds
const TEN_SECONDS = 10000;

/**
 * Controller for the login
 */
class ResetCtrl {

  constructor(APP_NAME, ROOT_STATE, $rootScope, $scope, $location, $mdToast, $state, $stateParams, UserStore) {
    this.appName = APP_NAME;
    this.rootState = ROOT_STATE;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$location = $location;
    this.$mdToast = $mdToast;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.UserStore = UserStore;
  }

  /**
   * Checks that the passwords match each other
   */
  checkMatching() {
    this.$scope.resetForm.retype_password.$setValidity('match', this.retype_password === this.password);
  }

  /**
   * Attempt a basic login, and handle validation err
   */
  onResetClick() {
    // stop flashing the dashboard
    this.$rootScope.redirecting = true;
    this.resetting = true;
    this.UserStore.changePassword(this.$stateParams.token, this.password, (error) => {
      this.resetting = false;
      if (error) {
        // on error notify the user
        this.$mdToast.show(
          this.$mdToast.simple()
          .textContent(error)
          .hideDelay(TEN_SECONDS)
          .position('bottom right')
        );
      }
      // refresh the ready promise
      this.$rootScope.ready = new Promise((resolve, reject) => {
        resolve(this.$rootScope.User);
      });
      this.$state.go(this.rootState);
    });
  }
}


(function(app) {
  app.controller('ResetCtrl', ResetCtrl);
}(angular.module('app.userlogin')));
