'use strict';

class ManageSampleDialogCtrl{

	constructor($scope, $state, $mdToast, $mdDialog, $rootScope, $http, SampleStore) {
    this.$state = $state;
    this.$http = $http;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.user = $rootScope.User;
    this.SampleStore = SampleStore;
    this.hideSamples = false;

    if (this.job.labTests.length > 1) {
      //remove duplicate labtest
      this.job.labTests = _.uniqBy(this.job.labTests, '_id');
      this.job.labTests = this.job.labTests.sort((x,y) => {return x.dependencies.length - y.dependencies.length});
    }
    
    this.onTypeChange();
    
    this.back = () => {
        $mdDialog.hide();
    };

    
    this.pullError = false;

  }

  pulljob(){
    this.hideSamples = true;
    this.pulling = true;
    this.samples = [];
    this.$http({
      method: 'GET',
      url: '/api/TimberPortal/pullNewPenetrationSample/' + this.job.job_id,
    })
    .then((res) => {
        this.hideSamples = false;
        if (res.data.job === 'ok') {

          if (res.data.identifiers!==null && res.data.size!==null) {
            this.samplesIdentifiers = res.data.identifiers;
            let pulledSamples = res.data.identifiers.split(',');
            if (pulledSamples.length > res.data.size && pulledSamples.length > this.job.samples.length) {
              let newPenetrationSamples = pulledSamples.filter( name => name.indexOf('.') > 0);
              let retentionSamples = pulledSamples.filter( name => name.indexOf('.') < 0);
              
              this.numberOfSamples = newPenetrationSamples.length;
              this.updateSampleCount();
  
              if (this.job.samples.length === res.data.size) {
                for (let i=0;i<newPenetrationSamples.length;i++) {
                  this.samples[i].sample_num = Number(newPenetrationSamples[i].split('.')[0]);
                  let ref = Number(newPenetrationSamples[i].split('.')[1])-1;
                  this.samples[i].relation = this.job.samples[ref].id;
                }
              } else {
                this.pullError = true;
              }
            } else {
              this.pullError = true;
            }
            
          } else {
            this.pullError = true;
          }

        } else if (res.data.job === 'noJob') {
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent('No job found for T' + this.job.job_id)
              .position('bottom right')
          );
        }
        else if(res.data.switch === false) //Timber Sceapping is off 
        {
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent(res.data.message)
              .position('bottom right'));
        }

        this.returnMessage = "Sample Identifiers:  " + this.samplesIdentifiers;

    });
  }

  onTypeChange(){
    this.samples = [];
    this.numberOfSamples = 1;
    this.updateSampleCount();
  }
  
  updateSampleCount(){
    if (this.numberOfSamples > this.samples.length) {
      let i =0;
      while ( i < this.numberOfSamples - this.samples.length) {
        let newsample = {
          job: this.job.id,
          type: this.type,
          status: [],
          received: true,
          sample_num: this.job.samples.length +1 + this.samples.length,
        };
        this.samples.push(newsample);
      }
    } else if (this.numberOfSamples < this.samples.length){
      this.samples.splice(this.numberOfSamples, (this.samples.length - this.numberOfSamples));
    } else {
      return
    }
  }

  save(){
    this.saveLoading = true;
    for (let i=0;i<this.job.labTests.length;i++) {
      if (this.labtests[i]) {
        // this.sample.status.push({ labTest : this.job.labTests[i].id })
        this.samples.map((sample) => sample.status.push({ labTest : this.job.labTests[i].id, checked : true }));
      }
      else
        this.samples.map((sample) => sample.status.push({ labTest : this.job.labTests[i].id, checked : false  })); 
    }

    this.SampleStore.createMultiple(this.job.id, this.samples, (err) => {
      this.$state.reload();
      this.$mdDialog.hide();
    })
  };

}

(function (app) {
    app.controller('ManageSampleDialogCtrl', ManageSampleDialogCtrl);
  }(angular.module('app.job')));