'use strict';

const color = require('tinycolor2');

(function (app) {
  app.factory('Program', function ($http, $injector, $mdDialog, Resource) {
    return class Program extends Resource {
      constructor (data, metadata, callback) {
        super({ model: 'Program' }, data, metadata, callback);
      }

      isLight() {
        let programColor = color(this.color);
        return programColor.isLight();          
      }
    };
  });
}(angular.module('app.program')));
