'use strict';

/**
 * Controller for the Location edit view
 */
class LabLocationCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($scope, $state, UserStore, labLocation) {
    this.$state = $state;
    this.UserStore = UserStore;
    this.labLocation = labLocation;
    
    this.users = [];
    
    if (this.users.length === 0) {
      // if no users have been loaded then default the calibrator to myuser
      this.loadUsers();

      //this.UserStore.me((err, myuser) => {
      //  this.users.push(myuser);
      //  //this.labLocation.personnel = myuser.id;
      //});
    }

    // Cleanup handler
    $scope.$on('$destroy', () => {
      this.labLocation.revert();
    });
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.labLocation.save( (err) => {
      if (err) {
        // on error show the error
        console.error(err);
      } else {
        // on success redirect
        this.$state.transitionTo('labLocations');
      }
    });
  }

  /**
   * Loads the list of users
   */
  loadUsers() {
    this.UserStore.get({}, (err, users) => {
      this.users = users;
    });
  }

}

(function (app) {
  app.controller('LabLocationCtrl', LabLocationCtrl);
}(angular.module('app.labLocation')));
