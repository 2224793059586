'use strict';

(function (app) {
  app.factory('RunsheetTypeRow', function (Resource) {

    return class RunsheetTypeRow extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'RunsheetTypeRow' }, data, metaData, callback);
      }

      /**
       * Whether a row is a sample row (otherwise it is a control row)
       * @return {Boolean} True if the row is a sample row and false otherwise
       */
      isSampleRow() {
        return this.type === 'Sample';
      }
    };

  });
}(angular.module('app.runsheetTypeRow')));
