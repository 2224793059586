'use strict';

const sift = require('sift');

// define constants
const ABSENT = -1;

/**
 * Controller for the role edit view
 */
class RoleEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($scope, $mdDialog, $state, PermissionStore, permissions, programs, roleInfo) {
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.$state = $state;
    this.PermissionStore = PermissionStore;
    this.permissions = _.uniqBy(permissions, function (e) {
      return e.code;
    });;
    this.filteredPermissions = angular.copy( this.permissions);
    
    this.programs = programs;
    this.role = roleInfo;
    this.permissionIds = [];

    // console.log(this.role)
    this.selectedProgram = 'any';
    
  }

  /**
   * Adds the permission in the to be added select
   * @param {Object} $event Javascript event to link dialog to
   */
  addPermissionDialog($event) {
    if (this.selectedPermission.role && this.selectedPermission.role !== this.role._id) {
      // if the permission is assigned to another role then prompt
      const dialog = this.$mdDialog
        .confirm()
        .title('Move Permission?')
        .textContent(this.selectedPermission.name + ' will be moved from ' + this.selectedPermission.role.name + ' to ' + this.role.name)
        .ok('Okay')
        .cancel('Cancel')
        .targetEvent($event)

      this.$mdDialog
        .show(dialog)
        .then(this.addPermission.bind(this))
        .catch(this.deselectPermission.bind(this));
    } else {
      // if the permission can be added
      this.addPermission();
    }
  }

  /**
   * Adds the permission in the to be added select
   */
  addPermission() {
    this.permissionIds.push(this.selectedPermission._id);
    this.role.permissions.push(this.selectedPermission);
    this.deselectPermission();
  }

  /**
   * Returns the permission to add select to be blank
   */
  deselectPermission() {
    this.selectedPermission = null;
    this.onPermissionChange();
  }

  /**
   * Removes the permission from the role
   * @param {Object} permission Permission object to remove
   */
  removePermission(permission) {
    const index = this.permissionIds.indexOf(permission._id);
    this.permissionIds.splice(index, 1);
    this.role.permissions = this.role.permissions.filter(function(perm) {
      return perm._id != permission._id;
    });
  }

  /**
   * Called when the form submit button is clicked
   */
  submit() {
    this.submitting = true;
    this.role.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
        this.submitting = false;
      } else {
        // on success redirect
        this.redirect();
      }
    });
  }

  /**
   * Redirects to the parent state after the user is done with this one
   */
  redirect() {
    this.$state.go('roles');
  }

  /**
   * Called when the program filter changes
   */
  onProgramChange() {
    if (this.selectedProgram) {
      const query = {
        program: this.selectedProgram._id
      }
      
      this.filteredPermissions = sift(query, this.permissions);
    } else {
      this.filteredPermissions = this.permissions;
    }
  }

  /**
   * Called when the selected permission to be added changes
   * Displays any validation messages about the selected permission
   */
  onPermissionChange() {
    if (this.selectedPermission) {
      // if the permission is set
      this.$scope.form.permission.$setValidity('isIncluded', this.permissionIds.indexOf(this.selectedPermission._id) === ABSENT);
      this.$scope.form.permission.$setValidity('isTaken', !this.selectedPermission.role);
      if (this.selectedPermission.role !== this.role._id) {
        // if the permission is assigned to another role then load it
        this.PermissionStore._populate(this.selectedPermission, this.PermissionStore.refs, 'role', angular.noop);
      }
    } else {
      // if the permission is unset then hide the messages
      this.$scope.form.permission.$setValidity('isIncluded', true);
      this.$scope.form.permission.$setValidity('isTaken', true);
    }
  }
}

(function (app) {
  app.controller('RoleEditCtrl', RoleEditCtrl);
}(angular.module('app.role')));
