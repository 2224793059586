'use strict';

// npm modules
const _ = require('lodash');

/**
 * Controller for the certificate view view
 */
class CustomerContactCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($rootScope, $state, $stateParams, customer) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    // Setting up the resources for the individual form views
    this.customer = customer;

    // Set the initial tab selection
    if (this.customer._id) {
      // if the location does exist then set up the contacts
      this.selectedContact = $stateParams.contact;
      if (this.selectedContact === 'new') {
        // if the new contact is selected then select the last contact
        this.selectedContact = this.customer.locations[this.selectedLocation].contacts.length - 1;
      } else if (this.customer.locations 
          && this.customer.locations[this.selectedLocation] 
          && this.customer.locations[this.selectedLocation].contacts
          &&  !this.customer.locations[this.selectedLocation].contacts[this.selectedContact]) {
        // if the contact does not exist then set default
        this.selectedContact = 0;
      }
    }
  }

  changeContact(index = 'new') {
    if (index == 'new') {
      // if the contact does not exist then load it
      const baseData = {
        identifier: 'New Contact',
        customer: this.customer._id,
        active: true,
        all_programs: true,
        locations: [this.customer.locations[this.selectedLocation].id]
      };
      // Adding the default contact to locations
      if (!_.isArray(this.customer.locations[this.selectedLocation].contacts)) {
        this.customer.locations[this.selectedLocation].contacts = [baseData];
        this.selectedContact = 0;
      } else {
        this.customer.locations[this.selectedLocation].contacts.push(baseData);
        this.selectedContact = this.customer.locations[this.selectedLocation].contacts.length - 1;
      }
      
    } else {
      this.selectedContact = index;
    }

    console.log(this)


    this.$state.go('customers.edit.customer.location.contact', { contact: this.selectedContact }, { reload: 'customers.edit.customer.location.contact' });
    
    // stop the spinner showing because the customer and location will not reload
    this.$rootScope.spinner = false;
  }
 
}

(function (app) {
  app.controller('CustomerContactCtrl', CustomerContactCtrl);
}(angular.module('app.customer')));
 
