'use strict';


/**
 * Controller for the lab locations list view
 */
class RunsheetTypeListCtrl {

  constructor (RunsheetTypeStore, $rootScope, $state, $stateParams) {
    this.RunsheetTypeStore = RunsheetTypeStore;
    this.$rootScope = $rootScope;
    this.$state = $state;

    const qualityCheckMapper = (value) => {
      return _.uniqBy(value, '_id').map(qc => qc.name).join(', ');
    }

    this.populate = 'labTests qualityChecks_topLeft qualityChecks_topRight qualityChecks_bottomLeft qualityChecks_bottomRight';
    this.order = { name: 1 };
    this.tabs = [{
      name: 'Draft',
      query: { status : 0 },
      status: '0',
      slug: 'Draft'
    },{
      name: 'Approved',
      query: { status : 2 },
      status: '2',
      slug: 'Approve'
    },{
      name: 'Retired',
      query: { status : 4 },
      status: '4',
      slug: 'Retire'
    }];

    this.tabs.map((tab) => {
      tab.active = $stateParams.tab === tab.status;
      return tab;
    })

    this.fields = [
      {
        name: 'Name',
        path: 'name',
      },
      {
        name: 'Short Name',
        path: 'short_name',
      },
      {
        name: 'Version',
        path: 'version_number',
      },
      {
        path: 'labTests',
        name: 'Lab Tests',
        transform: (value) => {
          return _.uniqBy(value, '_id').map(lt => lt.unique_name).join(', ');
        }
      }, {
        path: 'qualityChecks_topLeft',
        tpl_header:'/qualityChecks_topLeft.html',
        transform: qualityCheckMapper
      }, {
        path: 'qualityChecks_topRight',
        tpl_header:'/qualityChecks_topRight.html',
        transform: qualityCheckMapper
      }, {
        path: 'qualityChecks_bottomLeft',
        tpl_header:'/qualityChecks_BottomLeft.html',
        transform: qualityCheckMapper
      }, {
        path: 'qualityChecks_bottomRight',
        tpl_header:'/qualityChecks_BottomRight.html',
        transform: qualityCheckMapper
      }, {
        path: 'status',
        name: 'Status',
        transform: (value) => {
          if (value === 0) {
            return 'Draft';
          } else if (value === 2) {
            return 'Approved'
          } else if (value === 4) {
            return 'Retired'
          }
        }
      }
    ];

  }

  onTabChange(tab) {
    this.$state.go(this.$state.current, { tab: tab.status }, { notify: false });
  }

  onClick(selected) {
    this.lastClicked = selected;
  }

  rowClicked(selected) {
    this.$state.go('runsheetTypes.edit', { id: selected.id });
  }

  removeRunsheetType(runsheetType) {
    runsheetType.remove(true);
  };

  /**
   * Change the approval status of a runsheet type, and assigns a new assigned user to the array.
   * @param {Object}  runsheetType  The runsheetType resource object.
   * @return {void}                 Changes the approval status of a runsheetType.
   */
  approveRunsheetType(runsheetType) {
    let user = this.$rootScope.User;
    let date_approval = new Date();

    runsheetType.approvals.push({
      user: user,
      date_approved: date_approval
    });
    runsheetType.isApproved = true;

    runsheetType.save((err, saved) => {
      if (err) {
        console.log(err);
      } else {
        console.log(saved);
      }
    });
  };
}

(function(app) {
  app.controller('RunsheetTypeListCtrl', RunsheetTypeListCtrl);
}(angular.module('app.runsheetType')));
