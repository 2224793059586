'use strict';

/**
 * Controller for the generic form section
 */
class FormSectionCtrl {
  
  constructor() {
    
  }

  /**
   * Set defaults for input values
   */
  $onInit() {
  }
}

(function(app) {
  app.controller('FormSectionCtrl', FormSectionCtrl);
}(angular.module('app.formView')));