'use strict';

(function (app) {
  app.factory('CustomerStore', function (Store, $http) {
    const CustomerStore =  new Store({
      model: 'Customer',
      realtime: false,
      ignoreMeta: true
    });

    CustomerStore.saveLocation = function (location, callback) {
      const id = location.id ? location.id : 'new';
      $http({
        method: 'POST',
        url: this.baseRoute + '/location/' + id,
        data: location
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };

    CustomerStore.signupCustomer = function (customer, callback) {
      const id = location.id ? location.id : 'new';
      $http({
        method: 'POST',
        url: '/api/Signup/new',
        data: customer
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };

    CustomerStore.saveContact = function (contact, callback) {
      const id = contact.id ? contact.id : 'new';
      $http({
        method: 'POST',
        url: this.baseRoute + '/contact/' + id,
        data: contact
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };

  /*
	 * Retrieves all locations - Used where you need to filter based on location, and
	 * mongoose population doesn't work. 
	 *
	 */
    CustomerStore.getLocations = function (callback = function () {}) {
      $http({
        method: 'POST',
        url: this.baseRoute + '/locations'
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };
    
	/*
	 * Retrieves all contacts from the HUB Access API.
	 */
    CustomerStore.getContacts = function (callback = function () {}) {
	  $http({
        method: 'POST',
        url: this.baseRoute + '/contacts'
      })
      .then(
        function (res) {
          callback(null, res.data);
        },
        function (res) {
          callback(res);
        }
      );
    };


    return CustomerStore;
  });

}(angular.module('app.customerClient')));
