'use strict';

/**
 * Component that controls the layout of the navbar
 *
 * All input is provided through the sidenav provider
 */
(function (app) {
  app.component('p42Sidenav', {
    restrict: 'E',
    controller: 'SidenavCtrl',
    controllerAs: 'vm',
    templateUrl: '/html/sidenav/sidenav.component.html'
  });
}(angular.module('app.sidenav')));
