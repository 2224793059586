'use strict';

(function (app) {
  app.factory('RunsheetTypeRowCellStore', function (Store) {
    const RunsheetTypeRowCellStore = new Store({
      model: 'RunsheetTypeRowCell'
    });

    return RunsheetTypeRowCellStore;
  });

}(angular.module('app.runsheetTypeRowCell')));
