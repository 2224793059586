'use strict';

const _ = require('lodash');
const async = require('async');

/**
 * Creates a WebUSB service that allows USB devices to be used
 * See https://wicg.github.io/webusb/ for more details about how to interact with device
 */
function WebUSB($rootScope, Statics) {

  // set the defaults to be the first one
  const DEFAULT_CONFIG = 1;
  const DEFAULT_INTERFACE = 2;
  const DEFAULT_ALTERNATE = 0;

  class WebUSB {

    constructor () {
      this.devices = {};
      // device currently being used by WebUSB
      this.device = null;

      this.connected = false;
      
      if (!navigator) {
        // if navigator is not found
        console.warn('Browser does not support WebUSB');
      } else if (!navigator.usb) {
        // if the usb API is not found
        console.warn('Browser does not support or is incorrectly configured for WebUSB');
      } else {
        // if the usb API was found
        if(_.isEmpty(this.devices)) {
          this.getDevices();
        }
      }      
    }

    /**
     * Get a list of devices that can be connected to
     * @return {void} Returns object of devices via callback
     */
    getDevices (callback = () => {}) {
      Statics.get('devices', (error, devices) => {
        // on error
        if (error) return callback(error);
        // on success
        // cache the devices because one will probably be called
        this.devices = devices;
        // return only the device names
        callback(error, Object.keys(devices));
      });
    }

    /**
     * Request to connect to a device given by getDevices
     * @param {String} name Name of the device to use
     * @param {Function} callback Returns in the format (error, devices)
     * @return {void} Returns via callback
     */
    requestDevice (name, callback = () => {}) {
      if (!this.devices[name]) {
        // if the device is not found then error
        return callback(new Error('Device ' + name + ' not defined'));
      }
      if (this.device) {
        // if a device is already in use then close it
        this.device.close();
      }
      // get the device via the WebUSB API
      navigator.usb.requestDevice({ filters: this.devices[name].filters }).then((device) => {
        device.open().then(() => {
          // use default configuration
          return device.selectConfiguration(DEFAULT_CONFIG);
        }).then(() => {
          // use default interface
          return device.claimInterface(DEFAULT_INTERFACE);          
        }).then(() => {
          // send a request to the device that we want to use it
          return device.controlTransferOut({
            // WebUSB request options
            'requestType': 'class',
            'recipient': 'interface',
            // type of request as interpreted by the device
            'request': 0x22,
            // possibly corresponds to the interface
            'value': 0x01,
            'index': 0x02});
        }).then(() => {
          // device is now setup to send/receive data
          this.device = device;
          this.connected = true;
          callback(null, device);
        }).catch(callback);
      }).catch(callback);
    }

    /**
     * Sends data to the device via a bulk transfer
     * @param {Object} WebUSB data object
     * @return {Promise} Returns promise that resolved with the reponse from the device
     */
    sendData (data) {
      return this.device.transferOut(4, data);
    }

    /**
     * Continually gets data streamed by the device via a bulk transfer
     * @return {void} Returns data via Angular broadcast emitter
     */
    getData() {
      async.forever(
        function getDataLoop(next) {
          // for each piece of data get the data from the endpoint
          this._getData('bulk', (err, data) => {
            if(err) return next(err);

            // return the result
            $rootScope.$broadcast('webusbDataIn', data);
            // get the next piece of data
            next();
          });
        }.bind(this),
        function getDataErrorHandler(err) {
          console.log(err);
        }
      );
    }
    
    /**
     * Get a single piece of data streamed by the device
     * @param {String} type Type of transfer to get data with
     * @param {Function} callback Returns the data string in the format (error, data)
     * @return {void} Returns via callback
     */
    _getData (type, callback) {
      const endpoint = this.getEndpoint('in', type);
      // specify the method to use
      let method = 'transferIn';
      if (type === 'control' || type === 'isochronous') {
        // if we are using a method other than default ('bulk' or 'interrupt') then use that method
        method = type + method[0].toUpperCase() + method.slice(1);
      }
      // do a transfer that matches the parameters
      this.device[method](endpoint.endpointNumber, endpoint.packetSize).then((result) => {
        // result still needs to be decoded so decode it
        const decoder = new TextDecoder();
        const ints = [];
        callback(null, decoder.decode(result.data));
      }).catch(callback);
    }
    
    /**
     * Gets the endpoint from the device
     * @param {String} direction Direction of transfer to get the endpoint for
     * @param {String} type Type of endpoint to use for transferring data
     * @return {Endpoint} Returns endpoint
     */
    getEndpoint (direction, type) {
      return this.device.configuration.interfaces[DEFAULT_INTERFACE].alternates[DEFAULT_ALTERNATE].endpoints.find(function findEndpoint(endpoint) {
        // find the endpoint that matches the transferIn function
        return endpoint.direction === direction && endpoint.type === type;
      });
    }
  }

  return new WebUSB();
}

(function (app) {
  app.factory('WebUSB', WebUSB);
}(angular.module('app.webusb')));
