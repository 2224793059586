'use strict';

(function (app) {
  app.factory('LabChemical', function ($http, $injector, $mdDialog, Resource) {
    return class LabChemical extends Resource {
      constructor (data, metaData, callback) {
        super('LabChemical', data, metaData, callback);
      }
    };
  });
}(angular.module('app.labChemical')));
