'use strict';

/**
 * Controller for the runsheet list view
 */
class RunsheetListCtrl {

  constructor ($state, $mdMedia, $stateParams, $filter, BatchStore, UserStore, preservatives) {
    this.$state = $state;
    this.$mdMedia = $mdMedia;
    this.BatchStore = BatchStore;
    this.UserStore = UserStore;
    
    this.userMap = new Map();
    this.UserStore.get({ subscriptions :'59597614354ec7917fdce9e3'},{ populate: 'contact'}, (err, users) => {
      users.forEach(user => {
        this.userMap.set(user.id,user.given_name+" " + user.surname);
      })
    })

    this.batchQuery = {"jobs.0":{$exists:true}};
    // this.populate = 'runsheets.complete_by.contact';
    this.order = {updateAt: -1};
    this.actionHeader = '/runsheetListActionHeader.html';
    this.fields = [{
      name: 'Jobs',
      tpl: '/runsheetListJobNumber.html',
    },{
      name: 'hidden Jobs for job number searching',
      hidden: true,
      transform: (record) => {
        if (record.jobs.length > 1) {
          return record.jobs.reduce((jobNumbers, currentJob) =>     
            jobNumbers + currentJob.number, // reducer function
            '' // initial accumulator value
          );
          
        } else if (record.jobs.length == 1) {
          return record.jobs[0].number;
        } else {
          return "";
        }
      }
        
    }, {
      path: 'updateAt',
      name: 'Run Created Date',
      transform: (value) => {
        return $filter('date')(value, 'dd/MM/yy H:mm');
      }
    }];

    this.tabs =  [{
      name: "Runsheets in Progress", 
      query: { complete : false,"jobs.0":{$exists:true}}, 
      status: 'incomplete'
    },{
      name: "Runsheets Approved", 
      query: { complete : true,"jobs.0":{$exists:true}}, 
      status: 'complete'
    }];
    this.tabs.map((tab) => {
      tab.active = $stateParams.tab === tab.status;
      return tab;
    })
   
  }

  onTabChange(tab) {
    this.$state.go(this.$state.current, { tab: tab.status }, { notify: false });
  }

}

(function(app) {
  app.controller('RunsheetListCtrl', RunsheetListCtrl);
}(angular.module('app.runsheet')));
