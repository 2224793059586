'use strict';

(function(app) {
  app.factory('RunsheetTypeLabTestStore', function() {
    let uselatestlabtest;

    var setlabteststatus = function(status) {
      uselatestlabtest = status;
    };

    var getlabteststatus = function() {
      return uselatestlabtest;
    };

    return {
    setlabteststatus: setlabteststatus,
    getlabteststatus: getlabteststatus
  };

  });

}(angular.module('app.runsheetType')));
