'use strict';

/**
 * Main app controller for App, exposed as 'app' in template scope
 */
class AppCtrl {

  /**
   * - Assigns injected variables to this
   * - Calls this.init()
   */
  constructor($rootScope, $state, $document, $timeout, $mdSidenav, $window, $location, $cookies, $http, $q, UserStore, SocketIO, $mdDialog, $mdToast, APP_NAME,APP_VERSION) {
    this._$state = $state;
    this._$timeout = $timeout;
    this._$mdSidenav = $mdSidenav;
    this._$window = $window;
    this._$location = $location;
    this._$rootScope = $rootScope;
    this._UserStore = UserStore;
    this._$mdDialog = $mdDialog;
    this._appName = APP_NAME;
    this._appVersion = APP_VERSION;
    $rootScope.dateFormat = "dd/MM/yy";
    $rootScope.timeFormat = "h:mm a";
    
    //Apply default theme - this drives swapping themes on the go in case of redirects from ION or COO
    // NOTE: At the time of programming dynamic theme updates all the programs are overwritting the 
        //  default angularjs material theme, in the future if theme names are changed to something other
        //  than default, the below code needs to be updated to point at that respective theme, perhaps need
        //  to drive it off APP_NAME
    $rootScope.theme = 'default';

    $rootScope.User = {};
    $rootScope.online = true;
    // TODO: remove from here into a provider/service
    
    $rootScope.showError = (error) => {
      if (error !== 'invalidtoken') {
        /* If the error is not simply because the user is not logged in then toast
         * This check stops toasts showing for a failed login
         */
        console.warn('General Error caught in core controller:',error);
        
        $mdToast.show(
          $mdToast.simple()
            .textContent(error)
            .position('bottom left')
            .capsule(true)
            .hideDelay(false)
            .toastClass('toastError')
            .action('Close')
            //.actionKey('c')
        );

        // give it time enough for node to restart a process, then flick to root, error message should stay displayed
        setTimeout(5000,()=>this._$window.location.href = '/');
        
      }
    };

    $rootScope.showWarning = (msg) => {
      $mdToast.show(
        $mdToast.simple()
          .textContent(msg)
          .position('bottom left')
          .capsule(true)
          .hideDelay(false)
          .toastClass('toastError')
          .action('Close')
          //.actionKey('c')
      );
    };

    $rootScope.showToast = (text) => {
      $mdToast.show(
        $mdToast.simple()
          .textContent(text)
          .position('bottom right')
      );
    }

    $rootScope.ready = new Promise((resolve, reject) => {
      // check the server for the user
      UserStore.myuser((err, myuser) => {
        // on error
        if (err) return reject(err);
        // on success
        resolve();
      });
    });

    $rootScope.toggleSidenav = this.toggleSidenav.bind(this);
    $rootScope.wrapResolve = this.wrapResolve.bind(this);

    /** @deprecated */
    $rootScope.navigateTo = this.navigateTo.bind(this);
    $rootScope.transitionTo = this.transitionTo.bind(this);

    // Setup loading spinner
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
      // when ui-router is starting to change wait for user check
      if ($rootScope.User.logged_in) {
        // if the user is logged in then show the spinner until the page loads
        $rootScope.spinner = true;
      } else if (toState.name.split('.')[0] !== 'login') {
        // if the user is not logged in and not being directed to login route then wait for the check
        event.preventDefault();
        $rootScope.ready
          .then((myuser) => {
            // if the user is logged in then resume default
            $state.go(toState, toParams);
          })
          .catch((error) => {            
            // if the user is not logged in then redirect silently to the login state
            $state.go('login', { ref: toState.name, toParams: toParams }, { location: false });
          });
      }
    });
    $rootScope.$on('$viewContentLoaded', function(event, toState, toParams, fromState, fromParams) {
      // when ui-router is finished changing and has rendered the view
      $rootScope.spinner = false;
      $rootScope.displaySideNav = true;
      if($state.current.name == "landing") {
        $rootScope.displaySideNav = false;
      }
    });
  }

  /**
   * Returns the current page title
   */
  getTitle() {
    return this._appName + ' - ' + this._parseStateName();
  }

  getVersion() {
    return this._appVersion;
  }

  _parseStateName() {
    const split = this._$state.current.name.split('.');
    const name = [];
    for (let i in split) {
      let n = split[i];
      n = n.charAt(0).toUpperCase() + n.slice(1);
      name.push(n);
    }
    return name.join(' - ');
  }

  /**
   * Helper function to navigate to a URL
   * @DEPRECATED Please use $window or $location directly
   * @param  {String} url    URL to navigate to
   * @param  {Boolean} clean  Whether to clean load the URL
   * @param  {Boolean} newTab Whether to open in a new tab
   */
  navigateTo(url, clean, newTab) {
    console.warn('navigateTo is deprecated, please use $window.location.href instead');
    if (this._$mdSidenav('sidenav').isOpen()) {
      this._$mdSidenav('sidenav').close();
    }

    if (newTab) {
      this._$window.open(url);
    } else if (clean) {
      this._$window.location.href = url;
    } else {
      this._$location.url(url);
    }
  }

  /**
   * @DEPRECATED Please use $state directly
   */
  transitionTo() {
    console.warn('transitionTo is deprecated, please use $state.go instead');
    if (this._$mdSidenav('sidenav').isOpen()) {
      this._$mdSidenav('sidenav').close();
    }

    this._$state.go.apply(this, arguments);
  }

  /**
   * Toggles the main App sidenav
   */
  toggleSidenav() {
    this._$mdSidenav('sidenav').toggle();
  }

  /**
   * Helper function used to wrap a ui-router resolve function in a promise
   * Will be deprecated when Universe Framework changes made for the new Hub are released
   * @param {Function} resolveFunction UI Router resolve injector function
   * @param {Boolean}  waitUntilReady  Waits until the authentication token has been received
   * @return {Promise} Returns a promise that will be resolved/rejected when the base promise is resolved/rejected which is evaluated once the app is ready
   *
   * In practice, waitUntilReady should only be false when used on public routes
   */
  wrapResolve(resolveFunction, waitUntilReady = true) {
    if (!waitUntilReady) {
      // if we do not need to wait for the authentication token then return early
      return new Promise(resolveFunction);
    }
    // if we do need to wait then wait until the authentication token has been received
    return this._$rootScope.ready.then(function() {
      // once the authentication is ready
      return new Promise(resolveFunction);
    }).catch(function(reason) {
      // when the promise is rejected
      return Promise.reject(reason);
    });
  }
}

(function (app) {
  app.controller('AppCtrl', AppCtrl);
}(angular.module('app')));
