'use strict';

/**
 * Controller for the permission edit view
 */
class PermissionEditCtrl {

  /**
   * Assigns injected variables to this
   */
  constructor($state, permission, programs) {
    this.$state = $state;
    this.permission = permission;
    this.programs = programs;
  }

  /**
   * Gets called when the form submit button is clicked
   */
  submit() {
    this.submitting = true;
    if (this.programs && this.programs.length > 0 && this.programs[0].name === 'Certificate of Origin'){
      this.permission.program = this.programs[0]._id;
    }
    this.permission.save((err) => {
      if (err) {
        // on error show the error
        console.error(err);
        this.submitting = false;
      } else {
        // on success redirect
        this.redirect();
      }
    });
  }

  /**
   * Redirects to the parent state after the user is done with this one
   */
  redirect() {
    this.$state.go('permissions');
  }
}

(function (app) {
  app.controller('PermissionEditCtrl', PermissionEditCtrl);
}(angular.module('app.permission')));
