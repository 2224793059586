'use strict';

(function (app) {
  app.factory('QualityCheckStore', function (Store, $http) {
    const QualityCheckStore = new Store({
      model: 'QualityCheck'
    });

    QualityCheckStore.localSearch= function(searchText, error, qualityChecks){
        $http.post('/api/QualityCheck/search',{searchText:searchText}).then(function(docs){
        qualityChecks(docs.data);
      }).catch(function(err){
        error(err)
      })
    }
    return QualityCheckStore;
  });


}(angular.module('app.qualityCheck')));
