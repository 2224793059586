'use strict';

(function (app) {
  app.factory('Sample', function (Resource) {

    return class Sample extends Resource {
      constructor (data, metaData, callback) {
        super({ model: 'Sample' }, data, metaData, callback);
      }

      mark (labTest_id, status, callback = angular.noop) {
        $http({
          method: 'PUT',
          url: '/api/Sample/' + this._id + '/mark/' + labTest_id + '/' + status
        })
        .then(
          function (res) {
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }

      findByJob (job_id, callback = angular.noop) {
        $http({
          method: 'GET',
          url: '/api/Sample/findByJob/' + job_id,
          data: this
        })
        .then(
          function (res) {
            callback(null, res.data);
          },
          function (res) {
            callback(res);
          }
        );
      }

      getStatus (labTest) {
        let status = _.find(this.status, function (s) {
          return s.labTest._id === labTest._id || s.labTest === labTest._id;
        });
        if (!status) {
          status = {
            labTest: labTest,
            received: true
          };
          if (!Array.isArray(this.status)) {
            // if the status is not defined then define it
            this.status = [];
          }
          this.status.push(status);
        }
        return status;
      }

      checkStatus (labTest) {
        return _.find(this.status, function (s) {
          return s.labTest._id === labTest._id || s.labTest === labTest._id;
        });
      }

      /**
       * Returns a Boolean indicating whether this Sample is marked as Received (for at least one labTest)
       * @return {Boolean}
       */
      isMarkedAsReceived () {
        for (let val of this.status) {
          if (val.received) {
            return true;
          }
        }
        return false;
      }
    };

  });
}(angular.module('app.sample')));
