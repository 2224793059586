'use strict';

(function (app) {
  app.config(function ($stateProvider) {

    $stateProvider.state('programs', {
      url: '/programs',
      views: {
        '@': {
          templateUrl: '/html/program/program-list.html',
          controller: 'ProgramListCtrl',
          controllerAs: 'vm',
          bindToController: true
        }
      },
      ncyBreadcrumb: {
        label: 'Programs'
      }
    });

    $stateProvider.state('programs.edit', {
      url: '/edit/:id',
      views: {
        '@': {
          templateUrl: '/html/program/program-edit.html',
          controller: 'ProgramEditCtrl',
          controllerAs: 'vm',
          bindToController: true,
          resolve: {
            program: function ($stateParams, ProgramStore, Program) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                if (!$stateParams.id) {
                  // if program does not exist create new program
                  ProgramStore.newResource({}, (program) => {
                    resolve(program);
                  });
                } else {
                  // if program exists then load it
                  ProgramStore.get({ _id: $stateParams.id }, { populate: 'logo background' }, (err, programs) => {
                    if (err || programs.length === 0) {
                      // on fail
                      reject(err || 'No program found');
                    } else {
                      // on success
                      resolve(programs[0]);
                    }
                  });
                }
              });
            },
            roles: function ($stateParams, RoleStore) {
              // ui-router requires a promise
              return new Promise((resolve, reject) => {
                RoleStore.get({}, {}, (err, roles) => {
                  if (err || roles.length === 0) {
                    // on fail
                    reject(err || 'No role found');
                  } else {
                    resolve(roles);
                  }
                });
              });
            }
          }
        }
      },
      ncyBreadcrumb: {
        label: 'Edit Program'
      }
    });

  });

  app.config(function (SidenavProvider) {
    SidenavProvider.addItem('Security Config', {
      title: 'Programs',
      subtitle: 'Configure programs',
      icon: '/icons/md/action/svg/design/ic_important_devices_24px.svg',
      state: 'programs',
      params: {},
      permission: 'program-edit',
      items: [{
        title: 'Add Program',
        icon: '/icons/md/content/svg/design/ic_add_24px.svg',
        state: 'programs.edit',
        params: { id: undefined },
        permission: 'program-make'
      }]
    });
  });

}(angular.module('app.program', [
  require('angular-material'),
  'app.core',
  'app.sidenav',
  'app.route',
  'app.statics'
])));
